import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Card,
  CardActions,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import theme from '../../styles/theme';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandCard = ({ title, children, isSliderDefault, titleFontSize }) => {
  const [expanded, setExpanded] = useState(isSliderDefault ? true : false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card elevation={0} sx={{ width: '100%' }}>
      <CardActions disableSpacing>
        <Typography
          fontSize={titleFontSize || '24px'}
          color={theme.palette.grey.black}
          gutterBottom
          sx={{
            top: 0,
            right: 0,
            textAlign: 'left',
          }}
        >
          {title}
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ marginRight: '10px' }}>
        {children}
      </Collapse>
    </Card>
  );
};
export default ExpandCard;
