import React from 'react';
import Buttons from '../Button';

export default function Card(props) {
  const { active, idea, index, onPress, setActive } = props;

  const handleClick = () => {
    setActive(idea);
    onPress(idea);
  };

  return (
    <Buttons
      id={`platform_${index}`}
      onClick={handleClick}
      variant={'pill'}
      size={'large'}
      {...(active === idea ? { disabled: true } : { disabled: false })}
    >
      {idea}
    </Buttons>
  );
}
