import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AlertToasterBanner from '../../components/AlertToasterBannner';
import Buttons from '../../components/Button';
import Exporter from '../../components/Exporter';

import ExportPortfolio from '../../components/MCS2/ExportPortfolio';
import TerritoryCard from '../../components/MCS2/TerritoryCard';
import TerritoryDetailsCard from '../../components/MCS2/TerritoryDetailsCard';
import TerritorySearch from '../../components/MCS2/TerritorySearch';
import WorkspaceArea from '../../components/MCS2/WorkspaceArea';
import { MCS2Context, MCS2DispatchContext } from '../../context/MCS2Context';
import { useMachineCreativeStudioV2 } from '../../hooks/useMachineCreativeStudioV2';
// import { hexCodes, imageArray } from '../../mocks/MCSV2Data';
import { MCSV2_ACTION_TYPES } from '../../reducers/mcsv2ActionTypes';
import theme from '../../styles/theme';
// import { display } from '@mui/system';

const StyledTab = styled('button')(({ theme }) => ({
  background: theme.palette.grey.white,
  border: 'none',
  flexGrow: 1,
  cursor: 'pointer',
  ...theme.typography.body3,
}));

const stylePortfolio = {
  cardWidth: '318px',
  cardHeight: '',
  descriptorsSize: '12px',
  descriptorsLineHeight: '22px',
  headerSize: '18px',
  headerLineHeight: '16px',
  typefaceSize: '12px',
  typefaceLineHeight: '14px',
  descriptionSize: '14px',
  descriptionLineHeight: '20px',
};

const WorkSpace = () => {
  const { territories, userId, currentProject, territoryData, portfolio } =
    useContext(MCS2Context);
  const dispatch = useContext(MCS2DispatchContext);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [displayedContent, setDisplayedContent] = useState(territories);
  const [savedPortfolio, setSavedPortfolio] = useState(portfolio);
  const [editedTerritory, setEditedTerritory] = useState();
  const [shouldClearPortfolios, setShouldClearPortfolios] = useState(false);
  const [settingsView, setSettingsView] = useState(false);
  const [editMode, setEditMode] = useState();
  const [selectedTerritories, setSelectedTerritories] = useState([]);
  const [selectedPortfolioTerritory, setSelectedPortfolioTerritory] = useState(
    []
  );
  const [selectedTerritoriesExport, setSelectedTerritoriesExport] = useState(
    []
  );
  const [iterateText, setIterateText] = useState('');
  const [blendText, setBlendText] = useState('');
  const [requestType, setRequestType] = useState('');
  const [showExportPortfolio, setShowExportPortfolio] = useState(false);
  const [territoryIndexToReplace, setTerritoryIndexToReplace] = useState();
  const { mutateAsync } = useMachineCreativeStudioV2();
  const [isNew, setIsNew] = useState(true);

  const tabRefs = useRef({});
  const territoriesRefs = useRef({});
  const territoryDetailRef = useRef(null);
  const [isAlert, setIsAlert] = useState(false);
  const [alertBanners, setAlertBanners] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [inspectsliderInputsText, setinspectSliderInputsText] = useState('');
  const [imageNotLoaded, setImageNotLoaded] = useState(true);
  const [exportPortfolio, setExportPortfolio] = useState(null);

  // initial ping for images retry logic
  useEffect(() => {
    getInitialContent();
  }, []);

  // update portfolio when saved portfolios change
  useEffect(() => {
    if (selectedTerritoriesExport.length > 0) {
      const selectedContent = Object.values(savedPortfolio).filter((obj) =>
        selectedTerritoriesExport.includes(obj.header)
      );
      setExportPortfolio(selectedContent);
    }
    setSavedPortfolio(portfolio);
  }, [portfolio, selectedTerritoriesExport]);

  // fetch images for portfolio territories on initial load
  useEffect(() => {
    loadPortfolioImages();
  }, []);

  const getInitialContent = async () => {
    // this function will only ever run to fetch the initial images and colors
    try {
      const { presignedUrlList, refColorsList } = await pingForMaterials([
        territories[0].id,
        territories[1].id,
      ]);

      let updatedTerritories = territories.map((territory, i) => ({
        ...territory,
        imageArray: presignedUrlList[i],
        colorPalette: refColorsList[i],
      }));

      dispatch({
        type: MCSV2_ACTION_TYPES.SET_IMAGE_ASSETS,
        payload: updatedTerritories,
      });

      // add new territories to displayedContent
      setDisplayedContent(updatedTerritories);
    } catch (e) {
      console.log('error getting initial content: ', e);
    }
  };

  const loadPortfolioImages = async () => {
    let portfolioWithImages = [];
    // fetch images one by one
    for await (const portfolioItem of portfolio) {
      const payload = {
        userId,
        projectId: currentProject.projectId,
        // list of territory IDs we are seeking materials for
        territoryIdList: [portfolioItem.id],
        requestType: 'getPSUwithColors',
      };
      try {
        const { presignedUrlList, refColorsList } = await mutateAsync(payload);
        const tempPortfolioItem = {
          ...portfolioItem,
          imageArray: presignedUrlList[0],
          colorPalette: refColorsList[0],
        };
        portfolioWithImages.push(tempPortfolioItem);
      } catch (e) {
        console.log(
          `Error loading content for portfolio territory ${portfolioItem.header}: `,
          e
        );
      }
    }

    // update portfolio with new images
    // savedPortfolio automatically changes when we update portfolio in context
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_PORTFOLIO,
      payload: portfolioWithImages,
    });
  };

  const giveMeMore = () => {
    setImageNotLoaded(true);
    // setup payload
    const payload = {
      userId,
      projectId: currentProject.projectId,
      projectName: currentProject.projectName,
      creativeIdea: territoryData.bigIdea,
      refImagery: territoryData.imagery,
      refMaterials: [], // presignedUrls,
      refTone: territoryData.tone,
      refColors: territoryData.colors,
      requestType: 'territoryGen',
    };
    setIsNew(false);
    fetchNewTerritories(payload);
  };

  const iterateAndBlend = (
    requestType,
    territoryId1,
    territoryId2,
    directionText,
    sliderValues,
    userInput,
    isNewFlag,
    sliderInputsText
  ) => {
    setImageNotLoaded(true);
    //this is for passing directionText to territorySearch
    if (requestType === 'iterateTerritory') {
      setIterateText(directionText);
    } else {
      setBlendText(directionText);
    }
    setRequestType(requestType);
    setinspectSliderInputsText(sliderInputsText);

    const payload = {
      userId,
      projectId: currentProject.projectId,
      territoryIdList:
        requestType === 'blendTerritory' || sliderValues
          ? [territoryId1, territoryId2]
          : [territoryId1],
      requestType,
      directionText,
      refineResults: sliderValues ?? '',
    };
    setIsNew(isNewFlag);
    fetchNewTerritories(payload);
  };

  const fetchNewTerritories = async (payload) => {
    try {
      setshowLoader(true);
      const result1 = await mutateAsync(payload);
      const result2 = await mutateAsync(payload);

      const territoryData = [
        {
          id: result1.territoryId,
          typeface: 'Tagline',
          header: result1.title,
          description: result1.caption,
          descriptors: 'Title',
          imageArray: [],
          colorPalette: [],
          morph: payload.requestType,
        },
        {
          id: result2.territoryId,
          typeface: 'Tagline',
          header: result2.title,
          description: result2.caption,
          descriptors: 'Title',
          imageArray: [],
          colorPalette: [],
          morph: payload.requestType,
        },
      ];

      // keep reference to currently displayedContent
      const currentContent = displayedContent;

      // update displayedContent with responses
      // this is the data without images or colors
      setDisplayedContent([...territoryData, ...displayedContent]);

      setshowLoader(false);
      setEditMode(null);

      // fetch the images and colors
      const { presignedUrlList, refColorsList } = await pingForMaterials([
        result1.territoryId,
        result2.territoryId,
      ]);

      // map the new images and colors to the new territories
      const territoriesWithImages = territoryData.map((territory, i) => ({
        ...territory,
        imageArray: presignedUrlList[i],
        colorPalette: refColorsList[i],
      }));

      // update the territories in context and set the displayedContent
      const allTerritories = [...territoriesWithImages, ...currentContent];
      setDisplayedContent(allTerritories);
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_TERRITORIES,
        payload: allTerritories,
      });
    } catch (e) {
      setshowLoader(false);

      console.log(`Error on request: ${payload.requestType}`, e);
    }
  };

  async function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const pingForMaterials = async (territoryList) => {
    await delay(50000);

    try {
      // setup payload
      const payload = {
        userId,
        projectId: currentProject.projectId,
        // list of territory IDs we are seeking materials for
        territoryIdList: territoryList,
        requestType: 'getPSUwithColors',
      };
      let retryCount = 0;
      let res = await mutateAsync(payload);
      while (
        retryCount < 5 &&
        (res.presignedUrlList[0].length == 0 || res.refColorsList.length == 0)
      ) {
        // if we are here, we got empty data
        // increment retryCount, wait 10 seconds, try again
        retryCount += 1;
        await delay(10000);
        res = await mutateAsync(payload);
      }

      // if retryCount is still less than 5, that means we got back the data that we needed
      if (retryCount < 5) {
        setImageNotLoaded(false);
        return res;
      } else {
        setImageNotLoaded(true);
        throw new Error('response from image fetch contained empty data');
      }
    } catch (e) {
      console.log('Error fetching images and colors: ', e);
      console.log('retry limit reached. using default images and colors');
      // update territories with default values
    }
  };

  const removeFromPortfolio = async (data) => {
    const tempPortfolio = savedPortfolio.filter((elm) => {
      return elm.id !== data.id && elm.type !== 'bigCard';
    });
    // network call to remove territory from portfolio on the backend
    updateProfileTerritories([data.id], 'delete');
    // update portfolio in context
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_PORTFOLIO,
      payload: tempPortfolio,
    });
  };

  const saveToPortfolio = (data) => {
    const doesExist = savedPortfolio.find((elm) => {
      return elm.id == data.id ? true : false;
    });

    if (!doesExist) {
      const tempPortfolioTerritories = [...savedPortfolio, data];

      // network call to save territory to portfolio on the backend
      updateProfileTerritories([data.id], 'save');
      //update portfolio in context
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_PORTFOLIO,
        payload: tempPortfolioTerritories,
      });
    }
  };

  const clearPortfolio = () => {
    // delete entire saved portfolio
    updateProfileTerritories(savedPortfolio, 'delete');
    //set portfolio in context to empty
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_PORTFOLIO,
      payload: [],
    });
    document.activeElement.blur();
    handleAlert();
  };

  // function to call backend with add/remove from portfolio
  const updateProfileTerritories = async (ids, action) => {
    const updatePayload = {
      userId,
      projectId: currentProject.projectId,
      territoryId: ids,
      action,
    };

    const profilePayload = {
      userId,
      projectId: '',
      territoryId: '',
      action: 'fetch',
    };

    try {
      await mutateAsync(updatePayload);

      // also need to update projects so portfolio is current
      const { projects } = await mutateAsync(profilePayload);
      // set projects to context
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_PROJECTS,
        payload: projects,
      });
    } catch (e) {
      console.log('error saving territory: ', e);
    }
  };

  const handleTabChange = (newValue) => {
    setSelectedIndex(newValue);

    if (selectedIndex === 2) {
      setSettingsView(0);
    }

    if (newValue == 1) {
      setSelectedPortfolioTerritory([]);
    }
  };

  const handleRemoveDetailsCard = (zone = null) => {
    let territoriesToClean = zone ? savedPortfolio : displayedContent;
    const cleanedContent = territoriesToClean.filter(
      (d) => d.type != 'bigCard'
    );
    if (zone) {
      setSavedPortfolio(cleanedContent);
      setSelectedPortfolioTerritory([]);
    } else {
      setDisplayedContent(cleanedContent);
      setSelectedTerritories([]);
      setEditedTerritory(null);
      setEditMode();
    }
  };

  const handleTDCOptionsClick = (data) => {
    switch (data) {
      case 'blend':
        setEditMode('blend');
        break;
      case 'iterate':
        setEditMode('iterate');
        break;
      case 'home':
        setEditMode('home');
        break;
      default:
        break;
    }
  };

  const handleExitStartOver = () => {
    dispatch({
      type: MCSV2_ACTION_TYPES.CHOSEN_WORKSPACE,
      payload: { workspace: '' },
    });
  };

  const showTerritoryDetail = (
    refinedData,
    actualIndex,
    elm = null,
    region = null
  ) => {
    if ((actualIndex + 1) % 2 == 0) {
      refinedData.splice(actualIndex + 1, 0, { type: 'bigCard' });
    } else {
      refinedData.splice(actualIndex + 2, 0, { type: 'bigCard' });
    }

    if (region) {
      setSavedPortfolio(refinedData);
      setSelectedPortfolioTerritory([elm]);
    } else {
      setDisplayedContent(refinedData);
      setEditedTerritory(displayedContent[actualIndex]);
    }
  };

  const handleTerritoryClick = (i, elm, region = null) => {
    const data = region ? savedPortfolio : displayedContent;
    const refinedData = data.filter((d) => d.type != 'bigCard');
    const actualIndex = refinedData.findIndex((territory) => {
      return territory.id === data[i].id;
    });

    if (territoriesRefs.current[i]) {
      if (region != null) {
        showTerritoryDetail(refinedData, actualIndex, elm, region);
      } else {
        const newSelectedTerritories = [...selectedTerritories];
        if (editedTerritory && editMode != null) {
          if (territoryIndexToReplace == 0) {
            newSelectedTerritories[0] = displayedContent[i];
          } else {
            newSelectedTerritories[1] = displayedContent[i];
          }
        } else {
          showTerritoryDetail(refinedData, actualIndex);

          newSelectedTerritories[0] = displayedContent[i];
        }
        setSelectedTerritories(newSelectedTerritories);
        setTerritoryIndexToReplace(null);
      }
    }
    setTimeout(() => {
      territoryDetailRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const showNextTerritory = (territory, region = null) => {
    const data = region
      ? savedPortfolio.filter((d) => d.type != 'bigCard')
      : displayedContent.filter((d) => d.type != 'bigCard');
    const realIndex = data.findIndex((d) => {
      return d.id == territory.id;
    });

    if (realIndex == data.length - 1) {
      region
        ? setSelectedPortfolioTerritory([data[0]])
        : setSelectedTerritories([data[0]]);
    } else {
      region
        ? setSelectedPortfolioTerritory([data[realIndex + 1]])
        : setSelectedTerritories([data[realIndex + 1]]);
    }
  };

  const showPreviousTerritory = (territory, region = null) => {
    const data = region
      ? savedPortfolio.filter((d) => d.type != 'bigCard')
      : displayedContent.filter((d) => d.type != 'bigCard');
    const realIndex = data.findIndex((d) => {
      return d.id == territory.id;
    });
    if (realIndex == 0) {
      region
        ? setSelectedPortfolioTerritory([data[data.length - 1]])
        : setSelectedTerritories([data[data.length - 1]]);
    } else {
      region
        ? setSelectedPortfolioTerritory([data[realIndex - 1]])
        : setSelectedTerritories([data[realIndex - 1]]);
    }
  };

  const renderSavePortfolios = () => {
    if (savedPortfolio.length > 0) {
      const portfolios = savedPortfolio.map((elm, i) => {
        if (elm?.type) {
          return (
            <Grid
              container
              className="territory_container"
              key={`details_${i}`}
              ref={(element) => (territoriesRefs.current[i] = element)}
            >
              <TerritoryDetailsCard
                key={'territoryDetails'}
                region="sidebar"
                territories={selectedPortfolioTerritory}
                handleOnBackClick={() => {
                  setEditMode(null);
                }}
                closeOnClick={() => handleRemoveDetailsCard('sidebar')}
                TDCOptionsOnClick={handleTDCOptionsClick}
                handleReplaceTerritoryClick={(data) => {
                  replaceTerritory(data);
                }}
                updatePortfolioClick={removeFromPortfolio}
                nextOnClick={(data) => {
                  showNextTerritory(data, 'sidebar');
                }}
                previousOnClick={(data) => {
                  showPreviousTerritory(data, 'sidebar');
                }}
                isEditing={false}
                isDisabled={false}
                savedPortfolio={savedPortfolio}
              />
            </Grid>
          );
        } else {
          const key = elm.header.split(' ').join('');
          return (
            <Grid item xs={6} key={`${key}_${i}`}>
              <Box
                className="portfolio_territory_container"
                key={`${key}_${i}`}
                ref={(element) => (territoriesRefs.current[i] = element)}
                sx={{ paddingLeft: 4 }}
              >
                <TerritoryCard
                  className="territory_card"
                  typeface={elm.typeface}
                  header={elm.header}
                  description={elm.description}
                  descriptors={elm.descriptors}
                  imageArray={elm?.imageArray}
                  colorPalette={elm.colorPalette}
                  handleClick={() => {
                    handleTerritoryClick(i, elm, 'sidebar');
                  }}
                  width={'318px'}
                  styleVariants={stylePortfolio}
                  handleTerritoryRemoveClick={() => {
                    removeFromPortfolio(elm);
                  }}
                  zone="sidebar"
                  isSelected={elm.id === selectedPortfolioTerritory[0]?.id}
                />
              </Box>
            </Grid>
          );
        }
      });
      return (
        <>
          <Grid container>{portfolios}</Grid>
        </>
      );
    }

    return (
      <Typography
        variant={'subtitle1'}
        component={'div'}
        sx={{ marginBottom: '1rem' }}
      >
        There is currently nothing saved in your Portfolio.
      </Typography>
    );
  };

  // should this also remove the territory from context?
  const removeTerritory = (i) => {
    const tempDisplayedContent = [...displayedContent];
    tempDisplayedContent.splice(i, 1);
    setDisplayedContent(tempDisplayedContent);
  };

  const replaceTerritory = (data) => {
    const replacedIndex = selectedTerritories.findIndex(
      (territory) => territory.header === data.header
    );
    setTerritoryIndexToReplace(replacedIndex);
  };

  const setIndex = (index) => {
    const tab = tabRefs.current[index];
    if (tab) {
      tab.focus();
      setSelectedIndex(index);
    }
  };

  const onKeyDown = (event) => {
    const count = 2;
    const nextTab = () => setIndex(selectedIndex + 1);
    const prevTab = () => setIndex(selectedIndex - 1);
    const firstTab = () => setIndex(0);
    const lastTab = () => setIndex(count - 1);

    const keyMap = {
      ArrowRight: nextTab,
      ArrowLeft: prevTab,
      Home: firstTab,
      End: lastTab,
    };

    const action = keyMap[event.key];
    if (action) {
      event.preventDefault();
      action();
    }
  };

  const handleExportPortfolioClick = () => {
    setShowExportPortfolio(!showExportPortfolio);
    document.activeElement.blur();
  };
  const handleAlert = () => {
    const alertList = [
      {
        open: true,
        alertType: 'success',
        alertTitle: 'Clear portfolio',
        alertMessage: 'Your portfolio is cleared successfuly.',
      },
    ];
    setIsAlert(true);
    setAlertBanners(alertList);
  };
  const handleCancelClick = () => {
    setShowExportPortfolio(!showExportPortfolio);
    document.activeElement.blur();
  };

  const renderAlerts = (
    <div
      style={{
        position: 'absolute',
        zIndex: '500',
        bottom: '220px',
        left: '50%',
      }}
    >
      <AlertToasterBanner
        {...alertBanners[0]}
        style={{ position: 'relative' }}
      />
    </div>
  );

  const deleteNewSearch = () => {
    const tempDisplayedContent = [...displayedContent];
    let idx = 2;
    while (tempDisplayedContent.length > 2) {
      tempDisplayedContent.splice(idx, 1);
      var left = tempDisplayedContent.length - idx;
      if (left == 0) idx = 1;
      else if (left == 1) idx = 0;
      else idx++;
    }
    setDisplayedContent(tempDisplayedContent);
    setIsNew(true);
  };
  return (
    <>
      {isAlert && renderAlerts}
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.blue,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
          paddingTop: '4rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            // maxWidth: '1440px',
            flexGrow: 1,
          }}
        >
          <Box
            className="side_bar"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflowY: 'hidden',
              background: theme.palette.grey.white,
              maxWidth: selectedIndex === 2 || settingsView ? '708px' : '382px',
              width: '100%',
              height: 'calc(100vh - 65px)',
              transition: '0.4s ease-in-out',
              position: 'absolute',
              zIndex: '200',
            }}
          >
            <Box
              className="tabs"
              sx={{
                boxShadow: '-2px 2px 12px 0px rgba(0, 0, 0, 0.08)',
                borderBottom: `${theme.palette.grey.light} solid 3px`,
                padding: '1rem 1rem 0',
                background: theme.palette.grey.white,
                position: 'absolute',
                width: '100%',
              }}
            >
              <Box
                role="tablist"
                className="tablist"
                aria-orientation="horizontal"
                sx={{
                  display: 'flex',
                  height: '48px',
                }}
              >
                <StyledTab
                  key={`tab-1`}
                  id="tab-1"
                  type="button"
                  role="tab"
                  aria-selected={selectedIndex === 1}
                  aria-controls="tabpanel-1"
                  onClick={() => {
                    handleTabChange(1);
                  }}
                  ref={(element) => (tabRefs.current[1] = element)}
                  onKeyDown={onKeyDown}
                  style={{
                    width: '50%',
                    color:
                      selectedIndex === 1
                        ? theme.palette.primary.main
                        : theme.palette.grey.black,
                  }}
                >
                  <span className="focus">Workspace</span>
                </StyledTab>
                <StyledTab
                  key={`tab-2`}
                  id="tab-2"
                  type="button"
                  role="tab"
                  aria-selected={selectedIndex === 2}
                  aria-controls="tabpanel-2"
                  tabIndex="-1"
                  onClick={() => {
                    handleTabChange(2);
                  }}
                  ref={(element) => (tabRefs.current[2] = element)}
                  onKeyDown={onKeyDown}
                  style={{
                    width: '50%',
                    color:
                      selectedIndex === 2
                        ? theme.palette.primary.main
                        : theme.palette.grey.black,
                    borderBottom:
                      selectedIndex === 2
                        ? '3px solid #0573ff'
                        : '3px solid #ffffff',
                  }}
                >
                  <span className="focus">
                    Portfolio{' '}
                    {savedPortfolio.length > 0
                      ? `(${
                          savedPortfolio.filter((d) => d.type != 'bigCard')
                            .length
                        })`
                      : '(0)'}
                  </span>
                </StyledTab>
              </Box>
            </Box>

            {selectedIndex === 2 ? (
              <Box
                className="tabContent"
                sx={{
                  width: '100%',
                  maxHeight: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: '2rem 0',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  overflowY: 'auto',
                  marginTop: '4rem',
                }}
              >
                {showExportPortfolio ? (
                  <ExportPortfolio
                    savedPortfolio={savedPortfolio}
                    onSelectedTerritoriesChange={(
                      selectedTerritoriesExport
                    ) => {
                      setSelectedTerritoriesExport(selectedTerritoriesExport);
                    }}
                  />
                ) : (
                  <>
                    {!shouldClearPortfolios && renderSavePortfolios()}
                    {shouldClearPortfolios && (
                      <Box>
                        <Typography
                          variant="h3"
                          component={'div'}
                          sx={{ marginBottom: '1rem' }}
                        >
                          Are you sure you want to clear your Portfolio?
                        </Typography>
                        <Typography variant="subtitle1" component={'span'}>
                          This action cannot be undone.
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                    margin: '1rem 1.5rem 3rem',
                    paddingBottom: '3rem',
                    borderTop: `solid 1px ${theme.palette.grey.light2}`,
                    paddingTop: '3rem',
                  }}
                >
                  {showExportPortfolio ? (
                    <>
                      <Buttons
                        variant="song"
                        color="secondary"
                        label="Cancel"
                        sx={{ paddingX: '24px', paddingY: '10px' }}
                        style={{ marginRight: '1.5rem' }}
                        onClick={handleCancelClick}
                      />

                      <Exporter
                        dataExported={exportPortfolio}
                        dataName={'MCSPortfolioTerritory'}
                        buttonOverwrite={{
                          label: `Export Selected Portfolio (${selectedTerritoriesExport.length})`,
                          variant: 'song',
                          sx: { paddingTop: 3.2 },
                          startIcon: null,
                        }}
                        pdfHeading={{
                          title: 'Machine Creative Studio V2',
                          subTitle: 'Selected Portfolio Territory',
                        }}
                        fileTypeRequest={{ pdf: 'pdf' }}
                        boxStyle={{ marginLeft: 0 }}
                      />
                    </>
                  ) : (
                    <>
                      <Buttons
                        variant="song"
                        disabled={!savedPortfolio?.length}
                        color="secondary"
                        label={
                          shouldClearPortfolios ? 'Go back' : 'Clear Portfolio'
                        }
                        sx={{ padding: '5px 12px', fontSize: '16px' }}
                        style={{ marginRight: '1.5rem' }}
                        onClick={() => {
                          if (showExportPortfolio) {
                            handleCancelClick();
                          }
                          setShouldClearPortfolios(!shouldClearPortfolios);
                          document.activeElement.blur();
                        }}
                      />

                      <Buttons
                        variant="song"
                        disabled={!savedPortfolio?.length}
                        color="primary"
                        label={
                          shouldClearPortfolios
                            ? 'Clear Portfolio'
                            : 'Export Portfolio'
                        }
                        sx={{ padding: '5px 12px', fontSize: '16px' }}
                        onClick={() => {
                          if (shouldClearPortfolios) {
                            clearPortfolio();
                            setShouldClearPortfolios(!shouldClearPortfolios);
                          } else {
                            handleExportPortfolioClick();
                          }
                        }}
                      />
                    </>
                  )}
                </Box>
              </Box>
            ) : (
              <WorkspaceArea
                generateMore={iterateAndBlend}
                exit={handleExitStartOver}
                setSettingsView={setSettingsView}
                settingsView={settingsView}
                imageNotLoaded={imageNotLoaded}
              />
            )}
          </Box>

          <Box
            className="main"
            sx={{
              width: '100%',
              height: 'calc(100vh - 64px)',
              marginLeft: '21rem',
              padding: '4rem 2rem 0 5rem',
              zIndex: '10',
              overflowY: 'auto',
            }}
          >
            <Box>
              <TerritorySearch
                territories={selectedTerritories}
                userInput={territoryData.bigIdea}
                isNew={isNew}
                inspectRefinementText={
                  isNew
                    ? 'None Provided'
                    : requestType === 'iterateTerritory'
                    ? iterateText
                      ? `"${iterateText}"`
                      : 'None Provided'
                    : blendText
                    ? `"${blendText}"`
                    : 'None Provided'
                }
                inspectSliderInputs={
                  inspectsliderInputsText ||
                  `Equal parts playful and professional, equal parts expected and unexpected, and equal parts abstract and concrete.`
                }
                selectedIndex={selectedIndex}
                settingsView={settingsView}
                iterateText={iterateText || 'None Provided'}
                type="territory"
                blendText={blendText}
                requestType={''}
                generateMore={giveMeMore}
                deleteNewSearch={deleteNewSearch}
                imageNotLoaded={imageNotLoaded}
              />
            </Box>

            <Grid
              container
              className="territories_space"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              overflowX={'scroll'}
            >
              {showLoader && (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  width
                  height
                  marginBottom="2rem"
                >
                  <CircularProgress />
                </Box>
              )}
              {displayedContent.map((elm, i) => {
                if (elm.type) {
                  return (
                    <Grid
                      item
                      className="territory_container"
                      key={`details_${i}`}
                      ref={(element) => (territoriesRefs.current[i] = element)}
                    >
                      <div ref={territoryDetailRef}></div>
                      <TerritoryDetailsCard
                        key={'territoryDetails'}
                        territories={selectedTerritories}
                        handleOnBackClick={() => {
                          setEditMode('');
                        }}
                        closeOnClick={() => handleRemoveDetailsCard()}
                        TDCOptionsOnClick={handleTDCOptionsClick}
                        handleReplaceTerritoryClick={(data) => {
                          replaceTerritory(data);
                        }}
                        editMode={editMode}
                        updatePortfolioClick={saveToPortfolio}
                        isEditing={!!editMode}
                        isDisabled={selectedIndex == 2}
                        nextOnClick={(data) => {
                          showNextTerritory(data);
                        }}
                        handleBlendIterateClick={iterateAndBlend}
                        previousOnClick={(data) => {
                          showPreviousTerritory(data);
                        }}
                        savedPortfolio={savedPortfolio}
                      />
                    </Grid>
                  );
                }
                const key = elm.header.split(' ').join('');
                return (
                  <Grid
                    item
                    md={6}
                    flexBasis={{ md: '34% !important' }}
                    className="territory_container"
                    key={`${key}_${i}`}
                    ref={(element) => (territoriesRefs.current[i] = element)}
                  >
                    <TerritoryCard
                      className="territory_card"
                      typeface={elm.typeface}
                      header={elm.header}
                      description={elm.description}
                      descriptors={elm.descriptors}
                      imageArray={elm?.imageArray}
                      colorPalette={elm.colorPalette}
                      handleClick={() => {
                        handleTerritoryClick(i, elm);
                      }}
                      handleTerritoryRemoveClick={() => {
                        removeTerritory(i);
                      }}
                      handleReplaceTerritoryClick={() => {
                        replaceTerritory(i);
                      }}
                      isDisabled={selectedIndex == 2}
                      isSelected={elm.id === selectedTerritories[0]?.id}
                      displayedContentLength={displayedContent.length}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        {/* 
            sidebar backgreound translucent grey
       */}
        <Box
          className="translucent_grey_background"
          sx={{
            position: 'absolute',
            display: (selectedIndex === 2 || settingsView) && 'block',
            flexDirection: 'row',
            height: 'calc(100vh - 64px)',
            width: '100%',
            flexGrow: 1,
            background:
              (selectedIndex === 2 || settingsView) &&
              theme.palette.opacity.clear6,
            zIndex: (selectedIndex === 2 || settingsView) && '100',
            visibility: selectedIndex === 2 || settingsView ? '1' : '0',
            transition: 'visibility 0.1s ease-in, background .3s ease-in',
          }}
        ></Box>
      </Box>
    </>
  );
};

export default WorkSpace;
