import { Grid, Stack } from '@mui/material';
import React, { useContext, useState } from 'react';
import Buttons from '../../components/Button';
import CNSChat from '../../components/CNS/CNSChat';
import CNSHero from '../../components/CNS/CNSHero';
import CNSStepper from '../../components/CNS/CNSStepper';
import FooterButton from '../../components/FooterButton';
import LoadingPage from '../../components/LoadingPage';
import { CNSContext, CNSDispatchContext } from '../../context/CNSContext';
import { useCustomerNeedsSpotter } from '../../hooks/useCustomerNeedsSpotter';
import { CNS_ACTION_TYPES } from '../../reducers/cnsActionTypes';
import theme from '../../styles/theme';

const footerLayout = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 80px 16px 10px',
  alignItems: 'center',
  height:'76px'
};

export default function CNSIntro() {
  const [isCreateContentBtnDisabled, setIsCreateContentBtnDisabled] =
    useState(true);
  const [chatLog, setChatLog] = useState([]);
  const [fileLog, setFileLog] = useState([]);
  const cnsHeroHeadings = {
    title: 'Map your market landscape',
    caption:
      'Customer Needs Spotter analyzes current market landscapes, identifies market trends, and builds insights for your target audience',
  };
  const cnsHeroImages = [
    'assets/images/shoe_facing_left.png',
    'assets/images/shoe_facing_right.png',
    'assets/images/hero-tiny-screnshot.png',
  ];
  const state = useContext(CNSContext);
  const dispatch = useContext(CNSDispatchContext);
  const { mutateAsync, isLoading } = useCustomerNeedsSpotter();

  const handleGenerateAnalysis = async () => {
    dispatch({
      type: CNS_ACTION_TYPES.SET_CHAT_HISTORY,
      payload: chatLog,
    });
    dispatch({
      type: CNS_ACTION_TYPES.SET_FILES_URLS,
      payload: fileLog,
    });
    let urlsArray = fileLog
      .filter((x) => x.type === 'link')
      .map((y) => y.fileName);
    let outputArray = chatLog[1].text.map((x) => {
      if (x === 'Insight & Trends') {
        return 'insights';
      }
      if (x === 'Mindsets') {
        return 'mindset';
      }
    });
    let analysisPayload = {
      sessionId: state.sessionID,
      url: urlsArray,
      qna: [
        {
          question: chatLog[2].text,
          answer: chatLog[3].text,
        },
        {
          question: chatLog[4].text,
          answer: chatLog[5].text,
        },
        {
          question: chatLog[6].text,
          answer: chatLog[7].text,
        },
      ],
      outputOptions: outputArray,
      flag_data_uploaded: fileLog.some((i) => i.type.includes('file')),
    };
    let rawResponse = await mutateAsync(analysisPayload);
    dispatch({
      type: CNS_ACTION_TYPES.SET_ANALYSIS_SUMMARY,
      payload: rawResponse?.message,
    });
    dispatch({
      type: CNS_ACTION_TYPES.SET_SHOW_IDEAS,
      payload: true,
    });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Grid
      container
      direction={'column'}
      sx={{
        margin: '0 auto',
        position: 'relative',
        top: '65px',
        maxWidth: '100%',
        height: 'auto',
        backgroundColor: theme.palette.secondary.blue,
        paddingBottom: '4rem',
      }}
    >
      <Grid
        item
        display="flex"
        alignItems="flex-start"
        sx={{
          backgroundColor: theme.palette.grey.white,
          minHeight:'376px',
          paddingLeft: '80px',
          paddingTop:'64px'
        }}
      >
        <CNSHero
          title={cnsHeroHeadings.title}
          caption={cnsHeroHeadings.caption}
          images={cnsHeroImages}
          doScroll={{
            animate: {
              visible: { scale: 1.2 },
              transistion: { ease: 'easeInOut', delay: 2 },
            },
            downElemId: 'genai-app-footer',
          }}
        />
      </Grid>
      <Grid
        item
        display="flex"
        alignItems="center"
        sx={{
          flexDirection: 'column',
          width: '100%',
          backgroundColor: theme.palette.secondary.blue,
          mixHeight: '760px',
          paddingTop:'58px',
          paddingBottom:'24px'
        }}
      >
        <CNSChat setChatLog={setChatLog} setFileLog={setFileLog} />
        <FooterButton style={{ left: 0 }}>
          <>
            <Stack
              className="footer-button-stack"
              spacing={3}
              direction="row"
              style={footerLayout}
            >
              <CNSStepper
                setIsCreateContentBtnDisabled={setIsCreateContentBtnDisabled}
              />
              <Buttons
                variant="song"
                sx={{ paddingX: '25px', paddingY: '12px' }}
                onClick={() => handleGenerateAnalysis()}
                disabled={isCreateContentBtnDisabled}
              >
                Generate Analysis
              </Buttons>
            </Stack>
          </>
        </FooterButton>
      </Grid>
      <span
        id="genai-app-footer"
        style={{ backgroundColor: theme.palette.secondary.blue }}
      >
        _
      </span>
    </Grid>
  );
}
