import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  FormControl,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { MCS2Context } from '../../context/MCS2Context';
import theme from '../../styles/theme';
import useFileUpload from '../../util/useFileUpload';
import Buttons from '../Button';
import AdvancedSetup from './AdvancedSetup';
import { UploadCard } from './DefaultUploadCard';
import UploadModal from './UploadModal';

const formStyle = {
  paddingBottom: '48px',
  marginBottom: '48px',
  borderColor: '#e7f2ff',
  borderBottom: '1px solid #c9d0d7',
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid ' + theme.palette.grey.main,
    fontSize: 16,
    padding: '18px 12px',
    background: '#FFF',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  width: '100%',
}));

const upload = 'assets/images/upload.svg';

const CreateTerritoryForm = ({
  addFiles,
  addFileKeys,
  projects,
  handleProjectChange,
  setBigIdea,
  handleProjectNameChange,
  setImagesIdea,
  setColorsIdea,
  setToneOfVoice,
}) => {
  const { userId } = useContext(MCS2Context);
  const projectSelect = ['Create a new project', ...projects];
  const [selectedProject, setSelectedProject] = useState('');
  const [advancedSetupExpanded, setAdvancedSetupExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [enter, setEnter] = useState(false);
  const [error, setError] = useState('');

  const { uploadedFiles, currentProgress, handleFilesUpload, fileKeys } =
    useFileUpload(userId);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    addFileKeys(fileKeys);
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      addFiles(uploadedFiles);
    }
  }, [uploadedFiles]);

  const onDrop = async (file) => {
    try {
      if (enter) {
        handleFilesUpload(file, true);
      } else {
        setEnter(true);
      }
    } catch (e) {
      console.log('There is an error in adding: ', e);
    }
  };

  const onDragEnter = () => {
    setEnter(true);
  };

  const onDragLeave = () => {
    setEnter(false);
  };

  const onDropRejected = () => {
    setError('This file type is not allowed.');
  };

  return (
    <>
      <Box className="form_container" sx={formStyle}>
        <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
          Select a Project
        </Typography>
        <FormControl sx={{ width: '50%' }}>
          <Select
            defaultValue="Select or create a Project"
            renderValue={() => {
              return selectedProject === ''
                ? 'Select or create a Project'
                : selectedProject;
            }}
            displayEmpty={true}
            labelId="project-label"
            onChange={(event) => {
              handleProjectChange(event.target.value);
              setSelectedProject(event.target.value);
            }}
            label="project"
            input={<OutlinedInput />}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {projectSelect.map((project) => {
              return (
                <MenuItem
                  className="genai-select-option"
                  key={project}
                  value={project}
                  sx={{ paddingY: 8 }}
                >
                  {project}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {selectedProject === projectSelect[0] ? (
          <Box sx={{ paddingTop: 8 }}>
            <Typography
              fontWeight={100}
              gutterBottom
              sx={{ marginBottom: 4 }}
              color={theme.palette.grey.dark}
            >
              PROJECT NAME
            </Typography>
            <BootstrapInput
              placeholder="Project Name"
              id="input-label-project-name"
              onChange={(event) => handleProjectNameChange(event.target.value)}
              sx={{
                '& .MuiInputBase-input': {},
              }}
            />
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Box sx={formStyle}>
        <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
          What's the Big Idea?
        </Typography>
        <Typography
          paragraph
          gutterBottom
          sx={{ marginBottom: 4, fontSize: 18 }}
        >
          Describe the vision for what you want your creative territories to
          convey?
        </Typography>
        <Box role="textbox" label="txtboxLabel">
          <BootstrapInput
            multiline
            maxRows={3}
            minRows={3}
            placeholder="(e.g., New Sky Airlines will begin flying from New York and Paris in June. We want to highlight the beauty of Paris and our award-winning customer service.)"
            id="input-label-description"
            onChange={(event) => setBigIdea(event.target.value)}
            style={{
              '::placeholder': {
                color: '#868B90',
                fontFamily: 'GraphikRegular',
                fontSize: 16,
                lineHeight: '28px',
              },
            }}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
          Reference Materials
        </Typography>
        <Typography
          paragraph
          gutterBottom
          sx={{ marginBottom: 4, fontSize: 18 }}
        >
          Upload images or documents, or select materials from your existing
          project (if selected) to help us understand your brand.
        </Typography>
      </Box>
      <Box>
        <Dropzone
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
          }}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDropRejected={onDropRejected}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: 'dropzone',
                onClick: (event) => event.stopPropagation()
              })}
              style={{ display: 'contents' }}
            >
              <input {...getInputProps()} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '0px',
                  border: `2px dashed ${theme.palette.primary.main}`,
                  borderRadius: '8px',
                }}
              >
                <Buttons
                  sx={{
                    height: '230px',
                    width: '100%',
                    paddingTop: 25,
                  }}
                  component="label"
                  onClick={
                    selectedProject === projectSelect[0]
                      ? () => fileInput.current.click()
                      : openModal
                  }
                  label={
                    <Box>
                      <Box>
                        <img src={upload} height={25} />
                      </Box>
                      <Typography
                        color={theme.palette.grey.black}
                        sx={{
                          paddingTop: 3,
                          display: 'inline-block',
                          paddingRight: 1,
                          fontSize: 16,
                        }}
                        inline
                      >
                        Drag and Drop or
                      </Typography>
                      <Typography
                        inline
                        sx={{
                          display: 'inline-block',
                          textDecoration: 'underline',
                          fontSize: 16,
                        }}
                      >
                        Select a File to Upload
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: theme.palette.error.main,
                        }}
                      >
                        {error}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </div>
          )}
        </Dropzone>
      </Box>
      <UploadModal
        open={open}
        closeModal={closeModal}
        handleFilesUpload={(event) => {
          handleFilesUpload(event);
        }}
        uploaded={uploadedFiles}
      />

      {uploadedFiles.length > 0 && (
        <>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: 5, marginTop: 5 }}
          >
            Materials Uploaded
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 5,
              flexWrap: 'wrap',
            }}
          >
            {uploadedFiles.map((file, index) => {
              const fileExtension = file.name;
              const fileType =
                fileExtension === 'jpg' || fileExtension === 'png'
                  ? 'image'
                  : 'other';
              return (
                <UploadCard
                  key={index}
                  fileType={fileType}
                  fileName={file.name}
                  fileSize={(file.size / 1024).toFixed(2) + ' KB'}
                  percent={currentProgress[index]}
                  isError={false}
                />
              );
            })}
          </Box>
        </>
      )}

      <Buttons
        onClick={() => {
          setAdvancedSetupExpanded(!advancedSetupExpanded);
        }}
        startIcon={advancedSetupExpanded ? <RemoveIcon /> : <AddIcon />}
        sx={{ paddingX: 2, marginTop: 5 }}
      >
        <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
          Advanced Setup Options
        </Typography>
      </Buttons>
      {advancedSetupExpanded && (
        <AdvancedSetup
          setImagesIdea={setImagesIdea}
          setColorsIdea={setColorsIdea}
          setToneOfVoice={setToneOfVoice}
        />
      )}
    </>
  );
};

export default CreateTerritoryForm;
