import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputBase,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  Typography
} from '@mui/material';
import { genders } from '../../types/applicationConstants';

import { styled } from '@mui/material/styles';

import theme from '../../styles/theme';

const ageMarks = [
  {
    value: 0,
    label: '0 yr',
  },
  {
    value: 100,
    label: '100 yr',
  },
];

const temperatureMarks = [
  {
    value: 0,
    label: 'Sticks to the book (Low)',
  },
  {
    value: 50,
    label: 'Occasionally goes off script (Medium)',
  },
  {
    value: 100,
    label: 'Total wild card (High)',
  },
];

const questionStyle = {
  paddingBottom: '48px',
};

const gutterStyle = {
  paddingBottom: '8px',
};

const ColorSlider = styled(Slider)({
  color: theme.palette.primary.dark,
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: 'translateX(0%)',
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: 'translateX(-100%)',
    marginLeft: '165px',
  },
  '& .MuiSlider-markLabel[data-index="2"]': {
    transform: 'translateX(-100%)',
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid ' + theme.palette.grey.main,
    fontSize: 16,
    padding: '18px 12px',
    background: '#FFF',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  width: '80%',
}));

const RAIntroForm = ({ setData, person, participantNameError }) => {
  const [ageRange, setAgeRange] = useState([0, 100]);
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState('');
  const [desc, setDescription] = useState('');
  const [temperature, setTemperature] = useState(0);
  const [location, setLocation] = useState('');
  const [specificAgeBool, setSpecificAgeBool] = useState(false);
  const [localDialectBool, setLocalDialectBool] = useState(false);

  const handleAgeChange = (event, newValue) => {
    setAgeRange(newValue);
    setData({
      ...person,
      ageRange: newValue,
    });
  };

  const handleAge = (event) => {
    setAge(event.target.value);
    setData({
      ...person,
      age: event.target.value,
    });
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setData({
      ...person,
      gender: event.target.value,
    });
  };

  const handleParticipantChange = (event) => {
    setData({
      ...person,
      participantName: event.target.value,
    });
  };

  const setDesc = (event) => {
    setDescription(event.target.value);
    setData({
      ...person,
      participantDescription: event.target.value,
    });
  };

  const handleTemperature = (event) => {
    setTemperature(event.target.value);
    setData({
      ...person,
      temperature:
        event.target.value == ''
          ? '0.2'
          : (event.target.value / 100).toFixed(1),
    });
  };

  const handleLocation = (event) => {
    setLocation(event.target.value);
    setData({
      ...person,
      location: event.target.value,
    });
  };

  const handleSpecificAgeBool = (event) => {
    setSpecificAgeBool(event.target.checked);
    setData({
      ...person,
      specificAgeBool: event.target.checked,
    });
  };

  const handleLocalDialectBool = (event) => {
    setData({
      ...person,
      localDialectBool: event.target.checked,
    });
  };

  return (
    <>
      <Box
        marginTop="2.5em"
        paddingBottom="2rem"
        borderBottom={'1px solid ' + theme.palette.grey.light}
      >
        <Box label="txtboxLabel" sx={{ paddingBottom: '50px' }}>
          <InputLabel htmlFor="participant-input" sx={{ marginBottom: '8px' }}>
            <Typography
              variant="h4"
              color={theme.palette.grey.black}
              sx={{ display: 'inline', width: 'auto' }}
              htmlFor="input-label-participant"
            >
              What would you like to refer to this participant by?
            </Typography>
            <Typography
              variant="inputLabel"
              sx={{
                fontFamily: 'GraphikRegular',
                color: theme.palette.error.main,
              }}
            >
              &nbsp;*Required
            </Typography>
          </InputLabel>

          <BootstrapInput
            placeholder="Participant 1"
            id="input-label-participant"
            onChange={handleParticipantChange}
            error={participantNameError}
            sx={{
              '& .MuiInputBase-input': {
                borderColor: participantNameError
                  ? theme.palette.error.main
                  : theme.palette.grey.main,
              },
            }}
          />
          {participantNameError && (
            <Typography variant="subtitle2" color="error">
              Please enter a participant name.
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'inline', marginBottom: '8px' }}>
            <Typography
              htmlFor="input-label-description"
              variant="h4"
              color={theme.palette.grey.black}
            >
              Tell us about this participant. Think about details like
              demographics, personality, motivations, level of education,
              occupation, and family status. You can write as much or as little
              as you like.
            </Typography>
            <span
              style={{
                color: theme.palette.grey.main,
                fontSize: '18px',
                lineHeight: '24px',
                fontFamily: 'GraphikRegular',
              }}
            >
              &nbsp;(optional)
            </span>
          </Box>

          <BootstrapInput
            multiline
            maxRows={4}
            minRows={4}
            placeholder="Example: 40 year old mother with 2 toddlers living in Los Angeles with her husband, mother in law and 2 dogs. She just got promoted to partner in her law firm, and wishes she had more time for gardening and hiking. She is extroverted and really enjoys meeting and talking to new people."
            id="input-label-description"
            onChange={setDesc}
            style={{
              '::placeholder': {
                color: '#868B90',
                fontFamily: 'GraphikRegular',
                fontSize: '20px',
                lineHeight: '28px',
              },
            }}
          />
        </Box>
      </Box>
      <Box
      //Temporary removed while vernacular feature is removed
      // marginTop="2.5em"
      // paddingBottom="2rem"
      // borderBottom={'1px solid ' + theme.palette.grey.light}
      // marginBottom="2rem"
      >
        <Box sx={questionStyle}>
          <FormGroup>
            {specificAgeBool ? (
              <>
                <Box sx={{ display: 'flex', marginBottom: '8px' }}>
                  <Typography
                    htmlFor="participant-input-age"
                    variant="inputLabel"
                    component="label"
                    color={theme.palette.grey.black}
                  >
                    Age
                  </Typography>
                  <Typography
                    variant="inputLabel"
                    sx={{
                      color: theme.palette.grey.main,
                      fontFamily: 'GraphikRegular',
                    }}
                  >
                    &nbsp;(optional)
                  </Typography>
                </Box>

                <BootstrapInput
                  value={age}
                  onChange={handleAge}
                  placeholder="Enter Age"
                  id="participant-input-age"
                  sx={{ width: '40%' }}
                />
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    marginBottom: '45px',
                    marginTop: '20px',
                  }}
                >
                  <Typography
                    htmlFor="input-label-ageRange"
                    variant="inputLabel"
                    component="label"
                    color={theme.palette.grey.black}
                  >
                    Age Range
                  </Typography>
                  <Typography
                    variant="inputLabel"
                    sx={{
                      color: theme.palette.grey.main,
                      fontFamily: 'GraphikRegular',
                    }}
                  >
                    &nbsp;(optional)
                  </Typography>
                </Box>
                <ColorSlider
                  id="input-label-ageRange"
                  color={'primary'}
                  value={ageRange}
                  onChange={handleAgeChange}
                  marks={ageMarks}
                  sx={{
                    '& .MuiSlider-markLabel[data-index="1"]': {
                      transform: 'translateX(-100%)',
                      marginLeft: '0',
                      fontStyle: 'italic',
                    },
                    '& .MuiSlider-markLabel[data-index="0"]': {
                      transform: 'translateX(-100%)',
                      marginLeft: '30px',
                      fontStyle: 'italic',
                    },
                  }}
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) => <div>{value + ' yr'}</div>}
                />
              </>
            )}

            <FormControlLabel
              style={{ marginTop: '24px' }}
              control={<Checkbox onChange={handleSpecificAgeBool} />}
              label="Define specific age"
            />
          </FormGroup>
        </Box>
        <Box sx={questionStyle}>
          <Box sx={{ display: 'flex', marginBottom: '8px' }}>
            <Typography
              htmlFor="gender-label"
              variant="inputLabel"
              component="label"
              color={theme.palette.grey.black}
            >
              Gender
            </Typography>
            <Typography
              variant="inputLabel"
              sx={{
                color: theme.palette.grey.main,
                fontFamily: 'GraphikRegular',
              }}
            >
              &nbsp;(optional)
            </Typography>
          </Box>

          <FormControl sx={{ width: '25%' }}>
            <Select
              defaultValue="Select gender"
              renderValue={() => {
                return gender === '' ? 'Select gender' : gender;
              }}
              displayEmpty={true}
              labelId="gender-label"
              value={gender}
              onChange={handleGenderChange}
              label="Gender"
              input={<OutlinedInput />}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {genders.map((gender) => {
                return (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={questionStyle}>
          <Box shrink="true" sx={{ display: 'flex', marginBottom: '8px' }}>
            <Typography
              htmlFor="participant-input-age"
              variant="inputLabel"
              component="label"
              color={theme.palette.grey.black}
            >
              Location
            </Typography>
            <Typography
              variant="inputLabel"
              sx={{
                color: theme.palette.grey.main,
                fontFamily: 'GraphikRegular',
              }}
            >
              &nbsp;(optional)
            </Typography>
          </Box>

          <BootstrapInput
            value={location}
            onChange={handleLocation}
            placeholder="Enter Location"
            id="participant-input"
          />
        </Box>
        <Box sx={questionStyle}>
          <Box
            sx={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}
          >
            <Typography
              htmlFor="input-label-temperature"
              variant="inputLabel"
              component="label"
            >
              Temperature and Response Variability
            </Typography>
            <Typography
              variant="inputLabel"
              sx={{
                color: theme.palette.grey.main,
                fontFamily: 'GraphikRegular',
              }}
            >
              &nbsp;(optional)
            </Typography>
          </Box>
          <ColorSlider
            id="input-label-temperature"
            value={temperature}
            onChange={handleTemperature}
            defaultValue={0}
            aria-label="Default"
            marks={temperatureMarks}
            sx={{
              markLabel: {
                '&[data-index="0"]': {
                  marginLeft: '100px',
                },
              },
            }}
          />
        </Box>
        <Box
          marginTop="1rem"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1.5rem',
          }}
        ></Box>
      </Box>
      {
        // Temporarily removed for the time being
        /* <Box marginBottom={5} sx={{ display: 'flex', justifyContent: 'left' }}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox onChange={handleLocalDialectBool} />}
          label="Generate answers in local dialect and vernacular"
        />
        </FormGroup>
        <IconButton>
          <SVGIcon
            name={'info'}
            color={theme.palette.primary.main}
            aria-label="info"
          />
        </IconButton>
      </Box> */
      }
    </>
  );
};

export default RAIntroForm;
