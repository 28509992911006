import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import theme from '../../styles/theme';
import Buttons from '../Button';
import OutlinedAdd from '../icons/outlined-add';
import ParticipantCard from './ParticipantCard';

const TextInputBarRA = ({ people, addUser, deleteUser, navigation }) => {
  const backToLandingClick = () => {
    navigation();
  };

  const AddParticipant = () => {
    addUser(true);
  };

  const handleDelete = (uuid) => {
    deleteUser(uuid);
  };

  return (
    <Box
      className="bar"
      sx={{
        maxWidth: '100%',
        backgroundColor: '#FFF',
        height: '100vh',
        boxShadow: '0px 4px 40px rgba(30, 30, 30, 0.1)',
        padding: '30px 40px 120px 40px',
        flexBasis: '33.33%',
        width: '492px',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: '100',
        boxSizing: ' border-box',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
      }}
    >
      <Buttons
        variant={'link-light'}
        sx={{
          textDecoration: 'underline',
        }}
        onClick={backToLandingClick}
        startIcon={<img src="/assets/back_arrow.svg" alt="back" />}
        label={'Back to landing page'}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1" fontSize={24} marginTop="10px">
          Participant
        </Typography>
        {
          <Typography variant="p" fontSize={12}>
            {people.length}/3
          </Typography>
        }
      </Box>

      <Box marginBottom={5} marginTop={2} borderTop="1px solid #ccc"></Box>
      {people.map((person) => {
        return (
          <ParticipantCard
            key={`${person.age}+${person.participantName.split(' ')[0]}`}
            {...person}
            handleDelete={() => handleDelete(person.uuid)}
          />
        );
      })}

      {/* Add participant button */}
      {people.length < 3 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
            boxShadow: '0px 4px 40px rgba(30, 30, 30, 0.1)',
            border: `1px dashed ${theme.palette.primary.main}`,
            borderRadius: '8px',
            height: '130px',
          }}
        >
          <Buttons
            onClick={() => {
              AddParticipant();
            }}
            startIcon={<OutlinedAdd />}
            label={
              <Typography color={theme.palette.grey.black}>
                Add Participant
              </Typography>
            }
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            borderRadius: '8px',
            backgroundColor: '#E3E9EF59',
            height: '130px',
            width: '100%',
            color: '#868B90',
            gap: '3px',
          }}
        >
          <ErrorOutlineOutlinedIcon sx={{ width: 23, height: 23 }} />
          <Typography variant="p" fontWeight={500}>
            Maximum of 3 Participants
          </Typography>
          <Typography variant="p" fontSize={13}>
            Please delete a participant to add a new
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TextInputBarRA;
