import React from 'react';
import Lottie from 'lottie-react';
import animation from '../assets/animations/data.json';

const Animation = () => {
  return (
    <Lottie animationData={animation} loop={true} style={{ height: '80px' }} />
  );
};

export default Animation;
