import { Box, Button, TextField, Typography } from '@mui/material';
import { Storage } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { CNSContext } from '../../context/CNSContext';
import theme from '../../styles/theme';
import SVGIcon from '../SVGIconAnimate';

const CNSFileUpload = ({
  setFileLog,
  showText,
  isEdit,
  generatedFiles,
  removeAllFiles,
  setUndoAllFiles,
}) => {
  const [filesHistory, setFilesHistory] = useState([]);
  const state = useContext(CNSContext);
  const [showUploadBox, setShowUploadBox] = useState(false);
  const [uploadType, setUploadType] = useState('');
  const [enter, setEnter] = useState(false);
  const [error, setError] = useState('');
  const [linkText, setLinkText] = useState('');
  const [uniqueFileIdentity, setUniqueFileIdentity] = useState(null);

  useEffect(() => {
    if (generatedFiles && generatedFiles.length > 0) {
      setFilesHistory(generatedFiles);
    }
  }, [generatedFiles]);

  const onDrop = async (acceptedFiles) => {
    if (!enter) {
      setEnter(true);
    }
    let tempArr = [...filesHistory];
    try {
      for (let index = 0; index < acceptedFiles.length; index++) {
        await Storage.put(
          state.sessionID + acceptedFiles[index].name,
          acceptedFiles[index],
          {
            contentType: acceptedFiles[index].type,
          }
        );
        tempArr.push({
          fileName: acceptedFiles[index].name,
          type: 'file',
          isUndoAble: isEdit,
        });
      }
      setFilesHistory(tempArr);
      setFileLog(tempArr);
      setShowUploadBox(false);
    } catch (e) {
      console.log('There is an error in adding: ', e);
    }
  };

  const onDragEnter = () => {
    setEnter(true);
  };

  const onDragLeave = () => {
    setEnter(false);
  };

  const onDropRejected = () => {
    setError('This file type is not allowed.');
  };

  const deleteFile = async (filename, type) => {
    try {
      if (type === 'file') {
        const result = await Storage.remove(state.sessionID + filename);
      }
      let temp = filesHistory.filter((x) => {
        return x.fileName !== filename;
      });
      setFilesHistory(temp);
      setFileLog(temp);
      setUniqueFileIdentity(null);
    } catch (e) {
      console.log('Deleting file from S3 error: ', e);
    }
  };

  const uploadLink = () => {
    let tempArr = [...filesHistory];
    tempArr.push({
      fileName: linkText,
      type: 'link',
      isUndoAble: isEdit,
    });
    setFilesHistory(tempArr);
    setFileLog(tempArr);
    setShowUploadBox(false);
    setLinkText('');
  };

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return !!urlPattern.test(urlString);
  };

  const handlErrorState = (linkText) => {
    if (
      (linkText.trim().length > 0 && isValidUrl(linkText)) ||
      linkText.trim().length == 0
    ) {
      return true;
    }
  };

  useEffect(() => {
    let tempFilesHistoryArr = [...filesHistory];
    if (removeAllFiles) {
      let temp = tempFilesHistoryArr.filter((x) => {
        return x.isUndoAble !== false;
      });
      setFileLog(temp);
      setFilesHistory(temp);
      setUndoAllFiles(false);
    }
  }, [removeAllFiles]);

  return (
    <Box>
      {!showText && (
        <>
          <Typography
            marginTop={'8px'}
            variant="h4"
            fontSize={18}
            lineHeight={'24px'}
            fontFamily={'Graphik'}
            fontStyle={'normal'}
            fontWeight={600}
            color={theme.palette.grey.black}
            gutterBottom
          >
            Add Data Source
          </Typography>
          <Box display="flex" marginTop="15px">
            <Typography
              fontFamily={'GraphikSemiboldItalic'}
              fontWeight={600}
              color={theme.palette.grey.dark}
              fontSize={14}
            >
              Tip:&nbsp;
            </Typography>
            <Typography
              fontFamily={'GraphikRegularItalic'}
              fontWeight={400}
              color={theme.palette.grey.dark}
              fontSize={14}
            >
              Adding data sources can help us generate more informed analyses.
            </Typography>
          </Box>
        </>
      )}
      {!isEdit && (
        <Box display="flex" justifyContent="space-between" margin="15px 0">
          <Button
            onClick={() => {
              setShowUploadBox(true);
              setUploadType('file');
            }}
            startIcon={<img src="/assets/images/upload_pin.svg" alt="Upload" />}
            sx={{ fontSize: 16, paddingTop: 0.1, textDecoration: 'underline' }}
          >
            Upload Document
          </Button>
          <Button
            onClick={() => {
              setShowUploadBox(true);
              setUploadType('link');
            }}
            startIcon={<img src="/assets/images/link_pin.svg" alt="Link" />}
            sx={{ fontSize: 16, paddingTop: 0.1, textDecoration: 'underline' }}
          >
            Insert Link
          </Button>
        </Box>
      )}
      {showUploadBox &&
        !isEdit &&
        (uploadType === 'file' ? (
          <Box
            sx={{
              display: 'flex',
              padding: '24px 82px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
              alignSelf: 'stretch',
              background: theme.palette.grey.white,
              backgroundImage: `linear-gradient(to right, ${theme.palette.blue.link1} 50%, rgba(255, 255, 255, 0) 50%), linear-gradient(${theme.palette.blue.link1} 50%, rgba(255, 255, 255, 0) 50%), linear-gradient(to right, ${theme.palette.blue.link1} 50%, rgba(255, 255, 255, 0) 50%), linear-gradient(${theme.palette.blue.link1} 50%, rgba(255, 255, 255, 0) 50%)`,
              backgroundPosition: 'top, right, bottom, left',
              backgroundRepeat: 'repeat-x, repeat-y',
              backgroundSize: '30px 2.2px,2px 30px',
            }}
          >
            <Dropzone
              accept={{ 'text/pdf': ['.pdf'] }}
              onDrop={onDrop}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDropRejected={onDropRejected}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{ display: 'contents' }}
                >
                  <input {...getInputProps()} />
                  <Box
                    sx={{ fontSize: '14px', color: theme.palette.blue.link1 }}
                  >
                    <img
                      src="/assets/images/upload-icon-blue.svg"
                      alt="Upload"
                      style={{ marginRight: '10px', marginBottom: '-7px' }}
                    />
                    Drag and drop your file
                  </Box>
                  <Button
                    sx={{
                      display: 'flex',
                      padding: '8px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      borderRadius: '8px',
                      background: theme.palette.secondary.blue,
                      color: theme.palette.blue.link1,
                      fontSize: '14px',
                    }}
                  >
                    Choose file
                  </Button>
                  <Typography
                    sx={{ fontSize: '14px', color: theme.palette.error.main }}
                  >
                    {error}
                  </Typography>
                </div>
              )}
            </Dropzone>
          </Box>
        ) : (
          <Box
            sx={{
              p: '10px 0px 10px 10px',
              display: 'flex',
              alignItems: 'center',
              border: '1px solid',
              borderColor: theme.palette.grey.light2,
              borderRadius: '8px',
            }}
          >
            <TextField
              sx={{
                ml: 1,
                p: 1,
                flex: 1,
                width: '100%',
                '& input::placeholder': { fontSize: '14px' },
                '& input': { fontSize: '14px' },
              }}
              variant="standard"
              placeholder={'Enter website url'}
              onChange={(e) => {
                setLinkText(e.target.value);
              }}
              error={handlErrorState(linkText) ? false : true}
              helperText={
                handlErrorState(linkText) ? '' : 'Please enter valid url'
              }
              value={linkText}
            />
            <Button
              sx={{ p: '10px' }}
              startIcon={<img src="../../assets/send-icon.svg" alt="send" />}
              aria-label="send"
              onClick={uploadLink}
              disabled={isValidUrl(linkText) ? false : true}
            />
          </Box>
        ))}
      {filesHistory.map((each, idx) => {
        return (
          <>
            <Box
              key={`file_${idx}`}
              sx={{
                display: 'flex',
                padding: '16px',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: theme.palette.grey.light,
                margin: '10px',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                {each?.type === 'file' ? (
                  <img src="/assets/images/document_icon.svg" alt="File" />
                ) : (
                  <img src="/assets/images/link_icon_black.svg" alt="Link" />
                )}
                <Typography
                  fontFamily={'GraphikRegularItalic'}
                  fontSize={14}
                  lineHeight={'20px'}
                  color={theme.palette.grey.black}
                  sx={{ marginLeft: '10px', wordBreak: 'break-word' }}
                >
                  {each?.fileName}
                </Typography>
              </Box>
              {!isEdit && (
                <Button
                  onClick={() => setUniqueFileIdentity(idx)}
                  startIcon={
                    <SVGIcon
                      name="trash-empty"
                      color={
                        uniqueFileIdentity !== null &&
                        idx === uniqueFileIdentity
                          ? theme.palette.grey.light3
                          : theme.palette.grey.black
                      }
                    />
                  }
                  disabled={
                    uniqueFileIdentity !== null && idx === uniqueFileIdentity
                  }
                  aria-label="delete"
                />
              )}
            </Box>
            {uniqueFileIdentity !== null && idx === uniqueFileIdentity && (
              <Box
                sx={{
                  display: 'flex',
                  padding: '16px',
                  background: theme.palette.grey.light1,
                  margin: '-10px 10px 10px 10px',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  fontFamily={'GraphikRegularItalic'}
                  fontSize={14}
                  color={theme.palette.grey.black}
                  sx={{ marginLeft: '10px' }}
                >
                  Are you sure you want to delete? Files deleted cannot be
                  restored.
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Button
                    onClick={() => setUniqueFileIdentity(null)}
                    startIcon={
                      <img src="/assets/images/cross_icon.svg" alt="cross" />
                    }
                    aria-label="no"
                  />
                  <Button
                    onClick={
                      each.type === 'file'
                        ? () => deleteFile(each.fileName, 'file')
                        : () => deleteFile(each.fileName, 'link')
                    }
                    startIcon={
                      <img src="/assets/images/check_icon.svg" alt="check" />
                    }
                    aria-label="yes"
                  />
                </Box>
              </Box>
            )}
          </>
        );
      })}
    </Box>
  );
};

export default CNSFileUpload;
