import { RA_ACTION_TYPES } from './raActionTypes';

export const INITIAL_RA_STATE = {
  isIntro: true,
  avatars: ['avatar0.svg', 'avatar1.svg', 'avatar2.svg'],
  participants: [],
};

export const raReducer = (state, action) => {
  switch (action.type) {
    case RA_ACTION_TYPES.CONTINUE_FROM_INTRO:
      return {
        ...state,
        isIntro: false,
      };
    case RA_ACTION_TYPES.UPDATE_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload,
      };
    case RA_ACTION_TYPES.RESET_STEPS:
      return {
        ...state,
        isIntro: true,
      };
    case RA_ACTION_TYPES.UPDATE_AVATAR_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
