import React, { useReducer, useState } from 'react';
import { GCEContext, GCEDispatchContext } from '../context/GCEContext';
import { INITIAL_GCE_STATE, gceReducer } from '../reducers/gceReducer';
import GCEIdeas from '../views/GCE/GCEIdeas';
import GCEIntro from '../views/GCE/GCEIntro';

export default function GCE() {
  const [state, dispatch] = useReducer(gceReducer, INITIAL_GCE_STATE);
  const [showIdeas, setShowIdeas] = useState(false);

  return (
    <>
      <GCEContext.Provider value={state}>
        <GCEDispatchContext.Provider value={dispatch}>
          {showIdeas ? <GCEIdeas /> : <GCEIntro setShowIdeas={setShowIdeas} />}
        </GCEDispatchContext.Provider>
      </GCEContext.Provider>
    </>
  );
}
