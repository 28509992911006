import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import theme from '../../styles/theme';
import { ImageBox } from '../ImageBox';

const FacebookMock = ({ imgDesc, imgSrc }) => {
  return (
    <Box
      sx={{
        width: 300,
        height: '100%',
        border: `0.5px solid ${theme.palette.grey.light}`,
        backgroundColor: theme.palette.primary.white,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <Box
          sx={{
            width: 35,
            height: 35,
            backgroundColor: theme.palette.grey.light,
            marginRight: 2,
            borderRadius: '100%',
          }}
        ></Box>
        <Typography variant="subtitle1" fontSize={14}>
          Emotional AI
        </Typography>
      </Box>
      <Box
        sx={{
          padding: '10px',
        }}
      >
        {imgDesc ? (
          <>
            <Typography fontSize={14} lineHeight={1.2}>
              {imgDesc.length > 150
                ? imgDesc.substring(0, 150) + '...'
                : imgDesc}
            </Typography>
          </>
        ) : (
          ''
        )}
      </Box>
      <Box>
        <ImageBox
          src={imgSrc}
          styles={{
            width: '100%',
            height: '150px',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box>
        <img
          src="/assets/images/FacebookMock.png"
          alt=""
          style={{ width: '100%' }}
        />
      </Box>
    </Box>
  );
};

export default FacebookMock;
