import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import theme from '../../styles/theme';
import Buttons from '../Button';
import GCEChatBubble from '../GCE/GCEChatBubble';
import GCEChatInput from '../GCE/GCEChatInput';

const CNSChatTab = ({ setIsReset }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const addUserInputToChatHistory = (text) => {
    let tempChatHistory = [...chatHistory];
    tempChatHistory.push({
      user: true,
      text,
      iOption: false,
    });
    tempChatHistory.push({
      user: false,
      text: 'Let’s get an idea of what you need. Select all the boxes that interest you.',
      iOption: false,
    });
    setChatHistory(tempChatHistory);
  };

  const renderChatHistory = () => {
    return chatHistory.map((el, index) => {
      return el.user ? (
        <Box
          key={el.text}
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'right',
            width: '100%',
          }}
        >
          <GCEChatBubble
            key={`${index}-${el.text}`}
            isUser={el.user}
            text={el.text}
            basicStyle={true}
          />
        </Box>
      ) : (
        <GCEChatBubble
          key={`${index}-${el.text}`}
          isUser={el.user}
          text={el.text}
          basicStyle={true}
        />
      );
    });
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Buttons
          startIcon={<img src="/assets/rotate.svg" alt="Rotate" />}
          sx={{
            fontFamily: 'Graphik',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
          }}
          label={<Typography fontSize={12}>Reset Session</Typography>}
          onClick={() => setIsReset(true)}
        />
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.grey.light2}`,
          borderRadius: 2,
          marginTop: 2,
          marginBottom: 2,
          paddingBottom: 2,
          backgroundColor: theme.palette.grey.white,
        }}
      >
        <Grid
          sx={{
            maxHeight: '100%',
            overflowY: 'auto',
            height: '25rem',
            scrollBehavior: 'smooth',
          }}
        >
          <Box
            sx={{
              width: '100%',
              p: 2,
              height: '100vh',
            }}
          >
            {renderChatHistory()}
          </Box>
        </Grid>
      </Box>
      <Box sx={{ marginTop: '8px' }}>
        <Typography
          variant="h2"
          fontSize={20}
          color={theme.palette.primary.main}
          marginY={5}
        >
          Have any follow-up questions?
        </Typography>

        <Typography sx={{ fontSize: 14, fontStyle: 'italic', marginBottom: 4 }}>
          Note: Questions asked in the space will not affect the report
          generated
        </Typography>
        <GCEChatInput
          onClick={addUserInputToChatHistory}
          onEnterPress={addUserInputToChatHistory}
          isEnabled
        />
      </Box>
    </>
  );
};
export default CNSChatTab;
