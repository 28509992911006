import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { CNSContext } from '../../context/CNSContext';
import theme from '../../styles/theme';

let steps = [];

const step_container = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
};

const step_icon_container = {
  padding: '10px',
};

const step_number_icon = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  backgroundColor: theme.palette.blue.link1,
  outlineOffset: '3px',
  color: theme.palette.grey.white,
  marginRight: '5px',
  fontSize: '18px'
};

const step_title = {
  margin: '0 10px 0 0px',
  fontFamily: 'GraphikBold',
  fontSize: '14px',
  padding: '0',
  lineHeight: '20px',
  textTransform: 'uppercase',
  color: theme.palette.blue.link1,
  whiteSpace: 'nowrap',
};

const stepper_rule = {
  border: '0.5px thin',
  borderColor: theme.palette.grey.step_rule,
  width: '100%',
  marginRight: '10px',
  fontFamily: 'GraphikBold',
};

const StepIcon = ({ content, colorState, fontWidthState }) => {
  const dynamicStyles = {
    backgroundColor: colorState,
    outlineColor: colorState,
    fontFamily: fontWidthState,
  };
  return (
    <div style={step_icon_container}>
      <div style={{ ...step_number_icon, ...dynamicStyles }}>{content}</div>
    </div>
  );
};

StepIcon.propTypes = {
  content: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
};

export default function CustomizedStepper({ setIsCreateContentBtnDisabled }) {
  const state = useContext(CNSContext);
  steps = ['Topic', 'Audience', 'Context'];

  const populateStep = () => {
    return steps.map((step, index) => {
      let content;
      let spacing = {};
      const backgroundColor = index + 1 <= state.step ? theme.palette.blue.link1 : theme.palette.grey.step_rule;
      const textBold = index + 1 <= state.step ? 'GraphikBold' : 'GraphikBold';
      const textColor = index + 1 === state.step ? theme.palette.blue.link1 : theme.palette.grey.step_rule;
      const textLight = index + 1 === state.step ? 'GraphikBold' : 'GraphikLight';
      if (index < steps.length - 1) {
        spacing.grow = '2';
      }
      if (index + 2 <= state.step) {
        content = <CheckIcon />;
      } else {
        content = index + 1;
      }
      if (state.step >= 4) {
        setIsCreateContentBtnDisabled(false);
      }
      return (
        <div
          key={step + index}
          style={{ ...step_container, flexGrow: spacing.grow }}
        >
          <StepIcon
            content={content}
            colorState={backgroundColor}
            fontWidthState={textBold}
          />
          <Typography
            sx={{ ...step_title, color: textColor, fontFamily: textLight }}
          >
            {step}
          </Typography>
          {index < steps.length - 1 ? <hr style={stepper_rule} /> : null}
        </div>
      );
    });
  };

  return (
    <Box
      sx={{
        margin: 0,
        width: '80%',
        height: '47px',
        display: 'flex',
        direction: 'column',
        padding: '0 0 0 15%',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      {populateStep()}
    </Box>
  );
}
