//https://framer.com/m/framer/Animator.js
//https://www.framer.com/motion/introduction/
//Add or Expirement with SVG animation here
export const circleBlue = {
  hidden: {
    opacity: 0.2,
    scale: 50,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
  transition: {
    duration: 8,
    delay: 1 + 100 * 0.5,
    ease: 'easeInOut',
    opacity: { duration: 6 },
  },
  exit: {
    scale: 0,
  }
};

export const circlePink = {
  hidden: {
    opacity: 0,
    scale: 140
  },
  visible: {
    opacity: 1, scale: 1
  },
  transition: {
    duration: 0.3,
    ease: [0, 0.71, 0.2, 1.01],
    scale: {
      type: 'spring',
      damping: 5,
      stiffness: 100,
      restDelta: 0.001
    }
  },
};

export const testHero = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    scale: [1, 2, 2, 1, 1],
    rotate: [0, 0, 180, 180, 0],
    borderRadius: ['0%', '0%', '50%', '50%', '0%'],
  },
  transition: {
    duration: 5,
    ease: 'easeOut',
    times: [0, 0.2, 0.5, 0.8, 1],
    repeat: Infinity,
    repeatDelay: 1,
  },
  exit: {
    opacity: 0,
  },
};

//Draw a line of SVG <path d="0 to 1" >
export const drawPath = {
  hidden: { opacity: 0, pathLength: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        duration: 1,
        ease: 'easeInOut',
        pathLength: { delay, type: 'spring', duration: 2, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

//Draw a line in reverse of SVG <path d="1 to 0" >
export const drawPath2 = {
  hidden: { opacity: 0, pathLength: 1 },
  visible: (i) => {
    const delay = 1 + i * 1.5;
    return {
      pathLength: 0,
      opacity: 1,
      transition: {
        repeat: Infinity,
        duration: 5,
        ease: 'easeInOut',
        pathLength: { delay, type: 'spring', duration: 6, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

export const rotateSVG = {
  hidden: { opacity: 0, rotate: -180 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      rotate: 0,
      transition: {
        duration: 1,
        ease: 'easeInOut',
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

export const lineMorph = {
  hidden: { opacity: 1 },
  visible: (i) => {
    const delay = 1 + i * 1.5;
    return {
      opacity: 0,
      transition: {
        repeat: Infinity,
        ease: 'easeInOut',
        duration: 6,
        times: [0, 0.16, 0.33, 0.5, 0.66, 0.83],
      },
    };
  },
};
