import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import data from '../assets/data/svg-icon.json';

export default function SVGIcon(props) {
  const iconRef = useRef(null);
  //these are the path element in svg
  const [d1, setD1] = useState('');
  const [d2, setD2] = useState('');
  const [d3, setD3] = useState('');
  const [d4, setD4] = useState('');

  props.width && props.width.toString();
  props.height && props.height.toString();

  useEffect(() => {
    try {
      if (!props.name) {
        console.log('icon name is not provided');
      } else {
        const foundIcon = data.find((icon) => icon.name === props.name);
        iconRef.current = foundIcon;
      }
      if (iconRef.current) {
        setD1(iconRef.current.path?.d1);
        setD2(iconRef.current.path?.d2);
        setD3(iconRef.current.path?.d3);
        setD4(iconRef.current.path?.d4);
      }
    } catch (error) {
      console.log('Error in getting icon-data.json file', error);
    }
  }, [d1]);

  return (
    <>
      <svg
        width={props.width ?? iconRef.current?.width}
        height={props.height ?? iconRef.current?.height}
        viewBox={props.viewBox ?? iconRef.current?.viewBox}
        fill={!props.fill ? 'none' : 'props.fill'}
        x={!props.x ? '0' : props.x}
        y={!props.x ? '0' : props.y}
        onClick={(e) => {
          if (props.onClick) {
            props.onClick(e);
          }
        }}
        style={props.style}
        {...props}
      >
        <g
          filter={props.filter}
          stroke={props.strokecolor}
          strokeWidth={props.strokeWidth}
          fill={props.color}
          scale={props.scale}
          fillRule={props.fillRule && 'evenodd'}
          clipRule={props.clipRule && 'evenodd'}
        >
          <path d={d1} fill={props.colord1} />
          {d2 && <path d={d2} fill={props.colord2} />}
          {d3 && <path d={d3} fill={props.colord3} />}
          {d4 && <path d={d4} fill={props.colord4} />}
        </g>

        {props.name === 'mark-stretch' && (
          <defs>
            <filter
              id="filter0_i_3402_2736"
              x="0.448242"
              y="0.516113"
              width="263.744"
              height="441.185"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="30" />
              <feGaussianBlur stdDeviation="25" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_3402_2736"
              />
            </filter>
            <radialGradient
              id="paint0_radial_3402_2736"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(-72.5883 93.2184) rotate(41.7474) scale(483.224 3655.98)"
            >
              <stop stopColor="#FF50A0" />
              <stop offset="0.153907" stopColor="#A100FF" />
              <stop offset="0.764846" stopColor="#7500C0" />
              <stop offset="1" stopColor="#00FFFF" />
            </radialGradient>
          </defs>
        )}

        {props.name === 'mark-stretch2' && (
          <defs>
            <radialGradient
              id="paint0_radial_3441_1148"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(30.6774 270.75) rotate(-41.576) scale(243.695 1843.74)"
            >
              <stop stopColor="#FF50A0" />
              <stop offset="0.508713" stopColor="#A100FF" />
              <stop offset="0.800256" stopColor="#7500C0" />
              <stop offset="1" stopColor="#00FFFF" />
            </radialGradient>
          </defs>
        )}

        {props.name === 'mark-joint' && (
          <defs>
            <filter
              id="filter0_i_3441_1142"
              x="0.617188"
              y="0.451172"
              width="719.034"
              height="484.514"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="50" />
              <feGaussianBlur stdDeviation="15" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_3441_1142"
              />
            </filter>
            <radialGradient
              id="paint0_radial_3441_1142"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(136.584 354.371) rotate(-30.6164) scale(744.085 330.14)"
            >
              <stop stopColor="#FF50A0" />
              <stop offset="0.508713" stopColor="#A100FF" />
              <stop offset="0.800256" stopColor="#7500C0" />
              <stop offset="1" stopColor="#00FFFF" />
            </radialGradient>
          </defs>
        )}

        {props.name === 'mark-joint2' && (
          <defs>
            <filter
              id="filter0_i_3441_1147"
              x="0.321289"
              y="-39.8203"
              width="1087.82"
              height="534.377"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-50" />
              <feGaussianBlur stdDeviation="20" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_3441_1147"
              />
            </filter>
            <radialGradient
              id="paint0_radial_3441_1147"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(-231.964 506.345) rotate(-20.1366) scale(1500.87 655.323)"
            >
              <stop stopColor="#FF50A0" />
              <stop offset="0.301323" stopColor="#A100FF" />
              <stop offset="0.800256" stopColor="#7500C0" />
              <stop offset="1" stopColor="#00FFFF" />
            </radialGradient>
          </defs>
        )}

        {props.name === 'circle-stroke-gradient' && (
          <>
            <defs>
              <radialGradient
                id="paint0_radial_3103_887"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(40 76) rotate(-10.3124) scale(80.3465 221.946)"
              >
                <stop stopColor="#FF50A0" />
                <stop offset="0.508713" stopColor="#A100FF" />
                <stop offset="0.800256" stopColor="#7500C0" />
                <stop offset="1" stopColor="#00FFFF" />
              </radialGradient>
            </defs>
          </>
        )}

        {props.name === 'account-profile' && (
          <circle cx="14" cy="14" r="13.5" stroke={props.color} />
        )}
        {props.name === 'orb-gradient' && (
          <defs>
            <radialGradient
              id="paint0_radial_3441_1152"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(14.5257 98.5988) rotate(-40.0786) scale(151.692 415.937)"
            >
              <stop stopColor="#FF50A0" />
              <stop offset="0.276221" stopColor="#A100FF" />
              <stop offset="0.627889" stopColor="#7500C0" />
              <stop offset="1" stopColor="#00FFFF" />
            </radialGradient>
          </defs>
        )}

        {props.name === 'acn-blob-gradient' && (
          <defs>
            <filter
              id="filter0_i_4209_95489"
              x="0.00292969"
              y="19.0031"
              width="63.1484"
              height="24.1343"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="15" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_4209_95489"
              />
            </filter>
            <filter
              id="filter1_i_4209_95489"
              x="21.333"
              y="0"
              width="19.627"
              height="24"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="15" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_4209_95489"
              />
            </filter>
            <radialGradient
              id="paint0_radial_4209_95489"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(0.0029266 29.0703) rotate(-3.32024) scale(69.4474 62.9788)"
            >
              <stop stopColor="#FF50A0" />
              <stop offset="0.508713" stopColor="#A100FF" />
              <stop offset="0.800256" stopColor="#7500C0" />
              <stop offset="1" stopColor="#00FFFF" />
            </radialGradient>
            <radialGradient
              id="paint1_radial_4209_95489"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(21.333 10) rotate(-11.5047) scale(29.779 83.7014)"
            >
              <stop stopColor="#FF50A0" />
              <stop offset="0.508713" stopColor="#A100FF" />
              <stop offset="0.800256" stopColor="#7500C0" />
              <stop offset="1" stopColor="#00FFFF" />
            </radialGradient>
            <clipPath id="clip0_4209_95489">
              <rect width="64" height="40" fill="white" />
            </clipPath>
          </defs>
        )}

        {props.name === 'strings' && (
          <defs>
            <filter id="filter0_f_260_120" x="227.523" y="0.486328" width="1814.91" height="1203.51" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_260_120" />
            </filter>
            <filter id="filter1_f_260_120" x="480.012" y="26.9648" width="1535.1" height="1010.17" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_260_120" />
            </filter>
          </defs>
        )}
      </svg>
    </>
  );
}

SVGIcon.defaultProps = {};

SVGIcon.props = {
  name: PropTypes.string.isRequired, // see [iconNames] above for valid props.name
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.string, // custom css ex: style={{marginRight: 10+'em', outline: 1+'px'+' red'+' solid',}} />
  preserveAspectRatio: PropTypes.string, // usage link https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
};
