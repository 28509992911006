import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SendIcon from '@mui/icons-material/Send';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import theme from '../../styles/theme';
import { ImageBox } from '../ImageBox';

const IGIcons = {
  marginRight: '20px',
};

const InstagramMock = ({ imgDesc, imgSrc }) => {
  return (
    <Box
      sx={{
        width: 300,
        height: '100%',
        border: `0.5px solid ${theme.palette.grey.light}`,
        backgroundColor: theme.palette.primary.white,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <Box
          sx={{
            width: 35,
            height: 35,
            backgroundColor: theme.palette.grey.light,
            marginRight: 2,
            borderRadius: '100%',
          }}
        ></Box>
        <Typography variant="subtitle1" fontSize={14}>
        Emotional AI
        </Typography>
      </Box>
      <ImageBox
        src={imgSrc}
        styles={{
          width: '100%',
          height: '300px',
        }}
      />

      <Box
        sx={{
          padding: '10px',
        }}
      >
        <Box>
          <FavoriteIcon sx={{ fontSize: 20, color: 'red' }} style={IGIcons} />
          <ChatBubbleOutlineIcon sx={{ fontSize: 20 }} style={IGIcons} />
          <SendIcon sx={{ fontSize: 20 }} style={IGIcons} />
          <TurnedInNotIcon sx={{ fontSize: 20, float: 'right' }} />
        </Box>

        {imgDesc ? (
          <>
            <Typography fontSize={14} lineHeight={1.2}>
              <span style={{ fontFamily: 'Monterassat' }}>
                Emotional AI
              </span>{' '}
              {imgDesc.length > 80 ? imgDesc.substring(0, 80) + '...' : imgDesc}
            </Typography>
          </>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default InstagramMock;
