import React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import theme from '../styles/theme';

const footerStyle = {
  position: 'fixed',
  width: '100%',
  bottom: 0,
  left: 0,
  borderTop: '10px',
  fontSize: '25px',
  background: theme.palette.grey.white,
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  zIndex: 999,
  alignItems: 'center',
  margin: '0 auto',
};

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const FooterButton = ({ style, children }) => {
  return (
    <ElevationScroll>
      <footer style={{ ...footerStyle, ...style }}>{children}</footer>
    </ElevationScroll>
  );
};

FooterButton.propTypes = {
  setData: PropTypes.func.isRequired,
  style: PropTypes.object,
  formState: PropTypes.object,
  setLanding: PropTypes.func,
};

export default FooterButton;
