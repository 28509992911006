import { Storage } from 'aws-amplify';
import { useRef, useState } from 'react';

const useFileUpload = (userId) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentProgress, setCurrentProgress] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const [fileKeys, setFileKeys] = useState([]);

  const fileInput = useRef();

  const handleFilesUpload = async (event,isFile) => {
    const selectedFiles = isFile ? event : event.target.files;

    setUploadedFiles([...uploadedFiles, ...selectedFiles]);
    for (const file of selectedFiles) {
      setFileCount(fileCount + 1);
      const result = await Storage.put(
        `mcsv2/${userId}/input/${file.name}`,
        file,
        {
          progressCallback(progress) {
            const modifiedProgress = [...currentProgress];
            modifiedProgress[fileCount] = (
              (progress.loaded / progress.total) *
              100
            ).toFixed();
            setCurrentProgress(modifiedProgress);
          },
          contentType: file.type,
        }
      );
      setFileKeys([...fileKeys, result.key]);
    }
  };

  return {
    uploadedFiles,
    currentProgress,
    handleFilesUpload,
    fileInput,
    fileKeys,
  };
};

export default useFileUpload;
