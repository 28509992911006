import { Card, CardContent, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';

const cardStyling = {
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.06) 100%)',
  backdropFilter: 'blur(20px)',
  width: '300px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.50)',
  border: 'solid 1px',
  borderImage: 'linear-gradient(to right, #FF50A0, #A100FF, #7500C0, #00FFFF) 1 100%',
  borderImageSlice: 1,
  borderRadius: '8px',
  padding: '1px',
}

export default function UseCaseCard(props) {
  const useStyle = !props.cardStyling ? cardStyling : props.cardStyling;

  return (
    <Link to={props.route} >
      <Card sx={useStyle} >

        <img src={props.imgSrc} alt="A typical weekday for me text in image"
          width='100%'
          height='400px'
        />

        <CardContent sx={{
          width: '100%',
          height: 'auto',
          padding: '24px 32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyItems: 'flex-start',
          position: 'absolute'

        }}>
          <Typography variant="bigHeader2" component="h2" sx={{ 
            color: '#FFF' }}>
            {props.appTitle}
          </Typography>

          <Typography variant="body5" component="h3" sx={{ 
            color: '#FFF', textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)' }}>
            {props.appDescription}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}
