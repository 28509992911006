import React from 'react'

const BlendIcon = ({style}) => {
  return (
    <>
    <svg width="31815" height="23854" viewBox="0 0 31815 23854" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7258.97 15042.6C7815.16 14671.4 10899.8 12413.9 11578.3 11517.4C12256.9 10621 12406 11672.6 12941.1 11346.3C13476.3 11020 13851 10190.2 14494 9891.7C15137 9593.25 16701.3 11125.6 17445 10770.8C18188.6 10416.1 17673.7 6934.81 19383.6 6216.98C21093.6 5499.16 27929.2 6747.09 30406.7 5632.24" stroke="#F8FA8E" strokeWidth="8"/>
    <g style="mix-blend-mode:plus-lighter" filter="url(#filter0_f_253_111)">
    <path d="M7727.31 14481.4C8277.49 14138.2 11034.8 12141.7 11711.6 11400.2C12388.4 10658.6 12521.5 11404.4 13050.5 11096.6C13579.6 10788.8 13875.3 10349.9 14480.3 9900.31C15085.3 9450.73 16743.6 10338.4 17368 10140.5C17992.4 9942.6 17717.9 8253.43 18567.2 7191.06C19416.6 6128.7 21571.8 5763.08 23865.1 5990.82" stroke="#AEFDBB" strokeOpacity="0.8" strokeWidth="11"/>
    </g>
    <g style="mix-blend-mode:plus-lighter" filter="url(#filter1_f_253_111)">
    <path d="M7744.27 14477.5C8316.9 14167.8 10928.5 12154.2 11652.5 11454.5C12376.4 10754.8 12464.1 11507.2 13013.4 11231.7C13562.6 10956.2 13886 10535.7 14520.1 10123.2C15154.2 9710.64 16762.3 10696.1 17400.4 10536C18038.5 10375.8 17368.9 8058.07 18605.8 7138.84C19842.8 6219.6 21081.6 6366.35 23708.2 6699.08" stroke="#FBAEFD" strokeOpacity="0.8" strokeWidth="11"/>
    </g>
    <path d="M7643.75 14550.7C8169.21 14221.2 11087 12223 11736.3 11439.4C12385.5 10655.9 12505.2 11557.9 13010.2 11267.5C13515.3 10977.1 13879.9 10256.3 14485 9987.88C15090.1 9719.45 16520.5 11009.3 17220.5 10690.6C17920.4 10371.9 17504.3 7386.31 19112 6737.7C20719.7 6089.1 21331.5 5919.01 23491.5 6244.9" stroke="#F8FA8E" strokeWidth="8"/>
    <g style="mix-blend-mode:difference" filter="url(#filter2_f_253_111)">
    <path d="M2264.26 17961.2C5852.95 17504.5 10977.9 12399.5 11566.2 11602.6C12154.5 10805.7 12335.1 11663.4 12814.7 11349.8C13294.4 11036.3 13543.1 10557.4 14084.5 10087.7C14625.9 9618.03 16264.2 10742.8 16842.4 10560.9C17420.5 10378.9 18049.2 8132.74 18778 6982.76C19506.8 5832.79 27443.9 5813.51 29636.7 6231.38" stroke="#1FEEE1" strokeOpacity="0.95" strokeWidth="11"/>
    </g>
    <g style="mix-blend-mode:plus-lighter" filter="url(#filter3_f_253_111)">
    <path d="M2942.81 18245.1C3491.6 17934.6 11024.9 12240.4 11712.8 11528.5C12400.6 10816.6 12500.1 11597.8 13026.9 11322.3C13553.7 11046.7 14037.2 11096.8 14643.5 10680.8C15249.8 10264.9 16648.6 10835.2 17263.7 10680.8C17878.8 10526.5 17185.3 8112.67 18365.9 7182.57C19546.5 6252.47 20011.2 6283.64 22562.3 6676.01" stroke="#DE20E2" strokeWidth="11"/>
    </g>
    <path d="M24372.3 8087.76C23772.5 8268.65 20303 9153.59 19250.7 9110.06C18198.4 9066.52 18869.8 9800.8 18315.1 9993.31C17760.4 10185.8 16937.3 9962.3 16338.5 10249.3C15739.7 10536.3 15915.4 12580 15216 12906.2C14516.5 13232.5 12306.7 10786.3 10769.1 11594.8C9231.52 12403.2 6061.34 18088.1 3779.49 19214.9" stroke="#F8FA8E" strokeWidth="8"/>
    <g style="mix-blend-mode:plus-lighter" filter="url(#filter4_f_253_111)">
    <path d="M23687.6 8091.99C23111.7 8285.22 20025.8 9089.11 19086.5 9136.56C18147.3 9184.01 18607.1 9724.52 18069.4 9923.6C17531.8 10122.7 17038.3 10075 16352.9 10244.5C15667.5 10414 15321.2 12141.6 14806.2 12475.1C14291.1 12808.6 13233.6 11603.8 11959.6 11584.9C10685.7 11566 9137.4 12906.2 7935.84 14699.6" stroke="#AEFDBB" strokeOpacity="0.8" strokeWidth="11"/>
    </g>
    <g style="mix-blend-mode:plus-lighter" filter="url(#filter5_f_253_111)">
    <path d="M23674.6 8101.91C23109.6 8331.35 20098.2 9019.72 19161.1 9126.21C18224 9232.7 18715.6 9744.36 18189.2 9977.25C17662.9 10210.1 17166.3 10193.5 16490.3 10406.1C15814.3 10618.6 15568.7 12368.2 15072.8 12734.1C14576.9 13100 13300.3 11235.1 11898.9 11581.7C10497.5 11928.3 9865.54 12911.2 8541.3 15008.2" stroke="#FBAEFD" strokeOpacity="0.8" strokeWidth="11"/>
    </g>
    <path d="M23787.4 8072.83C23236.2 8256.38 20053.4 9175.27 19100.2 9177.79C18147.1 9180.32 18727.8 9804.2 18217 9996.28C17706.2 10188.4 16967.9 10022.6 16413.4 10300.1C15858.9 10577.5 15939.1 12380 15291.7 12696.1C14644.4 13012.3 12734 10932.8 11307.4 11708.6C9880.72 12484.4 9393.29 12825.3 8342.04 14581" stroke="#F8FA8E" strokeWidth="8"/>
    <g style="mix-blend-mode:difference" filter="url(#filter6_f_253_111)">
    <path d="M29457.6 6215.84C26989.6 8538.01 20246 9201.51 19319.5 9152.05C18392.9 9102.58 18905.4 9744.09 18393 9904.03C17880.6 10064 17386.2 9958.46 16724.1 10070C16062.1 10181.6 15899.2 12036 15423.2 12345.6C14947.3 12655.1 12948.9 11771.4 11683.5 11613.2C10418 11454.9 5675.59 17181.1 4671.31 19015.2" stroke="#1FEEE1" strokeOpacity="0.95" strokeWidth="11"/>
    </g>
    <g style="mix-blend-mode:plus-lighter" filter="url(#filter7_f_253_111)">
    <path d="M29258.6 6875.51C28707.2 7087.22 20103.1 9140.75 19178.6 9213.86C18254.2 9286.98 18759.7 9824.35 18246.6 10041C17733.6 10257.7 17241.1 10222.2 16579.2 10412.7C15917.2 10603.2 15737 12368.9 15258.9 12721.6C14780.9 13074.3 13449.1 11134.9 12073.4 11434.3C10697.7 11733.7 10443.4 12088.1 9207.27 14166.1" stroke="#DE20E2" strokeWidth="11"/>
    </g>
    <defs>
    <filter id="filter0_f_253_111" x="7693.37" y="5889.48" width="16203.2" height="8627.61" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_253_111"/>
    </filter>
    <filter id="filter1_f_253_111" x="7710.62" y="6406.36" width="16029.2" height="8106.96" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_253_111"/>
    </filter>
    <filter id="filter2_f_253_111" x="2232.48" y="5945.74" width="27436.2" height="12051.9" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_253_111"/>
    </filter>
    <filter id="filter3_f_253_111" x="2909.06" y="6380.39" width="19685" height="11900.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_253_111"/>
    </filter>
    <filter id="filter4_f_253_111" x="7900.29" y="8055.76" width="15820" height="6677.94" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_253_111"/>
    </filter>
    <filter id="filter5_f_253_111" x="8505.68" y="8065.79" width="15202" height="6976.32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_253_111"/>
    </filter>
    <filter id="filter6_f_253_111" x="4635.55" y="6180.79" width="24856.8" height="12868.1" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_253_111"/>
    </filter>
    <filter id="filter7_f_253_111" x="9171.58" y="6839.35" width="20120" height="7360.56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="15.5" result="effect1_foregroundBlur_253_111"/>
    </filter>
    </defs>
    </svg>
    </>
    
  )
}

export default BlendIcon