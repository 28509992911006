import React, { useEffect, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CNSContext, CNSDispatchContext } from '../context/CNSContext';
import { CNS_ACTION_TYPES } from '../reducers/cnsActionTypes';
import { INITIAL_CNS_STATE, cnsReducer } from '../reducers/cnsReducer';
import CNSIdeas from '../views/CNS/CNSIdeas';
import CNSIntro from '../views/CNS/CNSIntro';

export default function CNS() {
  const [state, dispatch] = useReducer(cnsReducer, INITIAL_CNS_STATE);

  useEffect(() => {
    dispatch({
      type: CNS_ACTION_TYPES.SET_S3_FOLDER,
      payload: uuidv4() + '/',
    });
  }, []);

  return (
    <>
      <CNSContext.Provider value={state}>
        <CNSDispatchContext.Provider value={dispatch}>
          {state.showIdeas ? <CNSIdeas /> : <CNSIntro />}
        </CNSDispatchContext.Provider>
      </CNSContext.Provider>
    </>
  );
}
