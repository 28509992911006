import React, { useReducer } from 'react';
import Chat from '../components/UXRA/Chat';
import RAIntro from '../views/UXRA/RAIntro';

import { RAContext, RADispatchContext } from '../context/RAContext';
import { INITIAL_RA_STATE, raReducer } from '../reducers/raReducer';

export default function ResearchAssistant() {
  const [state, dispatch] = useReducer(raReducer, INITIAL_RA_STATE);

  return (
    <>
      <RAContext.Provider value={state}>
        <RADispatchContext.Provider value={dispatch}>
          {state.isIntro ? <RAIntro /> : <Chat />}
        </RADispatchContext.Provider>
      </RAContext.Provider>
    </>
  );
}
