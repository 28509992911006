import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Buttons from '../../components/Button';
import SVGIcon from '../../components/SVGIcon';
import { GCEContext, GCEDispatchContext } from '../../context/GCEContext';
import { useGenerativeConceptEngine } from '../../hooks/useGenerativeConceptEngine';
import {
  GCE_ACTION_TYPES,
  GCE_API_TYPES,
  GCE_IDEA_TYPES,
} from '../../reducers/gceActionTypes';
import Spinner from '../Spinner';
import GCEChatBubble from './GCEChatBubble';
import GCEChatInput from './GCEChatInput';
import { GCEInitialQuestion } from './GCEInitialQuestion';
import GCEModalConversation from './GCEModalConversation';

const resetStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '8px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '25px',
  width: '650px',
};

const GCEChat = ({
  currentIdea,
  currentIdeaHistory,
  setCurrentIdeaHistory,
  isModal,
  counter,
  handleCounter,
  handlePredefinedQuestionCount,
  inputNote,
  style
}) => {
  const divRef = useRef(null);
  const state = useContext(GCEContext);
  const dispatch = useContext(GCEDispatchContext);
  const [chatHistory, setChatHistory] = useState(
    currentIdea ? currentIdeaHistory : []
  );
  const [isReset, setIsReset] = useState(false);
  const [predefinedQuestions, setPredefinedQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isTextEnabled, setIsTextEnabled] = useState(false);

  const { mutateAsync, isLoading } = useGenerativeConceptEngine();
  const [activePlatform, setActivePlatform] = useState('');

  const handleCategoryPress = async (category) => {
    const uuid = uuidv4();
    try {
      // fetch questions for idea type
      const questions = await mutateAsync({
        requestId: uuid,
        requestType: GCE_API_TYPES.GCE_DEFAULT_QUESTIONS,
        ideaType: GCE_IDEA_TYPES[category],
      });
      // store questions in state
      setPredefinedQuestions(questions);

      handlePredefinedQuestionCount(questions.length);

      //update chat history
      setChatHistory([
        ...chatHistory,
        {
          user: false,
          text: questions[counter],
        },
      ]);

      //update counter
      handleCounter(counter + 1);

      // set params
      dispatch({
        type: GCE_ACTION_TYPES.SET_INITIAL_PARAMS,
        payload: {
          requestId: uuid,
          ideaType: GCE_IDEA_TYPES[category],
        },
      });
      setIsTextEnabled(true);
    } catch (e) {
      console.log('error fetching default questions: ', e);
    }
  };

  const addUserInputToChatHistory = async (text) => {
    // fetch chatInitiate response
    let agentResponse;
    if (isModal) {
      agentResponse = await getRefinementResponse(text);
    } else {
      setAnswers([...answers, text]);
      agentResponse =
        counter < predefinedQuestions.length
          ? predefinedQuestions[counter]
          : 'Alright lets get started. Press Generate Ideas';
      handleCounter(counter + 1);
    }

    const historyObj = [
      ...chatHistory,
      {
        user: true,
        text,
      },
      {
        user: false,
        text: agentResponse,
      },
    ];
    setChatHistory(historyObj);
    if (currentIdea) {
      setCurrentIdeaHistory(historyObj);
    }
  };

  const getRefinementResponse = async (text) => {
    let response;
    try {
      // call API with chatInitiate request type
      response = await mutateAsync({
        requestId: state.requestId,
        requestType: currentIdea.ideaHistory.length
          ? GCE_API_TYPES.GCE_CHAT_CONTINUE
          : GCE_API_TYPES.GCE_CHAT_INITIATE,
        ideaType: state.ideaType,
        question: text,
        ideaSelected: currentIdea.id,
      });
    } catch (e) {
      console.log('error calling ideaRefinement: ', e);
      response = 'error response';
    }

    return response.response;
  };

  const getDefaultAnswers = async () => {
    try {
      // call API with getDefaultAnswers request type
      await mutateAsync({
        requestId: state.requestId,
        requestType: GCE_API_TYPES.GCE_DEFAULT_ANSWERS,
        ideaType: state.ideaType,
        answers,
      });
      // enable Generate Ideas button
      dispatch({
        type: GCE_ACTION_TYPES.GENERATE_IDEAS,
        payload: true,
      });

      dispatch({
        type: GCE_ACTION_TYPES.SET_CHAT_HISTORY,
        payload: chatHistory,
      });
    } catch (e) {
      console.log('error calling getDefaultAnswers: ', e);
    }
  };

  const resetSession = () => {
    setIsReset(false);
    setChatHistory([]);
    setPredefinedQuestions([]);

    handleCounter(0);
    setActivePlatform('');
    setIsTextEnabled(false);
    setAnswers([]);
    dispatch({
      type: GCE_ACTION_TYPES.GENERATE_IDEAS,
      payload: false,
    });
  };

  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    if (
      predefinedQuestions?.length &&
      answers?.length >= predefinedQuestions?.length
    ) {
      getDefaultAnswers();
    }
  }, [answers]);

  const renderChatHistory = () => {
    return chatHistory?.map((el, index) => {
      return el.user ? (
        <Box
          key={el.text}
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'right',
          }}
        >
          <GCEChatBubble
            key={`${index}-${el.text}`}
            isUser={el.user}
            text={el.text}
          />
        </Box>
      ) : (
        <Box
          key={el.text}
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'left',
          }}
        >
          <GCEChatBubble
            key={`${index}-${el.text}`}
            isUser={el.user}
            text={el.text}
          />
        </Box>
      );
    });
  };

  return (
    <>
     {isReset && (
        <Modal
          open={isReset}
          className="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isReset}>
            <Box className="modal_inner_container" sx={resetStyle}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  paddingBottom: '20px',
                }}
              >
                <Typography variant="h3" sx={{ alignSelf: 'left' }}>
                  Reset Session?
                </Typography>
                <Box
                  className={'genai-icon-close'}
                  sx={{ alignSelf: 'right', height: '32px' }}
                >
                  <SVGIcon
                    width={24}
                    height={27}
                    name="close"
                    color={'#000'}
                    onClick={() => setIsReset(false)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  paddingBottom: '20px',
                }}
              >
                <Typography>
                  Your current progress will be deleted. Are you sure you want
                  to continue and reset?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Buttons
                  variant="song"
                  color="secondary"
                  onClick={() => setIsReset(false)}
                >
                  Close
                </Buttons>
                <Buttons
                  variant="song"
                  startIcon={
                    <img
                      src="/assets/images/refresh_icon_black.svg"
                      alt="Refresh"
                    />
                  }
                  sx={{ marginLeft: '15px' }}
                  onClick={() => resetSession()}
                >
                  Reset Session
                </Buttons>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
      <Box
        className="GCEChat_space"
        sx={{
          borderRadius: '8px',
          border: '1px solid #C9D0D7',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100vh - 340px)',
          ...style,
        }}
      >
        <Grid
          sx={{
            height: '100%',
            overflowX: 'hidden',
            scrollBehavior: 'smooth',
          }}
          container
          className="GCEChat_container"
          ref={divRef}
        >
          <Box
            sx={{
              maxHeight: '68vh',
            }}
          >
            {isModal ? (
              <GCEModalConversation data={currentIdea} />
            ) : (
              <GCEInitialQuestion handleCategoryPress={handleCategoryPress} activePlatform={activePlatform} setActivePlatform={setActivePlatform}/>
            )}
            {renderChatHistory()}
            {isLoading && (
              <Box
                sx={{
                  justifyContent: 'center',
                  left: '50%',
                  paddingTop: 5,
                  paddingX: '50%',
                }}
              >
                <Spinner />
              </Box>
            )}
          </Box>
        </Grid>
        <Box sx={{ width: '100%', paddingTop: '24px' }}>
          <GCEChatInput
            onClick={addUserInputToChatHistory}
            onEnterPress={addUserInputToChatHistory}
            isEnabled={isTextEnabled || isModal}
          />
          {inputNote && <Typography variant="p">{inputNote}</Typography>}
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        backgroundColor="blue"
        sx={{ marginTop: '24px' }}
      >
        {!isModal && (
          <Buttons
            variant={'link-light'}
            startIcon={<SVGIcon name={'clock-counter'} color={'#0573ff'} />}
            label={'Reset Session'}
            onClick={() => setIsReset(true)}
          />
        )}
      </Box>
    </>
  );
};

export default GCEChat;
