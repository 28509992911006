import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useResearchAssistant() {
  const queryClient = useQueryClient();

  return useMutation(async (data) => {
    try {
      const response = await API.post('songbackend', '/api/uxra', {
        body: data,
      });
      // Invalidate relevant queries to update the cache
      queryClient.invalidateQueries('uxra');

      return response;
    } catch {
      throw new Error('Request failed');
    }
  });
}
