import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputBase,
  Link,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Buttons from '../Button';
import AdvancedSetup from './AdvancedSetup';
import { styled } from '@mui/material/styles';
import { MCS2Context, MCS2DispatchContext } from '../../context/MCS2Context';
import { useMachineCreativeStudioV2 } from '../../hooks/useMachineCreativeStudioV2';
import { MCSV2_ACTION_TYPES } from '../../reducers/mcsv2ActionTypes';
import theme from '../../styles/theme';
import BasicModal from '../BasicModal';
import TerritoryCarousel from './TerritoryCarousel';

const formStyle = {
  paddingBottom: '48px',
  marginBottom: '48px',
  borderColor: theme.palette.secondary.blue,
  borderBottom: '1px solid #c9d0d7',
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid ' + theme.palette.grey.main,
    fontSize: 16,
    padding: '18px 12px',
    background: '#FFF',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  width: '100%',
}));

const CreateAssetForm = ({
  projectNames,
  handleProjectChange,
  handleProjectNameChange,
  handleCreateCanvasChange,
  setBigIdea,
  setExperience,
  setImagesIdea,
  setColorsIdea,
  setToneOfVoice,
  setSelectedTerritory,
  selectedTerritory,
  setSelectedTerritoryName,
}) => {
  const { userId, currentProject, portfolio, projects } =
    useContext(MCS2Context);
  const dispatch = useContext(MCS2DispatchContext);
  const { mutateAsync } = useMachineCreativeStudioV2();
  const [advancedSetupExpanded, setAdvancedSetupExpanded] = useState(false);
  const projectSelect = ['Create a new project', ...projectNames];
  const [selectedProject, setSelectedProject] = useState('');
  const [creativeCanvas, setCreativeCanvas] = useState('');
  const [territoryBool, setTerritoryBool] = useState('');
  const [newProjectName, setNewProjectName] = useState('');
  const [open, setOpen] = useState(false);

  const platformArray = {
    instagram_post: 'Instagram Post',
    linkedin_post: 'LinkedIn Post',
    facebook_post: 'Facebook Post',
    youtube_thumbnail_post: 'Youtube Thumbnail',
    twitter_post: 'Twitter Post',
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // setPortfolio any time the currentProject in context changes
  // current project in context will always be an existing project
  useEffect(() => {
    if (currentProject.projectId) {
      setPortfolio();
    }
  }, [currentProject]);

  useEffect(() => {
    if (portfolio.length && portfolio[0].imageArray.length == 0) {
      loadPortfolioImages();
    }
  }, [portfolio]);

  const setPortfolio = async () => {
    try {
      // load portfolio into context if using existing project
      if (currentProject.projectName && currentProject.projectId) {
        let curProject = projects.find(
          (project) => project.projectId === currentProject.projectId
        );
        let projectPortfolio = curProject.territories_text_data.map((entry) => {
          const territoryId = Object.keys(entry)[0];
          const result = entry[territoryId];

          return {
            id: territoryId,
            typeface: 'Tagline',
            header: result.title,
            description: result.caption,
            descriptors: 'Title',
            imageArray: [],
            colorPalette: [],
            morph: '', // Replace 'payload.requestType' with the actual value
          };
        });

        dispatch({
          type: MCSV2_ACTION_TYPES.SET_PORTFOLIO,
          payload: projectPortfolio,
        });
      }
    } catch (e) {
      console.log('error: set project ', e);
    }
  };

  const loadPortfolioImages = async () => {
    const payload = {
      userId,
      projectId: currentProject.projectId,
      // list of territory IDs we are seeking materials for
      territoryIdList: portfolio.map((elm) => elm.id),
      requestType: 'getPSUwithColors',
    };
    try {
      const { presignedUrlList, refColorsList } = await mutateAsync(payload);
      let count = 0;
      const portfolioWithImages = portfolio.map((item) => ({
        ...item,
        imageArray: presignedUrlList[count],
        colorPalette: refColorsList[count++],
      }));
      count = 0;
      // update portfolio with new images
      // savedPortfolio automatically changes when we update portfolio in context
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_PORTFOLIO,
        payload: portfolioWithImages,
      });
    } catch (e) {
      console.log('Error loading content for portfolio territory: ', e);
    }
  };

  return (
    <>
      <Box className="form_container" sx={formStyle}>
        <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
          Select a Project
        </Typography>
        <FormControl sx={{ width: '50%' }}>
          <Select
            defaultValue="Select or create a Project"
            displayEmpty={true}
            renderValue={() => {
              return selectedProject === ''
                ? 'Select or create a Project'
                : selectedProject;
            }}
            labelId="project-label"
            onChange={(event) => {
              handleProjectChange(event.target.value);
              setSelectedProject(event.target.value);
            }}
            label="project"
            input={<OutlinedInput />}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {projectSelect.map((project) => {
              return (
                <MenuItem key={project} value={project} sx={{ paddingY: 8 }}>
                  {project}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {selectedProject === projectSelect[0] ? (
          <Box sx={{ paddingTop: 8 }}>
            <Typography
              fontWeight={100}
              gutterBottom
              sx={{ marginBottom: 4 }}
              color={theme.palette.grey.dark}
            >
              PROJECT NAME
            </Typography>
            <BootstrapInput
              placeholder="Project Name"
              id="input-label-project-name"
              onChange={(event) => {
                handleProjectNameChange(event.target.value);
                setNewProjectName(event.target.value);
              }}
              sx={{
                '& .MuiInputBase-input': {},
              }}
            />
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Box sx={formStyle}>
        <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
          Select a Territory
        </Typography>
        <Box>
          <Typography
            paragraph
            gutterBottom
            sx={{ marginBottom: 4, display: 'inline', paddingRight: 1 }}
          >
            The Territory you select will drive the Assets you create. If you do
            not have a Territory you would like to work with, we recommend
          </Typography>
          <Link
            onClick={() => {
              handleOpen();
            }}
          >
            <Typography
              color={theme.palette.primary.main}
              inline
              sx={{
                display: 'inline',
                paddingRight: 1,
                textDecoration: 'underline',
              }}
            >
              creating a new Territory first.
            </Typography>
          </Link>
          <Modal open={open} onClose={handleClose}>
            <BasicModal
              title={'Are you sure you want to leave this page?'}
              description={
                'Creating a new Territory will take you to a new page. Any unsaved content will be lost.'
              }
              buttonComponents={
                <Box sx={{ float: 'right' }}>
                  <Buttons
                    variant="song"
                    color="secondary"
                    sx={{ paddingX: 8, marginX: 6 }}
                    onClick={handleClose}
                  >
                    Stay on This Page
                  </Buttons>
                  <Buttons
                    variant="song"
                    sx={{ paddingX: 8, marginX: 6 }}
                    onClick={() => {
                      setExperience('territories');
                    }}
                  >
                    Leave this Page
                  </Buttons>
                </Box>
              }
            />
          </Modal>
        </Box>
        <FormControl
          sx={{ marginTop: 4 }}
          disabled={!(selectedProject != '' || newProjectName != '')}
        >
          <RadioGroup
            aria-labelledby="territory-radio-buttons-group"
            name="radio-buttons-group"
            onChange={(event) => setTerritoryBool(event.target.value)}
          >
            <FormControlLabel
              sx={{ paddingY: 1 }}
              value="current"
              control={<Radio />}
              label="Select from current Territories"
              disabled={
                selectedProject === 'Create a new project' ||
                selectedProject === ''
              }
            />
            <FormControlLabel
              sx={{ paddingY: 1 }}
              value="without"
              control={<Radio />}
              label="Continue without a Territory"
            />
          </RadioGroup>
        </FormControl>
        <Box>
          {territoryBool === 'current' && (
            <TerritoryCarousel
              setSelectedTerritory={setSelectedTerritory}
              selectedTerritory={selectedTerritory}
              projectPortfolio={portfolio}
              setSelectedTerritoryName={setSelectedTerritoryName}
              isExistingTerritory={true}
            />
          )}
          {territoryBool === 'without' && (
            <Box marginTop={5}>
              <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
                What's the Big Idea?
              </Typography>
              <Typography
                paragraph
                gutterBottom
                sx={{ marginBottom: 4, fontSize: 18 }}
              >
                Describe the vision for what you want your creative Asset to
                convey?
              </Typography>
              <Box role="textbox" label="txtboxLabel">
                <BootstrapInput
                  multiline
                  maxRows={3}
                  minRows={3}
                  placeholder="(e.g., Delta will begin flying from New York and Paris in June. We want to highlight the beauty of Paris and our award-winning customer service.)"
                  onChange={(event) => setBigIdea(event.target.value)}
                  style={{
                    '::placeholder': {
                      color: '#868B90',
                      fontFamily: 'GraphikRegular',
                      fontSize: 16,
                      lineHeight: '28px',
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box>
        <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
          Creative Canvas
        </Typography>
        <Typography
          paragraph
          gutterBottom
          sx={{ marginBottom: 4, display: 'inline-block', paddingRight: 1 }}
        >
          Select an Asset type to start generating text and images for.
        </Typography>
        <Box
          id={'genai-app-footer'}
          marginTop="1rem"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '24px',
          }}
        >
          <FormControl sx={{ width: '50%' }}>
            <Select
              displayEmpty={true}
              renderValue={() => {
                return creativeCanvas === ''
                  ? 'Select a creative canvas'
                  : creativeCanvas;
              }}
              labelId="canvas-label"
              onChange={(event) => {
                setCreativeCanvas(platformArray[event.target.value]);
                handleCreateCanvasChange(event.target.value);
              }}
              label="creative-canvas"
              input={<OutlinedInput />}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {Object.keys(platformArray).map((canvas) => {
                return (
                  <MenuItem key={canvas} value={canvas} sx={{ paddingY: 8 }}>
                    {platformArray[canvas]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Buttons
        onClick={() => {
          setAdvancedSetupExpanded(!advancedSetupExpanded);
        }}
        startIcon={advancedSetupExpanded ? <RemoveIcon /> : <AddIcon />}
        sx={{ paddingX: 2, marginTop: 5 }}
      >
        <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
          Advanced Setup Options
        </Typography>
      </Buttons>
      {advancedSetupExpanded && (
        <AdvancedSetup
          setImagesIdea={setImagesIdea}
          setColorsIdea={setColorsIdea}
          setToneOfVoice={setToneOfVoice}
        />
      )}
    </>
  );
};

export default CreateAssetForm;
