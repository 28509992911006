import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Typography, IconButton, InputBase } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const ChatInputRA = ({ onClick, isLoading, chatTextRef }) => {
  const [text, setText] = useState('');

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const resetText = () => {
    setText('');
  };

  const onKeyPressed = (e) => {
    if (e.key === 'Enter') {
      onClick(text);
      resetText();
    }
  }

  useEffect(() => {
    if (isLoading === false)
      chatTextRef.current.focus();
  },[isLoading])

  return (
    <Box sx={{ p: '10px' }}>
      <Box
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #777777',
          borderRadius: '8px',
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, width: '100%' }}
          placeholder={'Type your question here'}
          multiline
          disabled={isLoading}
          autoFocus
          rows={2}
          inputRef={chatTextRef}
          onChange={handleTextChange}
          value={text}
          onKeyDown={(e) => {
            onKeyPressed(e)
          }}
        />
        <IconButton
          type="button"
          sx={{ p: '10px' }}
          aria-label="question"
          onClick={() => {
            onClick(text);
            setText('');
          }}
          onKeyDown={(e) => onKeyPressed(e)}
        >
          <SendIcon />
        </IconButton>
      </Box>
      <Box sx={{ mt: '5px' }}>
        <Typography variant="p">
          Tip: Ask open ended questions rather than closed ended questions for
          more diverse answers.
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatInputRA;
