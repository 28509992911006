import { CNS_ACTION_TYPES } from './cnsActionTypes';

export const INITIAL_CNS_STATE = {
  showIdeas: false,
  chatHistory: [],
  filesHistory: [],
  sessionID: '',
  step: 0,
  analysisSummary: {}
};

export const cnsReducer = (state, action) => {
  switch (action.type) {
    case CNS_ACTION_TYPES.SET_SHOW_IDEAS:
      return {
        ...state,
        showIdeas: action.payload,
      };
    case CNS_ACTION_TYPES.SET_INITIAL_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case CNS_ACTION_TYPES.SET_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: action.payload,
      };
    case CNS_ACTION_TYPES.SET_FILES_URLS:
      return {
        ...state,
        filesHistory: action.payload,
      };
    case CNS_ACTION_TYPES.SET_S3_FOLDER:
      return {
        ...state,
        sessionID: action.payload,
      };
    case CNS_ACTION_TYPES.SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case CNS_ACTION_TYPES.SET_ANALYSIS_SUMMARY:
      return {
        ...state,
        analysisSummary: action.payload,
      };
    default:
      return state;
  }
};