import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import { jsPDF } from 'jspdf';
import React, { useEffect, useState } from 'react';
import theme from '../styles/theme';
import Buttons from './Button';
import SVGIcon from './SVGIcon';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '20px',
  width: '60rem',
  height: '95%',
  overflowY: 'scroll',
  overflowX: 'hidden',
};

const ExportPreview = ({
  isOpenClicked,
  previewTypes,
  displayText,
  setPreviewFlag,
  onClickExport
}) => {
  let keys = Object.keys(previewTypes);
  const tempSelected = keys.filter(function (key) {
    return previewTypes[key]
  });
  const [previewSelected, setPreviewSelected] = useState(tempSelected.toString())
  const dateTime =
    new Date().toLocaleDateString().replaceAll('/', '-') +
    '_' +
    new Date().toLocaleTimeString().replaceAll(':', '-').split(' ')[0];
  const [previewFile, setPreviewFile] = useState({
    type: previewSelected,
    name: `Export_${dateTime}${previewSelected}`,
  });
  const [previewArray, setPreviewArray] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  useEffect(() => {
    setPreviewArray([]);
    if (previewSelected === '.pdf') {
      let pdf = new jsPDF();
      try {
        let text = [];
        displayText.map((each) => {
          if (each.childrenNodes.length) {
            text.push(`label_pdf`);
            text.push(`${each.label}\n`);
            each.childrenNodes.map((ch) => {
              text.push(`sub_label_pdf`);
              text.push(`${ch.label}\n`);
              text.push(`${ch.content}\n`);
            });
          } else {
            text.push(`label_pdf`);
            text.push(`${each.label}\n`);
            text.push(`${each.content}\n`);
          }
        });
        let previewArr = [
          {
            page: 0,
            text: '',
          },
        ];
        let pageNo = 0;
        let bodyContent = pdf.splitTextToSize(text.join('\n'), 200);
        let pageHeight = pdf.internal.pageSize.getHeight();
        let y = 15;
        for (let i = 0; i < bodyContent.length; i++) {
          if (y + 10 > pageHeight && bodyContent[i].trim()) {
            y = 15;
            pdf.addPage();
            pageNo = pageNo + 1;
          }
          if (bodyContent[i - 1] === 'label_pdf') {
            bodyContent[i] =
              '<h4 style="margin-bottom: 0"><strong>' +
              bodyContent[i] +
              '</h4></strong>';
          }
          if (bodyContent[i - 1] === 'sub_label_pdf') {
            bodyContent[i] =
              '<h5 style="margin-bottom: 0"><strong>' +
              bodyContent[i] +
              '</h5></strong>';
          }
          if (
            bodyContent[i] !== 'label_pdf' &&
            bodyContent[i] !== 'sub_label_pdf'
          ) {
            if (pageNo === previewArr[pageNo]?.page) {
              previewArr[previewArr.length - 1].text =
                bodyContent[i].trim() &&
                  !bodyContent[i].includes('<h4') &&
                  !bodyContent[i].includes('<h5')
                  ? previewArr[pageNo]?.text.concat(
                    '<br>',
                    bodyContent[i].trim()
                  )
                  : previewArr[pageNo]?.text.concat('', bodyContent[i].trim());
            } else {
              previewArr.push({
                page: pageNo,
                text: bodyContent[i].trim(),
              });
            }
            y = y + 7;
          }
        }
        setPreviewArray(previewArr);
      } catch (e) {
        console.log('There is an error in export: ', e);
      }
    }
    if (previewSelected === '.doc') {
      const setType = { type: '.doc', name: `Export_${dateTime}${'.doc'}` };
      setPreviewFile(setType);
      let previewArr = [];
      let text = [];
      let pageNo = 0;
      displayText.map((each) => {
        if (each.childrenNodes.length) {
          text.push(`<p><h4><strong>${each.label}</strong></h4></p>`);
          each.childrenNodes.map((ch) => {
            text.push(
              `<p><h5><strong>${ch.label
              }</strong></h5></p><p>${ch.content.replaceAll('\n', '<br>')}</p>`
            );
          });
        } else {
          text.push(
            `<p><h4><strong>${each.label}</strong></h4></p><p>${each.content}</p>`
          );
        }
      });
      text = text.toString().replaceAll(',', '');

      const newText = text.split(' ');
      const len = newText.length;
      const wordcount = 560;
      let page = [];
      for (let i = 0; i < len / wordcount; i++) {
        pageNo = pageNo + 1;
        previewArr.push({
          page: pageNo,
          text: newText.slice(wordcount * i, wordcount * (i + 1)).join(' '),
        });
      }
      setPreviewArray(previewArr);
    }
    if (previewSelected === '.ppt') {
      try {
        let textArr = [];
        displayText.map((each) => {
          if (each.childrenNodes.length) {
            textArr.push({
              type: 'header',
              text: each.label,
              subtype: false
            });
            each.childrenNodes.map((ch) => {
              textArr.push({
                type: 'header',
                text: ch.label,
                subtype: true
              });
              textArr.push({
                type: 'content',
                text: ch.content,
                subtype: false
              });
            });
          } else {
            textArr.push({
              type: 'header',
              text: each.label,
              subtype: true
            });
            textArr.push({
              type: 'content',
              text: each.content,
              subtype: false
            });
          }
        });
        let previewArr = [];
        let pageNo = 0;
        let oldPageNo = 0;
        const increment = 2172;
        for (let i = 0; i < textArr.length; i++) {
          let textLen = textArr[i].text?.length;
          let textType = textArr[i].type;
          for (let j = 0; j < textLen; j += increment) {
            if (textType === 'header') {
              pageNo = pageNo + 1;
              oldPageNo = pageNo;
              previewArr.push({
                page: pageNo,
                text: `<div style="display: flex; justify-content: center; align-items: ${textArr[i].subtype ? 'start' : 'center'}; height: 100%;"><h4><strong>${textArr[i].text}</strong></h4></div>`,
              });
            } else {
              if (textLen < increment) {
                getPPTPreviewText(
                  oldPageNo,
                  pageNo,
                  previewArr,
                  textArr[i].text
                );
              } else {
                getPPTPreviewText(
                  oldPageNo,
                  pageNo,
                  previewArr,
                  textArr[i].text.substring(j, j + increment)
                );
                pageNo = pageNo + 1;
              }
            }
          }
        }
        setPreviewArray(previewArr);
      } catch (e) {
        console.log('There is an error in export: ', e);
      }
    }
  }, []);

  const getPPTPreviewText = (oldPageNo, pageNo, previewArr, text) => {
    if (oldPageNo === pageNo) {
      previewArr[previewArr.length - 1].text = previewArr[
        previewArr.length - 1
      ]?.text.concat('<br>', `<div style="position: absolute; top: 50%; left: 53%; transform: translateX(-50%) translateY(-35%); width: 88%">${text}</div>`);
    } else {
      previewArr.push({
        page: pageNo,
        text: `<div style="position: absolute; top: 50%; left: 53%; transform: translateX(-50%) translateY(-35%); width: 88%">${text}</div>`,
      });
    }
  };

  return (
    <>
      <Modal
        open={isOpenClicked}
        className='modal'
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpenClicked}>
          <Box className='modal_inner_container' sx={style}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                paddingBottom: '10px',
              }}
            >
              <Typography variant='h3' sx={{ alignSelf: 'left' }}>
                Export Preview
              </Typography>
              <Box
                className={'genai-icon-close'}
                sx={{ alignSelf: 'right', height: '32px' }}
              >
                <SVGIcon
                  width={24}
                  height={27}
                  name='close'
                  color={'#000'}
                  onClick={() => {
                    setPreviewFlag(false);
                    setCurrentPageNumber(1);
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                paddingBottom: '10px',
              }}
            >
              {previewSelected === '.pdf' ? (
                <img src='/assets/images/pdf_icon.svg' />
              ) : previewSelected === '.doc' ? (
                <img src='/assets/images/doc_icon.svg' />
              ) : previewSelected === '.ppt' ? (
                <img src='/assets/images/ppt_icon.svg' />
              ) : (
                ''
              )}
              <Typography
                fontSize='16px'
                fontWeight='500'
                color={theme.palette.grey.black}
                paddingLeft='5px'
              >
                {previewFile.name}
              </Typography>
            </Box>
            <Box display='grid' justifyContent='center'>
              <Box
                display='grid'
                justifyContent='center'
                width={
                  previewSelected === '.ppt'
                    ? '95%'
                    : previewSelected === '.doc'
                      ? '70%'
                      : '100%'
                }
                minHeight={
                  previewSelected === '.ppt'
                    ? '576.55px'
                    : previewSelected === '.doc'
                      ? '1398px'
                      : '960px'
                }
                minWidth={
                  previewSelected === '.ppt'
                    ? '858.037px'
                    : ''
                }
                marginLeft={
                  previewSelected === '.ppt'
                    ? '3%'
                    : previewSelected === '.doc'
                      ? '16%'
                      : ''
                }
                sx={{
                  backgroundColor: theme.palette.grey.light3,
                  overflow: 'hidden',
                }}
                padding={
                  previewSelected === '.ppt'
                    ? '0 15px'
                    : '15px'
                }
              >
                {previewArray.length ? (
                  <div
                    style={{ whiteSpace: 'pre-wrap' }}
                    dangerouslySetInnerHTML={{
                      __html: previewArray[currentPageNumber]?.text,
                    }}
                  />
                ) : (
                  ''
                )}
              </Box>
            </Box>
            <Box
              paddingTop='5px'
              paddingBottom='5px'
              display='flex'
              justifyContent='space-between'
              alignItems='baseline'
            >
              <Box width='100%'></Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='100%'
              >
                <Buttons
                  sx={{ alignSelf: 'right' }}
                  startIcon={
                    <SVGIcon
                      width={24}
                      height={24}
                      name='before-arrow'
                      color={
                        currentPageNumber <= 0 ||
                          currentPageNumber > previewArray.length
                          ? theme.palette.grey.main
                          : theme.palette.grey.black
                      }
                    />
                  }
                  disabled={
                    currentPageNumber <= 0 ||
                    currentPageNumber > previewArray.length
                  }
                  onClick={() => setCurrentPageNumber(currentPageNumber - 1)}
                />
                <Typography
                  fontSize='16px'
                  fontWeight='500'
                  color={theme.palette.grey.black}
                >
                  {'Page  ' +
                    (currentPageNumber + 1) +
                    ' / ' +
                    previewArray?.length}
                </Typography>
                <Buttons
                  sx={{ alignSelf: 'right' }}
                  startIcon={
                    <SVGIcon
                      width={24}
                      height={24}
                      name='after-arrow'
                      color={
                        currentPageNumber + 1 == previewArray.length
                          ? theme.palette.grey.main
                          : theme.palette.grey.black
                      }
                    />
                  }
                  disabled={currentPageNumber + 1 == previewArray.length}
                  onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                  paddingTop: '10px',
                }}
              >
                <Buttons
                  variant='song'
                  startIcon={<img src='/assets/images/exportbtn.svg' />}
                  sx={{ marginLeft: '15px' }}
                  onClick={onClickExport}
                >
                  Export Analysis
                </Buttons>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ExportPreview;
