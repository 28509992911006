// this is the data structure for the combined data in AssetWorkspace
const combinedData = [
  {
    id: 'Asset1FuaRMGt',
    type: 'text',
    content: {
      text: "👟 Step into Style: Shoes that Define You! 👟\n\nAre you ready to put your best foot forward and make a statement? Introducing our latest collection of shoes that will not only elevate your fashion game but also reflect your unique personality. From classic designs to trendy kicks, we've got something for everyone!",
    },
  },
  {
    id: 'AssetUbpzaR7Z',
    type: 'text',
    content: {
      text: "Step into style with these incredible shoes!👟✨ Not only are they fashionable, but they also have a hidden superpower: they generate creativity!🌟✨ Whether you're an artist, a writer, or simply someone looking to unleash their imagination, these shoes will take you to new creative heights!",
    },
  },
  {
    id: 'Asset3vHhbJ2f',
    type: 'text',
    content: {
      text: "👟 Step into Style with These Trendy Kicks! 👟\n\nAttention all fashionistas! We've got the perfect pair of shoes to elevate your style game! These shoes are not just any ordinary footwear; they are a fashion statement that will make heads turn wherever you go. ",
    },
  },
  {
    id: 'AssetSbgTcYes',
    type: 'image',
    content: {
      image: '/assets/images/test-image.png',
    },
  },
  {
    id: 'AssetFTLoQVpZ',
    type: 'image',
    content: {
      image: '/assets/images/test-image.png',
    },
  },
  {
    id: 'AssetUU5PZReX',
    type: 'image',
    content: {
      image: '/assets/images/test-image2.png',
    },
  },
];
export const defaultInspectSliderInputsValue= `Equal parts playful and professional, equal parts expected and unexpected, and equal parts abstract and concrete.`
