import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React, { useRef } from 'react';
import {
  assetStyle,
  documentPageStyle,
  gceModalStyle,
  gceStyle,
  territoryStyle,
  mockStyle,
  uxraStyle,
  portfolioTerStyle,
} from '../styles/exportPdfTemplate';
//import { limitWords } from '../util/stringModifierService';

const styleMain = StyleSheet.create(documentPageStyle);
const styleGCE = StyleSheet.create(gceStyle);
const styleGCEModal = StyleSheet.create(gceModalStyle);
const styleUXRA = StyleSheet.create(uxraStyle);
const styleMCS2Ter = StyleSheet.create(territoryStyle);
const styleMCS2Mock = StyleSheet.create(mockStyle);
const styleMCS2Asset = StyleSheet.create(assetStyle);
const styleMCS2PortfolioTer = StyleSheet.create(portfolioTerStyle);

export default function ExportRenderer({
  documentName,
  documentHeading,
  documentData,
}) {
  const documentRef = useRef(null);
  const { title, subTitle, author } = documentHeading ?? {
    title:
      (documentName === 'Export idea' && 'Generative Concepting Engine') ||
      (documentName === 'Export chat' && 'UX Research Assistant') ||
      (documentName === 'Export interview' && 'Research Assistant'),
    subTitle:
      (documentName === 'Export idea' && 'Bookmarked Ideas') ||
      (documentName === 'Export chat' && 'Bookmarked Chat') ||
      (documentName === 'Export interview' && 'Interview History'),
  };

  try {
    //check data properties and modify accordingly

    console.log('DOCUMENT DATA ', documentData);

    if (documentName === 'MCSPortfolioTerritory') {
      const cloneData = JSON.parse(JSON.stringify(documentData));
      console.log('CLONE DATA ', cloneData);

      documentRef.current = Object.values(cloneData).map((each, idx) => {
        console.log('EACH DATA ', each);
        return (
          <>
            <View key={each.id} style={styleMCS2PortfolioTer.item}>
              <View style={styleMCS2PortfolioTer.sectionImage}>
                <Image
                  break
                  src={each.image}
                  alt={`${each.title}_image`}
                  style={styleMCS2PortfolioTer.image}
                />
              </View>
              <View style={styleMCS2PortfolioTer.textWrapper}>
                <Text style={styleMCS2PortfolioTer.largeText}>
                  {each.title}{' '}
                </Text>
                <Text style={styleMCS2PortfolioTer.mediumText}>
                  {each.headline}
                </Text>
                <Text style={styleMCS2PortfolioTer.text}>{each.paragraph}</Text>
                <Text style={styleMCS2PortfolioTer.text}>{each.output}</Text>
              </View>
            </View>
          </>
        );
      });
    }

    if (documentName === 'MCSAsset') {
      const cloneData = JSON.parse(JSON.stringify(documentData));

      const imageType = cloneData.image ? true : false;
      console.log('3 IMage??? //', imageType);

      if (imageType) {
        documentRef.current = (
          <View break key={'123'} style={styleMCS2Asset.item}>
            <View style={styleMCS2Asset.sectionImage}>
              <Text style={styleMCS2Asset.largeText}>'Your Asset</Text>
              <Image
                src={cloneData.image}
                alt={`asset_image`}
                style={styleMCS2Asset.imageA}
              />
            </View>
          </View>
        );
      } else {
        documentRef.current = (
          <View break key={'234'} style={styleMCS2Asset.item}>
            <View style={styleMCS2Asset.sectionText}>
              <Text style={styleMCS2Asset.largeText}>'Your Asset</Text>
              <Text style={styleMCS2Asset.text}>{cloneData.text}</Text>
            </View>
          </View>
        );
      }
    }

    if (documentName === 'MCSMockup') {
      const cloneData = JSON.parse(JSON.stringify(documentData));

      const image = cloneData.imgSrc ?? 'assets/images/Mask3.png';
      const text = cloneData.imgDesc ?? '...';

      const renderMock = (
        <View key={'234'} style={styleMCS2Mock.item}>
          <View style={styleMCS2Mock.sectionHeading}>
            <Text style={styleMCS2Mock.largeText}>'Your Mockup</Text>
            <Text style={styleMCS2Mock.text}>{text}</Text>
          </View>

          <View style={styleMCS2Mock.sectionImage}>
            <Image
              src={image}
              alt={`mockup_image`}
              style={styleMCS2Mock.imageA}
            />
          </View>
        </View>
      );

      documentRef.current = renderMock;
    }

    if (documentName === 'MCSTerritory') {
      let contentData = [];

      contentData.push(JSON.parse(JSON.stringify(documentData)));

      documentRef.current = Object.values(contentData).map((each, idx) => {
        const imageA = each.imageArray[0] ?? '#';
        const imageB = each.imageArray[1] ?? '#';
        const imageC = each.imageArray[2] ?? '#';

        const hex1 = `${each.colorPalette[0]}` ?? '000';
        const hex2 = `${each.colorPalette[1]}` ?? '000';
        const hex3 = `${each.colorPalette[2]}` ?? '000';
        const hex4 = `${each.colorPalette[3]}` ?? '000';
        const hex5 = `${each.colorPalette[4]}` ?? '000';
        const hex6 = `${each.colorPalette[5]}` ?? '000';

        return (
          <View key={each.id} style={styleMCS2Ter.item}>
            <View style={styleMCS2Ter.sectionHeading}>
              <Text style={styleMCS2Ter.largeText}>{each.header} </Text>
              <Text style={styleMCS2Ter.mutedText}>{each.descriptors}</Text>
              <Text style={styleMCS2Ter.text}>{each.description}</Text>
              <Text style={styleMCS2Ter.mediumText}>
                Typeface: {each.typeface}
              </Text>
              <Text style={styleMCS2Ter.mediumText}>
                Palette: {hex1}&nbsp;&nbsp;{hex2}&nbsp;&nbsp;{hex3}&nbsp;&nbsp;
                {hex4}&nbsp;&nbsp;{hex5}&nbsp;&nbsp;{hex6}
              </Text>
            </View>
            <View style={styleMCS2Ter.sectionPalette}>
              <View
                style={{
                  backgroundColor: `#${hex1}`,
                  width: '100px',
                  height: '80%',
                }}
              />
              <View
                style={{
                  backgroundColor: `#${hex2}`,
                  width: '100px',
                  height: '80%',
                }}
              />
              <View
                style={{
                  backgroundColor: `#${hex3}`,
                  width: '100px',
                  height: '80%',
                }}
              />
              <View
                style={{
                  backgroundColor: `#${hex4}`,
                  width: '100px',
                  height: '80%',
                }}
              />
              <View
                style={{
                  backgroundColor: `#${hex5}`,
                  width: '100px',
                  height: '80%',
                }}
              />
              <View
                style={{
                  backgroundColor: `#${hex6}`,
                  width: '100px',
                  height: '80%',
                }}
              />
            </View>

            <View style={styleMCS2Ter.sectionImage}>
              <View style={styleMCS2Ter.mainImageBox}>
                <Image
                  src={imageA}
                  alt={`${each.header}_image`}
                  style={styleMCS2Ter.imageA}
                />
              </View>
              <View style={styleMCS2Ter.stackImageBox}>
                <Image
                  src={imageB}
                  alt={`${each.header}_image`}
                  style={styleMCS2Ter.imageB}
                />
                <Image
                  src={imageC}
                  alt={`${each.header}_image`}
                  style={styleMCS2Ter.imageC}
                />
              </View>
            </View>
          </View>
        );
      });
    }

    if (documentName === 'GCEIdeaModal') {
      documentRef.current = Object.values(documentData).map((each, idx) => {
        return (
          <View key={each.id} style={styleGCEModal.item}>
            <View style={styleGCEModal.sectionImage}>
              <Image
                break
                src={each.image}
                alt={`${each.title}_image`}
                style={styleGCEModal.image}
              />
            </View>
            <View style={styleGCEModal.textBox}>
              <Text style={styleGCEModal.largeText}>{each.title} </Text>
              <Text style={styleGCEModal.mediumText}>{each.headline}</Text>
              <Text style={styleGCEModal.text}>{each.paragraph}</Text>
              <Text style={styleGCEModal.text}>{each.output}</Text>
            </View>
          </View>
        );
      });
    }

    if (documentName === 'Export idea') {
      documentRef.current = Object.values(documentData).map((each, idx) => {
        return (
          <>
            <View key={each.id} style={styleGCE.item}>
              <View style={styleGCE.sectionImage}>
                <Image
                  break
                  src={each.image}
                  alt={`${each.title}_image`}
                  style={styleGCE.image}
                />
              </View>
              <View style={styleGCE.textWrapper}>
                <Text style={styleGCE.largeText}>{each.title} </Text>
                <Text style={styleGCE.mediumText}>{each.headline}</Text>
                <Text style={styleGCE.text}>{each.paragraph}</Text>
                <Text style={styleGCE.text}>{each.output}</Text>
              </View>
            </View>
          </>
        );
      });
    }

    if (documentName === 'Export chat' || documentName === 'Export interview') {
      let contentData = JSON.parse(JSON.stringify(documentData));

      const responseData = contentData.reduce((acc, item, idx) => {
        const questionNumber = item.questionNumber;
        const aiParticipant = {
          pId: idx,
          questionNumber,
          avatar: item.avatarURL,
          bookmarked: item.bookmarked,
          color: item.color,
          age: item.participantAge,
          description: item.participantDescription,
          gender: item.participantGender,
          location: item.participantLocation,
          name: item.participantName,
          temperature: item.participantTemperature,
          source: item.source,
          answer: { response: item.text, name: item.participantName },
          interview: {
            ask: '',
            response: item.text,
            name: item.paricipantName,
            qId: item.question,
          },
        };

        if (!acc[questionNumber]) {
          acc[questionNumber] = {
            aiParticipant: [],
          };
        }

        if (item.user === true) {
          const insert = { interview: { ask: item.text, qId: questionNumber } };
          acc[questionNumber].aiParticipant.push(insert);
        } else {
          acc[questionNumber].aiParticipant.push(aiParticipant);
        }
        return acc;
      }, {});

      const convertDataToArray = Object.values(responseData);
      const extractedArrayProperties = [].concat(
        ...convertDataToArray.map((obj) => obj.aiParticipant)
      );

      documentRef.current = Object.values(extractedArrayProperties).map(
        (each, idx) => {
          const keyId = `${idx}-${each ? 'user' : 'ai'}`;

          return (
            <View key={keyId} wrap={false} style={styleUXRA.main}>
              <View break style={styleUXRA.sectionIntro}>
                {each.interview && each.interview.qId && !each.answer && (
                  <View style={styleUXRA.questionBox}>
                    <Text style={styleUXRA.questionText}>
                      Question {each.interview.qId} : {each.interview.ask}
                    </Text>
                  </View>
                )}
                {each.pId && (
                  <View style={styleUXRA.headingBar}>
                    <Text style={styleUXRA.nameText}>{each.name}</Text>
                    <Text style={styleUXRA.describeText}>
                      {each.description} from {each.location} | {each.age} old{' '}
                      {each.gender}
                    </Text>
                  </View>
                )}

                {each.pId && (
                  <>
                    <View break style={styleUXRA.answerBox}>
                      <Text style={styleUXRA.describeText}>
                        {each.name} answer to Question {each.questionNumber}
                      </Text>
                      <Text style={styleUXRA.footerText}>{each.source}</Text>
                      <Text style={styleUXRA.answerText}>
                        {each.answer.response}
                      </Text>
                    </View>
                  </>
                )}
              </View>
            </View>
          );
        }
      );
    }
  } catch (e) {
    console.log('Error in Document renderData ', e);
  }

  return (
    <Document
      id={'export-renderer-doc'}
      onRender={(result) => console.log('onRender DOC -', result)}
    >
      <Page pageMode="useThumbs" size="A4" style={styleMain.body}>
        <Text style={styleMain.title}>{title ?? ''}</Text>
        <Text style={styleMain.author}>{author ?? 'Accenture SONG'}</Text>

        <Text style={styleMain.subTitle}>{subTitle ?? ''}</Text>

        <View
          style={
            (documentName === 'Export idea' && styleGCE.container) ||
            (documentName === 'Export chat' && styleUXRA.container) ||
            (documentName === 'Export interview' && styleUXRA.container) ||
            (documentName === 'GCEIdeaModal' && styleGCE.container) ||
            (documentName === 'MCSAsset' && styleMCS2Ter.container) ||
            (documentName === 'MCSTerritory' && styleMCS2Ter.container) ||
            (documentName === 'MCSMockup' && styleMCS2Mock.container) ||
            (documentName === 'MCSPortfolioTerritory' &&
              styleMCS2PortfolioTer.container)
          }
        >
          {documentRef.current ?? null}
        </View>
        <View style={styleMain.pageNumberBox}>
          <Text
            style={styleMain.pageNumber}
            fixed
            renderData={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
}
