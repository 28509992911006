import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Logout from '@mui/icons-material/Logout';
import Person from '@mui/icons-material/Person';
import Settings from '@mui/icons-material/Settings';
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { navLinks } from '../assets/data/constants/navLinks';
import theme from '../styles/theme';
import Buttons from './Button';
import SVGIcon from './SVGIcon';

const listItemTextStyle = {
  color: theme.palette.grey.white,
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '20px',
};

export default function NavDrawer({ open, setOpen }) {
  const { pink, lightPurple, darkPurple, lightBlue } = theme.palette.gradient;
  const { pathname } = useLocation();

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setOpen(false);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const renderNavLinks = () => {
    return navLinks.map((item) => (
      <ListItem key={item.text} disablePadding>
        <ListItemButton
          component="a"
          href={item.url}
          sx={{
            padding: '8px 24px',
            transition: 'box-shadow 0.3s', // Smooth transition for border
            '&:hover': {
              boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
              borderRadius: '5px',
              '& .MuiListItemIcon-root': {
                transition: 'color 0.3s', // Smooth transition for border
                color: theme.palette.primary.main, // Icon color on hover
              },
            },
          }}
        >
          <ListItemText
            primary={item.text}
            primaryTypographyProps={{ style: listItemTextStyle }}
          />
          <ListItemIcon
            sx={{
              fontSize: 20,
              color: theme.palette.grey.white,
              justifyContent: 'end',
            }}
          >
            <ArrowForwardIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    ));
  };

  return (
    <Drawer anchor={'left'} open={open} onClose={() => setOpen(false)}>
      <Box
        className="NavDrawer"
        sx={{
          width: 490,
          padding: '48px',
          background: theme.palette.primary.dark,
          height: '100%',
          minHeight: 1000,
        }}
        role="presentation"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <Buttons href={'https://www.maap.one/map'} sx={{
            display: 'flex'
          }}>
            <SVGIcon
              name=""
              color={theme.palette.grey.white}
              height={18}
              width={26}
              style={{ marginTop: '5px' }}
            />
            <Typography
              variant="h3"
              component={'p'}
              color={theme.palette.grey.white}
              marginLeft={2}
            >
             INTROSPECTION{' '}
              <span
                style={{
                  fontFamily: 'Montserrat',
                  marginRight: '10px',
                }}
              >
                
              </span>
            </Typography>
          </Buttons>
          <Button
            onClick={() => setOpen(false)}
            style={{ position: 'absolute', right: '48px' }}
          >
            <HighlightOffIcon fontSize="large" style={{ color: 'white' }} />
          </Button>
        </Box>
        <Box
          sx={{
            height: '1px',
            width: '100%',
            background: `linear-gradient(to right, ${pink}, ${lightPurple}, ${darkPurple}, ${lightBlue} 120%)`,
            marginBottom: '24px',
          }}
        />

        <Box>
          <List
            sx={{
              '& .MuiListItem-root:not(:last-child)': {
                marginBottom: '16px',
              },
            }}
          >
            {renderNavLinks()}
          </List>
        </Box>
        <Box
          sx={{
            height: '1px',
            width: '100%',
            background: `linear-gradient(to right, ${pink}, ${lightPurple}, ${darkPurple}, ${lightBlue} 120%)`,
            marginY: '48px',
          }}
        />
        <Box>
          <List>
            <ListItem key="saveditems" disablePadding>
              <ListItemButton component="a" href="#" disableGutters>
                <ListItemIcon
                  sx={{
                    fontSize: 20,
                    color: theme.palette.grey.white,
                  }}
                >
                  <FavoriteIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Saved items"
                  primaryTypographyProps={{ style: listItemTextStyle }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key="profile" disablePadding>
              <ListItemButton component="a" href="#" disableGutters>
                <ListItemIcon
                  sx={{
                    fontSize: 20,
                    color: theme.palette.grey.white,
                  }}
                >
                  <Person />
                </ListItemIcon>
                <ListItemText
                  primary="View profile"
                  primaryTypographyProps={{ style: listItemTextStyle }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key="settings" disablePadding>
              <ListItemButton component="a" href="#" disableGutters>
                <ListItemIcon
                  sx={{
                    fontSize: 20,
                    color: theme.palette.grey.white,
                  }}
                >
                  <Settings />
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  primaryTypographyProps={{ style: listItemTextStyle }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key="logout" disablePadding>
              <ListItemButton onClick={() => handleSignOut()} disableGutters>
                <ListItemIcon
                  sx={{
                    fontSize: 20,
                    color: theme.palette.grey.white,
                  }}
                >
                  <Logout />
                </ListItemIcon>
                <ListItemText
                  primary="Log Out"
                  primaryTypographyProps={{ style: listItemTextStyle }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Drawer>
  );
}
