import { Box, CardContent, InputBase, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import theme from '../../styles/theme';
import ExpandCard from './ExpandCard';

export default function RefineSlider({
  directionText,
  setDirectionText,
  versatility,
  setVersatility,
  expectation,
  setExpectation,
  clarity,
  setClarity,
}) {
  const [isFocused, setIsFocused] = useState(false);

  const versatilityMarks = [
    { value: 0, label: 'Playful' },
    { value: 1, label: 'Professional' },
  ];
  const expectationMarks = [
    { value: 0, label: 'Expected' },
    { value: 1, label: 'Unexpected' },
  ];
  const clarityMarks = [
    { value: 0, label: 'Abstract' },
    { value: 1, label: 'Concrete' },
  ];

  const RefinementSlider = styled(Slider)({
    '& .MuiSlider-markLabel[data-index="0"]': {
      transform: 'translateX(-10%)',
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      transform: 'translateX(-100%)',
      left: '80%',
    },
  });

  const cardContent = (
    <>
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          alignItems: 'flex-start',
          flexDirection: 'column',
          marginBottom: '1.5rem',
        }}
      >
        <RefinementSlider
          track={true}
          aria-label="Always visible"
          value={versatility}
          valueLabelDisplay="off"
          min={0}
          step={0.25}
          max={1}
          marks={versatilityMarks}
          classes="mcs-mood-slider"
          onChange={(event) => setVersatility(event.target.value)}
        />
        <RefinementSlider
          track={true}
          aria-label="Always visible"
          value={expectation}
          valueLabelDisplay="off"
          min={0}
          step={0.25}
          max={1}
          marks={expectationMarks}
          classes="mcs-mood-slider"
          onChange={(event) => setExpectation(event.target.value)}
        />
        <RefinementSlider
          track={true}
          aria-label="Always visible"
          value={clarity}
          valueLabelDisplay="off"
          min={0}
          step={0.25}
          max={1}
          marks={clarityMarks}
          classes="mcs-mood-slider"
          onChange={(event) => setClarity(event.target.value)}
        />
      </Box>
      <Typography
        variant="body3"
        id="track-false-slider"
        sx={{
          width: '100%',
          fontFamily: 'GraphikRegular',
        }}
      >
        Provide direction to adjust results
      </Typography>

      <InputBase
        placeholder={'( e.g., make the results more uplifting and edgy)'}
        onChange={(event) => setDirectionText(event.target.value)}
        value={directionText}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        multiline
        rows={4}
        disabled={false}
        sx={{
          flex: 1,
          width: '100%',
          border: `1px solid ${theme.palette.grey.light2}`,
          borderRadius: '8px',
          '& textarea': {
            fontSize: '14px',
            padding: '16px 20px',
            lineHeight: '14px',
          },
        }}
      />
    </>
  );

  return (
    <ExpandCard title="Refine Your Results" isSliderDefault>
      <CardContent>{cardContent}</CardContent>
    </ExpandCard>
  );
}
