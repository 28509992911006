import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import theme from '../styles/theme';

import { MCSContext } from '../context/MCSContext';
import { MCS_APPLICATION_Constants } from '../types/applicationConstants';

const RadioQuestions = ({ channel, setType, forceChecked }) => {
  const state = useContext(MCSContext);
  const [channelArray, setChannelArray] = useState([]);
  const [label, setLabel] = useState('');

  useEffect(() => {
    setType('');
    if (channel === 'content') {
      // eslint-disable-next-line quotes
      setLabel("Select the content type(s) you'd like to create:");
      setChannelArray([
        {
          label: 'Image',
          value: MCS_APPLICATION_Constants.CMS_IMAGE,
        },
        {
          label: 'Text',
          value: MCS_APPLICATION_Constants.CMS_TEXT,
        },
        {
          label: 'Image + Text',
          value: MCS_APPLICATION_Constants.CMS_IMAGE_TEXT,
        },
      ]);
    }

    if (channel === 'media') {
      setLabel('Which social channel would you like to design for?');
      setChannelArray([
        { label: 'Facebook', value: 'Facebook' },
        { label: 'Instagram', value: 'Instagram' },
        { label: 'LinkedIn', value: 'LinkedIn' },
        { label: 'Twitter', value: 'Twitter' },
      ]);
    }

    if (channel === 'export') {
      setChannelArray([
        { label: 'Export bookmarked chat', value: 'Export chat' },
        { label: 'Export interview', value: 'Export interview' },
      ]);
    }
  }, [channel]);

  return (
    <>
      <Box
        sx={{
          marginTop: '1.5rem',
        }}
      >
        <Box
          sx={{
            marginBottom: '5px',
          }}
        >
          <Typography id="radio-buttons-label" variant="p">
            {label || ''}
          </Typography>
        </Box>
        <RadioGroup
          aria-labelledby="radio-buttons-label"
          name="radio-buttons-group"
          defaultValue={
            channel === 'content'
              ? state.contentType
              : channel === 'media'
              ? state.media
              : ''
          }
        >
          <Box display={channel === 'export' ? 'grid' : 'flex'}>
            {channelArray?.map((each, idx) => {
              return (
                <FormControlLabel
                  key={`key_${idx}`}
                  id={`radio_${idx}`}
                  sx={{ fontSize: '12px' }}
                  value={each.value}
                  control={
                    <Radio
                      onClick={() => {
                        setType(each.value);
                      }}
                      sx={{
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                          {
                            border: `1px solid ${theme.palette.primary.main}`,
                            borderRadius: '50%',
                            fill: 'none',
                          },
                        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  }
                  label={<span style={{ fontSize: '16px' }}>{each.label}</span>}
                />
              );
            })}
          </Box>
        </RadioGroup>
      </Box>
    </>
  );
};

RadioQuestions.propTypes = {
  channel: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
};

export default RadioQuestions;
