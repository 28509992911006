import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import theme from '../../styles/theme';

function GCEModalConversation({ data }) {
  const { headline, paragraph } = data;

  return (
    <Box className="conversation_container">
      <Grid className="conversation_grid" container spacing={0}>
        <Grid
          className="conversation_grid_300"
          sx={{
            minWidth: 300,
          }}
        >
          <Box
            className="conversation_content"
            sx={{
              justifyContent: 'left',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography paragraph sx={{ margin: 0 }}>
              {headline}
            </Typography>
            <Grid container spacing={2}>
              <Grid>
                <Typography
                  sx={{ fontSize: 18, marginTop: 2, ...theme.typography.h4 }}
                >
                  Description
                </Typography>
                <Box
                  margin="16px 0 24px 0"
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '24px',
                  }}
                >
                  {paragraph}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GCEModalConversation;
