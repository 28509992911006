import React from 'react';

const footerStyle = {
  position: 'fixed',
  width: '100%',
  bottom: 0,
  left: 0,
  borderTop: '10px',
  fontSize: '25px',
  backgroundColor: 'white',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  zIndex: 999,
};

const footerButtonStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingRight: '80px',
};

const RAFooterButton = ({ style, children }) => {
  return (
    <footer style={{ ...footerStyle, ...style }}>
      {children}
    </footer>
  );
};

export default RAFooterButton;
