import { createTheme } from '@mui/material/styles';
import MontserratItalic from '../assets/fonts/Montserrat-Italic.ttf';
import Montserrat from '../assets/fonts/Montserrat.ttf';
import EBGaramond from '../assets/fonts/EBGaramond.ttf';


const theme = createTheme({
  spacing: (factor) => `${0.25 * factor}rem`,
  palette: {
    gradient: {
      pink: '#FF50A0',
      lightPurple: '#1E1E1E',
      darkPurple: '#7500C0',
      lightBlue: '#1E1E1E',
    },
    primary: {
      main: '#1E1E1E', // Electric Blue
      dark: '#1E1E1E', // Dark Blue
      light: '#CCE3FF',
      light2: '#E6F1FF', // Very Light Blue
    },
    secondary: {
      main: '#FFD600', // Yellow
      pink: '#FF66C3',
      blue: '#1E1E1E',
    },
    error: {
      main: '#EE3C31', // Red
      light: '#FFD7D4',
    },
    warning: {
      main: '#1E1E1E', // Yellow
      dark: '#CEAF09',
    },
    success: {
      main: '#21A366', // Green
      light: '#B6F4D7',
    },
    blue: {
      main: '#B8D7FF',
      light: '#E6F1FF',
      link1: '#0573FF',
    },
    grey: {
      main: '#868B90',
      light: '#E3E9EF',
      light1: '#EEEEEE',
      light2: '#C9D0D7',
      light3: '#CCCCCC',
      dark: '#444749',
      dark1: '#534C56',
      white: '#FFFFFF',
      black: '#1E1E1E',
      step_rule: '#8e9399', // stepper rule color grey
      btn_font_grey: '#00000042',
    },
    opacity: {
      clear0: '#FFFFFF00',
      clear1: '#E4E2E282',
      clear3: '#A09F9F7A',
      clear4: '#85848482',
      clear6: '#32323282',
      clear8: '#0C0C0C82',
    },
    state: {
      disabled_primary: '#C5A60A', // use for primary button
      disabled_secondary: '#5C6166', // use for secondary button
    },
    random: {
      // random colors that aren't really part of any palette or reused?
      bannerBackgroundDark: '#000236',
    },
  },

  typography: {
    h1: {
      fontFamily: 'EBGaramond',
      fontSize: '48px',

    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: '32px',

    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: '24px',

    },
    h4: {
      fontFamily: 'Montserrat',
      fontSize: '18px',

    },
    h5: {
      fontFamily: 'Montserrat',
      fontSize: '15px',

    },
    body1: {
      fontFamily: 'MontserratLight',
      fontSize: '20px',
    },
    body2: {
      fontFamily: 'EBGaramondLight',
      fontSize: '18px',
    },
    body3: {
      fontFamily: 'MontserratLight',
      fontSize: '16px',
    },
    body4: {
      fontFamily: 'EBGaramondLight',
      fontSize: '14px',

    },
    body5: {
      fontFamily: 'MontserratLight',
      fontSize: '12px',
    },
    body6: {
      fontFamily: 'Montserrat',
      fontSize: '9px',
      lineHeight: '1px',
    },
    headerTitle: {
      fontFamily: 'EBGaramond',
      fontSize: '24px',
    },
    headerTitleHomepage: {
      fontFamily: 'EBGaramondLight',
      fontSize: '54px',
      letterSpacing: '2px',
    },
    displayLarge: {
      fontFamily: 'EBGaramond',
      fontSize: '64px',
      letterSpacing: '-2px',
    },
    bigHeader1: {
      fontFamily: 'MontserratLight',
      fontSize: '36px',
    },
    bigHeader2: {
      fontFamily: 'EBGaramondLight',
      fontSize: '36px',
      letterSpacing: '1px',
    },
    subtitle1: {
      fontFamily: 'Montserrat',
      fontSize: '20px',

      letterSpacing: '0',
    },
    buttonLabel: {
      fontFamily: 'Montserrat',
      fontSize: '18px',

      letterSpacing: '0',
    },
    pillLabel: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0',
    },
    link1: {
      fontFamily: 'Montserrat',
      fontSize: '20px',

      letterSpacing: '0',
      textDecoration: 'underline',
      color: '#0573ff',
    },
    link2: {
      fontFamily: 'Montserrat',
      fontSize: '18px',

      letterSpacing: '0',
      textDecoration: 'underline',
      color: '#0573ff',
    },
    link3: {
      fontFamily: 'Montserrat',
      fontSize: '16px',

      letterSpacing: '0',
      textDecoration: 'underline',
      color: '#0573ff',
    },
    mediaTitle: {
      fontFamily: 'Montserrat',
      fontSize: '12px',

      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: '#444749',
    },
    appCardTitle: {
      fontFamily: 'Montserrat',
      fontSize: '24px',

      letterSpacing: '0',
    },
    ideaTitle: {
      fontFamily: 'Montserrat',
      fontSize: '15px',

      letterSpacing: '0',
    },
    imageLabel: {
      fontFamily: 'Montserrat',
      fontSize: '20px',

      letterSpacing: '0',
    },
    alertTitle: {
      fontFamily: 'Montserrat',
      fontSize: '16px',

      letterSpacing: '0',
    },
    inputLabel: {
      fontFamily: 'Montserrat',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0',
    },
    alertMessage: {
      fontFamily: 'Montserrat',
      fontSize: '14px',

      letterSpacing: '0',
    },
    sliderLabel: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      lineHeight: '26px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'EBGaramond';
          font-style: normal;
          src: local('EBGaramond'), 
          local('EBGaramond'), 
          url(${EBGaramond}) format('ttf');
        },
          @font-face {
          font-family: 'EBGaramondLight';
          font-style: normal;
          src: local('EBGaramond'), 
          local('EBGaramond'), 
          url(${EBGaramond}) format('ttf');
        },
      
         @font-face {
          font-family: "Montserrat";
          font-style: normal;
          src: local('Montserrat'),
          local('Montserrat'), 
          url(${Montserrat}) format("truetype");
         },
         @font-face {
          font-family: "MontserratItalic";
          font-style: italic;
          src: local('MontserratItalic'),
          local('MontserratItalic'), 
          url(${MontserratItalic}) format("truetype");
         }
      `,
    },
    MuiImageListItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          width: '500px',
          height: '800px',
          gridTemplateColumns: '800px 800px',
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          fontFamily: 'Montserrat',
        }),
      },
      variants: [
        {
          props: { variant: 'song', color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.grey.black,
            backgroundColor: theme.palette.secondary.main,
            '&&:hover': {
              color: theme.palette.grey.black,
              backgroundColor: 'rgba(255,190,0)',
            },
            '&&:focus': {
              outline: '5px solid ' + theme.palette.primary.main,
              outlineOffset: '5px',
            },
            borderRadius: '32px',

            fontSize: '18px',
            lineheight: '24px',
            letterSpacing: '0',
            padding: '16px 24px 16px 24px',
            minWidth: '140px',
          }),
        },
        {
          props: { variant: 'song', color: 'secondary' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey.white,
            '&&:hover': {
              color: theme.palette.grey.white,
              backgroundColor: theme.palette.primary.main,
            },
            '&&:focus': {
              outline: '5px solid ' + theme.palette.primary.main,
              border: '1px solid ' + theme.palette.primary.main,
              outlineOffset: '5px',
            },
            outline: '1px solid ' + theme.palette.primary.main,
            borderRadius: '32px',
            fontSize: '18px',
            lineheight: '24px',
            letterSpacing: '0',
            padding: '16px 24px 16px 24px',
            minWidth: '140px',
          }),
        },
        {
          props: { variant: 'song', color: 'primary', size: 'small' },
          style: ({ theme }) => ({
            color: theme.palette.grey.black,
            backgroundColor: theme.palette.secondary.main,
            outline: '1px thin ' + theme.palette.grey.black,
            borderRadius: '24px',

            fontSize: '8px',
            lineheight: '8px',
            letterSpacing: '0',
            padding: '0px 0px 0px 0px',
          }),
        },
        {
          props: { variant: 'song', color: 'secondary', size: 'tiny' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey.white,
            outline: '1px thin ' + theme.palette.primary.main,
            borderRadius: '20px',

            fontSize: '9px',
            lineheight: '9px',
            letterSpacing: '0',
            padding: '0px 0px',
          }),
        },
        {
          props: { variant: 'song', color: 'dark' },
          style: ({ theme }) => ({
            color: theme.palette.grey.white,
            backgroundColor: theme.palette.primary.dark,
            '&&:hover': {
              color: theme.palette.primary.dark,
              backgroundColor: theme.palette.grey.white,
            },
            '&&:focus': {
              outline: '5px solid ' + theme.palette.primary.dark,
              outlineOffset: '5px',
            },
            outline: '1px solid ' + theme.palette.grey.white,
            borderRadius: '32px',

            fontSize: '18px',
            lineheight: '24px',
            letterSpacing: '0',
            padding: '16px 24px 16px 24px',
          }),
        },
        {
          props: { variant: 'pill', size: 'small' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey.white,
            '&&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
            },
            '&&:focus': {
              outline: '2px solid ' + theme.palette.primary.main,
              outlineOffset: '2px',
            },
            borderRadius: '4px',
            outline: '1px solid ' + theme.palette.primary.main,

            fontSize: '12px',
            lineheight: '16px',
            letterSpacing: '8',
            padding: '8px 16px 8px 16px',
          }),
        },
        {
          props: { selected: !null, variant: 'pill', size: 'small' },
          style: ({ theme }) => ({
            color: theme.palette.grey.white,
            backgroundColor: theme.palette.primary.main,
            '&&:hover': {
              color: theme.palette.grey.white,
              backgroundColor: theme.palette.primary.main,
            },
            '&&:focus': {
              outline: '2px solid ' + theme.palette.primary.main,
              outlineOffset: '2px',
            },
            borderRadius: '4px',
            outline: '1px solid ' + theme.palette.primary.main,

            fontSize: '12px',
            lineheight: '16px',
            letterSpacing: '8',
            padding: '8px 16px 8px 16px',
          }),
        },
        {
          props: { variant: 'pill', size: 'large' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey.white,
            '&&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
            },
            '&&:disabled': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.grey.white,
            },
            '&&:focus': {
              outline: '5px solid ' + theme.palette.primary.main,
              outlineOffset: '5px',
            },
            borderRadius: '4px',
            outline: '1px solid ' + theme.palette.primary.main,

            fontSize: '16px',
            lineheight: '26px',
            letterSpacing: '0',
            padding: '16px 16px 16px 16px',
          }),
        },
        {
          props: { selected: !null, variant: 'pill', size: 'large' },
          style: ({ theme }) => ({
            color: theme.palette.grey.white,
            backgroundColor: theme.palette.primary.main,
            '&&:hover': {
              color: theme.palette.grey.white,
              backgroundColor: theme.palette.primary.main,
            },
            '&&:focus': {
              outline: '5px solid ' + theme.palette.primary.main,
              outlineOffset: '5px',
            },
            borderRadius: '4px',

            fontSize: '16px',
            lineheight: '26px',
            letterSpacing: '0',
            padding: '16px 16px 16px 16px',
          }),
        },
        {
          props: {
            variant: 'pill-multi-select',
            size: 'large',
            color: 'primary',
          },
          style: ({ theme }) => ({
            color: theme.palette.grey.white,
            backgroundColor: theme.palette.primary.main,
            '&&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
            },
            '&&:focus': {
              outline: '5px solid ' + theme.palette.primary.main,
              outlineOffset: '5px',
            },
            borderRadius: '4px',
            outline: '1px solid ' + theme.palette.primary.main,

            fontSize: '16px',
            lineheight: '26px',
            letterSpacing: '0',
            padding: '16px 16px 16px 16px',
          }),
        },
        {
          props: {
            variant: 'pill-multi-select',
            size: 'large',
            color: 'secondary',
          },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey.white,
            '&&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
            },
            '&&:focus': {
              outline: '5px solid ' + theme.palette.primary.main,
              outlineOffset: '5px',
            },
            borderRadius: '4px',
            outline: '1px solid ' + theme.palette.primary.main,

            fontSize: '16px',
            lineheight: '26px',
            letterSpacing: '0',
            padding: '16px 16px 16px 16px',
          }),
        },
        {
          props: { variant: 'white' },
          style: ({ theme }) => ({
            color: theme.palette.grey.white,
            backgroundColor: theme.palette.opacity.clear0,
            border: `1.5px solid ${theme.palette.grey.white}`,
            borderRadius: '32px',
            fontSize: '18px',
            lineheight: '24px',
            padding: '16px 24px 16px 24px',
            '&&:hover': {
              color: theme.palette.grey.white,
              backgroundColor: theme.palette.opacity.clear0,
            },
            '&&:focus': {
              outline: '5px solid ' + theme.palette.primary.main,
              outlineOffset: '5px',
            },
          }),
        },
        {
          props: { variant: 'link-light' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            '&&:hover': {
              color: theme.palette.primary.dark,
            },

            fontSize: '1rem',
            lineheight: '1rem',
            letterSpacing: '0',
            textDecoration: 'underline',
          }),
        },
        {
          props: { variant: 'transparent' },
          style: ({ theme }) => ({
            color: theme.palette.grey.white,
            backgroundColor: theme.palette.opacity.clear0,
            zIndex: 900,
            '&&:hover': {
              backgroundColor: theme.palette.opacity.clear0,
            },
            '&&:focus': {
              outline: '2px solid ' + theme.palette.primary.main,
            },
            border: '1px solid ' + theme.palette.grey.white,
            borderRadius: '32px',

            fontSize: '18px',
            lineheight: '24px',
            letterSpacing: '0',
            padding: '16px 24px 16px 24px',
          }),
        },
        {
          props: { variant: 'pill-mcs', size: 'large' },
          style: ({ theme }) => ({
            '&&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
            },
            '&&:disabled': {
              color: theme.palette.grey.light2,
            },
            '&&:focus': {
              outline: '1px solid ' + theme.palette.primary.main,
              outlineOffset: '3px',
            },
            fontFamily: 'Montserrat',
            outline: '0px solid ' + theme.palette.grey.light2,
            fontSize: '18px',
            lineheight: '24px',
            letterSpacing: '0',
            padding: '10px 16px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            color: theme.palette.grey.black,
            backgroundColor: theme.palette.grey.white,
            borderRadius: '8px',
          }),
        },
        {
          props: { variant: 'song-test', size: 'xxxl', disabled: !null },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey.white,
            outline: '1px solid ' + theme.palette.primary.main,
            borderRadius: '32px',
            fontSize: '18px',
            lineheight: '24px',
            letterSpacing: '0',
            padding: '16px 24px 16px 24px',
          }),
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: () => ({}),
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: ({ theme }) => ({
            borderRadius: '8px',
            background: theme.palette.grey.white,
            boxShadow: `0px 2px 18px 0px ${theme.palette.opacity.clear0}`,
            backdropFilter: 'blur(20px)',
            maxWidth: '748px',
            height: '100%',
            padding: '16px',
          }),
        },
        {
          props: { severity: 'error' },
          style: ({ theme }) => ({
            border: '1px solid ' + theme.palette.error.main,
          }),
        },
        {
          props: { severity: 'warning' },
          style: ({ theme }) => ({
            border: '1px solid ' + theme.palette.warning.main,
          }),
        },
        {
          props: { severity: 'success' },
          style: ({ theme }) => ({
            border: '1px solid ' + theme.palette.success.main,
          }),
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme }) => ({
          'MuiSlider-valueLabelCircle': {
            position: 'relative',
          },
          '.MuiSlider-valueLabel': {
            fontFamily: 'Montserrat',
            backgroundColor: theme.palette.opacity.clear0,
            color: theme.palette.grey.black,
          },
        }),
        valueLabel: ({ ownerState, theme }) => ({
          ...(ownerState.marks[0].label === '100 yr' && {
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontStyle: 'italic',
            position: 'absolute',
            top: '40px',
            zIndex: '200',
          }),
          ...(ownerState.classes === 'mcs-mood-slider' && {
            fontFamily: 'Montserrat',
            fontSize: '16px',
          }),
        }),
        rail: ({ ownerState, theme }) => ({
          ...(ownerState.classes === 'mcs-mood-slider' && {
            backgroundColor: theme.palette.blue.main,
            height: '8px',
            width: '100%',
          }),
        }),
        track: ({ ownerState, theme }) => ({
          ...(ownerState.classes === 'mcs-mood-slider' && {
            backgroundColor: theme.palette.primary.main,
            height: '6px',
            width: '98%',
          }),
        }),
        markLabel: ({ ownerState, theme }) => ({
          ...(ownerState.classes === 'mcs-mood-slider' && {
            color: theme.palette.grey.black,
            fontSize: '12px',
          }),
        }),
        mark: ({ ownerState, theme }) => ({
          ...(ownerState.classes === 'mcs-mood-slider' && {
            display: 'flex',
            justifyContent: 'space-between',
          }),
        }),

        valueLabelLabel: ({ ownerState, theme }) => ({
          ...(ownerState.classes === 'mcs-mood-slider' && {
            transform: 'translateX(-100%)',
            marginLeft: '50px',
          }),
        }),
      },
    },
  },
});

export default theme;
