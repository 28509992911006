import { GCE_ACTION_TYPES } from './gceActionTypes';

export const INITIAL_GCE_STATE = {
  generateIdeas: false,
  ideaType: '',
  chatHistory: [],
  ideas: [],
};

export const gceReducer = (state, action) => {
  switch (action.type) {
    case GCE_ACTION_TYPES.SET_INITIAL_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case GCE_ACTION_TYPES.GENERATE_IDEAS:
      return {
        ...state,
        generateIdeas: action.payload,
      };
    case GCE_ACTION_TYPES.SET_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: action.payload,
      };
    case GCE_ACTION_TYPES.UPDATE_IDEA_HISTORY:
    case GCE_ACTION_TYPES.SET_IDEAS:
      return {
        ...state,
        ideas: action.payload,
      };
    default:
      return state;
  }
};
