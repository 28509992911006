import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react';
import theme from '../../styles/theme';

const CNSSummary = ({ handleChangeForCheckbox, showExportOptions, summary }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {showExportOptions && (
          <FormControlLabel
            sx={{ alignItems: 'start' }}
            control={
              <Checkbox
                name={summary?.name}
                checked={summary?.checked}
                onChange={handleChangeForCheckbox}
              />
            }
          />
        )}
        <Typography
          variant="h3"
          fontSize={24}
          fontWeight={600}
          lineHeight={'45px'}
          sx={{ marginBottom: 2 }}
        >
          {summary?.label}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.grey.white,
          padding: '48px 32px',
          flexGrow: 0,
          flexDirection: 'column',
          justifyContent: 'space-between',
          zIndex: '100',
          boxSizing: ' border-box',
          marginBottom: 1,
          marginTop: '20px',
          marginLeft: `${showExportOptions ? '48px' : '0px'}`,
          boxShadow: '0px 4px 40px 0px rgba(30, 30, 30, 0.10)',
        }}
      >
        {summary?.content}
      </Box>
    </>
  );
};
export default CNSSummary;
