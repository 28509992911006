import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import React, { useState } from 'react';
import ExpandCard from './ExpandCard';


const CreativeCanvas = ({ handleCreateCanvasChange, oldCreativeCanvas }) => {
  const platformArray = {
    instagram_post: 'Instagram Post',
    linkedin_post: 'LinkedIn Post',
    facebook_post: 'Facebook Post',
    youtube_thumbnail_post: 'Youtube Thumbnail',
    twitter_post: 'Twitter Post',
  };
  const [creativeCanvas, setCreativeCanvas] = useState(platformArray[oldCreativeCanvas]);

  return (
    <ExpandCard title="Creative Canvas" isSliderDefault>
      <Box>
        <Box
          id={'genai-app-footer'}
          marginTop="1rem"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '24px',
          }}
        >
          <FormControl sx={{ width: '100%' }}>
            <Select
              displayEmpty={true}
              renderValue={() => {
                return creativeCanvas === '' ? 'Select a creative canvas' : creativeCanvas;
              }}
              labelId="canvas-label"
              onChange={(event) => {
                setCreativeCanvas(platformArray[event.target.value]);
                handleCreateCanvasChange(event.target.value);
              }}
              label="creative-canvas"
              input={<OutlinedInput />}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {Object.keys(platformArray).map((canvas) => {
                return (
                  <MenuItem key={canvas} value={canvas} sx={{ paddingY: 8 }}>
                    {platformArray[canvas]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </ExpandCard>
  )
}

export default CreativeCanvas;