export const imageArray = [
  '/assets/images/test-image.png',
  '/assets/images/test-image2.png',
  '/assets/images/test-image3.png',
];
export const hexCodes = ['A742E2', '5F8D33', 'C91F7D', '3B6A99', 'FABE0A'];
export const colorPalette = ['/assets/images/test-image4.png'];
export const territories = [
  {
    id: '0001',
    typeface: 'Tagline',
    header: 'Spring Awakening',
    description: 'Awaken Your Palate with the Flavors of Spring',
    descriptors: 'Title',
    image: '/assets/images/test-image.png',
    colorPalette: colorPalette,
  },
  {
    id: '0002',
    typeface: 'Tagline',
    header: 'Fresh and Light',
    description: 'The first fruits from our garden - just for you',
    descriptors: 'delicious • fresh',
    image: ['/assets/images/test-image2.png'],
    colorPalette: colorPalette,
  },
  {
    id: '0003',
    typeface: 'Tagline',
    header: 'Spring Awakening',
    description: 'Awaken Your Palate with the Flavors of Spring',
    descriptors: 'Title',
    image: '/assets/images/test-image3.png',
    colorPalette: colorPalette,
  },
  {
    id: '0004',
    typeface: 'Tagline',
    header: 'Spring Awakening',
    description: 'Awaken Your Palate with the Flavors of Spring',
    descriptors: 'Title',
    image: '/assets/images/test-image.png',
    colorPalette: colorPalette,
  },
  {
    id: '0005',
    typeface: 'Tagline',
    header: 'Spring Awakening',
    description: 'Awaken Your Palate with the Flavors of Spring',
    descriptors: 'Title',
    image: '/assets/images/test-image.png',
    colorPalette: colorPalette,
  },
  {
    id: '0006',
    typeface: 'Tagline',
    header: 'Spring Awakening',
    description: 'Awaken Your Palate with the Flavors of Spring',
    descriptors: 'Title',
    image: '/assets/images/test-image.png',
    colorPalette: colorPalette,
  },
];

export const mockups = [
  {
    id: '2001',
    image: '/assets/images/instagram_mockup_1.png',
  },
  {
    id: '2002',
    image: '/assets/images/instagram_mockup_2.png',
  },
];

export const imageAssets = [
  {
    id: '0001',
    image: imageArray[0],
  },
  {
    id: '0002',
    image: imageArray[1],
  },
  {
    id: '0003',
    image: imageArray[2],
  },
  {
    id: '0004',
    image: imageArray[0],
  },
];

export const textAssets = [
  {
    id: '1001',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque fermentum dui faucibus in ornare quam viverra orci. Sit amet cursus sit amet dictum sit amet justo donec. Enim facilisis gravida neque convallis a cras semper auctor neque. Neque aliquam vestibulum morbi blandit. Elit sed vulputate mi sit amet mauris commodo quis imperdiet. Faucibus pulvinar elementum integer enim neque volutpat ac. Pretium quam vulputate dignissim suspendisse in est ante in. A diam maecenas sed enim ut. Elementum tempus egestas sed sed risus. Nec feugiat nisl pretium fusce id velit ut tortor pretium. Nunc scelerisque viverra mauris in aliquam sem fringilla. Nunc sed velit dignissim sodales. Vulputate ut pharetra sit amet aliquam id diam maecenas. Ultricies mi quis hendrerit dolor magna eget. Suspendisse in est ante in.',
  },
  {
    id: '1002',
    text: 'Vulputate eu scelerisque felis imperdiet proin fermentum. Aliquam sem et tortor consequat id porta nibh. Leo urna molestie at elementum eu facilisis sed. Eu feugiat pretium nibh ipsum consequat nisl vel. Id nibh tortor id aliquet lectus proin nibh nisl. Ligula ullamcorper malesuada proin libero nunc. Lectus arcu bibendum at varius. Nibh tellus molestie nunc non. Risus feugiat in ante metus. Ornare suspendisse sed nisi lacus sed viverra tellus. Tristique senectus et netus et malesuada fames ac. Blandit cursus risus at ultrices mi tempus imperdiet nulla. Porttitor massa id neque aliquam vestibulum morbi blandit. Porttitor leo a diam sollicitudin tempor id eu nisl nunc. Sed vulputate odio ut enim blandit volutpat maecenas. Mattis pellentesque id nibh tortor id aliquet. Blandit volutpat maecenas volutpat blandit aliquam etiam erat. Quis lectus nulla at volutpat diam ut venenatis.',
  },
  {
    id: '1003',
    text: 'Amet cursus sit amet dictum sit amet justo donec enim. Enim tortor at auctor urna nunc id cursus. Facilisis magna etiam tempor orci eu lobortis elementum nibh. Tellus orci ac auctor augue mauris augue neque. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus accumsan. Varius morbi enim nunc faucibus a. Posuere sollicitudin aliquam ultrices sagittis orci. Nisi quis eleifend quam adipiscing vitae proin sagittis. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Nunc aliquet bibendum enim facilisis gravida neque convallis a cras. Ac orci phasellus egestas tellus rutrum tellus. Integer malesuada nunc vel risus commodo viverra maecenas accumsan lacus. Sit amet venenatis urna cursus eget nunc. Urna duis convallis convallis tellus id. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Cras pulvinar mattis nunc sed blandit libero volutpat sed cras.',
  },
  {
    id: '1004',
    text: 'Amet cursus sit amet dictum sit amet justo donec enim. Enim tortor at auctor urna nunc id cursus. Facilisis magna etiam tempor orci eu lobortis elementum nibh. Tellus orci ac auctor augue mauris augue neque. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus accumsan. Varius morbi enim nunc faucibus a. Posuere sollicitudin aliquam ultrices sagittis orci. Nisi quis eleifend quam adipiscing vitae proin sagittis. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Nunc aliquet bibendum enim facilisis gravida neque convallis a cras. Ac orci phasellus egestas tellus rutrum tellus. Integer malesuada nunc vel risus commodo viverra maecenas accumsan lacus. Sit amet venenatis urna cursus eget nunc. Urna duis convallis convallis tellus id. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Cras pulvinar mattis nunc sed blandit libero volutpat sed cras.',
  },
];
