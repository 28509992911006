export const MCS_APPLICATION_Constants = {
  // content types
  CMS_IMAGE: 'image',
  CMS_TEXT: 'text',
  CMS_IMAGE_TEXT: 'image_text',
};

export const genders = [
  'Male',
  'Female',
  'Non-Binary',
  //Removed for Now. Will add back with additional features to add text
  'Other',
  // 'Prefer Not to Say',
];

export const CNSQuesList = [
  'Let’s get an idea of what you need. Select all the boxes that interest you.',
  'What is the topic you’d like to explore? What is your motivation for exploring this topic, and what are you trying to achieve?',
  'Please provide as much detail as possible with respect to audience.',
  'Is there any other context that’s important to keep in mind?  Are there any open questions you have?',
  'One last question. Do you have any additional data sources that you would like to add? If not, go ahead and hit the ‘Generate Analysis’ button to view your analysis!'
];