import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Buttons from '../../components/Button';
import Exporter from '../../components/Exporter';
import ExportOptionsBar from '../../components/ExportOptionsBar';
import FooterButton from '../../components/FooterButton';
import GCEChat from '../../components/GCE/GCEChat';
import { GCEContext, GCEDispatchContext } from '../../context/GCEContext';
import { GCE_ACTION_TYPES } from '../../reducers/gceActionTypes';
import theme from '../../styles/theme';
import { ImageBox } from '../ImageBox';
import SVGIcon from '../SVGIcon';
import IdeasCard from './IdeasCard';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '8px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

const inputNote =
  'Note: To keep this idea relevant to your needs, any further information provided will refresh the idea generated';

const GeneratedIdeas = ({ setHideSidebar }) => {
  const [isExportIdeas, setIsExportIdeas] = useState(false);
  const [isViewBookmarks, setIsViewBookmarks] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [currentIdea, setCurrentIdea] = useState();
  const [currentIdeaHistory, setCurrentIdeaHistory] = useState();
  const state = useContext(GCEContext);
  const dispatch = useContext(GCEDispatchContext);
  const { ideas } = state;
  const [displayedIdeas, setDisplayedIdeas] = useState(ideas);
  const [ideasBookmarked, setIdeasBookmarked] = useState(ideas);

  const bookmarkRef = useRef();

  const exportTypes = {
    '.pdf': false,
    '.doc': false,
  };

  const displayModal = (id) => {
    setCurrentIdea(ideas[id]);
    setCurrentIdeaHistory(ideas[id].ideaHistory);
    setOpen(true);
  };

  const handleClose = () => {
    if (currentIdeaHistory?.length > 0) {
      updateIdeaHistory();
    }
    setOpen(false);
    setCurrentIdea(null);
    setCurrentIdeaHistory(null);
  };

  useEffect(() => {
    setTimeout(() => {
      bookmarkRef.current.scrollIntoView();
      window.scrollTo(0, 0);
    }, 50);
  }, [isViewBookmarks]);

  const updateIdeaHistory = () => {
    let { id } = currentIdea;
    const modifiedIdeas = {
      ...ideas,
      [id]: {
        ...ideas[id],
        ideaHistory: currentIdeaHistory,
      },
    };
    dispatch({
      type: GCE_ACTION_TYPES.UPDATE_IDEA_HISTORY,
      payload: modifiedIdeas,
    });
  };

  const bookmarkIdea = (id) => {
    const modifiedIdeas = {
      ...ideas,
      [id]: {
        ...ideas[id],
        isBookmarked: !ideas[id].isBookmarked,
      },
    };
    dispatch({ type: GCE_ACTION_TYPES.SET_IDEAS, payload: modifiedIdeas });
  };

  useEffect(() => {
    let bookmarks = Object.values(ideas).filter((idea) => idea.isBookmarked);
    setBookmarkCount(bookmarks.length);
    setDisplayedIdeas(isViewBookmarks ? bookmarks : ideas);
    setIdeasBookmarked(bookmarks);
  }, [ideas]);

  const removeIdea = (id) => {
    const modifiedIdeas = { ...ideas };
    delete modifiedIdeas[id];
    dispatch({ type: GCE_ACTION_TYPES.SET_IDEAS, payload: modifiedIdeas });
  };

  const viewBookmarks = () => {
    if (!isViewBookmarks) {
      const bookmarkedIdeas = Object.values(ideas).filter(
        (idea) => idea.isBookmarked
      );
      setHideSidebar(true);
      setDisplayedIdeas(bookmarkedIdeas);
    } else {
      setHideSidebar(false);
      setDisplayedIdeas(ideas);
    }
    setIsExportIdeas(false);
    setIsViewBookmarks((previousView) => !previousView);
  };

  const setupExport = () => {
    setIsExportIdeas(!isExportIdeas);

    const bookmarkedIdeas = Object.values(displayedIdeas).filter(
      (idea) => idea.isBookmarked === true
    );

    if (isExportIdeas) {
      setIdeasBookmarked(bookmarkedIdeas);
    }
  };

  const renderExpandedIdea = () => {
    return (
      <Box
        className="modal_content"
        borderRadius="8px"
        boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
        backgroundColor={theme.palette.grey.white}
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 'calc(100vw - 160px)',
          padding: '48px 32px',
          position: 'relative',
          maxWidth: currentIdea.image ? '1280px' : '878px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h2" component="h1" sx={{ width: '85%' }}>
            {currentIdea.title}
          </Typography>
          <Box>
            <Button
              startIcon={
                ideas[currentIdea.id].isBookmarked ? (
                  <BookmarkIcon htmlColor={theme.palette.primary.main} />
                ) : (
                  <BookmarkBorderIcon htmlColor={theme.palette.grey.black} />
                )
              }
              onClick={() => bookmarkIdea(currentIdea.id)}
            >
              <Typography
                sx={{ fontSize: 14, color: theme.palette.grey.black }}
              >
                {ideas[currentIdea.id].isBookmarked ? 'Bookmarked' : 'Bookmark'}
              </Typography>
            </Button>
            <Button
              startIcon={
                <SVGIcon
                  name="close"
                  width={28}
                  height={32}
                  color={theme.palette.grey.black}
                  strokecolor={theme.palette.grey.white}
                />
              }
              onClick={handleClose}
              aria-label="close"
            ></Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            paddingTop: '48px',
          }}
        >
          <Box
            borderRadius="8px"
            border={'1px solid ' + theme.palette.grey.light2}
            backgroundColor={theme.palette.grey.white}
            sx={{
              display: 'flex',
              flexGrow: '1.25',
              maxWidth: '68em',
            }}
          >
            <GCEChat
              currentIdea={currentIdea}
              currentIdeaHistory={currentIdeaHistory}
              isModal
              setCurrentIdeaHistory={setCurrentIdeaHistory}
              inputNote={inputNote}
            />
          </Box>
          {currentIdea.image ? (
            <Box
              className="moodboard_sidePannel"
              sx={{
                flexGrow: '1',
                marginLeft: '32px',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: '10px 20px',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  width: '377px',
                }}
              >
                <Typography
                  variant="h4"
                  component="p"
                  sx={{
                    gridColumn: 'span 2',
                    marginBottom: '8px',
                  }}
                >
                  Moodboard
                </Typography>
                <ImageBox
                  className="modal_image"
                  styles={{ width: '377px', height: 'auto' }}
                  src={currentIdea.image}
                  alt={'sample image'}
                  height={'447'}
                  width={'375'}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 5,
            paddingTop: 5,
          }}
        >
          <Exporter
            dataExported={{ currentIdea }}
            dataName={'GCEIdeaModal'}
            fileTypeRequest={{ pdf: 'pdf' }}
            buttonOverwrite={{
              label: `Export Idea`,
              variant: 'song',
              color: 'primary',
            }}
            pdfHeading={{
              title: 'Generative Concepting Engine',
              subTitle: 'Generated Idea',
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light2,
          minWidth: `${isViewBookmarks ? '1440px' : '1000px'}`,
          padding: `${
            isViewBookmarks ? '3rem 5rem 12rem 5rem' : '2rem 5rem 12rem 4rem'
          }`,
          display: `${isViewBookmarks && 'flex'}`,
          flexDirection: `${isViewBookmarks && 'column'}`,
          justifyContent: 'space-around',
        }}
        ref={bookmarkRef}
      >
        <Box sx={{ paddingBottom: `${isViewBookmarks ? '3rem' : '2rem'}` }}>
          {isViewBookmarks && (
            <Buttons
              component={'a'}
              variant={'link-light'}
              onClick={viewBookmarks}
              startIcon={<img src="/assets/back_arrow.svg" alt="back" />}
              style={{ marginBottom: '48px' }}
              sx={{
                fontFamily: 'GraphikRegular',
                fontSize: '18px',
                color: theme.palette.primary.main,
                textDecorationStyle: 'solid',
                textDecorationLine: 'underline',
                textDecorationColor: theme.palette.primary.main,
              }}
            >
              Back to generated ideas
            </Buttons>
          )}
          <Typography variant={'h2'} component={'h1'} gutterBottom>
            {isViewBookmarks ? 'Bookmarks' : 'Generated Ideas'}
          </Typography>
        </Box>
        <Grid container columns={12} columnSpacing={3} Spacing={2}>
          {Object.values(displayedIdeas).map((idea) => {
            return (
              <Grid item key={`${idea.id}_idea`}>
                <IdeasCard
                  idea={idea}
                  style={{
                    margin: '12px 12px',
                    width: '375px',
                    minHeight: '524px',
                  }}
                  tag={
                    idea.ideaHistory.length ? 'edited' : idea.isNew ? 'new' : ''
                  }
                  handleClick={displayModal}
                  handleBookmark={bookmarkIdea}
                  removeIdea={removeIdea}
                  isViewBookmarked={isViewBookmarks}
                />
              </Grid>
            );
          })}
        </Grid>
        {currentIdea && (
          <Modal
            className="modal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box className="modal_inner_container" sx={style}>
                {renderExpandedIdea()}
              </Box>
            </Fade>
          </Modal>
        )}
      </Box>
      <FooterButton>
        <Stack>
          {isExportIdeas ? (
            <ExportOptionsBar
              rowStyle
              isExportIdeas={isExportIdeas}
              closeExportOptionsBar={setupExport}
              exportTypes={exportTypes}
              bookmarkedIdeas={ideasBookmarked}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                padding: '24px 48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {!isViewBookmarks && (
                  <Buttons
                    variant="song"
                    color="secondary"
                    sx={{ paddingY: '10px' }}
                    startIcon={
                      <SVGIcon
                        name="bookmark"
                        strokecolor={theme.palette.primary.main}
                        color={
                          bookmarkCount
                            ? theme.palette.primary.main
                            : theme.palette.grey.white
                        }
                      />
                    }
                    endIcon={
                      <span
                        style={{
                          fontFamily: 'GraphikSemiBold',
                          fontSize: '16px',
                          color: theme.palette.grey.white,
                          lineHeight: '36px',
                          textAlign: 'center',
                          background: theme.palette.primary.main,
                          width: '36px',
                          height: '36px',
                          borderRadius: '18px',
                        }}
                      >
                        {bookmarkCount}
                      </span>
                    }
                    onClick={viewBookmarks}
                    disabled={!bookmarkCount}
                  >
                    View Bookmarks
                  </Buttons>
                )}
                <Buttons
                  variant="song"
                  sx={{ paddingY: '10px' }}
                  startIcon={
                    <SVGIcon
                      name="export"
                      color={
                        !bookmarkCount
                          ? theme.palette.state.disabled_primary
                          : theme.palette.grey.black
                      }
                    />
                  }
                  style={{ marginLeft: '48px' }}
                  onClick={setupExport}
                  disabled={!bookmarkCount}
                >
                  Export Bookmarks
                </Buttons>
              </Box>
            </Box>
          )}
        </Stack>
      </FooterButton>
    </>
  );
};

export default GeneratedIdeas;
