import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import { platformArray } from '../../assets/data/constants/platformArray';
import theme from '../../styles/theme';
import Buttons from '../Button';
import { ImageBox } from '../ImageBox';
import SVGIcon from '../SVGIcon';

const AssetSearch = ({
  assetType,
  giveMeMore,
  inputAssets,
  platform,
  refinementText,
  searchType,
  selectedIndex,
  settingsView,
  sliderValues,
  inspectSliderInputsText,
  imageNotLoaded,
}) => {
  const [isInspected, setIsInspected] = useState(false);

  const renderHeaderText = () => {
    switch (searchType) {
      case 'original':
        return 'Original Search: ';
      case 'blend':
        return `Blended ${assetType == 'image' ? 'Images' : 'Text'}`;
      case 'iterate':
        return 'Iterated Assets';
      default:
        return 'Search: ';
    }
  };

  const renderPlatform = () => {
    if (platform.length > 25) {
      return (
        <span>{`${(platformArray[platform] + ' ' + assetType).slice(
          0,
          20
        )}...`}</span>
      );
    } else {
      return <span>{`${platformArray[platform] + ' ' + assetType}`}</span>;
    }
  };

  const renderSliderInputText = () => {
    console.log('slider values: ', sliderValues);
    return inspectSliderInputsText;
  };

  const renderInspectPanel = () => {
    return (
      <Box
        className="territory_search_container"
        sx={{
          borderRadius: 3,
          border: `1px solid ${theme.palette.primary.main}`,
          marginBottom: 12,
        }}
      >
        <Box sx={{ padding: 12, fontSize: 12 }}>
          <Typography
            sx={{ textTransform: 'uppercase', paddingBottom: 3 }}
            color={theme.palette.primary.main}
            gutterBottom
          >
            Inspect
          </Typography>

          {searchType === 'iterate' && (
            <Grid container spacing={20}>
              <Grid item xs={7}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                  Original Asset
                </Typography>
                <Grid key={`${inputAssets[0].id}`}>
                  {assetType == 'image' ? (
                    <ImageBox
                      className="asset_card"
                      src={inputAssets[0].content.image}
                      width={'112px'}
                      height={'114px'}
                      alt="original iterate image"
                    />
                  ) : (
                    inputAssets[0].content.text
                  )}
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                  Iterate Directions
                </Typography>
                <Typography paragraph sx={{ fontSize: 18 }}>
                  {refinementText}
                </Typography>
              </Grid>
            </Grid>
          )}

          {searchType === 'blend' && (
            <Grid container spacing={20}>
              <Grid item xs={6}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                  Blended Assets
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '15px',
                    direction: 'row',
                  }}
                >
                  {assetType == 'image' ? (
                    <>
                      <ImageBox
                        className="asset_card"
                        src={inputAssets[0].image}
                        width={'120px'}
                        alt="original blend image"
                      />
                      <ImageBox
                        className="asset_card"
                        src={inputAssets[1].image}
                        width={'120px'}
                        alt="original blend image"
                      />
                    </>
                  ) : (
                    <Typography color={theme.palette.grey.main}>
                      {inputAssets.content.text}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                  Blend Directions
                </Typography>
                <Typography paragraph sx={{ fontSize: 18 }}>
                  {refinementText}
                </Typography>
              </Grid>
            </Grid>
          )}

          {searchType !== 'iterate' && searchType !== 'blend' && (
            <div>
              <Grid container spacing={20}>
                <Grid item xs={6}>
                  <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                    Refinement Text
                  </Typography>
                  <Typography paragraph sx={{ fontSize: 18 }}>
                    {refinementText}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                    Slider Inputs
                  </Typography>
                  <Typography paragraph sx={{ fontSize: 18 }}>
                    {renderSliderInputText()}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box className="territory_bar_container">
      <Grid
        container
        sx={{ paddingBottom: 5 }}
        justifyContent={'space-between'}
      >
        <Grid item>
          <Box>
            <Grid container spacing={6}>
              <Grid item>
                <Typography
                  variant="h2"
                  sx={{ paddingTop: '0.3rem' }}
                  color={theme.palette.primary.dark}
                  fontSize={22}
                >
                  {renderHeaderText(searchType)}{' '}
                  {searchType == 'original' && renderPlatform()}
                </Typography>
              </Grid>
              <Grid item>
                <Buttons
                  style={{ paddingTop: '0.75rem' }}
                  sx={{
                    fontFamily: 'GraphikRegular',
                    fontSize: '18px',
                    lineHeight: '24px',
                    textDecoration: 'underline',
                  }}
                  startIcon={
                    <SVGIcon
                      name="eye-inspect"
                      color={
                        selectedIndex === 2 || settingsView
                          ? theme.palette.state.disabled_secondary
                          : theme.palette.primary.main
                      }
                    />
                  }
                  onClick={() => {
                    setIsInspected(!isInspected);
                  }}
                  disabled={selectedIndex === 2 || settingsView}
                >
                  {isInspected ? 'Close Inspect' : 'Inspect'}
                </Buttons>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Buttons
              sx={{
                fontFamily: 'GraphikRegular',
                fontSize: '18px',
                lineHeight: '24px',
                textDecoration: 'underline',
                display: 'none',
              }}
              startIcon={
                <SVGIcon
                  name="trash"
                  color={
                    selectedIndex === 2 || settingsView
                      ? theme.palette.state.disabled_secondary
                      : theme.palette.primary.main
                  }
                />
              }
              disabled={selectedIndex === 2 || settingsView}
            >
              Delete Search
            </Buttons>
            <Buttons
              variant="song"
              color="secondary"
              style={{ marginLeft: '1.5rem' }}
              sx={{
                background: theme.palette.opacity.clear0,
                padding: '5px 12px',
                outlineColor:
                  (selectedIndex === 2 || settingsView) &&
                  theme.palette.grey.dark1,
              }}
              disabled={imageNotLoaded}
              onClick={giveMeMore}
            >
              Give Me More
            </Buttons>
          </Box>
        </Grid>
      </Grid>
      <Box>{isInspected ? renderInspectPanel() : ''}</Box>
    </Box>
  );
};

export default AssetSearch;
