import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import theme from '../../styles/theme';
import { ImageBox } from '../ImageBox';

export const TwitterMock = ({ imgDesc, imgSrc }) => {
  const truncatedImgDesc =
    imgDesc?.length > 280 ? imgDesc.slice(0, 280) + '...' : imgDesc;
  return (
    <Box
      sx={{
        width: 306,
        padding: '5px 15px 0px 15px',
        border: `0.5px solid ${theme.palette.grey.light}`,
        backgroundColor: theme.palette.primary.white,
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            width: 25,
            height: 25,
            backgroundColor: theme.palette.grey.light,
            marginRight: 2,
            marginTop: 2,
            borderRadius: '100%',
          }}
        ></Box>
        <Box
          sx={{
            width: '87%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography fontSize={12}>
              <span style={{ fontFamily: 'GraphikSemiBold' }}>
              Emotional AI 
              </span>{' '}
              @companyname . 5h
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                color: '#2D2936',
                fontSize: '10px',
                fontWeight: '400',
                lineHeight: '15px',
                marginBottom: '10px',
              }}
            >
              {truncatedImgDesc}
            </Typography>
          </Box>

          <Box>
            <ImageBox
              src={imgSrc}
              styles={{
                width: '239px',
                height: '119px',
                objectFit: 'cover',
                boxShadow: '0px 2px 18px 0px rgba(0, 0, 0, 0.12)',
                backdropFilter: 'blur(20px)',
              }}
            />
          </Box>
          <Box>
            <img
              src="/assets/images/TwitterMock.png"
              alt=""
              style={{ width: '100%' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
