import {
  Backdrop,
  Box,
  Fade,
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Buttons from '../../components/Button';
import CNSAnalysisSummary from '../../components/CNS/CNSAnalysisSummary';
import CNSChatSidebar from '../../components/CNS/CNSChatSidebar';
import ExportOptionsBar from '../../components/ExportOptionsBar';
import FooterButton from '../../components/FooterButton';
import SVGIcon from '../../components/SVGIcon';
import { CNSDispatchContext } from '../../context/CNSContext';
import { CNS_ACTION_TYPES } from '../../reducers/cnsActionTypes';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '8px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '25px',
  width: '650px',
};

const CNSIdeas = () => {
  const dispatch = useContext(CNSDispatchContext);
  const [isReset, setIsReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [isFileExported, setFileExported] = useState(false);
  const footerLayout = {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 80px 16px 10px',
    alignItems: 'center',
    height:'76px',
  };
  const [checkedSummary, setCheckedSummary] = useState([]);

  const handleReset = () => {
    dispatch({
      type: CNS_ACTION_TYPES.SET_INITIAL_PARAMS,
      payload: {
        chatHistory: [],
        filesHistory: [],
        sessionID: '',
        step: 0,
      },
    });
    dispatch({
      type: CNS_ACTION_TYPES.SET_SHOW_IDEAS,
      payload: false,
    });
  };

  useEffect(() => {
    if (isLoading) {
      setShowExportOptions(false);
    }
  }, [isLoading]);

  return (
    <>
      {isReset && (
        <Modal
          open={isReset}
          className="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isReset}>
            <Box className="modal_inner_container" sx={style}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  paddingBottom: '20px',
                }}
              >
                <Typography variant="h3" sx={{ alignSelf: 'left' }}>
                  Reset Session?
                </Typography>
                <Box
                  className={'genai-icon-close'}
                  sx={{ alignSelf: 'right', height: '32px' }}
                >
                  <SVGIcon
                    width={24}
                    height={27}
                    name="close"
                    color={'#000'}
                    onClick={() => setIsReset(false)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  paddingBottom: '20px',
                }}
              >
                <Typography>
                  Your current progress will be deleted. Are you sure you want
                  to continue and reset?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Buttons
                  variant="song"
                  color="secondary"
                  onClick={() => setIsReset(false)}
                >
                  Close
                </Buttons>
                <Buttons
                  variant="song"
                  startIcon={
                    <img
                      src="/assets/images/refresh_icon_black.svg"
                      alt="Refresh"
                    />
                  }
                  sx={{ marginLeft: '15px' }}
                  onClick={() => handleReset()}
                >
                  Reset Session
                </Buttons>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
      {isFileExported && (
        <Modal
          open={isFileExported}
          className="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isFileExported}>
            <Box className="modal_inner_container" sx={style}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  paddingBottom: '20px',
                }}
              >
                <Typography variant="h3" sx={{ alignSelf: 'left' }}>
                  Analysis Exported
                </Typography>
                <Box
                  className={'genai-icon-close'}
                  sx={{ alignSelf: 'right', height: '32px' }}
                >
                  <SVGIcon
                    width={24}
                    height={27}
                    name="close"
                    color={'#000'}
                    onClick={() => setFileExported(false)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  paddingBottom: '20px',
                }}
              >
                <Typography>
                  Your analysis has been exported. Would you like to start a new
                  session?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Buttons
                  variant="song"
                  color="secondary"
                  onClick={() => setFileExported(false)}
                >
                  Continue here
                </Buttons>
                <Buttons
                  variant="song"
                  startIcon={
                    <img
                      src="/assets/images/refresh_icon_black.svg"
                      alt="Refresh"
                    />
                  }
                  sx={{ marginLeft: '15px' }}
                  onClick={() => handleReset()}
                >
                  Start new session
                </Buttons>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
      <Grid
        container
        sx={{
          margin: '0 auto',
          position: 'relative',
          top: '65px',
          maxWidth: '100%',
          height: 'auto',
          paddingBottom: '4rem',
        }}
      >
        <Grid
          xs={4}
          sx={{
            maxHeight: '60rem',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': { width: '3px !important' },
            '&::-webkit-scrollbar-track': { background: '#f1f1f1 !important' },
            '&::-webkit-scrollbar-thumb': { background: '#888 !important' },
            '&::-webkit-scrollbar-thumb:hover': { background: '#555 !important' }
          }}>
          <Box
            sx={{ position: 'sticky', top: 0, bottom: 0, overflowY: 'auto' }}
          >
            <CNSChatSidebar
              setIsReset={setIsReset}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          </Box>
        </Grid>
        <Grid
          className="form_container"
          container
          xs={8}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            scrollBehavior: 'smooth',
            maxHeight: '60rem',
            '&::-webkit-scrollbar': { width: '3px !important' },
            '&::-webkit-scrollbar-track': { background: '#f1f1f1 !important' },
            '&::-webkit-scrollbar-thumb': { background: '#888 !important' },
            '&::-webkit-scrollbar-thumb:hover': { background: '#555 !important' }
          }}
        >
          <Box className="rendered_content_container">
            <CNSAnalysisSummary
              showExportOptions={showExportOptions}
              setCheckedSummary={setCheckedSummary}
              isLoading={isLoading}
            />
          </Box>
        </Grid>
      </Grid>
      {showExportOptions ? (
        <ExportOptionsBar
          rowStyle
          closeExportOptionsBar={() => setShowExportOptions(false)}
          chatHistory={checkedSummary}
          exportFrom={'CNS'}
          setFileExported={setFileExported}
        />
      ) : (
        <FooterButton style={{ left: 0 }}>
          <>
            <Stack
              className="footer-button-stack"
              spacing={3}
              direction="row"
              style={footerLayout}
            >
              <Buttons
                variant="song"
                sx={{ paddingX: '25px', paddingY: '12px' }}
                onClick={() => {
                  setShowExportOptions(true);
                }}
                startIcon={<img src="/assets/images/exportbtn.svg" />}
              >
                Export Analysis
              </Buttons>
            </Stack>
          </>
        </FooterButton>
      )}
    </>
  );
};

export default CNSIdeas;
