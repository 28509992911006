import { Button, InputBase, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import theme from '../../styles/theme';

const GCEChatInput = ({ onClick, onEnterPress, isEnabled }) => {
  const [text, setText] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  return (
    <Box sx={{ paddingBottom: '10px', marginTop: 2 }}>
      <Box
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          border: isFocused
            ? `1px solid ${theme.palette.primary.main}`
            : '1px solid #C9D0D7',
          borderRadius: '8px',
        }}
      >
        <InputBase
          sx={{
            ml: 1,
            p: 1,
            flex: 1,
            width: '100%',
            '& input::placeholder': { fontSize: '14px' },
          }}
          placeholder={'Type your question here'}
          onChange={handleTextChange}
          value={text}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setText('');
              onClick(text);
            }
          }}
          disabled={!isEnabled}
        />
        <Button
          sx={{ p: '10px' }}
          startIcon={<img src="../../assets/send-icon.svg" alt="send" />}
          aria-label="question"
          onClick={() => {
            onClick(text);
            setText('');
          }}
          disabled={!isEnabled}
          onKeyDown={onEnterPress}
        >
          <Typography sx={{ fontSize: 14, color: '#868B90', fontWeight: 600 }}>
            Send
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default GCEChatInput;
