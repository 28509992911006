import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  Card,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import BlendIcon from '../../components/icons/BlendIcon';
import { MCS2DispatchContext } from '../../context/MCS2Context';
import { MCSV2_ACTION_TYPES } from '../../reducers/mcsv2ActionTypes';
import theme from '../../styles/theme';
import Buttons from '../Button';
import Exporter from '../Exporter';
import { ImageBox } from '../ImageBox';
import ColorPalette from './ColorPalette';
import IterateTerritory from './IterateTerritory';
import PlaceholderTile from './PlaceholderTile';
import TerritoryCard from './TerritoryCard';

const formStyle = {
  marginBottom: '48px',
  borderColor: theme.palette.secondary.blue,
  borderBottom: `1px solid ${theme.palette.grey.light2}`,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid ' + theme.palette.grey.main,
    fontSize: 16,
    padding: '18px 12px',
    background: theme.palette.grey.white,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  width: '100%',
}));
const buttonStyle = {
  padding: 3,
  paddingY: '.5vw',
  margin: 1.5,
  width: '100%',
  float: 'left',
  alignItems: 'left',
  textAlign: 'left',
  justifyContent: 'left',
  borderRadius: 2,
  border: `2px solid ${theme.palette.grey.light2}`,
  fontSize: 16,
  color: 'black',
  paddingLeft: 6,
};

const TerritoryDetailsCard = ({
  territories,
  TDCOptionsOnClick,
  updatePortfolioClick,
  previousOnClick,
  nextOnClick,
  closeOnClick,
  handleReplaceTerritoryClick,
  handleOnBackClick,
  handleBlendIterateClick,
  isEditing,
  isDisabled,
  editMode,
  region = null,
  savedPortfolio,
}) => {
  const optionsRed = useRef();
  const [territory1, territory2 = null] = territories;
  const [canBlend, setCanBlend] = useState(true);
  const [directionText, setDirectionText] = useState('');
  const [blendText, setBlendText] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [isFirstSelected, setIsFirstSelected] = useState(false);
  const dispatch = useContext(MCS2DispatchContext);

  const heightRef = useRef(null);

  useEffect(() => {
    console.log('height');
    console.log(heightRef?.current?.clientHeight);
  });

  const isButtonDisabled = false;
  const saveToPortfolioText = savedPortfolio.some(
    (territory) => territory.id === territory1?.id
  )
    ? 'Saved to portfolio'
    : 'Save to portfolio';

  const setExperience = (experience) => {
    dispatch({
      type: MCSV2_ACTION_TYPES.CHOSEN_WORKSPACE,
      payload: { workspace: experience },
    });
  };

  useEffect(() => {
    if (territories.length > 1) {
      setCanBlend(false);
    }
  }, [territories]);

  useEffect(() => {
    if ((territory1 && !territory2) || (!territory1 && territory2)) {
      setIsFirstSelected(true);
    } else {
      setIsFirstSelected(false);
    }
  }, [territory1, territory2]);

  const renderInsideCard = (editMode) => {
    switch (editMode) {
      case 'blend':
        return <>{renderEditing()}</>;
      case 'iterate':
        return (
          <>
            <IterateTerritory
              imageArray={territory1?.imageArray}
              header={territory1?.header}
              colorPalette={territory1?.colorPalette}
              onClickIterateTerritory={() => {
                handleBlendIterateClick(
                  'iterateTerritory',
                  territory1.id,
                  null,
                  directionText
                );
              }}
              setIterateText={setDirectionText}
            />
          </>
        );
    }
  };

  const handleReplaceClear = (data) => {
    var i = territories.length;
    while (i--) {
      if (territories[i] && territories[i]['id'] === data.id) {
        territories[i] = null;
      }
    }
  };

  const renderEditing = () => {
    return (
      <>
        <Typography variant="h4" component={'h3'} sx={{ marginBottom: '16px' }}>
          Blend Territories
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <div ref={heightRef}>
                <Box sx={{ paddingX: 16 }}>
                  {territory1 !== null ? (
                    <TerritoryCard
                      imageArray={territory1.imageArray}
                      header={territory1.header}
                      colorPalette={territory1.colorPalette}
                      isEditing={isEditing}
                      width={'100%'}
                      height={'100%'}
                      handleTerritoryRemoveClick={() => {
                        removeTerritory(i);
                      }}
                      handleReplaceTerritoryClick={() => {
                        handleReplaceTerritoryClick(territory1);
                      }}
                      handleClick={() => {}}
                      isFirstSelected={isFirstSelected}
                      handleReplaceClear={() => handleReplaceClear(territory1)}
                    />
                  ) : (
                    <PlaceholderTile width={'80%'} height={'100%'} />
                  )}
                </Box>
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ paddingY: 'calc(24vh - 100px)' }}
              justifyContent="center"
              display="flex"
              height={'80%'}
            >
              <BlendIcon style={{ width: '10vw' }} />
            </Grid>
            <Grid item xs={5}>
              <Box sx={{ paddingX: 16 }}>
                {territory2 !== null ? (
                  <TerritoryCard
                    imageArray={territory2.imageArray}
                    header={territory2.header}
                    colorPalette={territory2.colorPalette}
                    isEditing={isEditing}
                    width={'100%'}
                    height={'100%'}
                    handleTerritoryRemoveClick={() => {
                      removeTerritory(i);
                    }}
                    handleReplaceTerritoryClick={() => {
                      handleReplaceTerritoryClick(territory2);
                    }}
                    handleClick={() => {}}
                    isFirstSelected={isFirstSelected}
                    handleReplaceClear={() => handleReplaceClear(territory2)}
                  />
                ) : (
                  <PlaceholderTile
                    width={'100%'}
                    height={heightRef?.current?.clientHeight || 200}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            borderTop: `1px solid  ${theme.palette.grey.light3}`,
          }}
        >
          <Buttons
            onClick={() => {
              setExpanded(!expanded);
            }}
            startIcon={expanded ? <RemoveIcon /> : <AddIcon />}
            sx={{ paddingX: 2, marginTop: 5 }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
              Add Blend Directions
            </Typography>
          </Buttons>
        </Box>
        {expanded && (
          <>
            <Typography
              variant="h3"
              gutterBottom
              fontSize={20}
              sx={{ marginBottom: 4, marginTop: 4 }}
            >
              How would you like to blend these territories?
            </Typography>
            <BootstrapInput
              multiline
              placeholder="Add Blend Directions"
              onChange={(event) => setBlendText(event.target.value)}
              style={{
                '::placeholder': {
                  color: theme.palette.grey.main,
                  fontFamily: 'GraphikRegular',
                  fontSize: 16,
                  lineHeight: '28px',
                },
              }}
            />
          </>
        )}

        <Buttons
          onClick={() => {
            handleBlendIterateClick(
              'blendTerritory',
              territory1.id,
              territory2.id,
              blendText
            );
          }}
          variant="song"
          sx={{
            paddingX: '25px',
            paddingY: '8px',
            alignSelf: 'flex-end',
            marginTop: 5,
          }}
          disabled={canBlend}
        >
          Blend
        </Buttons>
      </>
    );
  };

  const renderInspector = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant="h4" component="h3">
          Territory: {territory1?.header}
        </Typography>
        <Grid
          className="territory_space"
          container
          spacing={8}
          sx={{ paddingY: 3 }}
        >
          <Grid
            className="territory_detail_section"
            container
            item
            xs={region ? 7 : 8}
            spacing={4}
          >
            <Grid container xs={8} spacing={4} width={'110%'}>
              <Grid item xs={6.5}>
                <ImageBox
                  src={territory1.imageArray[0]}
                  alt=""
                  styles={{ borderRadius: '8px' }}
                />
              </Grid>
              <Grid
                container
                // spacing={4}
                xs={4}
                sx={{ flexDirection: 'column' }}
              >
                <Grid item xs={6} width="100%">
                  <ImageBox
                    src={territory1.imageArray[1]}
                    alt=""
                    styles={{ borderRadius: '8px' }}
                  />
                </Grid>
                <Grid item xs={6} width="100%">
                  <ImageBox
                    src={territory1.imageArray[2]}
                    alt=""
                    styles={{ borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
              <Grid
                id={'mcs-colorPalette-xpt'}
                callback={() => console.log('color here')}
                item
                xs={1.4}
              >
                <ColorPalette colors={territory1.colorPalette} large={true} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className="options_section"
            ref={optionsRed}
            item
            xs={region ? 5 : 4}
          >
            <Box sx={{ paddingX: region ? 0 : 8 }}>
              <Typography
                color={theme.palette.primary.main}
                fontFamily="GraphikSemiBold"
                gutterBottom
                sx={{ paddingBottom: '.2em' }}
                fontSize={18}
              >
                Select an Option
              </Typography>
              <Box>
                {!region && (
                  <Button
                    fontSize={18}
                    disabled={isDisabled}
                    sx={buttonStyle}
                    startIcon={<img width={20} src="assets/images/blend.svg" />}
                    onClick={() => {
                      TDCOptionsOnClick('blend');
                    }}
                  >
                    Blend Territory
                  </Button>
                )}
                {!region && (
                  <Button
                    fontSize={18}
                    disabled={isDisabled}
                    sx={buttonStyle}
                    startIcon={
                      <img width={20} src="assets/images/iterate.svg" />
                    }
                    onClick={() => {
                      //setIsEditing(!isEditing);

                      TDCOptionsOnClick('iterate');
                    }}
                  >
                    Iterate Territory
                  </Button>
                )}

                <Exporter
                  dataExported={territory1}
                  dataName={'MCSTerritory'}
                  buttonOverwrite={{
                    label: `Export Territory`,
                    variant: 'pill-mcs',
                    size: 'large',
                    fontFamily: 'GraphikLight',
                    padding: '12px 0',
                    sx: {
                      ...buttonStyle,

                      border: `1.6px solid ${theme.palette.grey.light2}`,
                    },
                  }}
                  pdfHeading={{
                    title: 'Machine Creative Studio',
                    subTitle: 'Territory',
                  }}
                  fileTypeRequest={{ pdf: 'pdf' }}
                  iconV2
                  boxStyle={{ width: '100%', marginLeft: '1.5%' }}
                  regularButton={true}
                />

                <Button
                  fontSize={18}
                  disabled={isDisabled}
                  sx={{ ...buttonStyle, letterSpacing: '-0.15px' }}
                  startIcon={<img width={20} src="assets/images/folder.svg" />}
                  onClick={() => updatePortfolioClick(territory1)}
                >
                  {!region ? saveToPortfolioText : 'Remove from portfolio'}
                </Button>
                <Button
                  fontSize={18}
                  disabled={isDisabled}
                  sx={buttonStyle}
                  startIcon={
                    <img width={20} src="assets/images/generate.svg" />
                  }
                  onClick={() => {
                    updatePortfolioClick(territory1);
                    setExperience('assets');
                  }}
                >
                  Generate Assets
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Card
        sx={{
          minHeight: '300px',
          borderRadius: 2,
          outlineWidth: 1.5,
          paddingTop: 2,
          paddingBottom: 6,
          paddingX: 2,
          marginBottom: '3rem',
        }}
      >
        <Box className="details_content" sx={{ marginX: region ? 6 : 10 }}>
          <Box
            sx={{
              paddingY: 4,
              borderBottom: `solid 1px ${theme.palette.primary.main}`,
            }}
          >
            <Box
              sx={{
                float: 'left',
              }}
            >
              <IconButton
                onClick={() => {
                  TDCOptionsOnClick('home');
                  setCanBlend(true);
                }}
              >
                <ArrowBackIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignContent: 'center',
              }}
            >
              <IconButton onClick={closeOnClick}>
                <HighlightOffIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
          </Box>
          <Box
            className="main_territory_container"
            sx={{ paddingTop: 4, display: 'flex', flexDirection: 'column' }}
          >
            {isEditing && editMode !== 'home'
              ? renderInsideCard(editMode)
              : renderInspector()}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default TerritoryDetailsCard;
