import { Box, Typography } from '@mui/material';
import React from 'react';
import Animation from '../components/Animation';

const HangTightLoader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width
      height
      borderRadius="18px"
      justifyContent="center"
    >
      <Animation />
      <Typography
        sx={{
          color: '#02054E',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '20px',
          margin: '1rem',
        }}
      >
        Hang tight!
      </Typography>
      <Typography
        sx={{
          color: '#868B90',
          fontFamily: 'Graphik',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px',
          textAlign: 'center',
          width: '312px',
        }}
      >
        Our creation is materializing. Polishing pixels, stand by for glory!
      </Typography>
    </Box>
  );
};
export default HangTightLoader;
