import { useMutation } from '@tanstack/react-query';

export function useCustomerNeedsSpotter() {
  return useMutation(async (data) => {
    try {
      const ws = new WebSocket(
        'wss://oky3xhdzsg.execute-api.us-east-1.amazonaws.com/test'
      );

      ws.onopen = (event) => {
        ws.send(JSON.stringify(data));
      };

      return new Promise((resolve, reject) => {
        ws.onmessage = (event) => {
          if (event.data !== '') {
            const response = JSON.parse(event.data);
            if (response.message.summary) {
              ws.close();
              resolve(response);
            }
          }
        };
      })
    } catch (err) {
      console.log(err);
    }
  });
}
