import { Box, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ArrowIcon from '../../assets/ArrowIcon';
import Buttons from '../../components/Button';
import LoadingPage from '../../components/LoadingPage';
import CreateAssetForm from '../../components/MCS2/CreateAssetForm';
import CreateTerritoryForm from '../../components/MCS2/CreateTerritoryForm';
import { MCS2Context, MCS2DispatchContext } from '../../context/MCS2Context';
import { useMachineCreativeStudioV2 } from '../../hooks/useMachineCreativeStudioV2';
import { imageAssets, territories, textAssets } from '../../mocks/MCSV2Data';
import { MCSV2_ACTION_TYPES } from '../../reducers/mcsv2ActionTypes';
import theme from '../../styles/theme';

const footerLayout = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
  marginTop: '48px',
};

const ExperienceSetup = () => {
  const { userId, workspace, projects } = useContext(MCS2Context);
  const dispatch = useContext(MCS2DispatchContext);
  const [selectedProject, setSelectedProject] = useState('');
  const [newProjectName, setNewProjectName] = useState('');
  const [creativeCanvas, setCreativeCanvas] = useState('');
  const [bigIdea, setBigIdea] = useState('');
  const [imagesIdea, setImagesIdea] = useState('');
  const [colorsIdea, setColorsIdea] = useState('');
  const [toneOfVoice, setToneOfVoice] = useState('');
  const [files, setFiles] = useState([]);
  const [fileKeys, setFileKeys] = useState([]);
  const [selectedTerritory, setSelectedTerritory] = useState('');
  const [selectedTerritoryName, setSelectedTerritoryName] = useState('');

  const { mutateAsync, isLoading } = useMachineCreativeStudioV2();
  const title = workspace == 'territories' ? 'Territory' : 'Assets';
  const projectNames = projects.map((project) => project.projectName);

  const addFile = (newFiles) => {
    setFiles([...newFiles]);
  };

  const addFileKey = (newFileKeys) => {
    setFileKeys([...fileKeys, ...newFileKeys]);
  };

  const setProject = (project) => {
    setSelectedProject(project);
    // set new project to context every time it is changed
    if (project !== 'Create a new project') {
      const curProject = projects.find((elm) => elm.projectName === project);
      // set the current project id
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_CURRENT_PROJECT,
        payload: { projectId: curProject.projectId, projectName: project },
      });
    }
  };

  const generateTerritories = async () => {
    let territoryData;

    // create new projectId if the user selects 'Create a new project', otherwise find the projectId associated with the selectedProject
    const projectId =
      selectedProject === 'Create a new project'
        ? uuidv4()
        : projects.find((project) => project.projectName === selectedProject)
            .projectId;

    const projectName =
      selectedProject === 'Create a new project'
        ? newProjectName
        : selectedProject;

    try {
      // setup payload
      const payload = {
        userId,
        projectId,
        projectName,
        creativeIdea: bigIdea,
        refImagery: imagesIdea,
        refMaterials: fileKeys,
        refTone: toneOfVoice,
        refColors: colorsIdea,
        requestType: 'territoryGen',
      };

      // make request twice
      let result1 = await mutateAsync(payload);
      let result2 = await mutateAsync(payload);

      // save territory data to context
      territoryData = [
        {
          id: result1.territoryId,
          typeface: 'Tagline',
          header: result1.title,
          description: result1.caption,
          descriptors: 'Title',
          imageArray: [],
          colorPalette: [],
        },
        {
          id: result2.territoryId,
          typeface: 'Tagline',
          header: result2.title,
          description: result2.caption,
          descriptors: 'Title',
          imageArray: [],
          colorPalette: [],
        },
      ];

      // set the current project id
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_CURRENT_PROJECT,
        payload: { projectId, projectName },
      });

      // load portfolio into context if using existing project
      if (selectedProject !== 'Create a new project') {
        let curProject = projects.find(
          (project) => project.projectName === selectedProject
        );
        let projectPortfolio = curProject.territories_text_data.map((entry) => {
          const territoryId = Object.keys(entry)[0];
          const result = entry[territoryId];

          return {
            id: territoryId,
            typeface: 'Tagline',
            header: result.title,
            description: result.caption,
            descriptors: 'Title',
            imageArray: [],
            colorPalette: [],
            morph: '', // Replace 'payload.requestType' with the actual value
          };
        });

        dispatch({
          type: MCSV2_ACTION_TYPES.SET_PORTFOLIO,
          payload: projectPortfolio,
        });
      } else {
        dispatch({
          type: MCSV2_ACTION_TYPES.SET_PORTFOLIO,
          payload: [],
        });
      }
    } catch (e) {
      console.log('error generating territories: ', e);
      // if request fails, set territoryData to the mock data
      territoryData = territories;
    }
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_TERRITORIES,
      payload: territoryData,
    });
    // switch to workspace view
    setExperience('territoryWorkspace');
  };

  const generateAssets = async () => {
    let imageAssetData = [];
    let textAssetData = [];

    // create new projectId if the user selects 'Create a new project', otherwise find the projectId associated with the selectedProject
    const projectId =
      selectedProject === 'Create a new project'
        ? uuidv4()
        : projects.find((project) => project.projectName === selectedProject)
            .projectId;
    const projectName =
      selectedProject === 'Create a new project'
        ? newProjectName
        : selectedProject;

    try {
      // setup payload
      const payload1 = {
        userId,
        projectId,
        projectName,
        creativeIdea: bigIdea || '',
        creativeCanvas: creativeCanvas,
        territoryId: selectedTerritory || '',
        requestType: 'assetGentext',
        directionText: '',
        refineResults: '',
      };
      let { assetid, text_response } = await mutateAsync(payload1);

      for (let i = 0; i < assetid.length; i++) {
        textAssetData.push({
          id: assetid[i],
          type: 'text',
          content: {
            text: text_response[i],
          },
        });
      }

      const payload2 = {
        userId,
        projectId,
        projectName,
        territoryId: selectedTerritory || '',
        creativeIdea: bigIdea || '',
        refImagery: imagesIdea,
        refMaterials: [],
        refTone: toneOfVoice,
        refColors: colorsIdea,
        requestType: 'assetGenImg',
        creativeCanvas: creativeCanvas,
      };
      let { assetIdListNew } = await mutateAsync(payload2);
      for (let i = 0; i < assetIdListNew.length; i++) {
        imageAssetData.push({
          id: assetIdListNew[i],
          type: 'image',
          content: {
            image: '',
          },
        });
      }

      // set the current project id
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_CURRENT_PROJECT,
        payload: { projectId, projectName },
      });
    } catch (e) {
      console.log('error generating assets: ', e);
      // if request fails, set assetData to the mock data
      imageAssetData = imageAssets;
      textAssetData = textAssets;
    }
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_IMAGE_ASSETS,
      payload: imageAssetData,
    });
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_TEXT_ASSETS,
      payload: textAssetData,
    });
    // switch to workspace view
    setExperience('assetWorkspace');
  };

  const saveTerritoryData = () => {
    const userInput = {
      bigIdea,
      files,
      imagery: imagesIdea ?? '',
      colors: colorsIdea ?? '',
      tone: toneOfVoice ?? '',
    };
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_TERRITORY_DATA,
      payload: userInput,
    });
  };

  const saveAssetData = () => {
    const userInput = {
      creativeIdea: bigIdea,
      creativeCanvas: creativeCanvas,
      territoryId: selectedTerritory,
      imagery: imagesIdea ?? '',
      colors: colorsIdea ?? '',
      tone: toneOfVoice ?? '',
      selectedTerritory: selectedTerritoryName ?? '',
    };
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_ASSETS_INPUT_DATA,
      payload: userInput,
    });
  };

  const setExperience = (experience) => {
    dispatch({
      type: MCSV2_ACTION_TYPES.CHOSEN_WORKSPACE,
      payload: { workspace: experience },
    });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.blue,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        paddingTop: '64px',
        position: 'relative',
      }}
    >
      <Box
        className="top_banner"
        sx={{
          width: '100%',
          padding: '80px 32px 72px 32px',
          background: theme.palette.primary.dark,
        }}
      >
        <Box
          className="title_container"
          sx={{
            maxWidth: '1440px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Buttons
            variant={'link-light'}
            sx={{
              textDecoration: 'underline',
              color: theme.palette.grey.white,
            }}
            startIcon={<ArrowIcon color={theme.palette.grey.white} />}
            onClick={() => {
              setExperience('intro');
            }}
          >
            Back to Landing Page
          </Buttons>
          <Typography
            variant="h1"
            sx={{
              color: theme.palette.grey.white,
              margin: '2rem 0 5.5rem 0',
            }}
          >
            Workspace Setup: Creative {title}
          </Typography>
        </Box>
      </Box>
      <Box
        className="form_shell"
        sx={{
          borderRadius: ' 8px',
          padding: '48px',
          background: theme.palette.grey.white,
          boxShadow: '0px 2px 18px 0px rgba(0, 0, 0, 0.12)',
          backdropFilter: 'blur(20px)',
          marginTop: '-48px',
          marginBottom: '48px',
          width: '70%',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: theme.palette.primary.main,
            margin: '0rem 0 3rem 0',
          }}
        >
          Create Your {title}
        </Typography>
        {workspace === 'territories' ? (
          <CreateTerritoryForm
            addFiles={addFile}
            addFileKeys={addFileKey}
            projects={projectNames}
            handleProjectChange={setSelectedProject}
            handleProjectNameChange={setNewProjectName}
            setBigIdea={setBigIdea}
            setImagesIdea={setImagesIdea}
            setColorsIdea={setColorsIdea}
            setToneOfVoice={setToneOfVoice}
          />
        ) : (
          <CreateAssetForm
            projectNames={projectNames}
            handleCreateCanvasChange={setCreativeCanvas}
            handleProjectChange={setProject}
            handleProjectNameChange={setNewProjectName}
            setBigIdea={setBigIdea}
            setImagesIdea={setImagesIdea}
            setColorsIdea={setColorsIdea}
            setToneOfVoice={setToneOfVoice}
            setExperience={setExperience}
            setSelectedTerritory={(id) => setSelectedTerritory(id)}
            selectedTerritory={selectedTerritory}
            setSelectedTerritoryName={setSelectedTerritoryName}
          />
        )}

        <Box style={footerLayout}>
          <Buttons
            variant="song"
            color="secondary"
            sx={{ paddingX: '25px', paddingY: '8px' }}
            onClick={() => {
              setExperience('intro');
            }}
          >
            Exit
          </Buttons>
          <Buttons
            onClick={
              workspace === 'territories'
                ? () => {
                    saveTerritoryData();
                    generateTerritories();
                  }
                : () => {
                    saveAssetData();
                    generateAssets();
                  }
            }
            disabled={
              workspace !== 'territories'
                ? !(
                    (bigIdea != '' || selectedTerritory != '') &&
                    creativeCanvas != ''
                  )
                : !(
                    bigIdea !== '' &&
                    (selectedProject === 'Create a new project'
                      ? newProjectName !== ''
                      : selectedProject !== '')
                  )
            }
            variant="song"
            sx={{ paddingX: '25px', paddingY: '8px' }}
          >
            Create
          </Buttons>
        </Box>
      </Box>
    </Box>
  );
};

export default ExperienceSetup;
