import { Box, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import theme from '../../styles/theme';
import Buttons from '../Button';
import SVGIcon from '../SVGIcon';

const DefaultUploadCard = ({
  fileType,
  isError,
  percent,
  fileName,
  fileSize = '0MB',
  isSmall = false,
}) => {
  const fileTypeSrc = fileType
    ? 'assets/images/fileDoc.svg'
    : 'assets/images/filePdf.svg';
  const checkmarkSrc = 'assets/images/checkmark.svg';
  const errorSrc = 'assets/images/error.svg';
  const xSrc = 'assets/images/x.svg';

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Card
        sx={{
          width: isSmall ? '300px' : '470px',
          maxWidth: isSmall ? '300px' : '470px',
          borderRadius: 2,
          outlineWidth: 1.5,
          backgroundColor: theme.palette.grey.light1,
          boxShadow: 0,
        }}
      >
        <CardContent
          sx={{
            '&:last-child': {
              paddingBottom: '16px',
            },
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item container>
              <Grid item>
                <img
                  src={isError ? errorSrc : fileTypeSrc}
                  height={30}
                  alt="File Icon"
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                sx={{ marginLeft: '8px' }}
              >
                <Grid item>
                  <Typography variant="body4">{fileName}</Typography>
                </Grid>
                <Grid item>
                  {!isError && (
                    <Typography variant="body5">{fileSize}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {isSmall ? (
                <Buttons
                  style={{
                    padding: '0',
                  }}
                  aria-label="close"
                >
                  <SVGIcon
                    name="close"
                    width={10}
                    height={10}
                    color={theme.palette.grey.black}
                  />
                </Buttons>
              ) : (
                <img
                  src={isError ? xSrc : checkmarkSrc}
                  height={isError ? 10 : 30}
                  alt={isError ? 'Error' : 'Checkmark'}
                />
              )}
            </Grid>
          </Grid>

          {isError ? (
            <Typography
              variant="body2"
              sx={{ fontSize: 15, paddingX: 15, paddingY: 1.3 }}
              color={theme.palette.error.main}
            >
              Upload failed. Click to retry
            </Typography>
          ) : (
            percent !== undefined && (
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item xs={10.3}>
                  <Box
                    sx={{
                      position: 'relative',
                      backgroundColor: theme.palette.grey.white,
                      width: '100%',
                      height: '8px',
                      borderRadius: '5px',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        height: '8px',
                        width: `${percent}%`,
                        borderRadius: '5px',
                        background: `linear-gradient(90deg, ${theme.palette.gradient.pink} 0%, ${theme.palette.gradient.lightPurple} 50%, ${theme.palette.gradient.darkPurple} 81%, ${theme.palette.gradient.lightBlue} 100%)`,
                      }}
                    ></Box>
                  </Box>
                </Grid>
                <Typography variant="body" sx={{ paddingLeft: '16px' }}>
                  {percent}%
                </Typography>
              </Grid>
            )
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export { DefaultUploadCard as UploadCard };
