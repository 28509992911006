import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useReducer } from 'react';
import { MCS2Context, MCS2DispatchContext } from '../context/MCS2Context';
import { useMachineCreativeStudioV2 } from '../hooks/useMachineCreativeStudioV2';
import { MCSV2_ACTION_TYPES } from '../reducers/mcsv2ActionTypes';
import { initial_MCSV2_State, mcsv2Reducer } from '../reducers/mcsv2Reducer';
import AssetWorkSpace from '../views/MCS2/AssetWorkSpace';
import ExperienceSetup from '../views/MCS2/ExperienceSetup';
import Intro from '../views/MCS2/Intro';
import WorkSpace from '../views/MCS2/WorkSpace';

const MCSV2 = () => {
  const [state, dispatch] = useReducer(mcsv2Reducer, initial_MCSV2_State);
  const { user } = useAuthenticator((context) => [context.authStatus]);
  const { mutateAsync } = useMachineCreativeStudioV2();
  const { workspace } = state;

  const renderComponents = () => {
    switch (workspace) {
      case 'territories':
      case 'assets':
        return <ExperienceSetup />;
      case 'territoryWorkspace':
        return <WorkSpace />;
      case 'assetWorkspace':
        return <AssetWorkSpace />;
      default:
        return <Intro />;
    }
  };

  const getUserData = async (id) => {
    const payload = {
      userId: id,
      projectId: '',
      territoryId: '',
      action: 'fetch',
    };

    const { projects } = await mutateAsync(payload);
    // set projects to context
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_PROJECTS,
      payload: projects,
    });
  };

  useEffect(() => {
    // set userId to context
    if (user) {
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_USER_ID,
        payload: user.username,
      });
      // call projectMgmt API to set projects for that user
      getUserData(user.username);
    }
  }, [user]);

  return (
    <>
      <MCS2Context.Provider value={state}>
        <MCS2DispatchContext.Provider value={dispatch}>
          {renderComponents()}
        </MCS2DispatchContext.Provider>
      </MCS2Context.Provider>
    </>
  );
};

export default MCSV2;
