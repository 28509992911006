import { DeleteOutlineOutlined } from '@mui/icons-material';
import { Box, Button, Card, Typography } from '@mui/material';
import React from 'react';
import theme from '../../styles/theme';
import HangTightLoader from '../HangTightLoader';
import { ImageBox } from '../ImageBox';

const AssetCard = ({
  type,
  content,
  id,
  width = 390,
  height = 390,
  noRemove,
  handleClick,
  isSelected,
  isButtonDisabled,
  handleAssetRemoveClick,
  isPortfolio,
  secondaryAsset,
  displayedImageLength,
  displayedTextLength,
}) => {
  const isSecondaryAssetSelected = id === secondaryAsset?.id ? true : false;

  const gradientStyle =
    isSelected || isSecondaryAssetSelected
      ? {
        borderRadius: '18px',
        boxSizing: isPortfolio ? 'inherit' : 'content-box',
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        padding: '4px',
        background: `linear-gradient(to top right, ${theme.palette.gradient.pink}, ${theme.palette.gradient.lightPurple}, ${theme.palette.gradient.darkPurple}, ${theme.palette.gradient.lightBlue} 120%)`,
      }
      : {
        borderRadius: '18px',
        boxSizing: isPortfolio ? 'inherit' : 'content-box',
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        padding: '4px',
        background: `${theme.palette.primary.light}00`,
      };

  const renderImageCard = () => {
    return (
      <Box sx={{ width: '100%' }}>
        {content.image ? (
          <ImageBox
            alt="AI generated image"
            src={`${content.image}`}
            styles={{
              borderRadius: '18px',
              width,
              height: 'auto',
            }}
          />
        ) : (
          <Box
            sx={{
              background: '#fff',
              width,
              height,
              borderRadius: '18px',
              padding: '0px',
            }}
          >
            <HangTightLoader />
          </Box>
        )}
      </Box>
    );
  };
  const renderTextCard = () => {
    return (
      <Card
        sx={{
          padding: 6,
          width: 390,
          height: 390,
          borderRadius: '18px',
          overflowY: 'hidden',
          textAlign: 'left',
          scrollbarGutter: 'stable',
          '&:hover': {
            overflowY: 'scroll',
          },
          '&::-webkit-scrollbar': {
            width: '8px',
            padding: '9px 0',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundImage: `linear-gradient(to top, ${theme.palette.gradient.pink}66, ${theme.palette.gradient.lightPurple}66, ${theme.palette.gradient.darkPurple}66, ${theme.palette.gradient.lightBlue}66 120%)`,
            borderRadius: '6px',
          },
          '&::-webkit-scrollbar-track': {
            margin: '12px 0',
          },
          whiteSpace: 'pre-wrap',
        }}
      >
        {content.text && <Typography>{content.text}</Typography>}
      </Card>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <button
        className="asset_card_button"
        style={{
          marginBottom: 2,
          border: 'none',
          background: 'none',
          cursor: isButtonDisabled ? 'unset' : 'pointer',
          padding: '0',
          width,
          height,
        }}
        onClick={handleClick}
      >
        <Box sx={{ width, height, alignItems: 'center', ...gradientStyle }}>
          {type === 'image' ? renderImageCard() : renderTextCard()}
        </Box>
      </button>
      {!noRemove && (
        <Button
          onClick={handleAssetRemoveClick}
          startIcon={<DeleteOutlineOutlined />}
          sx={{ paddingX: 2 }}
          disabled={
            (type === 'image' && !isPortfolio
              ? !(displayedImageLength > 1)
              : type === 'text' && !isPortfolio
                ? !(displayedTextLength > 1)
                : false) || isSelected
          }
        >
          <Typography
            sx={{ fontSize: '1.2em', marginLeft: -1, paddingTop: 0.1 }}
          >
            Remove
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export default AssetCard;
