/* eslint-disable indent */
import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import Buttons from '../../components/Button';
import RAFooterButton from '../../components/UXRA/RAFooterButton';
import RAHero from '../../components/UXRA/RAHero';
import RAIntroForm from '../../components/UXRA/RAIntroForm';
import { RAContext, RADispatchContext } from '../../context/RAContext';
import { useResearchAssistant } from '../../hooks/useResearchAssistant';
import { RA_ACTION_TYPES, RA_API_TYPES } from '../../reducers/raActionTypes';
import theme from '../../styles/theme';
import { formatAddParticicpant } from '../../util/randomizeParticipant';

import LoadingPage from '../../components/LoadingPage';
import { participantObj } from '../../reducers/raActionTypes.js';

const caption =
  'Welcome to the UX Research Assistant, a powerful tool designed to conduct synthetic interviews with users.';
const subCaption =
  'By simulating user behavior and responses, you can effectively prepare your interview guide and gain valuable practice before engaging with real individuals. To begin interviewing your user, start by entering relevant information about whom you would like to interview';

const footerButtonStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingRight: '80px'
};

function RAIntro() {
  const state = useContext(RAContext);
  const { participants } = state;

  const dispatch = useContext(RADispatchContext);
  const { avatars } = useContext(RAContext);
  const [participant, setParticipant] = useState(participantObj);
  const [participantNameError, setParticipantNameError] = useState(false);
  const [availableAvatars, setAvailableAvatars] = useState(avatars);

  const { mutateAsync: uxraMutateAsync, isLoading } = useResearchAssistant();

  const validateParticipantName = () => {
    if (!participant.participantName) {
      setParticipantNameError(true);
      return false;
    }
    setParticipantNameError(false);
    return true;
  };

  const AddParticipant = async (randomParticipantBool = false) => {
    if (!validateParticipantName() && randomParticipantBool === false) {
      return;
    }
    if (randomParticipantBool === true) {
      setParticipantNameError(false);
    }

    const avatar = availableAvatars[0] || '';

    const initiated = false;
    const updatedParticipant = formatAddParticicpant(
      randomParticipantBool,
      avatar,
      participant,
      setAvailableAvatars
    );

    setAvailableAvatars(availableAvatars.shift());

    const payload = {
      participantName: updatedParticipant.participantName,
      requestId: updatedParticipant.uuid,
      year: randomParticipantBool
        ? ''
        : `${updatedParticipant.ageRange[0]} to ${updatedParticipant.ageRange[1]}`, // need to set this to either the age range or specific age?
      description: randomParticipantBool
        ? ''
        : updatedParticipant.participantDescription,
      city: updatedParticipant.location,
      gender: randomParticipantBool ? '' : updatedParticipant.gender,
      temperature: updatedParticipant.temperature
    };

    try {
      const response = await uxraMutateAsync({
        ...payload,
        requestType: RA_API_TYPES.RA_REGISTER
      });

      if (randomParticipantBool === true) {
        updatedParticipant.participantDescription = response.description;
        updatedParticipant.age = response.year;
        updatedParticipant.gender = response.gender;
      }

      const addedParticipant = {
        ...updatedParticipant,
        requestId: updatedParticipant.uuid,
        avatar,
        initiated
      };

      dispatch({
        type: RA_ACTION_TYPES.UPDATE_PARTICIPANTS,
        payload: [...participants, addedParticipant]
      });
      dispatch({
        type: RA_ACTION_TYPES.UPDATE_AVATAR_LIST,
        payload: availableAvatars
      });
    } catch (err) {
      console.log('Error fetching response ', err);
    }

    dispatch({ type: RA_ACTION_TYPES.CONTINUE_FROM_INTRO });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Grid
        container
        direction={'column'}
        sx={{
          margin: '64px auto 0',
          position: 'relative',
          maxWidth: '100%',
          height: 'auto',
          backgroundColor: theme.palette.primary.light2,
          paddingBottom: '17.5rem'
        }}
      >
        <Grid
          item
          display="flex"
          sx={{
            flexDdirection: 'column',
            backgroundColor: theme.palette.grey.white,
            justifyContent: 'center'
          }}
        >
          <RAHero
            title="Let's get this conversation started!"
            caption={caption}
            subCaption={subCaption}
            images={[
              'assets/images/chat1.png',
              'assets/images/chat2.png',
              'assets/images/chat3.png'
            ]}
            doScroll={{
              animate: {
                visible: { scale: 1.2 },
                transistion: { ease: 'easeInOut', delay: 2 }
              },
              downElemId: 'genai-app-footer'
            }}
          />
        </Grid>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.light2
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            borderRadius="8px"
            boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
            backgroundColor={theme.palette.grey.white}
            width="1440px"
            sx={{
              width: '1062px',
              position: 'relative',
              top: '143px',
              padding: '2rem 6rem 0 6rem'
            }}
          >
            <Box
              marginTop="2.5em"
              paddingBottom="2rem"
              borderBottom="1px solid #777777"
            >
              <Typography
                variant="h1"
                color={theme.palette.primary.main}
                gutterBottom
              >
                Who are you interviewing?
              </Typography>
              <Typography
                variant="subtitle1"
                color={theme.palette.grey.dark}
                gutterBottom
                fontWeight={400}
              >
                Create and add a participant to your interview. Participants
                will answer the question you ask. You can add up to 3
                participants.
              </Typography>
              <Typography
                variant="subtitle2"
                color={theme.palette.grey.dark}
                gutterBottom
                fontWeight={400}
              >
                Note: generated responses will be tailored to the information
                you provide
              </Typography>
            </Box>
            <RAIntroForm
              setData={setParticipant}
              person={participant}
              participantNameError={participantNameError}
            />
          </Box>
        </Box>
      </Grid>

      <RAFooterButton>
        <Stack
          className="footer-button-stack"
          spacing={6}
          direction="row"
          style={footerButtonStyle}
        >
          <Buttons
            variant="song"
            color="secondary"
            sx={{ paddingX: '25px' }}
            onClick={() => {
              AddParticipant(true);
            }}
          >
            Generate Random Participant
          </Buttons>
          <Buttons
            variant="song"
            sx={{ paddingX: '25px' }}
            onClick={() => {
              AddParticipant();
            }}
          >
            Add Participant
          </Buttons>
        </Stack>
      </RAFooterButton>
      <span
        id="genai-app-footer"
        style={{ backgroundColor: theme.palette.secondary.blue }}
      >
        _
      </span>
    </>
  );
}

export default RAIntro;
