import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';

import CloseX from './icons/CloseX.jsx';

const sr_only = {
  position: 'absolute',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
};

function CloseButton({ btnAction, styles }) {
  return (
    <>
      <Button variant="text" style={{ ...styles }} onClick={btnAction}>
        <CloseX />
        <span style={sr_only}>Close</span>
      </Button>
    </>
  );
}

export default CloseButton;

CloseButton.propTypes = {
  btnAction: PropTypes.func.isRequired,
  styles: PropTypes.object,
};
