import { Box, Typography } from '@mui/material';
import React from 'react';
import HeroIcons from '../components/HeroIcons';
import HeroScrollDown from '../components/HeroScrollDown';
import HomeUseCaseGrid from '../components/UseCaseCardGrid';
// import YourProjects from '../components/YourProjects';
import theme from '../styles/theme';

export default function LandingPage() {
  const heading = 'Introspection';
  const subHeading =
    'Is a structured method of self-reflection developed by Yoshimoto Ishin in the 1940s. The practice is based around asking oneself three questions about a person in ones life What did I receive from this person? What did I return to this person? What troubles, worries, unhappiness did I cause this person?.';

  const darkThemeColor = [`${theme.palette.primary.light}`, `${theme.palette.primary.dark}`];

  return (
    <Box
      sx={{
        padding: '0 0 550px 0',
        background: darkThemeColor[1],
        margin: 'auto 0',
        width: '100%',
        maxHeight: 'auto',
        overflowX: 'hidden',
        textShadow: '0 4px 10px rgba(30, 30, 30, 0.5)'
      }}
    >
      <Box
        sx={{
          background: darkThemeColor[1],
          position: 'relative',
          padding: '100px 0'
        }}
      >
        <Box sx={{ zIndex: '300', position: 'relative' }}>
          <Typography
            component="h1"
            variant="headerTitleHomepage"
            sx={{
              color: `${theme.palette.grey.white}`,
              textAlign: 'center',
              padding: '3% 24%',
              lineHeight: '20px',
              textShadow: '0 4px 10px rgba(30, 30, 30, 0.1)',
            }}
          >
            {heading}
          </Typography>
          <Typography
            component="h2"
            variant="body3"
            sx={{
              lineHeight: '24px',
              color: '#FFF',
              textAlign: 'center',
              padding: '2% 24%',
              textShadow: '0 4px 10px rgba(30, 30, 30, 0.1)',
            }}
          >
            {subHeading}
          </Typography>
        </Box>
        <HeroIcons />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          maxWidth: '1440px',
          height: '100%',
          padding: '100px 0 0 24px',
          margin: '0 auto',
        }}
      >
        <HeroScrollDown />
        <HomeUseCaseGrid />
        {/* <YourProjects /> */}
      </Box>
    </Box>
  );
}
