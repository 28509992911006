export const restructureIdeas = (descriptions, isNew) => {
  return descriptions.reduce((result, idea) => {
    result[idea.id] = {
      ...idea,
      ideaHistory: [],
      isBookmarked: false,
      image: idea.image ?? 'assets/images/idea-sample-image-1.png',
      isNew,
    };
    return result;
  }, {});
};
