import { Button, InputBase, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import theme from '../styles/theme';
import Card from './Card';

const ChatInput = ({
  onClick,
  onEnterPress,
  showOptions,
  setShowOptions,
  ideaArray,
  activeIdeas,
  handleSelection,
}) => {
  const [text, setText] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box sx={{ paddingBottom: '10px', marginTop: 2 }}>
      {showOptions ? (
        <Box
          sx={{
            p: '10px 2px 10px 20px',
            display: 'flex',
            border: isFocused
              ? `1px solid ${theme.palette.primary.main}`
              : `1px solid ${theme.palette.grey.light2}`,
            borderRadius: '8px',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: 14,
                marginBottom: 2,
                color: theme.palette.grey.main,
              }}
            >
              Select all that apply and then hit send
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '18px',
              }}
            >
              {ideaArray.map((idea, idx) => {
                return (
                  <Card
                    key={`rad${idx}${idea}`}
                    each={idea}
                    index={idx}
                    handleClick={handleSelection}
                    active={activeIdeas}
                  />
                );
              })}
            </Box>
          </Box>
          <Button
            sx={{ p: '10px' }}
            startIcon={<img src="../../assets/send-icon.svg" alt="send" />}
            aria-label="question"
            onClick={() => {
              onClick(activeIdeas, 'option');
              setShowOptions(false);
            }}
            disabled={activeIdeas?.length ? false : true}
          >
            <Typography
              sx={{
                fontSize: 14,
                color: theme.palette.grey.main,
                fontWeight: 600,
              }}
            >
              Send
            </Typography>
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid',
            borderColor: theme.palette.grey.light2,
            borderRadius: '8px',
          }}
        >
          <InputBase
            sx={{
              ml: 1,
              p: 1,
              flex: 1,
              width: '100%',
              '& input::placeholder': { fontSize: '14px' },
            }}
            placeholder={'Type your question here'}
            onChange={(e) => {
              setText(e.target.value);
            }}
            value={text}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && text.trim()) {
                e.preventDefault();
                onClick(text);
                setText('');
              }
            }}
            multiline={true}
          />
          <Button
            sx={{ p: '10px' }}
            startIcon={<img src="../../assets/send-icon.svg" alt="send" />}
            aria-label="question"
            onClick={() => {
              onClick(text, 'text');
              setText('');
            }}
            disabled={text.trim() ? false : true}
            onKeyDown={text.trim() && onEnterPress}
          >
            <Typography
              sx={{
                fontSize: 14,
                color: theme.palette.grey.main,
                fontWeight: 600,
              }}
            >
              Send
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ChatInput;
