import { Box, CardContent, Typography } from '@mui/material';
import React from 'react';
import theme from '../../styles/theme';
// import Buttons from '../Button';
// import SVGIcon from '../SVGIcon';
import ExpandCard from './ExpandCard';

const YourCreativeBrief = ({ bigIdea, fileCount, editCreativeResult, isAsset }) => {
  const cardContent = (
    <Box
      sx={{
        border: `2px solid ${theme.palette.grey.light2}`,
        borderRadius: '8px',
        width: '100%',
        minHeight: !isAsset ? 300 : '',
        height: '100%',
        padding: 5,
      }}
    >
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Buttons
          label={'Edit'}
          endIcon={<SVGIcon name={'edit'} color={theme.palette.primary.main} />}
          sx={{ fontSize: '15px' }}
          onClick={editCreativeResult}
        />
      </Box> */}

      <Typography
        color={theme.palette.primary.main}
        fontSize="15px"
        fontWeight={600}
        lineHeight="21.5px"
        fontFamily="GraphikSemibold"
      >
        {isAsset && bigIdea?.selectedTerritory ? 'Territory' : 'The Big Idea'}
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={400}
        color={theme.palette.grey.black}
        sx={{ marginTop: 2, paddingBottom: 4 }}
        borderBottom={!isAsset ? `1px solid ${theme.palette.blue.main}` : ``}
      >
        {isAsset
          ? bigIdea?.creativeIdea || bigIdea?.selectedTerritory
          : bigIdea}
      </Typography>

      {!isAsset && (
        <>
          <Typography
            color={theme.palette.primary.main}
            fontSize="20px"
            fontWeight={900}
            sx={{ marginTop: '16px' }}
          >
            Reference Images
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={400}
            color={theme.palette.grey.black}
            sx={{ marginTop: '8px' }}
          >
            Reference Materials ({fileCount})
          </Typography>
        </>
      )}
    </Box>
  );

  return (
    <ExpandCard title="Your Creative Brief" isSliderDefault>
      <CardContent sx={{ padding: 0 }}>{cardContent}</CardContent>
    </ExpandCard>
  );
};

export default YourCreativeBrief;
