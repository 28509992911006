import { Box, CardContent, Typography } from '@mui/material';
import React from 'react';
import theme from '../../styles/theme';

const PlaceholderTile = ({
  width='470px',
  height='210px',
  isEditing = false,
  description=`Click on a Territory Above`
}) => {

  return (
      <Box
          sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '4px',
              marginBottom: '2rem',
              border: `1px dashed ${theme.palette.primary.main}`,
              borderRadius: '8px',
              padding: '105px 0px',
              width: width,
              height: height
          }}
      >
          
              <CardContent sx={{ paddingBottom: 0, textAlign:'center' }}>
                  <Typography variant='body3' gutterBottom sx={{paddingY:2}}>
                      {description}
                  </Typography>
              </CardContent>
          
      </Box>
  );
};

export default PlaceholderTile