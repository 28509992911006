import React from 'react';
import { Box } from '@mui/material';
import SVGIcon from './SVGIcon';
// import SVGThread from './SVGThread';

export default function HeroIcons(props) {
    const iconColor = ['url(#filter1_f_260_120)', '#FFF', '#FF1678'];
    const iconName = ['strings', 'star-5points', 'mark-stretch'];
    const iconVPosition = ['400', '400'];
    const iconHPosition = ['600', '-300'];
    const iconRotation = [0, 12, 90, 125];

    return (
        <Box sx={{ margin: '0 auto', maxWidth: '1440px', position: 'relative', top: '-380px', left: '1%', zIndex: '100' }}>








            {/* <SVGIcon name={iconName[1]} colord1={iconColor[2]} filter='url(#filter0_i_3402_2736)'
    style={{
        position: 'absolute', zIndex: '100',
        top: `${iconVPosition[3]}`,
        left: `${iconHPosition[3]}`,
    }} />
    



            {/* {/* <SVGIcon width={41} height={48} name={iconName[2]} strokecolor={iconColor[1]} style={{
    position: 'absolute', zIndex: 140,
    top: `${iconVPosition[4]}`,
    left: `${iconHPosition[4]}`,
    transform: `rotate(${iconRotation[3]}deg)`,
}} /> */}




            <SVGIcon width={141} height={248} name={iconName[2]} strokecolor={iconColor[1]} style={{
                position: 'absolute', zIndex: 140,
                top: `${iconVPosition[0]}`,
                left: `${iconHPosition[1]}`,
                transform: `rotate(${iconRotation[3]}deg)`,
            }} />



            {/* <SVGIcon width={21} height={28} name={iconName[2]} color={iconColor[8]} style={{
    position: 'absolute', zIndex: '140',
    top: `${iconVPosition[7]}`,
    left: `${iconHPosition[7]}`,
    transform: `rotate(${iconRotation[1]}deg)`,
}} />
<SVGIcon width={22} height={22} name={iconName[0]} color={iconColor[2]} style={{
    position: 'absolute', 
    top: `${iconVPosition[8]}`,
    left: `${iconHPosition[8]}`,
}} /> */}
            {/* <SVGIcon name={iconName[0]} color={iconColor[3]} style={{
    position: 'absolute', 
    top: `${iconVPosition[9]}`,
    left: `${iconHPosition[9]}`,
}} />
<SVGIcon width={40} height={40} name={iconName[0]} color={iconColor[4]} style={{
    position: 'absolute',
    top: `${iconVPosition[10]}`,
    left: `${iconHPosition[10]}`,
}} />
<SVGIcon width={40} height={40} name={iconName[0]} color={iconColor[3]} style={{
    position: 'absolute', 
    top: `${iconVPosition[11]}`,
    left: `${iconHPosition[11]}`,
}} />
<SVGIcon width={66} height={66} name={iconName[8]} color={iconColor[11]} style={{
    position: 'absolute', zIndex: '100',
    top: `${iconVPosition[12]}`,
    left: `${iconHPosition[12]}`,
}} /> */}
        </Box>

    );
}
