import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import theme from '../../styles/theme';
import Buttons from '../Button';
import SVGIcon from '../SVGIcon';
import TerritoryCard from './TerritoryCard';
import ThumbnailsDetailCard from './ThumbnailsDetailCard';

const TerritorySearch = ({
  territories,
  isNew,
  userInput,
  inspectRefinementText,
  inspectSliderInputs,
  style,
  selectedIndex,
  settingsView,
  directionText,
  type,
  generateMore,
  blendText,
  iterateText,
  requestType,
  deleteNewSearch,
  imageNotLoaded
}) => {
  const [isInspected, setIsInspected] = useState(false);
  const [territory1, territory2 = null] = territories;
  const renderInspectPanel = () => {
    return (
      <Box
        className="territory_search_container"
        sx={{
          borderRadius: 3,
          border: `1px solid ${theme.palette.primary.main}`,
          marginBottom: 12,
        }}
      >
        <Box sx={{ padding: 12, fontSize: 12 }}>
          <Typography
            sx={{ textTransform: 'uppercase', paddingBottom: 3 }}
            color={theme.palette.primary.main}
            gutterBottom
          >
            Inspect
          </Typography>

          {requestType === 'iterateTerritory' && (
            <Grid container spacing={20}>
              <Grid item xs={7}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                  Original {type === 'territory' ? `Territory` : `Asset`}
                </Typography>
                <TerritoryCard
                  imageArray={territory1?.imageArray}
                  header={territory1?.header}
                  colorPalette={territory1?.colorPalette}
                  width={'475px'}
                  noRemove
                />
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                  Iterate Directions
                </Typography>
                <Typography paragraph sx={{ fontSize: 18 }}>
                  {iterateText}
                </Typography>
              </Grid>
            </Grid>
          )}

          {requestType === 'blendTerritory' && (
            <Grid container spacing={20}>
              <Grid item xs={6}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                  Blended Territories
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '15px',
                    direction: 'row',
                  }}
                >
                  <ThumbnailsDetailCard
                    imageArray={territory1?.imageArray}
                    header={territory1?.header}
                    colorPalette={territory1?.colorPalette}
                  />
                  <ThumbnailsDetailCard
                    imageArray={territory2?.imageArray}
                    header={territory2?.header}
                    colorPalette={territory2?.colorPalette}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                  Blend Directions
                </Typography>
                <Typography paragraph sx={{ fontSize: 18 }}>
                  {blendText}
                </Typography>
              </Grid>
            </Grid>
          )}

          {requestType !== 'iterateTerritory' &&
            requestType !== 'blendTerritory' && (
              <div>
                <Grid container spacing={20}>
                  <Grid item xs={6}>
                    <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                      Refinement Text
                    </Typography>
                    <Typography paragraph sx={{ fontSize: 18 }}>
                      {inspectRefinementText}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h4" gutterBottom sx={{ fontSize: 18 }}>
                      Slider Inputs
                    </Typography>
                    <Typography paragraph sx={{ fontSize: 18 }}>
                      {inspectSliderInputs}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
        </Box>
      </Box>
    );
  };

  const showUserInput = () => {
    const userInputStyle = {
      display: 'inline',
      fontWeight: 200,
    };

    if (userInput.length > 25) {
      return <span style={userInputStyle}>{userInput.slice(0, 20)}...</span>;
    } else {
      return <span style={userInputStyle}>{userInput}</span>;
    }
  };

  return (
    <Box className="territory_bar_container" sx={{ ...style }}>
      <Grid container spacing={2} sx={{ paddingBottom: 10 }}>
        <Grid item xs={7.5}>
          <Box>
            <Grid container spacing={6}>
              <Grid item>
                <Typography
                  variant="h2"
                  sx={{ paddingTop: '0.3rem' }}
                  color={theme.palette.primary.dark}
                  fontSize={22}
                >
                  {isNew ? 'Original Search' : 'Search: '}
                  {isNew ? '' : showUserInput()}
                </Typography>
              </Grid>
              <Grid item>
                <Buttons
                  style={{ paddingTop: '0.75rem' }}
                  sx={{
                    fontFamily: 'GraphikRegular',
                    fontSize: '18px',
                    lineHeight: '24px',
                    textDecoration: 'underline',
                  }}
                  startIcon={
                    <SVGIcon
                      name="eye-inspect"
                      color={
                        selectedIndex === 2 || settingsView
                          ? theme.palette.state.disabled_secondary
                          : theme.palette.primary.main
                      }
                    />
                  }
                  onClick={() => {
                    setIsInspected(!isInspected);
                  }}
                  disabled={selectedIndex === 2 || settingsView}
                >
                  {isInspected ? 'Close Inspect' : 'Inspect'}
                </Buttons>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={4.5}>
          <Box sx={{ float: 'right' }}>
            <Buttons
              sx={{
                fontFamily: 'GraphikRegular',
                fontSize: '18px',
                lineHeight: '24px',
                textDecoration: 'underline',
              }}
              startIcon={
                <SVGIcon
                  name="trash"
                  color={
                    // selectedIndex === 2 || settingsView
                    isNew
                      ? theme.palette.state.disabled_secondary
                      : theme.palette.primary.main
                  }
                />
              }
              // disabled={selectedIndex === 2 || settingsView}
              disabled={isNew}
              onClick={() => deleteNewSearch()}
            >
              Delete Search
            </Buttons>
            <Buttons
              variant="song"
              color="secondary"
              style={{ marginLeft: '1.5rem' }}
              sx={{
                background: theme.palette.opacity.clear0,
                padding: '5px 12px',
                outlineColor:
                  (selectedIndex === 2 || settingsView) &&
                  theme.palette.grey.dark1,
              }}
              disabled={imageNotLoaded}
              onClick={() => generateMore()}
            >
              Give Me More
            </Buttons>
          </Box>
        </Grid>
      </Grid>
      <Box>{isInspected ? renderInspectPanel() : ''}</Box>
    </Box>
  );
};

export default TerritorySearch;
