import React, { useState, useMemo, useEffect, useRef } from 'react';

import Buttons from './Button';
import SVGIcon from './SVGIcon';
import { PDFDownloadLink } from '@react-pdf/renderer';
import AlertToasterBanner from './AlertToasterBannner';
import PropTypes from 'prop-types';
import ExportRenderer from './ExportRenderer';
import { Box } from '@mui/material';
import theme from '../styles/theme';
import { zipItservice } from '../util/exporterService';

export default function Exporter({
  dataExported,
  dataName,
  fileTypeRequest,
  pdfHeading,
  fileMetadata,
  buttonOverwrite,
  alerObject,
  zipIt,
  boxStyle,
  iconV2,
  regularButton,
}) {
  const exportLinkRef = useRef(null);
  const [isAlert, setIsAlert] = useState(false);
  const [alertBanners, setAlertBanners] = useState([]);
  const [exportData, setExportData] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [category, setCategory] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [documentLink, setDocumentLink] = useState(false);
  const [blobObject, setBlobObject] = useState([]);
  const [renderResult, setRenderResult] = useState(null);
  const [fileType, setFileType] = useState({
    image: '',
    text: '',
    pdf: '',
  });
  const iconExport = (
    <SVGIcon
      height={`${!iconV2 ? 24 : 22}`}
      weight={`${!iconV2 ? 24 : 22}`}
      name={`${!iconV2 ? 'export' : 'export-v2'}`}
      color={
        !documentLink
          ? `${
              !iconV2
                ? theme.palette.state.disabled_primary
                : theme.palette.state.disabled_secondary
            }`
          : `${!iconV2 ? theme.palette.grey.black : theme.palette.primary.main}`
      }
    />
  );

  const buttonDefault = {
    id: `${dataName}-button`,
    label: 'Export',
    sx: { textDecoration: 'none' },
    style: { width: '100%', margin: '0', transition: '0.4s ease-in' },
    startIcon: iconExport,
  };

  const { appName = 'MCS-2' } = fileMetadata || {};
  const headings = pdfHeading ?? {
    title: 'Generative AI v2',
    subTitle: 'exported Documents',
    author: 'Emotional AI',
  };
  const time = new Date()
    .toLocaleTimeString()
    .replaceAll(':', '-')
    .split(' ')[0];
  const date = new Date().toLocaleDateString().replaceAll('/', '-');
  const dateTimeStamp = `${date}'-'${time}`;

  const defaultTypes = {
    image: 'image/png',
    text: 'text/plain',
    pdf: 'application/pdf',
  };

  const typeAdjusted = {
    pdf: fileTypeRequest.pdf
      ? fileTypeRequest.pdf === 'pdf'
        ? 'application/pdf'
        : 'application/pdf'
      : null,
    text: fileTypeRequest.text
      ? fileTypeRequest.text === 'text'
        ? 'text/plain'
        : 'text/plain'
      : null,
    image: fileTypeRequest.image
      ? fileTypeRequest.image === 'image'
        ? 'image/png'
        : 'image/png'
      : null,
  };

  //Initiate PDF rendering
  const documentReady = useMemo(() => {
    return exportData && category ? (
      <ExportRenderer
        documentHeading={headings}
        documentName={category}
        documentData={exportData}
      />
    ) : null;
  }, [exportData, category]);

  useEffect(() => {
    if (documentReady) {
      setDocumentLink(true);
      setIsDisabled(false);
    }
  }, [documentReady]);

  useEffect(() => {
    let blobArray = [{}, {}, {}];
    if (typeAdjusted) {
      setFileType(typeAdjusted);
    } else {
      setFileType(defaultTypes);
    }
    if (dataExported && dataName) {
      setCategory(dataName);
      setExportData(dataExported);

      try {
        if (fileType.image) {
          //imageData = is type Object.imageUrl ?
          const imageUrl = dataExported;
          if (imageUrl) setIsDisabled(false);
          blobArray[0] = { data: imageUrl, type: fileType.image };
        }
        if (fileType.text) {
          //textData = is type Object.text ?
          const textObject = dataExported;
          if (textObject) setIsDisabled(false);
          blobArray[1] = { data: textObject, type: fileType.text };
        }
        if (fileType.pdf && exportLinkRef.current) {
          blobArray[2] = { data: exportLinkRef.current, type: fileType.pdf };
        }
        setBlobObject(blobArray);
      } catch (error) {
        console.error('Error in Export Option Request', error);
      }
    } else {
      setIsDisabled(true);
      console.error('Data & Name are not provided');
    }
  }, [dataExported, dataName]);

  const renderAlerts = (
    <div>
      <AlertToasterBanner
        {...alertBanners[0]}
        style={{ position: 'absolute', zIndex: '500', top: 0, left: '100%' }}
      />
      <AlertToasterBanner
        {...alertBanners[1]}
        style={{ position: 'absolute', zIndex: '500', top: 0, left: '100%' }}
      />
    </div>
  );

  const handleAlert = (alertFrom) => {
    if (!alerObject && exportLinkRef.current) {
      const alertList = [
        {
          open: alertFrom === 'pdf' ? true : false,
          alertType: exportLinkRef.current.error ? 'error' : 'success',
          alertTitle: exportLinkRef.current.error
            ? 'Export Error'
            : 'Export Successful',
          alertMessage: exportLinkRef.current.error
            ? `There is an error in ${dataName}${fileType}  download`
            : `Your ${dataName}${fileType} document is successfuly downloaded`,
        },
        {
          open: alertFrom === 'info' ? true : false,
          alertType: 'warning',
          alertTitle: 'Information title',
          alertMessage: `inormation message`,
        },
      ];
      setIsAlert(true);
      setAlertBanners(alertList);
    } else {
      const alertList = alerObject;
      setIsAlert(true);
      setAlertBanners(alertList);
    }
  };

  const handleZipIt = () => {
    const imageIndex = exportMixFormat.indexOf(fileType.image);
    const textIndex = exportMixFormat.indexOf(fileType.text);
    const pdfIndex = exportMixFormat.indexOf(fileType.pdf);
    const imageData = exportMixFormat[imageIndex];
    const textData = exportMixFormat[textIndex];
    const pdfData = exportMixFormat[pdfIndex];

    const imageLink = imageData ?? null;
    const textObject = textData ?? null;
    const pdfLink = pdfData ?? null;
    const blobInfo = exportMixFormat ?? null;
    const fileName = `${category}_${dateTimeStamp}`;

    try {
      console.log('zipIt and download');
      const zipped = zipItservice(
        imageLink,
        textObject,
        pdfLink,
        blobInfo,
        fileName
      );
    } catch (error) {
      console.error('Error in zipIt and download');
    }
  };

  const handleExport = () => {
    if (exportLinkRef.current.error) {
      handleAlert('pdf');
      console.error(error);
      return;
    }

    if (documentReady) {
      const pdfUrl = exportLinkRef.current?.url;
      const anchor = document.createElement('a');
      if (anchor && pdfUrl) {
        anchor.href = pdfUrl;
        anchor.download = `${category}-${dateTimeStamp}.pdf`;
        anchor.click();
        handleAlert('pdf');
      }
    }

    if (zipIt) {
      console.log('handle ZIP');
    }
  };

  const handlePreview = () => {
    setOpen(true);
  };

  const pdfDownloadLink = (
    <PDFDownloadLink
      document={documentReady}
      fileName={`${category}-${dateTimeStamp}.pdf`}
    >
      {({ blob, url, loading, error }) => {
        if (error) {
          console.log('PDF Download Link error :', error);
          exportLinkRef.current = { error: true };
        }
        if (url && blob) {
          exportLinkRef.current = { error: false, url, blob };
        }
      }}
    </PDFDownloadLink>
  );

  return (
    <>
      {documentReady && pdfDownloadLink}
      {isAlert && renderAlerts}
      <Box id={`${dataName}-exporter-ui`} sx={boxStyle ?? { margin: '0' }}>
        <Buttons
          {...buttonDefault}
          {...buttonOverwrite}
          className={'exporter-button'}
          onClick={() => handleExport()}
          disabled={isDisabled}
          regularButton={regularButton}
        />
      </Box>
    </>
  );
}

Exporter.propTypes = {
  dataExported: PropTypes.array || PropTypes.object || PropTypes.func,
  dataName:
    PropTypes.string ||
    PropTypes.oneOf([
      'GCEIdeaModal',
      'GCEIdea',
      'UXRAChat',
      'UXRAInterview',
      'MCSTerritory',
      'MCSPortfolioAsset',
      'MCSPortfolioTerritory',
      'MCSAsset',
      'MCSMockup',
    ]).required,
  fileTypeRequest: PropTypes.object,
  pdfHeadings: PropTypes.object,
  buttonOverwrite: PropTypes.object,
  fileMetadata: PropTypes.object,
  zipIt: PropTypes.bool,
};
