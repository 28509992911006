import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../styles/theme';

const cardContentStyle = {
  padding: '16px 24px',
  '&:last-child': {
    pb: 2,
  },
};

const GCEChatBubble = ({ isUser, text, basicStyle }) => {
  const renderUserChatBubble = () => {
    return (
      <Box
        sx={{
          maxWidth: 574,
          marginTop: 3,
        }}
      >
        {basicStyle ? (
          <>
            <Card
              sx={{
                maxWidth: 574,
                borderRadius: 4,
                boxShadow: 0,
                border: `1.5px solid ${theme.palette.grey.light}`,
              }}
            >
              <CardContent sx={cardContentStyle}>
                <Typography paragraph sx={{ margin: 0 }}>
                  {text}
                </Typography>
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            <Card
              sx={{
                maxWidth: 574,
                borderRadius: 4,
                boxShadow: 0,
                backgroundColor: theme.palette.grey.light,
              }}
            >
              <CardContent sx={cardContentStyle}>
                <Typography paragraph sx={{ margin: 0 }}>
                  {text}
                </Typography>
              </CardContent>
            </Card>
          </>
        )}
      </Box>
    );
  };

  const renderAIChatBubble = () => {
    return (
      <Box key={text}>
        {basicStyle ? (
          <>
            <Grid container spacing={0}>
              <Grid xs={8}>
                <Box
                  sx={{
                    paddingY: 1.5,
                  }}
                ></Box>
                <Card
                  sx={{
                    width: '100%',
                    minWidth: 230,
                    borderRadius: 4,
                    boxShadow: 0,
                    border: `1.5px solid ${theme.palette.blue.main}`,
                  }}
                >
                  <CardContent sx={cardContentStyle}>
                    <Typography paragraph sx={{ margin: 0, fontSize: 18 }}>
                      {text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={0}>
              <Grid sx={{ paddingTop: 3 }}>
                <Avatar
                  src="assets/images/avatar3.svg"
                  sx={{ width: 74, height: 74 }}
                />
              </Grid>
              <Grid xs={8}>
                <Box
                  sx={{
                    paddingY: 1.5,
                    color: theme.palette.grey.dark,
                  }}
                ></Box>
                <Card
                  sx={{
                    width: '100%',
                    borderRadius: 4,
                    boxShadow: 0,
                    backgroundColor: theme.palette.secondary.blue,
                  }}
                >
                  <CardContent sx={cardContentStyle}>
                    <Typography paragraph sx={{ margin: 0 }}>
                      {text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    );
  };

  return <>{isUser ? renderUserChatBubble() : renderAIChatBubble()}</>;
};

GCEChatBubble.propTypes = {
  isUser: PropTypes.bool,
  text: PropTypes.string,
  basicStyle: PropTypes.bool,
};

export default GCEChatBubble;
