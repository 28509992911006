import { DeleteOutlineOutlined } from '@mui/icons-material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import theme from '../../styles/theme';
import Buttons from '../Button';
import { ImageBox } from '../ImageBox';

const cardStyle = {
  maxWidth: '455px',
  width: '100%',
  bgcolor: 'theme.palette.grey.white',
  boxShadow: '0px 2px 18px 0px rgba(0, 0, 0, 0.12)',
  backdropFilter: 'blur(20px)',
  height: 'auto',
  maxHeight: '520px',
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: '0px',
  gap: '24px',
};

const tagStyle = {
  background:
    '-webkit-linear-gradient(90deg, #FF50A0 0%, #A100FF 50.87%, #7500C0 80.03%, #0FF 100%)',
  backgroundClip: 'text',
  '-webkit-text-fill-color': 'transparent',
  '-webkit-background-clip': 'text',
};

const containerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  alignSelf: 'stretch',
};

export default function IdeasCard(props) {
  const {
    idea,
    tag,
    handleClick,
    handleBookmark,
    removeIdea,
    isViewBookmarked,
    style,
  } = props;
  const { id, title, headline, isBookmarked, image } = idea;
  const abbreviatedCaptionText =
    headline.length > 180 ? `${headline.slice(0, 177)}...` : headline;

  return (
    <>
      <Box className="ideaCard_shell" style={{...style}}>
        <Card className="ideaCard" style={cardStyle}>
          <CardContent
            className="ideaCard_content_wrapper"
            style={{ padding: '0px', width: '100%' }}
          >
            <Box className="ideaCard_content">
              {image && (
                <ImageBox
                  className="ideaCard_image"
                  styles={{ background: '#D9D9D9', height: '242px' }}
                  src={image}
                  alt={'sample image'}
                  height={'242'}
                  width={'375'}
                />
              )}
              <Box sx={{ padding: '24px', height: 176 }}>
                {tag && (
                  <Typography
                    variant="h5"
                    style={tagStyle}
                    fontSize={16}
                    lineHeight={'20px'}
                    fontStyle={'normal'}
                    fontWeight={500}
                    gutterBottom
                  >
                    {tag.toUpperCase()}
                  </Typography>
                )}
                {title && (
                  <Typography
                    marginTop={'8px'}
                    variant="h4"
                    fontSize={18}
                    lineHeight={'24px'}
                    fontStyle={'normal'}
                    fontWeight={600}
                    color={theme.palette.grey.black}
                    gutterBottom
                  >
                    {title}
                  </Typography>
                )}
                {headline && (
                  <Typography
                    marginTop={'12px'}
                    variant="body1"
                    fontSize={16}
                    lineHeight={'26px'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    color={theme.palette.grey.black}
                    gutterBottom
                  >
                    {abbreviatedCaptionText}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              style={containerStyle}
              sx={{
                justifyContent: 'left',
                display: 'flex',
                padding: '16px 24px 24px 24px',
              }}
            >
              <Buttons
                variant="song"
                color="secondary"
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '8px',
                }}
                onClick={() => handleClick(idea.id)}
              >
                <Typography
                  style={{
                    textAlign: 'center',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '24px',
                  }}
                >
                  View More
                </Typography>
              </Buttons>
              <Button
                startIcon={
                  isBookmarked ? (
                    <BookmarkIcon />
                  ) : (
                    <BookmarkBorderIcon htmlColor={theme.palette.grey.black} />
                  )
                }
                onClick={() => handleBookmark(id)}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '26px',
                    color: '#1E1E1E',
                  }}
                >
                 {isBookmarked ? 'Bookmarked' : 'Bookmark'} 
                </Typography>
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {!isViewBookmarked && (
        <Box>
          <Button
            onClick={() => removeIdea(id)}
            startIcon={<DeleteOutlineOutlined />}
            sx={{ paddingX: 2 }}
          >
            <Typography sx={{ fontSize: 14, marginLeft: -1, paddingTop: 0.1 }}>
              Remove
            </Typography>
          </Button>
        </Box>
      )}
    </>
  );
}

IdeasCard.propTypes = {
  idea: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    paragraph: PropTypes.string,
  }),
  // tag if any - new / edited
  tag: PropTypes.oneOf(['new', 'edited', '']),
  // handles displaying the modal
  handleClick: PropTypes.func.isRequired,
  // handles bookmarking idea
};
