import React from 'react';

const ArrowIcon = ({color="#0573FF"}) => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.46875 10.707C5.29297 10.8477 5.04688 10.8477 4.87109 10.707L0.230469 6.06641C0.0898438 5.89062 0.0898438 5.64453 0.230469 5.46875L4.87109 0.828125C5.04688 0.6875 5.29297 0.6875 5.46875 0.828125L6.17188 1.53125C6.3125 1.70703 6.3125 1.95312 6.13672 2.12891L3.32422 4.83594H15.4531C15.6641 4.83594 15.875 5.04688 15.875 5.25781V6.24219C15.875 6.48828 15.6641 6.66406 15.4531 6.66406H3.32422L6.13672 9.40625C6.3125 9.58203 6.3125 9.82812 6.17188 10.0039L5.46875 10.707Z" fill={color}/>
</svg>

  )
}

export default ArrowIcon