import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Buttons from '../../components/Button';
import SVGIcon from '../../components/SVGIcon';
import { GCEContext, GCEDispatchContext } from '../../context/GCEContext';
import { useGenerativeConceptEngine } from '../../hooks/useGenerativeConceptEngine';
import { GCE_ACTION_TYPES, GCE_API_TYPES } from '../../reducers/gceActionTypes';
import theme from '../../styles/theme';
import { restructureIdeas } from '../../util/restructureIdeas';
import Spinner from '../Spinner';
import GCEChatBubble from './GCEChatBubble';
import GCEChatInput from './GCEChatInput';
import { SidebarInitialQuestion } from './GCEInitialQuestion';

const resetStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '8px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '25px',
  width: '650px',
};

const GCEChatSidebar = () => {
  const state = useContext(GCEContext);
  const dispatch = useContext(GCEDispatchContext);
  const { mutateAsync, isLoading } = useGenerativeConceptEngine();
  const { ideas } = state;
  const [isReset, setIsReset] = useState(false);

  const divRef = useRef(null);
  const [chatHistory, setChatHistory] = useState(state.chatHistory);

  const resetSession = () => {
    setIsReset(false);
  };
  
  const addUserInputToChatHistory = async (text) => {
    setChatHistory([
      ...chatHistory,
      {
        user: true,
        text,
        basicStyle: true,
      },
    ]);
    dispatch({
      type: GCE_ACTION_TYPES.SET_CHAT_HISTORY,
      payload: [...chatHistory,{ 
        user: true,
        text,
        basicStyle: true
      }]
    }); 
    try {
      let { result } = await mutateAsync({
        requestId: state.requestId,
        requestType: GCE_API_TYPES.GCE_REFRESH_IDEAS,
        ideaType: state.ideaType,
        newInfo: text,
      });
      let newIdeas = restructureIdeas(result, true);
      dispatch({
        type: GCE_ACTION_TYPES.SET_IDEAS,
        payload: { ...newIdeas, ...ideas },
      });
    } catch (e) {
      console.log('Error fetching new ideas: ', e);
    }
  };

  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const renderChatHistory = () => {
    return chatHistory.map((el, index) => {
      return el.user ? (
        <Box
          key={el.text}
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'right',
            width: '100%',
          }}
        >
          <GCEChatBubble
            key={`${index}-${el.text}`}
            isUser={el.user}
            text={el.text}
            basicStyle={true}
          />
        </Box>
      ) : (
        <GCEChatBubble
          key={`${index}-${el.text}`}
          isUser={el.user}
          text={el.text}
          basicStyle={true}
        />
      );
    });
  };

  return (
    <>
    {isReset && (
        <Modal
          open={isReset}
          className="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={isReset}>
            <Box className="modal_inner_container" sx={resetStyle}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  paddingBottom: '20px',
                }}
              >
                <Typography variant="h3" sx={{ alignSelf: 'left' }}>
                  Reset Session?
                </Typography>
                <Box
                  className={'genai-icon-close'}
                  sx={{ alignSelf: 'right', height: '32px' }}
                >
                  <SVGIcon
                    width={24}
                    height={27}
                    name="close"
                    color={'#000'}
                    onClick={() => setIsReset(false)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  paddingBottom: '20px',
                }}
              >
                <Typography>
                  Your current progress will be deleted. Are you sure you want
                  to continue and reset?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Buttons
                  variant="song"
                  color="secondary"
                  onClick={() => setIsReset(false)}
                >
                  Close
                </Buttons>
                <Buttons
                  href='/gce'
                  variant="song"
                  startIcon={
                    <img
                      src="/assets/images/refresh_icon_black.svg"
                      alt="Refresh"
                    />
                  }
                  sx={{ marginLeft: '15px' }}
                  onClick={() => resetSession()}
                >
                  Reset Session
                </Buttons>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
    <Box
      sx={{
        backgroundColor: theme.palette.grey.white,
        boxShadow: '0px 4px 40px rgba(30, 30, 30, 0.1)',
        padding: '34px',
        width: '100%',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'left',
        paddingBottom: '110px',
        overflowY: 'auto'
      }}
    >
      <Box>
        <Typography
          variant="h2"
          fontSize={24}
          color={theme.palette.primary.main}
          sx={{ marginBottom: 2 }}
        >
          What’s your idea?
        </Typography>
        <hr style={{ borderTop: '1px solid #ccc' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Buttons
            sx={{
              textDecoration: 'underline',
              fontFamily: 'GraphikRegular',
              fontSize: '18px',
            }}
            startIcon={
              <SVGIcon
                name={'clock-counter'}
                color={theme.palette.primary.main}
              />
            }
            label={'Reset Session'}
            onClick={() => setIsReset(true)}
          />

          <Buttons
            sx={{
              textDecoration: 'underline',
            }}
            startIcon={<AccessTimeIcon />}
            disabled={true}
            label={<Typography fontSize={18}>Review History</Typography>}
          />
        </Box>
      </Box>

      <Box
        sx={{
          border: `1px solid ${theme.palette.grey.light2}`,
          borderRadius: 2,
          marginTop: 2,
          marginBottom: 2,
          maxHeight: '40vh',
          paddingBottom: 2,
          backgroundColor: theme.palette.grey.white,
        }}
      >
        <Grid
          sx={{
            maxHeight: '100%',
            overflowY: 'auto',
            scrollBehavior: 'smooth',
          }}
          container
          className="GCEChat_container"
          ref={divRef}
        >
          <Box
            sx={{
              width: '100%',
              p: 2,
            }}
          >
            <SidebarInitialQuestion ideaType={state.ideaType} />
            {renderChatHistory()}
            {isLoading && (
              <Box
                sx={{
                  justifyContent: 'center',
                  left: '50%',
                  paddingTop: 5,
                  paddingX: '50%',
                }}
              >
                <Spinner />
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
      <Box
        sx={{marginTop: '8px'}}
      >
        <Typography
          variant="h2"
          fontSize={20}
          color={theme.palette.primary.main}
        >
          Add more detail and refresh ideas
        </Typography>

        <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>
          Note: To keep ideas relevant to your needs, any further information
          provided will refresh and affect any ideas that have been generated.
        </Typography>
        <GCEChatInput
          onClick={addUserInputToChatHistory}
          onEnterPress={addUserInputToChatHistory}
          isEnabled
        />
      </Box>
    </Box>
    </>
  );
};

export default GCEChatSidebar;
