import { v4 as uuidv4 } from 'uuid';

const randomNames = [
  'Asim Valère',
  'Xhafer Moacir',
  'Zharko Cúán',
  'Elias Serena',
  'Críostóir Brady',
  'Steinunn Myrto',
  'Vincent Ismail',
  'Pipin Alicja',
  'Jacinthe Delicia',
  'Tod Sandie',
  'Gareth Walganus',
  'Bedwyr Lynet',
  'Lionesse Nala',
  'Furiosa Medraut',
  'Tintin Guendoloena',
];

const randomPlaces = [
  'Ceresara, Italy',
  'Brincones, Spain',
  'Corato, Italy',
  'Costa Valle Imagna, Italy',
  'Yanbodu, China',
  'Bamburgh, United Kingdom',
  'Sadang, Indonesia',
  'Spencerville, NM, United States',
  'Songadh, India',
  'Bustillo de la Vega, Spain',
  'Daqiao, China',
  'Nacimiento, Chile',
  'Asso, Italy',
  'Benson, United Kingdom',
  'Leibertingen, Germany',
  'Nellore, India',
  'Sonsk, Poland',
  'Cipanjang, Indonesia',
  'Ursberg, German',
  'Saint-Savin, France',
  'Mundo Nuevo, Mexico',
  'Krajan Gebangan, Indonesia',
  'Revin, France',
  'Alunis, Romania',
  'Halmagel, Romania',
  'Ampezzo, Italy',
  'Tullamore, Ireland',
  'Balau, Germany',
  'Hull, MA, United States',
  'Meadowlakes, TX, United States',
  'Butler, PA, United States',
  'Komadi, Hungary',
  'Highland, AR, United States',
  'Pasargunung, Indonesia',
  'Monreal de Ariza, Spain',
  'East Falmouth, MA, United States',
  'Isfara, Tajikistan',
  'Cedar Hills, UT, United States',
];

const randomQualifier = ['Good', 'Bad', 'Mediocre', 'Great', 'Terrible'];

const randomizeParticipant = () => {
  return {
    participantName:
      randomNames[Math.floor(Math.random() * randomNames.length)],
    location: randomPlaces[Math.floor(Math.random() * randomPlaces.length)],
    temperature: (Math.floor(Math.random() * 99 + 1) / 100).toFixed(1),
  };
};

export const formatAddParticicpant = (randomParticipantBool, avatar, participant) => {
  const uuid = uuidv4();
  let randomParticipant = {};

  if (randomParticipantBool) {
    randomParticipant = randomizeParticipant();
  }
  
  const updatedParticipant = randomParticipantBool
    ? {
      ...participant,
      ...randomParticipant,
      avatar,
      uuid,
    }
    : {
      ...participant,
      avatar,
      uuid,
    };
  return updatedParticipant;
}

export default randomizeParticipant;
