import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import theme from '../../styles/theme';
import { ImageBox } from '../ImageBox';

const YoutubeMock = ({ imgDesc, imgSrc }) => {
  return (
    <Box
      sx={{
        width: 300,
        height: '100%',
        border: `0.5px solid ${theme.palette.grey.light}`,
        backgroundColor: theme.palette.primary.white,
      }}
    >
      <Box>
        <ImageBox
          src={imgSrc}
          styles={{
            width: '100%',
            height: '150px',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          marginBottom: 3,
        }}
      >
        <Box
          sx={{
            width: 35,
            height: 35,
            backgroundColor: theme.palette.grey.light,
            marginRight: 2,
            marginTop: 2,
            marginLeft: 2,
            borderRadius: '100%',
          }}
        ></Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontSize={16}
            fontFamily="GraphikSemiBold"
          >
            Spring Awakening
          </Typography>
          <Typography fontSize={14} lineHeight={1}>
            Emotional AI
          </Typography>
          <Typography fontSize={14}>5M views・2 hours ago</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default YoutubeMock;
