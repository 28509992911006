import { useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header';

import CNS from './pages/CNS';
import Components from './pages/Components';
import GCE from './pages/GCE';
import MCSV2 from './pages/MCSV2';
import MCSV2Components from './pages/MCSV2Components';
import ResearchAssistant from './pages/ResearchAssistant';
import theme from './styles/theme';
import LandingPage from './pages/LandingPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000,
    },
  },
});

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

 

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <Header />
  
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/MCSV2components" element={<MCSV2Components />} />
            <Route path="/MCS" element={<MCSV2 />} />
            <Route path="/research" element={<ResearchAssistant />} />
            <Route path="/GCE" element={<GCE />} />
            <Route path="/CNS" element={<CNS /> } />
            <Route path="/components" element={<Components />} />
          </Routes> : 
          <Routes>
            <Route path="/*" />
          </Routes>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
