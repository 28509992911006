import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from '@mui/icons-material/Link';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import theme from '../styles/theme';

import PropTypes from 'prop-types';

const ChatLog = ({
  isUser,
  questionNumber,
  participantName,
  participantTemperature,
  text,
  avatarURL,
  color,
  bookmarksIcon,
  handleBookmarks,
  basic = false,
  source,
  onDelete,
}) => {
  const [isSourceOpen, setIsSourceOpen] = useState(false);
  const renderUserChatLog = () => {
    return (
      <Box
        sx={{
          maxWidth: 574,
        }}
      >
        {!basic && (
          <Box
            sx={{
              justifyContent: 'right',
              display: 'flex',
              paddingY: 1,
              color: theme.palette.grey.dark,
            }}
          >
            <Typography sx={{ fontSize: 14 }}>
              Question {questionNumber}
            </Typography>
          </Box>
        )}
        <Card
          sx={{
            maxWidth: 574,
            minHeight: 76,
            borderRadius: 4,
            boxShadow: 0,
            backgroundColor: theme.palette.grey.light,
            padding: 1,
            minWidth: 100,
          }}
        >
          <CardContent>
            <Typography paragraph sx={{ margin: 0 }}>
              {text}
            </Typography>
          </CardContent>
        </Card>
        {!basic && (
          <Box sx={{ justifyContent: 'right', display: 'flex' }}>
            <Grid container spacing={2}>
              <Grid>
                <Button
                  startIcon={<DeleteOutlineIcon />}
                  onClick={() => onDelete()}
                >
                  <Typography sx={{ fontSize: 14 }}>Delete</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };

  const renderAIChatLog = () => {
    return (
      <Box>
        <Grid container spacing={0} sx={{ flexGrow: 1 }}>
          <Grid sx={{ paddingTop: 3 }}>
            <Avatar src={avatarURL} sx={{ width: 74, height: 74 }} />
          </Grid>
          <Grid xs={8}>
            <Box
              sx={{
                maxWidth: 574,
              }}
            >
              <Box
                sx={{
                  justifyContent: 'left',
                  display: 'flex',
                  paddingY: 1,
                  color: theme.palette.grey.dark,
                }}
              >
                {!basic ? (
                  <Grid container spacing={3}>
                    <Grid>
                      <Typography sx={{ fontSize: 14 }}>
                        {participantName}
                      </Typography>
                    </Grid>
                    <Grid container spacing={0.2}>
                      <Grid xs={3} sx={{ paddingTop: 1.4 }}>
                        <img src={'assets/images/temperature.svg'} />
                      </Grid>
                      <Grid xs={3}>
                        <Typography sx={{ fontSize: 14 }}>
                          {participantTemperature}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Box height={24}></Box>
                )}
              </Box>
              <Card
                sx={{
                  maxWidth: 574,
                  minHeight: 76,
                  borderRadius: 4,
                  boxShadow: 0,
                  backgroundColor: color,
                  padding: 1,
                }}
              >
                <CardContent>
                  <Typography paragraph sx={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                    {text}
                  </Typography>
                </CardContent>
              </Card>
              {!basic && (
                <Box sx={{ justifyContent: 'left', display: 'flex' }}>
                  <Grid container spacing={2}>
                    <Grid>
                      <Button
                        startIcon={
                          bookmarksIcon ? (
                            <BookmarkIcon />
                          ) : (
                            <BookmarkBorderIcon />
                          )
                        }
                        onClick={() => handleBookmarks(text)}
                      >
                        <Typography sx={{ fontSize: 14 }}>Bookmark</Typography>
                      </Button>
                    </Grid>

                    <Grid>
                      <Button
                        startIcon={<LinkIcon />}
                        onClick={() => setIsSourceOpen(!isSourceOpen)}
                      >
                   
                        <Typography sx={{ fontSize: 14 }}>Source</Typography>
                      </Button>
                    </Grid>

                    {isSourceOpen && (
                      <Grid>
                        <Button disabled>
                          <Typography sx={{ fontSize: 14 }}>
                            {source}
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return <>{isUser ? renderUserChatLog() : renderAIChatLog()}</>;
};

ChatLog.propTypes = {
  isUser: PropTypes.bool,
  questionNumber: PropTypes.string,
  participantName: PropTypes.string,
  participantTemperature: PropTypes.string,
  text: PropTypes.string,
  avatarURL: PropTypes.string,
  color: PropTypes.string,
  setData: PropTypes.func,
  isBookMarked: PropTypes.bool,
  basic: PropTypes.bool,
};

export default ChatLog;
