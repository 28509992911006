import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import data from '../assets/data/svg-icon.json';

export default function SVGIcon(props) {
  props.width && props.width.toString();
  props.height && props.height.toString();
  const iconRef = useRef({});
  const [d1, setD1] = useState('');
  const [d2, setD2] = useState('');
  const [d3, setD3] = useState('');
  const [d4, setD4] = useState('');
  const [d5, setD5] = useState('');
  const [d6, setD6] = useState('');

  useEffect(() => {
    try {
      if (!props.name) {
        console.log('icon name is not provided');
      } else {
        const foundIcon = data.find((icon) => icon.name === props.name);
        iconRef.current = foundIcon;
      }
      if (iconRef.current) {
        setD1(iconRef.current.path?.d1);
        setD2(iconRef.current.path?.d2);
        setD3(iconRef.current.path?.d3);
        setD4(iconRef.current.path?.d4);
        setD5(iconRef.current.path?.d5);
        setD6(iconRef.current.path?.d6);
      }
    } catch (error) {
      console.log('Error in getting icon-data.json file', error);
    }
  }, [d1]);

  return (
    <motion.svg
      {...props}
      width={props.width ?? iconRef.current?.width}
      height={props.height ?? iconRef.current?.height}
      viewBox={!props.viewBox ? iconRef.current?.viewBox : props.viewBox}
      style={props.style}
      initial="hidden"
      animate="visible"
      transition="transition"
      exit="exit"
      variants={props.variants}
    >
      <motion.path
        fill={props.color}
        stroke={props.strokecolor}
        strokeWidth={props.strokeWidth}
        variants={props.variants1}
        fillRule={props.fillRule} 
        clipRule={props.clipRule}
        d={d1}
      />
      {d2 && (
        <motion.path
          fill={!props.colord1 ? props.color : props.colord1}
          stroke={props.strokecolor}
          strokeWidth={props.strokeWidth}
          variants={props.variants2}
          d={d2}
        />
      )}
      {d3 && (
        <motion.path
          fill={!props.colord1 ? props.color : props.colord1}
          stroke={props.strokecolor}
          strokeWidth={props.strokeWidth}
          variants={props.variants3}
          d={d3}
        />
      )}
      {d4 && (
        <motion.path
          fill={!props.colord1 ? props.color : props.colord1}
          stroke={props.strokecolor}
          strokeWidth={props.strokeWidth}
          variants={props.variants4}
 
          d={d4}
        />
      )}
      {d5 && (
        <motion.path
          fill={!props.colord1 ? props.color : props.colord1}
          stroke={props.strokecolor}
          strokeWidth={props.strokeWidth}
          variants={props.animate && props.animated5 ? props.animated5 : {}}
          d={d5}
        />
      )}
      {d4 && (
        <motion.path
          fill={!props.colord1 ? props.color : props.colord1}
          stroke={props.strokecolor}
          strokeWidth={props.strokeWidth}
          variants={props.animate && props.animated6 ? props.animated6 : {}}
          d={d6}
        />
      )}
      {props.children}
    </motion.svg>
  );
}

SVGIcon.defaultProps = {
  color: '#000',
};

SVGIcon.props = {
  initial: PropTypes.object,
  // initial={{ opacity: 0 }}
  // animate={{ opacity: 1 }}
  // exit={{ opacity: 0 }}
  animate: PropTypes.object,
  // animate={{ x: 100 }}
  // animate={{ x: [0, 100, 0] }}
  transition: PropTypes.object,
  // transition={{ ease: "easeOut", duration: 2 }}
  exit: PropTypes.object,
  name: PropTypes.string.isRequired, // see [iconNames] above for valid props.name'
  viewBox: PropTypes.string || PropTypes.number, // svg required properties ex: viewBox={'0 0 20 20'}
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.string, // custom css ex: style={{marginRight: 10+'em', outline: 1+'px'+' red'+' solid',}} />
  preserveAspectRatio: PropTypes.string, // usage link https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
};
