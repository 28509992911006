import React, { forwardRef, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SVGIcon from './SVGIcon';
import PropTypes from 'prop-types';

import theme from '../styles/theme';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

export default function AlertToasterBanner(props) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (props.open) {
      setOpen(!open);
    }
  };

  const alertType = props.alertType;
  const alertMessage = props.alertMessage;
  let alertTitle = '';
  let alertColor = '';

  switch (alertType) {
    case 'error':
      alertTitle = !props.alertTitle ? 'Error' : props.alertTitle;
      alertColor = theme.palette.error.main;
      break;
    case 'warning':
      alertTitle = !props.alertTitle ? 'Warning' : props.alertTitle;
      alertColor = theme.palette.warning.main;
      break;
    case 'info':
      alertTitle = !props.alertTitle ? 'Information' : props.alertTitle;
      alertColor = theme.palette.secondary.main;
      break;
    case 'success':
      alertTitle = !props.alertTitle ? 'Success' : props.alertTitle;
      alertColor = theme.palette.success.main;
      break;
    default:
      alertTitle = 'This is the Default';
      alertColor = theme.palette.warning.main;
  }

  const alertDisplay = (
    <Alert
      severity={alertType}
      closeText="Close"
      icon={false}
      variant="outlined"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="top"
        justifyContent="space-between"
        gap={1}
        sx={{ minWidth: '700px', maxHeight: '90px' }}
      >
        <Box style={{ width: '30px', alignItems: 'center' }}>
          <SVGIcon
            name={'alert-' + alertType}
            width={24}
            height={24}
            color={alertColor}
          />
        </Box>
        <Stack
          sx={{
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'space-evenly',
            padding: '0 15px',
          }}
          gap={2}
        >
          <Typography variant="alertTitle" component="h5">
            {alertTitle}
          </Typography>
          <Typography variant="alertMessage" component="p">
            {alertMessage}
          </Typography>
        </Stack>

        <Box style={{ width: '30px', alignItems: 'center' }}>
          <SVGIcon
            className={'genai-icon-close'}
            onClick={handleClose}
            name="close"
            color={theme.palette.grey.black}
            width={15}
            height={15}
          />
        </Box>
      </Box>
    </Alert>
  );

  return (
    <>
      <Snackbar
        key={props.anchorVertical + props.anchorHorizontal}
        autoHideDuration={props.autoHideDuration}
        onClose={handleClose}
        open={open ? !open : props.open}
        anchorOrigin={{
          vertical: props.anchorVertical,
          horizontal: props.anchorHorizontal
        }}
        sx={props.style}
      >
        {alertDisplay}
      </Snackbar>
    </>
  );
}

AlertToasterBanner.defaultProps = {
  autoHideDuration: 3000,
  anchorVertical: 'top',
  anchorHorizontal: 'left'
};

AlertToasterBanner.propTypes = {
  open: PropTypes.bool, //alert will open when set to true
  alertMessage: PropTypes.string,
  alertType: PropTypes.oneOf(['error', 'warning', 'success']),
  anchorVertical: PropTypes.oneOf(['top', 'bottom']),
  anchorHorizontal: PropTypes.oneOf(['left', 'center', 'right']),
  autoHideDuration: PropTypes.number //milliseconds to hide alert
};
