import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { formatAddParticicpant } from '../../util/randomizeParticipant';
import Buttons from '../Button';
import ChatContent from '../ChatContent';
import ExportOptionsBar from '../ExportOptionsBar';
import ChatInputRA from './ChatInputRA';
import RAFooterButton from './RAFooterButton';
import RAIntroForm from './RAIntroForm';
import ResetModal from './ResetModal';
import TextInputBarRA from './TextInputBarRA';

import { RAContext, RADispatchContext } from '../../context/RAContext';
import {
  RA_ACTION_TYPES,
  RA_API_TYPES,
  participantObj,
} from '../../reducers/raActionTypes.js';

import theme from '../../styles/theme';

import { useResearchAssistant } from '../../hooks/useResearchAssistant';

const footerButtonStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingRight: '80px',
  padding: '20px 80px 20px 56px',
};

const Chat = () => {
  const divRef = useRef(null);
  const chatTextRef = useRef(null);

  const { avatars, participants } = useContext(RAContext);
  const dispatch = useContext(RADispatchContext);

  const [participantNameError, setParticipantNameError] = useState(false);
  const [participant, setParticipant] = useState(participantObj);
  const [questionHistory, setQuestionHistory] = useState(1);
  const [chatHistory, setChatHistory] = useState([]);
  const [showExportBar, setShowExportBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddUserForm, setShowAddUserForm] = useState(false);

  const { mutateAsync: uxraMutateAsync } = useResearchAssistant();
  const [bookmarkedChats, setBookmarkedChats] = useState([]);

  const removeUser = (uuid) => {
    const updatedParticipants = participants.filter((participant) => {
      if (participant.uuid !== uuid) {
        return participant;
      }
      avatars.push(participant.avatar);
    });

    dispatch({ type: RA_ACTION_TYPES.UPDATE_AVATAR_LIST, payload: avatars });
    dispatch({
      type: RA_ACTION_TYPES.UPDATE_PARTICIPANTS,
      payload: updatedParticipants,
    });
  };
  const mapTemperature = (temp) => {
    if (temp <= 0.3) {
      return 'Low';
    } else if (temp <= 0.7) {
      return 'Medium';
    }
    return 'High';
  };

  const setColor = (avatar) => {
    switch (avatar) {
      case 'avatar0.svg':
        return '#E6F1FF';
      case 'avatar1.svg':
        return '#EBECFF';
      default:
        return '#FFE6F5';
    }
  };

  const goToPage = () => {
    dispatch({ type: RA_ACTION_TYPES.RESET_STEPS });
    dispatch({
      type: RA_ACTION_TYPES.UPDATE_PARTICIPANTS,
      payload: []
    });
  };

  const textFocus = () => {
    chatTextRef.current && chatTextRef.current.focus();
  };

  const validateParticipantName = () => {
    if (!participant.participantName) {
      setParticipantNameError(true);
      return false;
    }
    setParticipantNameError(false);
    return true;
  };

  const AddParticipant = async (randomParticipantBool = false) => {
    if (!validateParticipantName() && randomParticipantBool === false) {
      return;
    }

    if (randomParticipantBool === true) {
      setParticipantNameError(false);
    }
    const avatar = avatars[0] || '';

    const initiated = false;
    const updatedParticipant = formatAddParticicpant(
      randomParticipantBool,
      avatar,
      participant
    );

    const remainingAvatars = avatars.shift();

    const payload = {
      participantName: updatedParticipant.participantName,
      requestId: updatedParticipant.uuid,
      year: randomParticipantBool
        ? ''
        : `${updatedParticipant.ageRange[0]} to ${updatedParticipant.ageRange[1]}`, // need to set this to either the age range or specific age?
      description: randomParticipantBool
        ? ''
        : updatedParticipant.participantDescription,
      city: updatedParticipant.location,
      gender: randomParticipantBool ? '' : updatedParticipant.gender,
      temperature: updatedParticipant.temperature,
    };

    try {
      const response = await uxraMutateAsync({
        ...payload,
        requestType: RA_API_TYPES.RA_REGISTER,
      });

      if (randomParticipantBool === true) {
        updatedParticipant.participantDescription = response.description;
        updatedParticipant.age = response.year;
        updatedParticipant.gender = response.gender;
      }

      const addedParticipant = {
        ...updatedParticipant,
        requestId: updatedParticipant.uuid,
        avatar,
        initiated,
      };

      dispatch({
        type: RA_ACTION_TYPES.UPDATE_PARTICIPANTS,
        payload: [...participants, addedParticipant],
      });
      dispatch({
        type: RA_ACTION_TYPES.UPDATE_AVATAR_LIST,
        payload: remainingAvatars,
      });
    } catch (err) {
      console.log('Error fetching response ', err);
    }
    setShowAddUserForm(false);
  };

  const addUserInputToChatHistory = async (text) => {
    setQuestionHistory(questionHistory + 1);
    const participantResponses = [];

    let userInput = {
      questionNumber: questionHistory,
      user: true,
      text,
    };

    // render chat history here to the user speech bubble shows up first
    setChatHistory([...chatHistory, userInput]);
    const updatedUsers = [];
    for (const p of participants) {
      let response = '';
      let source;
      const requestType = p.initiated
        ? RA_API_TYPES.RA_CONTINUE
        : RA_API_TYPES.RA_INITIATE;
      const payload = {
        participantName: p.participantName,
        requestId: p.uuid,
        requestType: requestType,
        question: text,
        temperature: p.temperature,
      };

      // execute API calls or set to error message if failed
      try {
        setIsLoading(true);
        let apiResponse = await uxraMutateAsync(payload);
        response = apiResponse.response;
        source = apiResponse.Source;
        setIsLoading(false);
        textFocus();
      } catch (err) {
        response = 'Error: request failed';
        setIsLoading(false);
        textFocus();
      }

      // push the response onto the array
      participantResponses.push({
        questionNumber: questionHistory,
        source: source,
        user: false,
        text: response,
        participantName: p.participantName,
        participantTemperature: mapTemperature(p.temperature),
        avatarURL: `assets/images/${p.avatar}`,
        color: setColor(p.avatar),
        bookmarked: false,
        participantLocation: p.location,
        participantDescription: p.participantDescription,
        participantGender: p.gender,
        participantAge: `${
          p.age ? p.age : p.ageRange[0] + '-' + p.ageRange[1]
        } yr`,
      });
      updatedUsers.push({ ...p, initiated: true });
    }

    // set initiated flag to true
    dispatch({
      type: RA_ACTION_TYPES.UPDATE_PARTICIPANTS,
      payload: updatedUsers,
    });

    // render history again with participant responses
    setChatHistory([...chatHistory, userInput, ...participantResponses]);
  };

  const clearInterview = () => {
    setChatHistory([]);
  };

  const handleBookmarks = (chat) => {
    let tempArray = chatHistory;
    let index = chatHistory.findIndex((x) => x.text === chat);
    if (index > -1) {
      tempArray[index].bookmarked = !tempArray[index].bookmarked;
    } else {
      tempArray[index].bookmarked = false;
    }
    setChatHistory(tempArray);
    let tempChats = tempArray.filter((x) => x.bookmarked || x.user);
    setBookmarkedChats(tempChats);
  };

  const renderPageContent = () => {
    if (showAddUserForm) {
      return (
        <Box
          className="add_user_form_scroll"
          sx={{
            overflowY: 'scroll',
            height: 'calc(100vh - 200px)',
            scrollBehavior: 'smooth',
            padding: '48px 64px ',
          }}
        >
          <Box paddingBottom="2rem" borderBottom="1px solid #777777">
            <Typography
              variant="h1"
              color={theme.palette.grey.black}
              gutterBottom
            >
              Add Participant
            </Typography>

            <Typography
              variant="subtitle1"
              color={theme.palette.grey.dark}
              gutterBottom
            >
              Create and add a participant to your interview. Participants will
              answer the question you ask. You can add up to 3 participants.
            </Typography>
            <Typography
              variant="subtitle1"
              color={theme.palette.grey.dark}
              gutterBottom
            >
              Note: generated responses will be tailored to the information you
              provide
            </Typography>
          </Box>
          <RAIntroForm
            setData={setParticipant}
            person={participant}
            participantNameError={participantNameError}
          />
        </Box>
      );
    }
    return (
      <Box
        ref={divRef}
        className="chat_content_container"
        sx={{
          height: 'calc(100vh - 310px)',
          overflowY: 'scroll',
          scrollBehavior: 'smooth',
        }}
      >
        <ChatContent
          content={chatHistory}
          handleBookmarks={handleBookmarks}
          handleDelete={handleDelete}
        />
      </Box>
    );
  };

  const handleDelete = (questionNumber) => {
    const updatedChatHistory = chatHistory.filter(
      (el) => el.questionNumber !== questionNumber
    );
    setChatHistory(updatedChatHistory);
  };

  const renderFooterContent = () => {
    if (showAddUserForm) {
      return (
        <>
          <Stack
            className="footer-button-stack"
            spacing={6}
            direction="row"
            style={{ ...footerButtonStyle, justifyContent: 'space-between' }}
          >
            <Buttons
              variant={'link-light'}
              sx={{
                textDecoration: 'underline',
              }}
              onClick={() => {
                setShowAddUserForm(false);
              }}
              label={'Cancel'}
            />
            <Box>
              <Buttons
                variant="song"
                color="secondary"
                sx={{ paddingX: '25px', marginRight: '32px' }}
                onClick={() => {
                  AddParticipant(true);
                }}
              >
                Generate Random Participant
              </Buttons>
              <Buttons
                variant="song"
                sx={{ paddingX: '25px' }}
                onClick={() => {
                  AddParticipant();
                }}
              >
                Add Participant
              </Buttons>
            </Box>
          </Stack>
        </>
      );
    }
    return (
      <>
        <Stack
          className="footer-button-stack"
          spacing={6}
          direction="row"
          style={footerButtonStyle}
        >
          <ResetModal onClick={clearInterview} />
          <Buttons
            variant="song"
            sx={{ paddingX: '25px' }}
            onClick={() => {
              setShowExportBar(true);
            }}
            disabled={
              showExportBar || (chatHistory.length === 0 ? true : false)
            }
          >
            Export
          </Buttons>
        </Stack>
      </>
    );
  };

  const scrollToBottom = () => {
    if (divRef.current && !showAddUserForm) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    renderPageContent();
    if (!showAddUserForm) {
      scrollToBottom();
    }
  }, [showAddUserForm]);

  return (
    <Box className="base" sx={{ height: '100vh', marginTop: '64px' }}>
      <Grid container>
        <Grid xs={3.5}>
          {showExportBar ? (
            <ExportOptionsBar
              closeExportBar={() => setShowExportBar(false)}
              chatHistory={chatHistory}
              bookmarkedChats={bookmarkedChats}
            />
          ) : (
            <TextInputBarRA
              people={participants}
              addUser={setShowAddUserForm}
              deleteUser={removeUser}
              navigation={goToPage}
            />
          )}
        </Grid>
        <Grid
          className="form_container"
          container
          xs={8.5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box className="rendered_content_container">
            {renderPageContent()}
          </Box>
          {!showAddUserForm && (
            <>
              <Box sx={{ paddingX: 9 }}>
                {chatHistory.length < 1 && (
                  <Typography
                    variant="h2"
                    gutterBottom
                    color={theme.palette.primary.main}
                    sx={{ marginTop: -6 }}
                  >
                    Ask a question to get started!
                  </Typography>
                )}
                <ChatInputRA
                  onClick={addUserInputToChatHistory}
                  isLoading={isLoading}
                  chatTextRef={chatTextRef}
                />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <RAFooterButton>{renderFooterContent()}</RAFooterButton>
    </Box>
  );
};

export default Chat;
