import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import GCEChatSidebar from '../../components/GCE/GCEChatSidebar';
import GeneratedIdeas from '../../components/GCE/GeneratedIdeas';
import theme from '../../styles/theme';

const GCEIdeas = () => {
  const [hideSidebar, setHideSidebar] = useState(false);
  return (
    <>
      <Grid
        container
        sx={{
          height: '100vh',
          backgroundColor: theme.palette.secondary.blue,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box>
          <Grid
            container
            sx={{
              margin: '0 auto',
              minHeight: '90vh',
              padding: '0px 0px 0px 0px',
              WebkitFlexWrap: 'nowrap',
              flexWrap: 'nowrap',
            }}
          >
            <Grid
              className='sidebar_container'
              item
              sx={{
                margin: '0 0 0 0',
                display: 'inline-block',
                height: 'calc(100vh - 76px)',
                marginTop: '65px',
                maxWidth: 420,
                marginRight: '32px',
                minWidth: 'auto'
              }}
            >
              {!hideSidebar && <GCEChatSidebar />}
            </Grid>

            <Grid
              className='generated_ideas_section'
              item
              sx={{
                margin: '0 auto',
                position: 'relative',
                maxHeight: '100vh',
              }}
            >
              <Box
                className='generated_ideas_container'
                sx={{
                  height:' calc(100vh - 76px)',
                  marginTop: '65px',
                  overflowY: 'scroll',
                  scrollBehavior: 'smooth',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  scrollbarWidth: 'none',
                  '-ms-overflow-style': 'none',
                }}
              >
                <GeneratedIdeas setHideSidebar={setHideSidebar} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default GCEIdeas;
