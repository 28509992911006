export const MCSV2_ACTION_TYPES = {
  SET_USER_ID: 'set_user_id',
  CHOSEN_WORKSPACE: 'chosen_workspace',
  SET_TERRITORY_DATA: 'set_territory_data',
  SET_TERRITORIES: 'set_territories',
  SET_PROJECTS: 'set_projects',
  SET_CURRENT_PROJECT: 'set_current_project',
  SET_PORTFOLIO: 'set_portfolio',
  SET_IMAGE_ASSETS: 'set_image_assets',
  SET_TEXT_ASSETS: 'set_text_assets',
  SET_PORTFOLIO_ASSET: 'set_portfolio_asset',
  SET_ASSETS_INPUT_DATA: 'set_assets',
};
