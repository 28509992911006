import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import theme from '../../styles/theme';
import Button from '../Button';
import SVGIconAnimate from '../SVGIconAnimate';

function Hero({ title, caption, images, doScroll }) {
  const [hover, setHover] = useState(false);
  const scrollRef = useRef(true);

  const handleScroll = () => {
    scrollRef.current = !doScroll ? true : false;

    if (!scrollRef.current) {
      const downElemId = document.getElementById(doScroll.downElemId);
      downElemId.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '90rem',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          backgroundColor: theme.palette.grey.white,
          columnGap: '0px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '17px',
            width: '100%',
            flexWrap: 'wrap'
          }}
        >
          <Typography
            component={'h1'}
            variant={'displayLarge'}
            sx={{
              padding: '0',
              margin: '0',
              display: 'inline',
              lineHeight: '1',
              fontSize:'64px',
              fontWeight:'600'
            }}
          >
            {title}
          </Typography>
          <Typography
            component={'h2'}
            variant="body1"
            sx={{
              padding: '0',
              margin: '0',
              display: 'inline',
            }}
          >
            {caption}
          </Typography>

          <Box
            sx={{
              position: 'relative',
              top: '28px',
              left: '195px',
              zIndex: 1000,
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Typography
              variant="body3"
              sx={{
                position: 'absolute',
                top: '5px',
                left: '5px',
                color: theme.palette.blue.link1,
              }}
            >
              Scroll Down
            </Typography>
            <Button
              variants={'transparent'}
              sx={{
                position: 'absolute',
                top: '30px',
                left: '0',
                paddingRight: '0',
                borderRadius: '50px',
                rotate: '180',
              }}
              onClick={() => handleScroll()}
              endIcon={
                <SVGIconAnimate
                  name={'scroll-down'}
                  sx={{ opacity: 1 }}
                  color={'none'}
                  height={'72px'}
                  strokecolor={theme.palette.blue.link1}
                  variants={!hover ? {} : doScroll.animate}
                />
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            background: 'none',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0px',
            width: '100%',
            flexWrap: 'wrap',
            position: 'relative',
          }}
        >
        </Box>
      </Box>
    </>
  );
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  doScroll: PropTypes.object || PropTypes.func,
};

export default Hero;
