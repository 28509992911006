import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext } from 'react';
import Buttons from '../../components/Button';
import Home from '../../components/icons/Home';
import { MCS2DispatchContext } from '../../context/MCS2Context';
import { MCSV2_ACTION_TYPES } from '../../reducers/mcsv2ActionTypes';

import theme from '../../styles/theme';

const StyledButton = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '2px',
  borderRadius: '8px',
  height: '100%',
  background: `linear-gradient(to top right, ${theme.palette.gradient.pink}, ${theme.palette.gradient.lightPurple}, ${theme.palette.gradient.darkPurple}, ${theme.palette.gradient.lightBlue} 120%)`,
  button: {
    border: 'none',
    backdropFilter: 'blur(20px)',
    borderRadius: '8px',
    width: '100%',
    textAlign: 'left',
    padding: '32px',
    background: `linear-gradient(0deg, ${theme.palette.primary.dark} 0%, #33355d 100%)`,
    color: `${theme.palette.grey.white}`,
    marginRight: '32px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}));
const Intro = () => {
  const dispatch = useContext(MCS2DispatchContext);
  // TODO: need way of fetching project so that we can display them here
  // these projects will also need to be saved into context
  // as well as the current project

  const setExperience = (experience) => {
    dispatch({
      type: MCSV2_ACTION_TYPES.CHOSEN_WORKSPACE,
      payload: { workspace: experience },
    });
  };

  return (
    <Box
      className='intro-container'
      sx={{
        backgroundColor: theme.palette.secondary.blue,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        paddingTop: '64px',
      }}
    >
      <Box
        sx={{
          background:
            'url(assets/images/CMSV2_intro_background.png) no-repeat right top',
          backgroundColor: theme.palette.random.bannerBackgroundDark,
          width: '100%',
          height: '840px',
          display: 'inherit',
          justifyContent: 'center',
          color: `${theme.palette.grey.white}`,
        }}
      >
      
        <Box
          className='intro-content-container'
          sx={{
            height: '100%',
            width: '100%',
            maxWidth: '1440px',
            padding: '72px 0px 134px 80px',
          }}
        >
          <Box
          className='breadcrumbs'
          sx={{
            display:'flex',
            marginBottom: '3rem',
            alignItems: 'baseline',
          }}
          >
            <Buttons href='https://genai.songassets.accenture.com/home' sx={{
              paddingLeft:' 0px',
              justifyContent: 'flex-start',
              minWidth: 'unset',
              alignItems:' baseline',
            }}>
                <Home />
                <ArrowForwardIosOutlinedIcon sx={{ 
                  marginLeft: '5px',
                  fontSize: '.8rem', 
                  color: theme.palette.grey.light2
                }}/>
                <Typography sx={{
                  clip: 'rect(1px, 1px, 1px, 1px)',
                  clipPath: 'inset(50%)',
                  height: '1px',
                  width: '1px',
                  margin: '-1px',
                  overflow: 'hidden',
                  padding: '0',
                  position: 'absolute',
                  }}>home</Typography>
            </Buttons>
            <Typography sx={{...theme.typography.link3, color: theme.palette.grey.white}}>Machine Creative Studio</Typography>
          </Box>
          <Box
          className='title-section'
            sx={{
              width: '39.5rem',
              marginBottom: '72px',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                marginBottom: '1rem',
              }}
            >
              See your creative directions come to life
            </Typography>
            <Typography variant="body1">
              Our suite of Generative AI tools are your innovation co-pilot.
              Explore, create, and generate, all powered by  Emotional AI.
            </Typography>
          </Box>

          <Grid
            className="buttons_container"
            container
            spacing={16}
            sx={{
              alignItems: 'stretch',
            }}
          >
            <Grid item xs={5.4} sx={{}}>
              <StyledButton className="styled_button">
                <button
                  onClick={() => setExperience('territories')}
                  role="link"
                >
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        marginBottom: '1rem',
                      }}
                    >
                      Generate Creative Territories
                    </Typography>
                    <Typography variant="body2">
                      Explore ideas to broaden your brainstorming of new
                      campaigns, products, events, and more.
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'inherit',
                      alignItems: 'center',
                    }}
                  >
                    Create New Territory <ArrowForwardOutlinedIcon sx={{
                      fontSize: '16px',
                      marginLeft: '8px',
                    }}/>
                  </Typography>
                </button>
              </StyledButton>
            </Grid>
            <Grid item xs={3.8}>
              <StyledButton className="styled_button">
                <button onClick={() => setExperience('assets')} role="link">
                  <Box
                    sx={{
                      marginBottom: '1.5rem',
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        marginBottom: '1rem',
                      }}
                    >
                      Generate Assets
                    </Typography>
                    <Typography variant="body2">
                      Enhance brainstorming for campaigns, products, events, and
                      more.
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'inherit',
                      alignItems: 'center',
                    }}
                  >
                    Create New Asset <ArrowForwardOutlinedIcon sx={{
                      fontSize: '16px',
                      marginLeft: '8px',
                    }}/>
                  </Typography>
                </button>
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Intro;
