import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import theme from '../../styles/theme';
import Button from '../Button';
import SVGIcon from '../SVGIcon';
import SVGIconAnimate from '../SVGIconAnimate';

function GCEHero({ title, caption, images, doScroll }) {
  const [hover, setHover] = useState(false);
  const scrollRef = useRef(true);

  const handleScroll = () => {
    scrollRef.current = !doScroll ? true : false;

    if (!scrollRef.current) {
      const downElemId = document.getElementById(doScroll.downElemId);
      downElemId.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };

  return (
    <>
      <Box
      className='hero_banner_container'
        sx={{
          height: 'auto',
          maxHeight: '440px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          backgroundColor: '#FFF',
          columnGap: '0px',
        }}
      >
        <Box
        className='hero_banner_title'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            rowGap: '20px',
            width: '100%',
            flexWrap: 'wrap',
            padding: '100px 0px 110px 0px',
            position: 'relative',
          }}
        >
          <Typography
            component={'h1'}
            variant={'displayLarge'}
            gutterBottom
            sx={{
              padding: '0',
              margin: '0',
              display: 'inline',
              lineHeight: '1',
            }}
          >
            {title}
          </Typography>
          <Typography
            fontSize={22}
            variant="body1"
            gutterBottom
            sx={{
              padding: '0',
              margin: '0',
              display: 'inline',
              whiteSpace: 'pre-line',
            }}
          >
            {caption}
          </Typography>
          
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              flexDirection: "column",
              bottom: '-68px',
              left: 'calc(100vw/6)',
              zIndex: 1000,
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Typography
              variant="body3"
              sx={{
                color: '#0573FF',
              }}
            >
              Scroll Down
            </Typography>
            <Button
              variants={'transparent'}
              sx={{
                paddingRight: '0',
                borderRadius: '50px',
                rotate: '180',
                padding: '0px'
              }}
              onClick={() => handleScroll()}>
                <SVGIconAnimate
                  name={'scroll-down'}
                  sx={{ opacity: 1 }}
                  color={'none'}
                  height={'72px'}
                  strokecolor={theme.palette.blue.link1}
                  variants={!hover ? {} : doScroll.animate}
                />
              </Button>
          </Box>
        </Box>
        <Box
          sx={{
            background: 'none',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            width: '100%',
            flexWrap: 'wrap',
            position: 'relative',
          }}
        >
          <SVGIconAnimate
            name={'circle'}
            color={'#FFD600'}
            width={34.313}
            style={{
              opacity: 1,
              position: 'absolute',
              top: '218px',
              left: '228px',
              flexShrink: '1',
            }}
          />
            <img
              src={images[1]}
              alt="standard concept card"
              style={{
                position: 'absolute',
                top: '42px',
                right: '-30px',
                borderRadius: '8px',
                flexShrink: '1',
              }}
            />
          <img
            src={images[0]}
            alt="influencer collaborations"
            style={{
              position: 'absolute',
              top: '79px',
              right: '175px',
              borderRadius: '8px',
              flexShrink: '1',
            }}
          />
          
          <SVGIcon
            name={'star-3'}
            strokecolor={'#0573FF'}
            strokeWidth={1}
            width={54.864}
            height={63.527}
            color={'none'}
            style={{
              position: 'absolute',
              top: '67px',
              right: '195px',
              zIndex: '800',
              flexShrink: '0',
              opacity: '0.9',
            }}
          />
          <SVGIconAnimate
            name={'circle'}
            color={'#02054E'}
            width={23.152}
            height={23.152}
            style={{
              opacity: 1,
              position: 'absolute',
              top: '85px',
              right: '182px',
              zIndex: '5',
              flexShrink: '1',
            }}
          />
          <SVGIconAnimate
            name={'circle'}
            color={'#FF66C3'}
            width={33.905}
            height={33.905}
            style={{
              opacity: 1,
              position: 'absolute',
              top: '360px',
              right: '366px',
              zIndex: '5',
              flexShrink: '1',
              transform: 'rotate(' + '-30deg)',
            }}
          />
          <SVGIcon
            name={'star-4'}
            strokecolor={'#0573FF'}
            strokeWidth={1}
            width={54.864}
            height={63.527}
            color={'none'}
            style={{
              position: 'absolute',
              top: '393px',
              right: '349px',
              zIndex: '800',
              flexShrink: '0',
              opacity: '0.9',
              transform: 'rotate(' + '5deg)',
            }}
          />
        </Box>
      </Box>
    </>
  );
}

GCEHero.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  doScroll: PropTypes.object,
};

export default GCEHero;
