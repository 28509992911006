import { DeleteOutlineOutlined } from '@mui/icons-material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import theme from '../../styles/theme';
import HangTightLoader from '../HangTightLoader';
import { ImageBox } from '../ImageBox';
import ColorPalette from './ColorPalette';

const TerritoryCard = ({
  zone = null,
  imageArray,
  colorPalette,
  typeface,
  header,
  description,
  descriptors,
  width = '470px',
  height,
  handleClick,
  handleTerritoryRemoveClick,
  handleReplaceTerritoryClick,
  isEditing = false,
  styleVariants,
  noRemove,
  isDisabled = false,
  isSelected,
  isFirstSelected,
  handleReplaceClear,
  displayedContentLength,
}) => {
  const isButtonDisabled = isDisabled;
  const truncatedHeader =
    header?.length > 55
      ? `${header?.slice(0, 51)}..."`
      : header?.replace(/"/g, '');

  const titleEditingStyles = {
    textAlign: 'left',
    fontSize: '18px',
  };

  const gradientStyle = isSelected
    ? {
      borderRadius: '10px',
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      padding: '3px',
      background: `linear-gradient(to top right, ${theme.palette.gradient.pink}, ${theme.palette.gradient.lightPurple}, ${theme.palette.gradient.darkPurple}, ${theme.palette.gradient.lightBlue} 120%)`,
    }
    : {
      borderRadius: '10px',
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      padding: '3px',
      background: `${theme.palette.primary.light}00`,
    };

  const renderBottomButtons = () => {
    if (isEditing) {
      return (
        <Button
          disabled={isFirstSelected}
          onClick={() => {
            handleReplaceTerritoryClick();
            handleReplaceClear();
          }}
          startIcon={<SyncAltIcon />}
          sx={{ paddingX: 2 }}
          title="After clicking this button, please select another territory to replace the current one."
        >
          <Typography
            sx={{ fontSize: '1.2em', marginLeft: -1, paddingTop: 0.1 }}
          >
            Replace
          </Typography>
        </Button>
      );
    }
    return (
      <>
        {!noRemove && (
          <Button
            //  disabled={isDisabled}
            onClick={handleTerritoryRemoveClick}
            startIcon={<DeleteOutlineOutlined />}
            sx={{ paddingX: 2 }}
            disabled={
              (zone !== 'sidebar' ? !(displayedContentLength > 1) : false) ||
              isSelected
            }
          >
            <Typography
              sx={{ fontSize: '1.2em', marginLeft: -1, paddingTop: 0.1 }}
            >
              Remove
            </Typography>
          </Button>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '2rem',
      }}
    >
      <button
        className="territory_card_button"
        style={{
          marginBottom: 2,
          border: 'none',
          background: 'none',
          cursor: isButtonDisabled ? 'unset' : 'pointer',
          padding: '0',
        }}
        disabled={isButtonDisabled}
        onClick={() => {
          handleClick();
        }}
      >
        <Box className="territory_card_gradient_border" sx={gradientStyle}>
          <Card
            className="territory_card"
            sx={{
              textAlign: 'left',
              width: width,
              height: isEditing ? '0' : height,
              borderRadius: 2,
              outlineWidth: 1.5,
              paddingY: styleVariants ? 0.5 : 1,
              paddingX: styleVariants ? 0.5 : 2,
            }}
          >
            <CardContent sx={{ paddingBottom: 0 }}>
              <Typography
                variant="body3"
                gutterBottom
                sx={{
                  textTransform: 'uppercase',
                  color: theme.palette.grey.main,
                  fontSize: styleVariants?.descriptorsSize,
                  lineHeight: styleVariants?.descriptorsLineHeight,
                }}
              >
                {descriptors}
              </Typography>
              <Typography
                variant="h3"
                gutterBottom
                sx={[
                  {
                    paddingY: 2,
                    fontSize: styleVariants?.headerSize,
                    lineHeight: styleVariants?.headerLineHeight,
                    height: '60px',
                    wordWrap: 'break-word',
                  },
                  isEditing || noRemove ? { ...titleEditingStyles } : '',
                ]}
              >
                {truncatedHeader}
              </Typography>
              {typeface && (
                <Typography
                  variant="body3"
                  gutterBottom
                  sx={{
                    textTransform: 'uppercase',
                    color: theme.palette.grey.main,
                    fontSize: styleVariants?.typefaceSize,
                    lineHeight: styleVariants?.typefaceLineHeight,
                  }}
                >
                  {typeface}
                </Typography>
              )}
              {description && (
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    margin: '0.45rem 0 1rem 0',
                    height: '52px',
                    overflow: 'hidden',
                    fontSize: styleVariants?.typefaceSize,
                    lineHeight: styleVariants?.typefaceLineHeight,
                  }}
                >
                  {description}
                </Typography>
              )}
              {!imageArray?.length ? (
                <Box>
                  <HangTightLoader />
                </Box>
              ) : (
                <Box>
                  <Grid
                    container
                    spacing={3}
                    sx={{ flexWrap: 'unset', maxHeight: height || '210px' }}
                  >
                    <Grid
                      item
                      xs={7}
                      sx={{
                        flexGrow: '2',
                        textAlign: 'center',
                      }}
                    >
                      <ImageBox
                        src={imageArray[0]}
                        alt="image1"
                        styles={{ borderRadius: '8px' }}
                      />
                    </Grid>
                    <Grid
                      item
                      spacing={3}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: '1',
                        gap: '5px',
                        textAlign: 'center',
                        justifyContent: 'space-between',
                      }}
                      xs={zone ? 5 : 3}
                    >
                      <Grid
                        item
                        xs={6}
                        sx={{
                          flexGrow: 1,
                        }}
                      >
                        <ImageBox
                          src={imageArray[1]}
                          alt="image2"
                          styles={{ borderRadius: '8px' }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          flexGrow: 1,
                        }}
                      >
                        <ImageBox
                          src={imageArray[2]}
                          alt="image3"
                          styles={{ borderRadius: '8px' }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={2}>
                      {/* {colorPalette.map((color, i) => {
                        return (
                          <ImageBox
                            key={`${color}_${i}`}
                            src={color}
                            alt={color}
                            styles={{ borderRadius: '8px' }}
                          />
                        );
                      })} */}
                      <ColorPalette colors={colorPalette} />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </button>
      {renderBottomButtons()}
    </Box>
  );
};

export default TerritoryCard;
