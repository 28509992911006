import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RemoveIcon from '@mui/icons-material/Remove';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  Box,
  Button,
  Card,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import BlendIcon from '../../components/icons/BlendIcon';
import theme from '../../styles/theme';
//import { downloadImage } from '../../util/exporterService';
import Buttons from '../Button';
import Exporter from '../Exporter';
import { ImageBox } from '../ImageBox';
import FacebookMock from './FacebookMock';
import InstagramMock from './InstagramMock';
import LinkedInMock from './LinkedInMock';
import PlaceholderTile from './PlaceholderTile';
import { TwitterMock } from './TwitterMock';
import YoutubeMock from './YoutubeMock';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid ' + theme.palette.grey.main,
    fontSize: 16,
    padding: '18px 12px',
    background: theme.palette.grey.white,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  width: '100%',
}));
const buttonStyle = {
  padding: 3,
  paddingY: '.5vw',
  margin: 1.5,
  width: '100%',
  float: 'left',
  alignItems: 'left',
  textAlign: 'left',
  justifyContent: 'left',
  borderRadius: 2,
  border: `2px solid ${theme.palette.grey.light2}`,
  fontSize: 16,
  color: 'black',
  paddingLeft: 6,
};
const AssetsDetailsCard = ({
  assets,
  closeOnClick,
  isEditing,
  isDisabled,
  ADCOptionsOnClick,
  region = null,
  editMode,
  savedPortfolio,
  updatePortfolioClick,
  blendIterateText,
  blendImage,
  iterateImage,
  secondaryAsset,
  creativeCanvas,
}) => {
  const optionsRef = useRef();
  const [asset1] = assets;
  const assetType = asset1.type;
  const [canBlend, setCanBlend] = useState(true);
  const [blendText, setBlendText] = useState('');
  const [iterateText, setIterateText] = useState('');
  const [expanded, setExpanded] = useState(false);

  const mockupSavePortfolio = {
    imgDesc: secondaryAsset?.content.text || asset1?.content.text,
    imgSrc: asset1.content.image || secondaryAsset?.content.image,
  };

  useEffect(() => {
    if (
      secondaryAsset &&
      secondaryAsset !== undefined &&
      secondaryAsset !== null
    ) {
      setCanBlend(false);
    }
  }, [secondaryAsset]);

  const creativeCanvasComponents = {
    instagram_post: InstagramMock,
    linkedin_post: LinkedInMock,
    facebook_post: FacebookMock,
    youtube_thumbnail_post: YoutubeMock,
    twitter_post: TwitterMock,
  };

  const saveToPortfolioText = savedPortfolio.some(
    (asset) => asset.id === asset1.id
  )
    ? 'Saved to portfolio'
    : 'Save to portfolio';

  const renderSecondaryAsset = (secondaryAsset) => {
    if (secondaryAsset.content.image)
      return (
        <ImageBox
          src={secondaryAsset?.content.image}
          alt=""
          styles={{
            borderRadius: '10px',
            width: '300px',
            height: '300px',
            objectFit: 'cover',
          }}
        />
      );
    else
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '300px',
            width: '300px',
            overflowX: 'hidden',
            border: `2px solid ${theme.palette.grey.light2}`,
            padding: '16px',
            borderRadius: '8px',
          }}
        >
          <Typography height={'300px'} color={theme.palette.grey.main}>
            {secondaryAsset?.content.text}
          </Typography>
        </Box>
      );
  };

  const renderInspector = () => {
    return (
      <>
        <Typography variant="h4" component="h3" fontSize="22px">
          {assetType == 'image' ? 'Image Asset' : 'Text Asset'}
        </Typography>
        <Grid
          className="asset_space"
          container
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Grid
            className="asset_detail_section"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              component="h3"
              sx={{
                color: theme.palette.grey.light3,
                fontSize: '18px',
                fontFamily: 'GraphikSemiBold',
                p: '0 0 5px 0',
              }}
            >
              {assetType == 'image' ? 'Your Image' : 'Your Text'}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '500px',
                height: '300px',
                overflow: 'scroll',
              }}
            >
              {assetType == 'image' ? (
                <div id="imageDomEl">
                  <ImageBox
                    src={asset1.content.image}
                    alt=""
                    styles={{
                      borderRadius: '10px',
                      width: '300px',
                      height: '300px',
                      objectFit: 'cover',
                      marginBottom: '2rem',
                    }}
                  />
                </div>
              ) : (
                <Typography height={'300px'} color={theme.palette.grey.dark}>
                  {asset1.content.text}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            className="options_section"
            ref={optionsRef}
            item
            xs={region ? 5 : 4}
          >
            <Box sx={{ paddingX: region ? 0 : 8 }}>
              <Typography
                color={theme.palette.primary.main}
                fontFamily="GraphikSemiBold"
                gutterBottom
                sx={{ paddingBottom: '.2em' }}
                fontSize={18}
              >
                Select an Option
              </Typography>
              <Box>
                <Button
                  fontSize={18}
                  disabled={isDisabled}
                  sx={buttonStyle}
                  startIcon={
                    <img width={20} src="assets/images/generate.svg" />
                  }
                  onClick={() => {
                    ADCOptionsOnClick('mockup');
                  }}
                >
                  Create Mockup
                </Button>
                {!region && (
                  <Button
                    fontSize={18}
                    disabled={isDisabled}
                    sx={buttonStyle}
                    startIcon={<img width={20} src="assets/images/blend.svg" />}
                    onClick={() => {
                      ADCOptionsOnClick('blend');
                    }}
                  >
                    Blend Asset
                  </Button>
                )}
                {!region && (
                  <Button
                    fontSize={18}
                    disabled={isDisabled}
                    sx={buttonStyle}
                    startIcon={
                      <img width={20} src="assets/images/iterate.svg" />
                    }
                    onClick={() => {
                      ADCOptionsOnClick('iterate');
                    }}
                  >
                    Iterate Asset
                  </Button>
                )}

                <Exporter
                  dataExported={
                    asset1.type === 'image' ? asset1.content : asset1.content
                  }
                  dataName={'MCSAsset'}
                  buttonOverwrite={{
                    label: `Export Asset`,
                    variant: 'pill-mcs',
                    size: 'large',
                    fontFamily: 'GraphikLight',
                    sx: {
                      ...buttonStyle,
                      border: `1.6px solid ${theme.palette.grey.light2}`,
                    },
                  }}
                  pdfHeading={{
                    title: 'Machine Creative Studio',
                    subTitle: 'Asset',
                  }}
                  fileTypeRequest={{ pdf: 'pdf' }}
                  iconV2
                  boxStyle={{
                    width: '100%',
                    marginLeft: '1.5%',
                    minHeight: '24px',
                    marginBottom: '0.7%',
                  }}
                  regularButton={true}
                />

                <Button
                  fontSize={18}
                  disabled={isDisabled}
                  sx={{ ...buttonStyle, letterSpacing: '-0.15px' }}
                  startIcon={<img width={20} src="assets/images/folder.svg" />}
                  onClick={() => updatePortfolioClick(assets[0])}
                >
                  {!region ? saveToPortfolioText : 'Remove to portfolio'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderBlend = () => {
    return (
      <>
        <Typography variant="h4" component={'h3'} sx={{ marginBottom: '16px' }}>
          Blend Assets
        </Typography>
        <Grid
          className="asset_space"
          container
          justifyContent="center"
          flexDirection="row"
        >
          <Grid item>
            {assetType == 'image' ? (
              <ImageBox
                src={asset1.content.image}
                alt=""
                styles={{
                  borderRadius: '10px',
                  width: '300px',
                  height: '300px',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '300px',
                  width: '300px',
                  overflowX: 'hidden',
                  border: `2px solid ${theme.palette.grey.light2}`,
                  padding: '16px',
                  borderRadius: '8px',
                }}
              >
                <Typography height={'300px'} color={theme.palette.grey.main}>
                  {asset1.content.text}
                </Typography>
              </Box>
            )}
            <Button
              onClick={() => {}}
              startIcon={<SyncAltIcon />}
              sx={{ paddingX: 2 }}
            >
              <Typography
                sx={{ fontSize: '1.2em', marginLeft: -1, paddingTop: 0.1 }}
              >
                Replace
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={2} justifyContent="center" display="flex">
            <BlendIcon style={{ marginTop: '130px' }} />
          </Grid>
          <Grid item>
            {secondaryAsset ? (
              renderSecondaryAsset(secondaryAsset)
            ) : (
              <PlaceholderTile
                width={'300px'}
                height={'300px'}
                description={
                  assetType == 'image'
                    ? 'Click to select a photo'
                    : 'Click to select a text asset'
                }
              />
            )}
          </Grid>
        </Grid>
        <Box>
          <Buttons
            onClick={() => {
              setExpanded(!expanded);
            }}
            startIcon={expanded ? <RemoveIcon /> : <AddIcon />}
            sx={{ paddingX: 2, marginTop: 5 }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
              Add Blend Directions
            </Typography>
          </Buttons>
        </Box>
        {expanded && (
          <>
            <Typography
              variant="h3"
              gutterBottom
              fontSize={20}
              sx={{ marginBottom: 4, marginTop: 4 }}
            >
              How would you like to blend these assets?
            </Typography>
            <BootstrapInput
              multiline
              placeholder="Add Blend Directions"
              onChange={(event) => setBlendText(event.target.value)}
              style={{
                '::placeholder': {
                  color: theme.palette.grey.main,
                  fontFamily: 'GraphikRegular',
                  fontSize: 16,
                  lineHeight: '28px',
                },
              }}
            />
          </>
        )}
        <Buttons
          onClick={() => {
            assetType == 'image'
              ? blendImage(asset1.id, secondaryAsset.id, blendText)
              : blendIterateText(
                  'blend',
                  asset1.id,
                  secondaryAsset.id,
                  blendText
                );
          }}
          variant="song"
          sx={{
            paddingX: '25px',
            paddingY: '8px',
            alignSelf: 'flex-end',
          }}
          disabled={canBlend}
        >
          Blend
        </Buttons>
      </>
    );
  };

  const renderIterate = () => {
    return (
      <>
        <Typography variant="h4" component={'h3'} sx={{ marginBottom: '16px' }}>
          {assetType == 'image' ? 'Image Asset' : 'Text Asset'}
        </Typography>
        <Grid
          className="asset_space"
          container
          justifyContent="space-between"
          flexDirection="row"
        >
          <Grid
            className="asset_detail_section"
            display="flex"
            flexDirection="column"
            xs={5}
          >
            <Typography
              component="h3"
              sx={{
                color: theme.palette.grey.light3,
                fontSize: '18px',
                fontFamily: 'GraphikSemiBold',
                p: '0 0 5px 0',
              }}
            >
              {assetType == 'image' ? 'Your Image' : 'Your Text'}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px',
                overflow: 'auto',
                border: `2px solid ${theme.palette.grey.light2}`,
                padding: '16px',
                borderRadius: '8px',
              }}
            >
              {assetType == 'image' ? (
                <ImageBox
                  src={asset1.content.image}
                  alt=""
                  styles={{
                    borderRadius: '10px',
                    width: '300px',
                    height: '300px',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <Typography height={'300px'} color={theme.palette.grey.main}>
                  {asset1.content.text}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography gutterBottom sx={{ paddingBottom: 2 }}>
              How would you like to iterate on this Asset?
            </Typography>
            <BootstrapInput
              width={'100%'}
              multiline
              maxRows={3}
              minRows={3}
              placeholder="(e.g., make the results more uplifting and edgy)"
              onChange={(event) => setIterateText(event.target.value)}
              style={{
                '::placeholder': {
                  color: '#868B90',
                  fontFamily: 'GraphikRegular',
                  fontSize: '20px',
                  lineHeight: '28px',
                },
              }}
            />
          </Grid>
        </Grid>
        <Buttons
          onClick={() => {
            assetType === 'image'
              ? iterateImage(asset1.id, null, iterateText)
              : blendIterateText('iterate', asset1.id, null, iterateText);
          }}
          variant="song"
          sx={{
            paddingX: '25px',
            paddingY: '8px',
            alignSelf: 'flex-end',
          }}
          disabled={!iterateText.length}
        >
          Iterate Asset
        </Buttons>
      </>
    );
  };

  const renderMockup = () => {
    return (
      <>
        <Typography variant="h4" component={'h3'} sx={{ marginBottom: '16px' }}>
          Create Mockup
        </Typography>
        <Box sx={{ paddingX: 60 }}>
          <Grid container spacing={45}>
            <Grid item>
              <Box>
                <Typography
                  component="h3"
                  sx={{
                    color: theme.palette.grey.light3,
                    fontSize: '18px',
                    fontFamily: 'GraphikSemiBold',
                    p: '0 0 5px 0',
                    textAlign: 'center',
                  }}
                >
                  Your Image
                </Typography>
                <ImageBox
                  src={asset1?.content.image}
                  alt=""
                  styles={{
                    borderRadius: '10px',
                    width: '300px',
                    height: '300px',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Typography
                component="h3"
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '18px',
                  fontFamily: 'GraphikSemiBold',
                  p: '0 0 5px 0',
                  textAlign: 'center',
                }}
              >
                Select Your Text
              </Typography>
              {secondaryAsset ? (
                renderSecondaryAsset(secondaryAsset)
              ) : (
                <PlaceholderTile
                  width={'300px'}
                  height={'300px'}
                  description={`Click to select a text asset to complete your mockup `}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Buttons
          onClick={() => {
            ADCOptionsOnClick('mockup-result');
          }}
          variant="song"
          sx={{
            paddingX: '25px',
            paddingY: '8px',
            alignSelf: 'flex-end',
            marginTop: 5,
          }}
          // disabled={canBlend}
        >
          View Mockup
        </Buttons>
      </>
    );
  };

  const renderMockupText = () => {
    return (
      <>
        <Typography variant="h4" component={'h3'} sx={{ marginBottom: '16px' }}>
          Create Mockup
        </Typography>
        <Box sx={{ paddingX: 60 }}>
          <Grid container spacing={45}>
            <Grid item>
              <Box>
                <Typography
                  component="h3"
                  sx={{
                    color: theme.palette.grey.light3,
                    fontSize: '18px',
                    fontFamily: 'GraphikSemiBold',
                    p: '0 0 5px 0',
                    textAlign: 'center',
                  }}
                >
                  Your Text
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '300px',
                    width: '300px',
                    overflowX: 'hidden',
                    border: `2px solid ${theme.palette.grey.light2}`,
                    padding: '16px',
                    borderRadius: '8px',
                  }}
                >
                  <Typography height={'300px'} color={theme.palette.grey.main}>
                    {asset1?.content.text}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Typography
                component="h3"
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '18px',
                  fontFamily: 'GraphikSemiBold',
                  p: '0 0 5px 0',
                  textAlign: 'center',
                }}
              >
                Select Your Image
              </Typography>
              {secondaryAsset ? (
                renderSecondaryAsset(secondaryAsset)
              ) : (
                <PlaceholderTile
                  width={'300px'}
                  height={'300px'}
                  description={`Click to select a text asset to complete your mockup `}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Buttons
          onClick={() => {
            ADCOptionsOnClick('mockup-result');
          }}
          variant="song"
          sx={{
            paddingX: '25px',
            paddingY: '8px',
            alignSelf: 'flex-end',
            marginTop: 5,
          }}
          // disabled={canBlend}
        >
          View Mockup
        </Buttons>
      </>
    );
  };

  const renderMockupResult = () => {
    const MockupComponent = creativeCanvasComponents[creativeCanvas];
    return (
      <>
        <Box>
          <Typography
            component="h3"
            sx={{
              color: theme.palette.primary.main,
              fontSize: '18px',
              fontFamily: 'GraphikSemiBold',
              p: '0 0 5px 0',
              textAlign: 'center',
            }}
          >
            Your Mockup
          </Typography>
        </Box>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            paddingY: 4,
          }}
        >
          <Box>
            <MockupComponent
              imgDesc={secondaryAsset?.content.text || asset1?.content.text}
              imgSrc={asset1.content.image || secondaryAsset?.content.image}
            />
          </Box>
        </Box>

        <Grid
          container
          spacing={8}
          sx={{
            float: 'right',
            alignContent: 'right',
            justifyContent: 'right',
          }}
        >
          <Grid item>
            <Buttons
              onClick={() => {
                updatePortfolioClick(mockupSavePortfolio);
              }}
              variant="song"
              color="secondary"
              sx={{
                paddingX: '25px',
                paddingY: '8px',
                alignSelf: 'flex-end',
                marginTop: 5,
              }}
              // disabled={canBlend}
            >
              Save to Portfolio
            </Buttons>
          </Grid>

          <Grid item>
            <Box sx={{ marginTop: 4 }}>
              <Exporter
                dataExported={mockupSavePortfolio}
                dataName={'MCSMockup'}
                buttonOverwrite={{
                  label: `Export Asset`,
                  variant: 'song',
                  sx: { paddingTop: 3.2 },
                  startIcon: null,
                }}
                pdfHeading={{
                  title: 'Machine Creative Studio',
                  subTitle: 'Mockup',
                }}
                fileTypeRequest={{ pdf: 'pdf' }}
                boxStyle={{ marginLeft: 0 }}
                regularButton={false}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderComponent = (editMode) => {
    switch (editMode) {
      case 'blend':
        return <>{renderBlend()}</>;
      case 'iterate':
        return <>{renderIterate()}</>;
      case 'mockup':
        return (
          <>{assetType === 'image' ? renderMockup() : renderMockupText()}</>
        );
      case 'mockup-result':
        return <>{renderMockupResult()}</>;
      case 'home':
        return <>{renderInspector()}</>;
      default:
        return <>{renderInspector()}</>;
    }
  };

  return (
    <Box>
      <Card
        sx={{
          minHeight: '300px',
          borderRadius: 2,
          outlineWidth: 1.5,
          paddingTop: 2,
          paddingBottom: 6,
          paddingX: 2,
          marginBottom: '3rem',
        }}
      >
        <Box className="details_content" sx={{ marginX: 10 }}>
          <Box
            sx={{
              paddingY: 4,
              borderBottom: `solid 1px ${theme.palette.primary.main}`,
            }}
          >
            <Box
              sx={{
                float: 'left',
              }}
            >
              <IconButton
                onClick={() => {
                  ADCOptionsOnClick('home');
                  setCanBlend(true);
                }}
              >
                <ArrowBackIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignContent: 'center',
              }}
            >
              <IconButton onClick={closeOnClick}>
                <HighlightOffIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Box>
          </Box>
          <Box
            className="main_asset_container"
            sx={{ paddingTop: 4, display: 'flex', flexDirection: 'column' }}
          >
            {/* {isEditing ? renderInsideCard(editMode) : renderInspector()} */}
            {renderComponent(editMode)}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default AssetsDetailsCard;
