import React from 'react';
import theme from '../../styles/theme'

const Home = () => {
  return (
    <svg
      width="16" 
      height="14" 
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.9062 6.1875C15.9375 6.21875 15.9688 6.3125 15.9688 6.375C15.9688 6.40625 15.9688 6.5 15.9375 6.53125L15.625 6.90625C15.5625 6.96875 15.5 7 15.4062 7C15.375 7 15.3125 6.96875 15.25 6.9375L14 5.90625V13.5C14 13.7812 13.75 14 13.5 14H9.5C9.21875 14 9 13.7812 9 13.5V9.5H7V13.5C7 13.7812 6.75 14 6.5 14H2.5C2.21875 14 2 13.7812 2 13.5V5.90625L0.71875 6.9375C0.65625 6.96875 0.59375 7 0.5625 7C0.5 7 0.40625 6.96875 0.34375 6.90625L0.03125 6.53125C0 6.5 0 6.40625 0 6.375C0 6.3125 0.03125 6.21875 0.09375 6.1875L7.34375 0.25C7.5 0.125 7.78125 0.03125 8 0.03125C8.1875 0.03125 8.46875 0.125 8.625 0.25L13 3.8125V2.1875C13 2.09375 13.0625 2 13.1875 2H13.8125C13.9062 2 14 2.09375 14 2.1875V4.625L15.9062 6.1875ZM12.9688 13L13 5.09375L8 1L3 5.09375V13H5.96875V9C5.96875 8.71875 6.21875 8.5 6.46875 8.5H6.5H9.5C9.75 8.5 9.96875 8.75 9.96875 9V13.0312L12.9688 13Z" fill={theme.palette.grey.light2}/>
    </svg>
  );
}

export default Home