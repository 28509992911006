import React from 'react';
import Buttons from './Button';

export default function Card({ each, index, handleClick, active }) {
  return (
    <Buttons
      id={`platform_${index}`}
      onClick={() => handleClick(each)}
      variant={'pill-multi-select'}
      size={'large'}
      {...(active === each || active.includes(each)
        ? { color: 'primary' }
        : { color: 'secondary' })}
    >
      {each}
    </Buttons>
  );
}
