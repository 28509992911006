import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import Spinner from './Spinner';

/**
 * A button component, used for user input and interaction
 * @param {object} props - props for component
 */
export default function Buttons(props) {
  return (
    <>
      <Button
        {...(props.id && { id: props.id })}
        {...(props.ariaLabel && { 'aria-label': props.ariaLabel })}
        {...(props.loading && { 'aria-label': 'Loading...' })}
        {...(props.href && { role: 'link' })}
        href={props.type ? null : props.href}
        disabled={props.loading ? true : props.disabled}
        variant={props.variant}
        color={props.color}
        type={props.type}
        sx={props.sx}
        startIcon={props.startIcon && (props.loading ? null : props.startIcon)}
        endIcon={props.endIcon && (props.loading ? null : props.endIcon)}
        onClick={(e) => {
          if (!props.disabled && props.onClick) {
            props.onClick(e);
          }
        }}
        size={props.size}
        selected={props.selected}
        disableFocusRipple={true}
        disableRipple={false}
        style={{ ...props.style }}
        onKeyDown={props.onKeyDown}
        download={props.download}
      >
        {props.loading ? (
          <span
            className="font-graphik-semibold"
            style={{
              ...props.sx,
              fontSize: 'inherit',
              height: 'inherit',
              lineHeight: 'inherit',
              display: 'flex',
              columnGap: '10px',
              zIndex: 500,
              color: `${props.color === 'secondary' ? '#0573FF' : '#1E1E1E'}`,
              opacity: '0.6',
            
            }}
          >
            <Spinner loadersize={props.loadersize ? props.loadersize : 18} />
            Loading....
          </span>
        ) : (
          <span className={props.regularButton ? "" : "font-graphik-semibold"} style={props.regularButton ? {} : { ...props.sx }}>
            {props.label || props.children}
          </span>
        )}
      </Button>
    </>
  );
}

Buttons.propTypes = {
  // id for this instance
  id: PropTypes.string,

  loading: PropTypes.bool,

  // whether this button can be interacted with or not
  disabled: PropTypes.bool,

  // handler for when this button is clicked by the user
  onClick: PropTypes.func,

  // aria-label for this button, used for accessibility
  ariaLabel: PropTypes.string,

  // size of the component e.g. 'small'|'medium'|'large'|string
  size: PropTypes.string,

  // URL to link to when the button is clicked
  href: PropTypes.string,

  // content of the component.
  children: PropTypes.node,

  // element placed before the children.
  startIcon: PropTypes.node,

  // element placed after the children.
  endIcon: PropTypes.node,

  // overide css object
  sx: PropTypes.object,

  // type of the button
  type: PropTypes.oneOf(['submit', 'reset']),

  /// Examples of Spinner Props - see Spinner.jsx compoent ///
  loaderColor: PropTypes.string,

  // e.g. 12px
  loaderSize: PropTypes.string,

  // used to create inline styles on the loaders. Any html css property is valid here (e,g. {color: "red"})
  loaderCss: PropTypes.object,
};
