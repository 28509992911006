import { MCSV2_ACTION_TYPES } from './mcsv2ActionTypes';

export const initial_MCSV2_State = {
  currentProject: {},
  projects: [],
  portfolio: [],
  territoryData: {},
  territories: [],
  userId: '',
  workspace: '',
  generatedImageAssets: {},
  generatedTextAssets: {},
  portfolioAsset :[],
  assets: {}
};

export const mcsv2Reducer = (state, action) => {
  switch (action.type) {
    case MCSV2_ACTION_TYPES.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case MCSV2_ACTION_TYPES.CHOSEN_WORKSPACE:
      return {
        ...state,
        ...action.payload,
      };
    case MCSV2_ACTION_TYPES.SET_TERRITORY_DATA:
      return {
        ...state,
        territoryData: action.payload,
      };
    case MCSV2_ACTION_TYPES.SET_TERRITORIES:
      return {
        ...state,
        territories: action.payload,
      };
    case MCSV2_ACTION_TYPES.SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.payload,
      };
    case MCSV2_ACTION_TYPES.SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case MCSV2_ACTION_TYPES.SET_PORTFOLIO:
      return {
        ...state,
        portfolio: action.payload,
      };
    case MCSV2_ACTION_TYPES.SET_IMAGE_ASSETS:
      return {
        ...state,
        generatedImageAssets: action.payload,
      };
    case MCSV2_ACTION_TYPES.SET_TEXT_ASSETS:
      return {
        ...state,
        generatedTextAssets: action.payload,
      };
    case MCSV2_ACTION_TYPES.SET_ASSETS_INPUT_DATA:
      return {
        ...state,
        assets: action.payload,
      };
      case MCSV2_ACTION_TYPES.SET_PORTFOLIO_ASSET:
      return {
        ...state,
        portfolioAsset: action.payload,
      };
    default:
      return state;
  }
};
