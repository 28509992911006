export const RA_ACTION_TYPES = {
  CONTINUE_FROM_INTRO: 'CONTINUE_FROM_INTRO',
  SET_PARTICIPANTS: 'SET_PARTICIPANTS',
  SET_PARTICIPANT_DATA: 'SET_PARTICIPANT_DATA',
  RESET_STEPS: 'RESET_STEPS',
  UPDATE_AVATAR_LIST: 'UPDATE_AVATARS_LIST',
  UPDATE_PARTICIPANTS: 'UPDATE_PARTICIPANTS',
  MERGE_PARTICIPANT: 'MERGE_PARTICIPANT'
};

export const RA_API_TYPES = {
  RA_REGISTER: 'newProfile',
  RA_INITIATE: 'chatInitiate',
  RA_CONTINUE: 'chatContinue',
};

export const participantObj = {
  uuid: '',
  age: '',
  ageRange: [0, 100],
  gender: '',
  participantName: '',
  participantDescription: '',
  temperature: '',
  location: '',
  requestType: '',
  initiated: false,
  specificAgeBool: false,
  localDialectBool: false,
  avatar: '',
};
