import { Box } from '@mui/material';
import React from 'react';

const ColorPalette = ({ colors, large, small }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      {colors?.map((color, index) => (
        <Box
          key={index}
          style={{
            backgroundColor: `#${color}`,
            flexGrow: 1,
          }}
        />
      ))}
    </Box>
  );
};

export default ColorPalette;