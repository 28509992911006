import { Box, Typography } from '@mui/material';
import React from 'react';
import theme from '../../styles/theme';
import GCEChat from './GCEChat';

const footerButtonStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingRight: '80px',
};

const GCEIntroForm = ({
  counter,
  handleCounter,
  handlePredefinedQuestionCount,
  styles
}) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        borderRadius="8px"
        boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
        backgroundColor={theme.palette.grey.white}
        width="1440px"
        sx={{
          maxWidth: '1062px',
          paddingBottom: '0px',
          position: 'relative',
          top: '143px',
          bottom: 0,
        }}
      >
        <Box sx={{ padding: '2rem 4rem' }}>
          <Box marginTop="2.5em" paddingBottom="1.5rem" sx={{ borderBottom: '1px solid #C9D0D7', marginBottom: '2rem' }}>
            <Typography
              variant="h1"
              color={theme.palette.primary.main}
              gutterBottom
            >
              Let’s get started!
            </Typography>
            <Typography
              variant="subtitle1"
              color={theme.palette.grey.black}
              gutterBottom
              fontWeight={400}
              
            >
              What problem are you looking to solve? Try telling us what you’re
              looking for.
            </Typography>
          </Box>
          <GCEChat
            counter={counter}
            handleCounter={handleCounter}
            handlePredefinedQuestionCount={handlePredefinedQuestionCount}
            styles={styles}
          />
        </Box>
      </Box>
    </>
  );
};

export default GCEIntroForm;
