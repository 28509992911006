import { DeleteOutlineOutlined } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import theme from '../../styles/theme';
import Buttons from '../Button';

const ParticipantCard = ({
  age,
  ageRange,
  gender,
  temperature,
  participantName,
  participantDescription,
  location,
  avatar,
  handleDelete,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpansion = (state) => {
    setIsExpanded(state);
  };

  const handleParticipantDelete = () => {
    handleDelete();
  };

  const temperatureRating = !(temperature >= 0 && temperature <= 33)
    ? !(temperature > 33 && temperature <= 66)
      ? 'High'
      : 'Mid'
    : 'Low';

  const mapTemperature = (temp) => {
    if (temp <= 0.3) {
      return 'Low';
    } else if (temp <= 0.7) {
      return 'Medium';
    }
    return 'High';
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Card
        sx={{
          minHeight: 146,
          width: '100%',
          borderRadius: 2,
          outlineColor: theme.palette.primary.main,
          outlineStyle: 'solid',
          outlineWidth: 1.5,
        }}
      >
        <CardContent sx={{ paddingBottom: 0 }}>
          <Grid container spacing={1}>
            <Grid xs={3}>
              <Box sx={{ float: 'right' }}>
                <Avatar
                  src={`assets/images/${avatar}`}
                  sx={{ width: 74, height: 74 }}
                />
              </Box>
            </Grid>
            <Grid xs={9} sx={{ paddingTop: 2 }}>
              <Typography sx={{ fontWeight: 900 }}>
                {participantName}
              </Typography>
              <Box
                sx={{
                  fontFamily: 'GraphikRegular',
                  fontWeight: 'light',
                  fontSize: 16,
                }}
              >
                <Grid container spacing={3.5}>
                  <Grid>{age ? age : `${ageRange[0]}-${ageRange[1]}`} yr</Grid>
                  <Grid>{gender}</Grid>
                  <Grid container spacing={0}>
                    <Grid xs={3} sx={{paddingTop:.6}}>
                      <img src={'assets/images/temperature.svg'} />
                    </Grid>
                    <Grid xs={3}>
                      <Box>{mapTemperature(temperature)}</Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        {isExpanded ? (
          <CardContent>
            <Box>
              <Grid container spacing={4} sx={{ paddingLeft: 2 }}>
                <Grid xs={1}>
                  <img src={'assets/images/location.svg'} />
                </Grid>
                <Grid xs={10}>{location}</Grid>
              </Grid>
              <Grid container spacing={4} sx={{ paddingLeft: 2 }}>
                <Grid xs={1}>
                  <img src={'assets/images/description.svg'} />
                </Grid>
                <Grid xs={10}>{participantDescription}</Grid>
              </Grid>
            </Box>
          </CardContent>
        ) : (
          <Box></Box>
        )}
        <CardActions sx={{ justifyContent: 'center' }}>
          <Box>
            {isExpanded ? (
              <Button
                size="small"
                endIcon={<ExpandLessIcon />}
                onClick={() => handleExpansion(false)}
              >
                Show less
              </Button>
            ) : (
              <Button
                size="small"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={() => handleExpansion(true)}
              >
                Show more
              </Button>
            )}
          </Box>
        </CardActions>
      </Card>

      {/* Est time Component */}
      <Box
        sx={{
          mt: '3px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {//Removed time estimates for now
          /* <Typography variant="p" fontSize={12}>
            {`Est. Interview Time: ${0} mins`}
          </Typography>
          <IconButton>
            <SVGIcon
              name={'info'}
              color={theme.palette.primary.main}
              aria-label="info"
            />
          </IconButton> */}
        </Box>

        <Buttons
          loaderSize={'10px'}
          startIcon={<DeleteOutlineOutlined />}
          label={<Typography fontSize={12}>Delete</Typography>}
          onClick={handleParticipantDelete}
        />
      </Box>
    </Box>
  );
};

ParticipantCard.propTypes = {
  age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gender: PropTypes.string,
  temperature: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  participantName: PropTypes.string,
  participantDescription: PropTypes.string,
  location: PropTypes.string,
  avatar: PropTypes.string,
  handleDelete: PropTypes.func,
};

export default ParticipantCard;
