import { Box, InputBase, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import Buttons from '../Button';
import TerritoryCard from './TerritoryCard';

import { styled } from '@mui/material/styles';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid ' + theme.palette.grey.main,
    fontSize: 16,
    padding: '18px 12px',
    background: '#FFF',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  width: '100%',
}));

const renderTerritoryCard = (header, imageArray, colorPalette) => {
  return (
    <TerritoryCard
      imageArray={imageArray}
      header={header}
      colorPalette={colorPalette}
      width={'100%'}
      height={'100%'}
      noRemove
      handleClick={() => {}}
    />
  );
};

const IterateTerritory = ({
  header,
  imageArray,
  colorPalette,
  onClickIterateTerritory,
  setIterateText,
}) => {
  return (
    <>
      <Typography variant="h4" component={'h3'} sx={{ marginBottom: '16px' }}>
        Image Asset
      </Typography>
      <Grid container spacing={12}>
        <Grid item xs={5}>
          {renderTerritoryCard(header, imageArray, colorPalette)}
        </Grid>
        <Grid item xs={7}>
          <Typography gutterBottom sx={{ paddingBottom: 2 }}>
            How would you like to iterate on this Territory?
          </Typography>
          <BootstrapInput
            multiline
            maxRows={3}
            minRows={3}
            placeholder="(e.g., make the results more uplifting and edgy)"
            onChange={(event) => setIterateText(event.target.value)}
            style={{
              '::placeholder': {
                color: '#868B90',
                fontFamily: 'GraphikRegular',
                fontSize: '20px',
                lineHeight: '28px',
              },
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 10 }}>
        <Buttons
          variant="song"
          color="primary"
          sx={{ float: 'right', paddingX: 8 }}
          onClick={() => onClickIterateTerritory()}
        >
          Iterate Territory
        </Buttons>
      </Box>
    </>
  );
};

export default IterateTerritory;
