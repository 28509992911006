import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import theme from '../../styles/theme';
import Buttons from '../Button';
import SVGIcon from '../SVGIconAnimate';
import CNSFileUpload from './CNSFileUpload';

const CNSCustomerNeedTab = ({
  setIsReset,
  customerNeed,
  setCustomerNeed,
  handleChangeOnEdit,
  handleUndoChange,
  handleRegenerateAnalysis,
  isValueChange,
  setFileLog,
  setIsEdit,
  isEdit,
  removeAllFiles,
  setUndoAllFiles,
  isLoading,
  chatHistory,
}) => {
  const heading = {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: 'Graphik',
    my: 3,
  };
  const labelStyle = {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    marginBottom: 8,
    marginLeft: 8,
  };

  const valueStyle = {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '26px',
    marginBottom: '8px',
    paddingLeft: '26px',
  };
  const handleChangeForCheckbox = (event) => {
    const tempCustomerNeed = JSON.parse(JSON.stringify(customerNeed));
    Object.keys(tempCustomerNeed.areaOfFocus).forEach((item) => {
      if (item == event.target.name) {
        tempCustomerNeed.areaOfFocus[item] = event.target.checked;
      }
    });
    setCustomerNeed(tempCustomerNeed);
  };

  const getLabelText = (each) => {
    if (each == 'insights') {
      return 'Insight & Trends';
    }
    if (each == 'mindset') {
      return 'Mindsets';
    }
  };

  return (
    <>
      <Typography
        variant="h3"
        fontSize={24}
        fontWeight={600}
        lineHeight={'30px'}
        color={theme.palette.primary.main}
        sx={{ marginBottom: 2 }}
      >
        Map your market landscape
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Buttons
          startIcon={<img src="/assets/rotate.svg" alt="Rotate" />}
          sx={{
            fontFamily: 'Graphik',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
          }}
          label={'Reset Session'}
          onClick={() => setIsReset(true)}
        />
        {!isEdit && (
          <Buttons
            startIcon={
              <SVGIcon
                name="edit-pen"
                color={
                  !isEdit && !isLoading
                    ? theme.palette.primary.main
                    : theme.palette.grey.light3
                }
              />
            }
            sx={{
              fontFamily: 'Graphik',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 400,
            }}
            label={'Edit'}
            disabled={isLoading}
            onClick={() => setIsEdit(true)}
          />
        )}
      </Box>
      <hr
        style={{
          borderTop: `1px solid  ${theme.palette.grey.light3}`,
        }}
      />
      {isEdit && (
        <Box sx={{ marginTop: '8px' }}>
          <Typography
            sx={{ fontSize: 14, fontStyle: 'italic', marginBottom: 4 }}
          >
            Note: Looks like you’ve added additional information. Hit
            “Regenerate Analysis” to update the analysis
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Buttons
              sx={{
                paddingTop: 0.1,
                textDecoration: 'underline',
                fontFamily: 'Graphik',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 600,
              }}
              label={'Undo Changes'}
              onClick={handleUndoChange}
              disabled={!isValueChange}
            />
            <Buttons
              variant="song"
              color="secondary"
              sx={{
                paddingY: '10px',
                fontFamily: 'Graphik',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 600,
              }}
              label={'Regenerate Analysis'}
              onClick={handleRegenerateAnalysis}
            />
          </Box>
          <hr
            style={{
              borderTop: `1px solid ${theme.palette.grey.light3}`,
            }}
          />
        </Box>
      )}
      <h4 style={heading}>Customer Needs</h4>
      <Box
        sx={{
          border: `1px solid ${theme.palette.blue.link1}`,
          minHeight: 200,
          borderRadius: 2,
          height: '100%',
          backgroundColor: theme.palette.grey.white,
          display: 'flex',
          padding: '20px 30px 20px 30px',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            marginTop: 2,
            marginRight: 2,
          }}
        >
          <SVGIcon name="eye-inspect" color={theme.palette.primary.dark} />
          <Typography variant="p" style={labelStyle}>
            Area Of Focus
          </Typography>
        </Box>
        {isEdit ? (
          <Box
            sx={{
              border: `1px solid ${theme.palette.grey.light2}`,
              mx: '4px',
              px: '12px',
            }}
          >
            {Object.keys(customerNeed?.areaOfFocus).map((each, id) => {
              return (
                <>
                  <FormControlLabel
                    key={`checkbox_${each}`}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '16px',
                        lineHeight: '26px',
                        fontWeight: 400,
                        fontFamily: 'Graphik',
                      },
                      display: 'block',
                    }}
                    control={
                      <Checkbox
                        checked={customerNeed?.areaOfFocus[each]}
                        onChange={handleChangeForCheckbox}
                        name={each}
                      />
                    }
                    label={getLabelText(each)}
                  />
                </>
              );
            })}
          </Box>
        ) : (
          <Typography variant="body2" style={valueStyle}>
            {chatHistory[1].text.toString()}
          </Typography>
        )}
        <Box
          sx={{
            marginTop: 2,
            marginRight: 2,
          }}
        >
          <img src={'assets/user.svg'} style={{ marginBottom: '-5px' }} />
          <Typography variant="p" style={labelStyle}>
            Audience
          </Typography>
        </Box>
        {isEdit ? (
          <TextField
            sx={{
              p: 1,
            }}
            value={customerNeed.audience}
            name="audience"
            onChange={handleChangeOnEdit}
            multiline={true}
            maxRows={3}
          />
        ) : (
          <Typography variant="body2" style={valueStyle}>
            {customerNeed.audience}
          </Typography>
        )}
        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <img src={'assets/topic.svg'} style={{ marginBottom: '-5px' }} />
          <Typography variant="p" style={labelStyle}>
            Topic
          </Typography>
        </Box>
        {isEdit ? (
          <TextField
            sx={{
              p: 1,
            }}
            value={customerNeed.topic}
            name="topic"
            onChange={handleChangeOnEdit}
            multiline={true}
            maxRows={3}
          />
        ) : (
          <Typography variant="body2" style={valueStyle}>
            {customerNeed.topic}
          </Typography>
        )}
        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <img src={'assets/content.svg'} style={{ marginBottom: '-5px' }} />
          <Typography variant="p" style={labelStyle}>
            Context
          </Typography>
        </Box>
        {isEdit ? (
          <TextField
            sx={{
              p: 1,
            }}
            value={customerNeed.context}
            name="context"
            defaultValue="Normal"
            onChange={handleChangeOnEdit}
            multiline={true}
            maxRows={3}
          />
        ) : (
          <Typography variant="body2" style={valueStyle}>
            {customerNeed.context}
          </Typography>
        )}
      </Box>
      <h4 style={heading}>Data Sources</h4>
      <Box
        sx={{
          border: `1px solid ${theme.palette.blue.link1}`,
          borderRadius: 2,
          marginTop: 2,
          marginBottom: 2,
          padding: 3,
          backgroundColor: theme.palette.grey.white,
        }}
      >
        {customerNeed.datasource.length > 0 || isEdit ? (
          <CNSFileUpload
            setFileLog={setFileLog}
            showText={true}
            isEdit={!isEdit}
            generatedFiles={customerNeed.datasource}
            removeAllFiles={removeAllFiles}
            setUndoAllFiles={setUndoAllFiles}
          />
        ) : (
          <Box display="flex" justifyContent="center">
            <Typography fontSize="16px">
              No data sources are uploaded!
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
export default CNSCustomerNeedTab;
