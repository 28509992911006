import {
  Backdrop,
  Box,
  Checkbox,
  Fade,
  FormControlLabel,
  FormGroup,
  Modal,
  Stack,
  Typography
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { jsPDF } from 'jspdf';
import PptxGenJS from 'pptxgenjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import theme from '../styles/theme';
import AlertToasterBanner from './AlertToasterBannner';
import Buttons from './Button';
import CloseButton from './CloseButton';
import ExportPreview from './ExportPreview';
import ExportRenderer from './ExportRenderer';
import RadioQuestions from './RadioQuestions';
import SVGIcon from './SVGIcon';

const ExportOptionsBar = ({
  closeExportBar,
  chatHistory,
  bookmarkedChats,
  rowStyle,
  closeExportOptionsBar,
  exportFrom,
  setFileExported,
  bookmarkedIdeas
}) => {
  const fileType =
    exportFrom === 'CNS'
      ? { '.pdf': false, '.doc': false, '.ppt': false }
      : { '.pdf': false };
  const [preview, setPreview] = useState();
  const time = new Date()
    .toLocaleTimeString()
    .replaceAll(':', '-')
    .split(' ')[0];
  const date = new Date().toLocaleDateString().replaceAll('/', '-');
  const dateTime = `${date}'_'${time}`;
  const [category, setCategory] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isAlert, setIsAlert] = useState(false);
  const [alertBanners, setAlertBanners] = useState([]);
  const [exportTypeSelected, setExportTypeSelected] = useState(fileType);
  const [exportData, setExportData] = useState();
  const [open, setOpen] = useState(false);
  const [documentLink, setDocumentLink] = useState(false);
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(true);
  const pdfLinkRef = useRef();

  const pdfHeadings = {
    title:
      (category === 'Export idea' && 'Generative Concepting Engine') ||
      (category === 'Export chat' && 'UX Research Assistant') ||
      (category === 'Export interview' && 'UX Research Assistant'),
    subTitle:
      (category === 'Export idea' && 'Bookmarked Ideas') ||
      (category === 'Export chat' && 'Bookmarked Chat') ||
      (category === 'Export interview' && 'Interview History')
  };

  const handleAlert = (alertFrom) => {
    const isError = pdfLinkRef.current?.error;
    const alertList = [
      {
        open: alertFrom === 'pdf' && true,
        alertType: isError ? 'error' : 'success',
        alertTitle: isError ? 'Export Error' : 'Export Successful',
        alertMessage: isError
          ? `There is an error in ${category} download link`
          : `Your ${category} is successfuly downloaded as pdf`
      },
      {
        open: alertFrom === 'chat' && true,
        alertType: 'info',
        alertTitle: 'Chat not started',
        alertMessage: `Please start chatting wit participants`
      }
    ];
    setIsAlert(true);
    setAlertBanners(alertList);
  };

  const documentReady = useMemo(() => {
    return category && exportData ? (
      <ExportRenderer documentName={category} documentData={exportData} />
    ) : null;
  }, [category, exportData]);

  useEffect(() => {
    if (documentReady) {
      setDocumentLink(true);
    }
  }, [documentReady]);

  useEffect(() => {
    const typeSelected = Object.values(exportTypeSelected).some((type) => type);
    const option = ['Export interview', 'Export chat', 'Export idea'];
    const optionSelected = option.indexOf(category);

    try {
      if (typeSelected && (optionSelected <= 2 || exportFrom === 'CNS')) {
        //UXRA & GCE functions here
        if (optionSelected <= 1 && documentLink) setIsDisabled(false);
        if (optionSelected === 0) {
          setExportData(chatHistory);
        } else if (optionSelected === 1) {
          setExportData(bookmarkedChats);
        } else {
          handleAlert('chat');
        }
        if (optionSelected === 2) {
          setExportData(bookmarkedIdeas);
          setIsDisabled(false);
        }

        //CNS functions here
        if (exportFrom === 'CNS') {
          setIsDisabled(false);
          let previewSelected = Object.keys(exportTypeSelected).filter(
            function (key) {
              return exportTypeSelected[key];
            }
          );
          if (previewSelected.length === 1) {
            setIsPreviewEnabled(false);
          } else {
            setIsPreviewEnabled(true);
          }
        }
      } else {
        setIsDisabled(true);
      }
    } catch (e) {
      console.log('Error in Export Option Request', e);
    }
  }, [
    exportTypeSelected,
    category,
    bookmarkedIdeas,
    chatHistory,
    bookmarkedChats,
    documentLink
  ]);

  const handleChange = (keyName, valueName) => {
    let tempArr = { ...exportTypeSelected, [keyName]: !valueName };
    setExportTypeSelected(tempArr);
    if (bookmarkedIdeas) {
      setCategory('Export idea');
    }
  };

  const handleCNSExport = async () => {
    let alertList = [];
    if (exportTypeSelected['.pdf']) {
      let pdf = new jsPDF();
      try {
        let text = [];
        chatHistory.map((each) => {
          if (each.childrenNodes.length) {
            text.push(`label_pdf`);
            text.push(`${each.label}\n`);
            each.childrenNodes.map((ch) => {
              text.push(`sub_label_pdf`);
              text.push(`${ch.label}\n`);
              text.push(`content_pdf`);
              text.push(`${ch.content}\n`);
            });
          } else {
            text.push(`label_pdf`);
            text.push(`${each.label}\n`);
            text.push(`content_pdf`);
            text.push(`${each.content}\n`);
          }
        });
        var bodyContent = pdf.splitTextToSize(text.join('\n'), 200);
        var pageHeight = pdf.internal.pageSize.getHeight();
        var y = 15;
        for (var i = 0; i < bodyContent.length; i++) {
          if (y + 10 > pageHeight && bodyContent[i].trim()) {
            y = 15;
            pdf.addPage();
          }
          if (bodyContent[i - 1] === 'label_pdf') {
            pdf.setFontSize(22);
            pdf.setFont('times', '', 600);
          }
          if (bodyContent[i - 1] === 'sub_label_pdf') {
            pdf.setFontSize(20);
            pdf.setFont('times', '', 600);
          }
          if (bodyContent[i - 1] === 'content_pdf') {
            pdf.setFont('times', '', 400);
            pdf.setFontSize('16');
          }
          if (
            bodyContent[i] !== 'label_pdf' &&
            bodyContent[i] !== 'sub_label_pdf' &&
            bodyContent[i] !== 'content_pdf'
          ) {
            pdf.text(bodyContent[i].trim(), 10, y);
            y = y + 7;
          }
        }
        pdf.save(`Export_${dateTime}.pdf`);
        if (setFileExported) {
          setFileExported(true);
        }
        alertList.push({
          id: '1-pdf',
          alertType: 'success',
          alertMessage: 'Pdf export success: ',
          open: true
        });
      } catch (e) {
        alertList.push({
          id: '1-pdf',
          alertType: 'error',
          alertMessage: 'Pdf export error: ',
          open: true
        });
        console.log('There is an error in CNS pdf export: ', e);
      }
    }

    if (exportTypeSelected['.doc']) {
      try {
        let text = [];
        chatHistory.map((each) => {
          if (each.childrenNodes.length) {
            text.push(`<p><h4><strong>${each.label}</strong></h4></p>`);
            each.childrenNodes.map((ch) => {
              text.push(
                `<p><h5><strong>${
                  ch.label
                }</strong></h5></p><p>${ch.content.replaceAll(
                  '\n',
                  '<br>'
                )}</p>`
              );
            });
          } else {
            text.push(
              `<p><h4><strong>${each.label}</strong></h4></p><p>${each.content}</p>`
            );
          }
        });
        document.getElementById('pdf_block').innerHTML = text
          .toString()
          .replaceAll(',', '');
        var header =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="utf-8"><title>Chat export</title></head><body>';
        var footer = '</body></html>';
        const element = document.getElementById('pdf_block');
        element.style.display = 'block';
        var content = header + element.innerHTML + footer;
        var download_link = document.createElement('a');
        download_link.href =
          'data:text/html;charset=utf-8,' + encodeURIComponent(content);
        download_link.download = `Export_${dateTime}.doc`;
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
        element.style.display = 'none';

        if (setFileExported) {
          setFileExported(true);
        }
        setFileExported(true);
        alertList.push({
          id: '1-doc',
          alertType: 'success',
          alertMessage: 'Doc export success: ',
          open: true
        });
      } catch (e) {
        alertList.push({
          id: '1-doc',
          alertType: 'error',
          alertMessage: 'Doc export error: ',
          open: true
        });
        console.log('There is an error in CNS doc export: ', e);
      }
    }

    if (exportTypeSelected['.ppt']) {
      try {
        let pptx = new PptxGenJS();
        let textArr = [];
        chatHistory.map((each) => {
          if (each.childrenNodes.length) {
            textArr.push({
              type: 'header',
              text: each.label,
              options: {
                fontSize: 32,
                align: 'center',
                h: 0.4,
                w: '100%',
                color: '008899',
                breakLine: true,
                x: 0,
                y: '50%'
              }
            });
            each.childrenNodes.map((ch) => {
              textArr.push({
                type: 'header',
                text: ch.label,
                options: {
                  fontSize: 20,
                  align: 'center',
                  h: 0.4,
                  w: '100%',
                  fill: { color: 'D3E3F3' },
                  color: '008899',
                  breakLine: true
                }
              });
              textArr.push({
                type: 'content',
                text: ch.content,
                options: {
                  fontSize: 14,
                  w: '95%',
                  color: '000000',
                  breakLine: true,
                  x: 0.2,
                  y: 3
                }
              });
            });
          } else {
            textArr.push({
              type: 'header',
              text: each.label,
              options: {
                fontSize: 22,
                align: 'center',
                h: 0.4,
                w: '100%',
                fill: { color: 'D3E3F3' },
                color: '008899',
                breakLine: true
              }
            });
            textArr.push({
              type: 'content',
              text: each.content,
              options: {
                fontSize: 14,
                w: '95%',
                color: '000000',
                breakLine: true,
                x: 0.2,
                y: 2.7
              }
            });
          }
        });
        let counter = 0;
        let slide = [];
        const increment = 2172;
        for (let i = 0; i < textArr.length; i++) {
          let textLen = textArr[i].text?.length;
          let textType = textArr[i].type;
          for (let j = 0; j < textLen; j += increment) {
            if (textType === 'header') {
              counter = counter + 1;
            }
            if (!slide[counter]) {
              slide[counter] = pptx.addSlide();
            }
            if (textLen < increment) {
              slide[counter].addText(textArr[i].text, textArr[i].options);
            } else {
              slide[counter].addText(
                textArr[i].text.substring(j, j + increment),
                textArr[i].options
              );
              counter = counter + 1;
            }
          }
        }
        pptx.writeFile({ fileName: `Export_${dateTime}.pptx` });
        if (setFileExported) {
          setFileExported(true);
        }
        alertList.push({
          id: '1-ppt',
          alertType: 'success',
          alertMessage: 'Ppt export success: ',
          open: true
        });
      } catch (e) {
        alertList.push({
          id: '1-ppt',
          alertType: 'error',
          alertMessage: 'Ppt export error: ',
          open: true
        });
        console.log('There is an error in CNS ppt export: ', e);
      }
    }
    if (alertList.length) {
      setIsAlert(true);
      const alertObject = JSON.parse(JSON.stringify(alertList));
      setAlertBanners(alertObject);
    }
    setExportTypeSelected(exportTypeSelected);
  };

  //Functions below are use in GCE & UXRA//
  const handlePreview = () => {
    if (documentReady) {
      setOpen((open) => !open);
    }
  };
  const handleDownload = () => {
    const downloadLink = pdfLinkRef.current?.url;
  
      const anchor = document.createElement('a');
      anchor.href = downloadLink;
      anchor.download = `${category}-${dateTime}.pdf`;
      document.body.appendChild(anchor);
      anchor.click();
      setIsDisabled(false);
      document.body.removeChild(anchor);
 

   
      handleAlert('pdf');
    
  };

  const renderAlerts = (
    <div
      style={{
        position: 'absolute',
        zIndex: '500',
        bottom: `${rowStyle && '220px'}`,
        left: `${rowStyle && '50%'}`
      }}
    >
      <AlertToasterBanner
        {...alertBanners[0]}
        style={{ position: 'relative' }}
      />
    </div>
  );

  const pdfDownloadLink = (
    <PDFDownloadLink
      document={documentReady}
      fileName={`${category}-${dateTime}.pdf`}
    >
      {({ blob, url, loading, error }) => {
        if (error) {
          console.log('PDF Download Link error :', e);
          pdfLinkRef.current.error = true;
        }
        if (url) {
          pdfLinkRef.current = { url, blob, loading };
          pdfLinkRef.current.error = null;
        }
      }}
    </PDFDownloadLink>
  );





  return (
    <>
      {isAlert && renderAlerts}
      {documentLink && pdfDownloadLink}
      {!rowStyle ? (
        <>
          <Box
            sx={{
              backgroundColor: theme.palette.grey.white,
              height: '100%',
              width: '100%',
              boxShadow: '0px 4px 40px rgba(30, 30, 30, 0.1)',
              padding: '115px 40px 30px 40px',
              display: 'flex',
              flexBasis: '33.33%',
              flexGrow: 0,
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'left'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '20px',
                borderBottom: '1px solid',
                borderColor: theme.palette.grey.light2
              }}
            >
              <Typography variant="h1" fontSize={24}>
                Export
              </Typography>
              <CloseButton
                styles={{ fill: theme.palette.grey.black }}
                btnAction={closeExportBar}
              />
            </Box>
            <Box
              sx={{
                paddingBottom: '20px',
                borderBottom: '1px solid',
                borderColor: theme.palette.grey.light2
              }}
            >
              <RadioQuestions checked channel="export" setType={setCategory} />
            </Box>

            <Box sx={{ paddingTop: '0px' }}>
              <Typography variant="body3" fontSize={18}>
                Export Format
              </Typography>

              <FormGroup sx={{ paddingTop: '20px' }}>
                {Object.keys(exportTypeSelected)?.map((item, idx) => {
                  return (
                    <FormControlLabel
                      key={`checkbox_${idx}`}
                      control={
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                          checked={exportTypeSelected[item] ? true : false}
                        />
                      }
                      label={item}
                      name={item}
                      onChange={() =>
                        handleChange(item, exportTypeSelected[item])
                      }
                    />
                  );
                })}
              </FormGroup>
            </Box>

            <Stack
              spacing={4}
              direction="row"
              sx={{
                width: '100%',
                padding: '24px 8px',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: '30rem'
              }}
            >
              <Buttons
                label="Preview"
                variant="song"
                color="secondary"
                startIcon={
                  <SVGIcon
                    name="preview"
                    color={
                      !documentReady
                        ? theme.palette.state.disabled_secondary
                        : theme.palette.primary.main
                    }
                  />
                }
                onClick={() => handlePreview()}
                loading={pdfLinkRef.current ? pdfLinkRef.current.loading : null}
                disabled={isDisabled}
              />
              <Buttons
                label={`Export`}
                variant="song"
                color="primary"
                component="a"
                startIcon={
                  <SVGIcon
                    name="export"
                    color={
                      isDisabled
                        ? theme.palette.state.disabled_secondary
                        : theme.palette.grey.black
                    }
                  />
                }
                href={pdfLinkRef.current?.url}
                download={`${category}-${dateTime}.pdf`}
                disabled={isDisabled}
                onClick={() => handleDownload()}
              />
            </Stack>
          </Box>
        </>
      ) : (
        <>
          <Stack
            sx={{ width: '100%', margin: '0 auto', padding: '24px 48px' }}
          >
            <Box
              sx={{
                borderBottom: '1px solid',
                borderColor: theme.palette.grey.light2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                paddingBottom: '24px'
              }}
            >
              <Typography variant="h3" sx={{ alignSelf: 'left' }}>
                Select Export Format
              </Typography>
              <Buttons
                sx={{ alignSelf: 'right', height: '32px' }}
                startIcon={
                  <SVGIcon
                    width={24}
                    height={27}
                    name="close"
                    color={theme.palette.grey.black}
                  />
                }
                onClick={closeExportOptionsBar}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                paddingTop: '24px'
              }}
            >
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                {Object.keys(exportTypeSelected)?.map((item, idx) => {
                  return (
                    <Box key={idx.toString()} sx={{ alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: '24px',
                                fontFamily: 'GraphikRegular',
                                lineHeight: '32px'
                              }
                            }}
                            checked={exportTypeSelected[item] ? true : false}
                          />
                        }
                        label={item}
                        name={item}
                        onChange={() =>
                          handleChange(item, exportTypeSelected[item])
                        }
                      />
                    </Box>
                  );
                })}
              </FormGroup>

              <Box sx={{ alignItems: 'right', justifyItems: 'right' }}>
                {exportFrom != 'CNS' ? (
                  <>
                    <Buttons
                      label="Preview"
                      variant="song"
                      color="secondary"
                      startIcon={
                        <SVGIcon
                          name="preview"
                          color={
                            !documentReady
                              ? theme.palette.state.disabled_secondary
                              : theme.palette.primary.main
                          }
                        />
                      }
                      onClick={() => handlePreview()}
                      loading={
                        pdfLinkRef.current
                          ? pdfLinkRef.current.loading
                          : undefined
                      }
                      disabled={isDisabled}
                    />

                    <Buttons
                      label="Export Bookmarks"
                      variant="song"
                      color="primary"
                      startIcon={
                        <SVGIcon
                          name="export"
                          color={
                            isDisabled && documentReady
                              ? theme.palette.state.disabled_primary
                              : theme.palette.grey.black
                          }
                        />
                      }
                      style={{ marginLeft: '16px' }}
                      loading={
                        pdfLinkRef.current
                          ? pdfLinkRef.current.loading
                          : undefined
                      }
                      disabled={isDisabled}
                      onClick={() => handleDownload()}
                    />
                  </>
                ) : (
                  <>
                    <Buttons
                      label="Preview"
                      variant="song"
                      color="secondary"
                      startIcon={
                        <SVGIcon
                          name="preview"
                          color={
                            isPreviewEnabled
                              ? theme.palette.grey.btn_font_grey
                              : theme.palette.blue.link1
                          }
                        />
                      }
                      onClick={() => setPreview(true)}
                      disabled={isPreviewEnabled}
                    />
                    <Buttons
                      label={'Export Analysis'}
                      variant="song"
                      startIcon={
                        <SVGIcon
                          name="export"
                          color={
                            isDisabled
                              ? theme.palette.grey.btn_font_grey
                              : theme.palette.grey.black
                          }
                        />
                      }
                      sx={{ marginLeft: '16px' }}
                      onClick={() => handleCNSExport()}
                      disabled={isDisabled}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Stack>
          <div
            id="pdf_block"
            style={{ display: 'none', padding: '20px', whiteSpace: 'pre-line' }}
          ></div>
        </>
      )}
      {preview && (
        <ExportPreview
          isOpenClicked={preview}
          previewTypes={exportTypeSelected}
          displayText={chatHistory}
          setPreviewFlag={setPreview}
          onClickExport={() => handleCNSExport()}
        />
      )}

      {documentReady && open && (
        <Modal
          className="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handlePreview}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Fade in={open}>
            <Box
              className="modal_inner_container"
              sx={{
                width: '900px',
                height: '1200px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                borderRadius: '8px',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24
              }}
            >
              <PDFViewer
                width={700}
                height={1000}
                style={{ position: 'relative', left: '11%', top: '7%' }}
              >
                {documentReady}
              </PDFViewer>
              <SVGIcon
                onClick={() => handlePreview()}
                name="close"
                width={40}
                height={30}
                color={theme.palette.grey.black}
                style={{
                  position: 'relative',
                  left: '14%',
                  bottom: '78%',
                  zIndex: '200'
                }}
              />
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default ExportOptionsBar;