import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { GCE_IDEA_TYPES } from '../../reducers/gceActionTypes';
import theme from '../../styles/theme';
import GCECard from './GCECard';

const ideaArray = [
  'Products',
  'Campaign Concepts',
  'Events',
  'Content Ideas',
  'Other',
];

const getKeyByValue = (value) => {
  return Object.keys(GCE_IDEA_TYPES).find(
    (key) => GCE_IDEA_TYPES[key] === value
  );
};

export const GCEInitialQuestion = ({ handleCategoryPress, activePlatform, setActivePlatform }) => {

  return (
    <>
      <Typography sx={{ fontSize: 14, fontStyle: 'italic', paddingBottom: '8px' }}>
        Machine Buddy Assistance
      </Typography>
      <Grid
        container
        spacing={0}
        sx={{
          flexGrow: 1,
        }}
      >
        <Grid>
          <Avatar
            src="assets/images/avatar3.svg"
            sx={{ width: 74, height: 74 }}
          />
        </Grid>
        <Grid xs={8}>
          <Card
            sx={{
              maxWidth: 510,
              borderRadius: 4,
              boxShadow: 0,
              backgroundColor: theme.palette.secondary.blue,
            }}
          >
            <CardContent
              sx={{ padding: '16px 24px', '&:last-child': { pb: 2 } }}
            >
              <Typography paragraph sx={{ margin: 0 }}>
                What kind of ideas are you looking to generate?
              </Typography>
            </CardContent>
          </Card>

          <Grid
            sx={{
              minWidth: 600,
            }}
          >
            <Grid>
              <Typography sx={{ fontSize: 14, marginTop: 2 }}>
                Select all that apply
              </Typography>
              <Box
                margin="16px 0 24px 0"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  margin: '16px 0 8px 0',
                  gap: 3,
                }}
              >
                {ideaArray.map((idea, idx) => {
                  return (
                    <GCECard
                      key={`rad${idx}${idea}`}
                      idea={idea}
                      index={idx}
                      setActive={setActivePlatform}
                      active={activePlatform}
                      onPress={handleCategoryPress}
                    />
                  );
                })}
              </Box>

              <Typography
                sx={{
                  fontSize: 14,
                  color: '#C9D0D7',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                (Coming soon!)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const SidebarInitialQuestion = ({ ideaType }) => {
  return (
    <Box>
      <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>
        Machine Buddy Assistance
      </Typography>
      <Grid container spacing={0}>
        <Grid
          xs={8}
          sx={{
            minWidth: 300,
          }}
        >
          <Box>
            <Box
              sx={{
                justifyContent: 'left',
                display: 'flex',
                marginTop: 1,
                color: theme.palette.grey.dark,
              }}
            ></Box>
            <Card
              sx={{
                maxWidth: 574,
                borderRadius: 4,
                boxShadow: 0,
                border: `1.5px solid ${theme.palette.blue.main}`,
              }}
            >
              <CardContent
                sx={{ padding: '16px 24px', '&:last-child': { pb: 2 } }}
              >
                <Typography paragraph sx={{ margin: 0, fontSize: 18 }}>
                  What kind of ideas are you looking to generate?
                </Typography>
              </CardContent>
            </Card>
            <Box sx={{ justifyContent: 'left', display: 'flex' }}>
              <Grid container spacing={2}>
                <Grid>
                  <Typography sx={{ fontSize: 14, marginTop: 2 }}>
                    Select all that apply
                  </Typography>
                  <Box
                    margin="16px 0 24px 0"
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '24px',
                    }}
                  >
                    {ideaArray.map((idea, idx) => {
                      return (
                        <GCECard
                          key={`rad${idx}${idea}`}
                          idea={idea}
                          index={idx}
                          setActive={() => console.log('button clicked')}
                          active={getKeyByValue(ideaType)}
                        />
                      );
                    })}
                  </Box>
                  <Typography sx={{ fontSize: 14, marginTop: 2 }}>
                    Don’t see what you need? Use the response box below to type
                    what you need.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
