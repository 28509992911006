import { Box, Typography } from '@mui/material';
import React from 'react';
import Button from './Button';
import SVGIcon from './SVGIcon';

export default function HeroScrollDown(props) {
  const iconColor = ['#000', '#FFF', 'url(#paint0_radial_3103_887)'];
  const iconName = ['orb', 'circle-stroke-gradient', 'chevron-down'];

  const scrollToTools = () => {
    document.getElementById('tools_container')?.scrollIntoView();
  };

  return (
    <Box sx={{ position: 'relative', top: '-120px', left: 'calc(50% - 48px)' }}>
      <Typography
        sx={{
          zIndex: '400',
          position: 'absolute',
          color: iconColor[1],
          left: '-15px',
          top: '-30px',
        }}
        variant="body3"
      >
        Explore tools
      </Typography>
      <Button
        variants={'transparent'}
        onClick={scrollToTools}
        sx={{
          position: 'absolute',
          height: '80px',
          width: '75px',
          zIndex: '400',
          top: '500',
          left: '500',
        }}
        endIcon={
          <>
            <SVGIcon
              name={iconName[1]}
              color={iconColor[2]}
              style={{
                position: 'absolute',
                top: '-40px',
                left: '-40px',
              }}
            />
            <SVGIcon
              name={iconName[2]}
              color={iconColor[1]}
              style={{
                position: 'absolute',
                top: '28px',
                left: '25px',
              }}
            />
            <SVGIcon
              name={iconName[0]}
              width={72}
              height={72}
              style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
                borderRadius: '36px',
                opacity: 0.2,
              }}
            />
          </>
        }
      />
    </Box>
  );
}
