/* eslint-disable react/no-unknown-property */
import React from 'react';

function OutlinedAdd({ style }) {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2966_3396)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.1673 25.0001C32.1673 25.4001 31.884 25.6667 31.459 25.6667H27.209V29.6667C27.209 30.0667 26.9257 30.3334 26.5007 30.3334C26.0757 30.3334 25.7923 30.0667 25.7923 29.6667V25.6667H21.5423C21.1173 25.6667 20.834 25.4001 20.834 25.0001C20.834 24.6001 21.1173 24.3334 21.5423 24.3334H25.7923V20.3334C25.7923 19.9334 26.0757 19.6667 26.5007 19.6667C26.9257 19.6667 27.209 19.9334 27.209 20.3334V24.3334H31.459C31.884 24.3334 32.1673 24.6001 32.1673 25.0001Z"
          fill="#0573FF"
        />
        <rect
          x="6"
          y="4.50006"
          width="41"
          height="41"
          rx="20.5"
          stroke="#0573FF"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2966_3396"
          x="0.5"
          y="6.10352e-05"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0 0.117647 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2966_3396"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2966_3396"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default OutlinedAdd;
