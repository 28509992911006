import { Box, Toolbar, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import theme from '../styles/theme';
import Buttons from './Button';
import NavDrawer from './NavDrawer';
import SVGIcon from './SVGIcon';

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function Header(props) {
  const [open, setOpen] = useState(false);
  const { isLoggedIn } = props;
  const { pathname } = useLocation();
  const { pink, lightPurple, darkPurple, lightBlue } = theme.palette.gradient;

  const renderHeaderTitle = () => {
    switch (pathname) {
      case '/GCE':
        return 'Influence';
      case '/CNS':
        return 'Embedding';
      case '/research':
        return 'Interact';
      case '/MCS':
        return 'Insight';
      default:
        return ' Emotional AI';
    }
  };

  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar className="appbar">
          <Toolbar
            className="toolbar"
            elevation={0}
            sx={{
              backgroundColor: isLoggedIn
                ? `${theme.palette.primary.dark}`
                : `${theme.palette.grey.white}`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box
                className="hamburger_button_container"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <Buttons
                  className="hamburger"
                  variants={'transparent'}
                  onClick={() => setOpen(true)}
                >
                  <SVGIcon
                    name="hamburger"
                    color={
                      isLoggedIn
                        ? `${theme.palette.grey.white}`
                        : `${theme.palette.grey.black}`
                    }
                    style={{ display: 'flex', marginRight: '24px' }}
                  />
                </Buttons>
                <NavDrawer open={open} setOpen={setOpen} />
                {pathname == '/' ? (
                  <>
                    <Link
                      className="header_text_link"
                      to={!props.route ? '/' : props.route}
                      style={{
                        textDecoration: 'none',
                        color: isLoggedIn
                          ? `${theme.palette.grey.white}`
                          : `${theme.palette.grey.black}`,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="h3" component={'p'}>
                        Emotion AI{' '}
                        <span
                          style={{
                            fontFamily: 'Monteserrat',
                            marginRight: '10px',
                          }}
                        >
                          App
                        </span>
                      </Typography>
                    </Link>
                  </>
                ) : (
                  <>
                    <SVGIcon
                      name="acn-blob-gradient"
                      color={
                        isLoggedIn
                          ? `${theme.palette.grey.white}`
                          : 'url(#paint0_radial_4209_95489)'
                      }
                      height={11}
                      width={19}
                    />
                    <Typography
                      variant="h3"
                      component={'div'}
                      style={{
                        fontFamily: 'GraphikRegular',
                        marginLeft: '8px',
                      }}
                    >
                      {renderHeaderTitle()}
                    </Typography>
                  </>
                )}
              </Box>
              <Buttons
                variants={'transparent'}
                endIcon={
                  <SVGIcon
                    width={38}
                    height={38}
                    name="account-profile"
                    color={
                      isLoggedIn
                        ? `${theme.palette.grey.white}`
                        : `${theme.palette.grey.main}`
                    }
                  />
                }
              />
            </Box>
          </Toolbar>
          <Box
            sx={{
              height: '4px',
              width: '100%',
              background: isLoggedIn
                ? `linear-gradient(to right, ${pink}, ${lightPurple}, ${darkPurple}, ${lightBlue} 120%)`
                : theme.palette.grey.light,
            }}
          ></Box>
        </AppBar>
      </ElevationScroll>
    </React.Fragment>
  );
}
