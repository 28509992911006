export const GCE_ACTION_TYPES = {
  UPDATE_BOOKMARKED: 'update_book_marked',
  GENERATE_IDEAS: 'generate_ideas',
  SET_INITIAL_PARAMS: 'set_initial_params',
  SET_CHAT_HISTORY: 'set_chat_history',
  SET_IDEAS: 'set_ideas',
  UPDATE_IDEA_HISTORY: 'update_idea_history',
};

export const GCE_API_TYPES = {
  GCE_DEFAULT_QUESTIONS: 'askDefaultQuestions',
  GCE_DEFAULT_ANSWERS: 'getDefaultAnswers',
  GCE_IDEA_GEN: 'generateIdea',
  GCE_CHAT_INITIATE: 'chatInitiate',
  GCE_CHAT_CONTINUE: 'chatContinue',
  GCE_GET_DATA: 'getData',
  GCE_REFRESH_IDEAS: 'refreshIdea',
};

export const GCE_IDEA_TYPES = {
  Products: 'products',
  'Campaign Concepts': 'campaignConcepts',
  Events: 'events',
  'Content Ideas': 'contentIdeas',
};

export const fakeResponse = {
  descriptions: [
    {
      headline:
        ' Get a Free Sample of Our Energy Drink When You Sign Up for Our Email List!',
      id: 'id1',
      paragraph:
        " Our energy drink is the perfect way to give your chihuahua a boost of energy. It's made with natural ingredients and is caffeine-free, so you can feel good about giving it to your furry friend. When you sign up for our email list, we'll send you a free sample of our energy drink so you can try it for yourself. You'll also be the first to know about new products and promotions. So what are you waiting for? Sign up today!",
      title: 'Free sample',
      image: 'assets/images/idea-sample-image-1.png',
    },
    {
      headline: " Win a Year's Supply of Our Energy Drink!",
      id: 'id2',
      paragraph:
        "\n\nWe're giving away a year's supply of our energy drink to one lucky chihuahua owner! To enter, simply follow us on social media and share this post. The winner will be randomly selected and announced on [date]. Good luck!",
      title: 'Win Energy',
      chatHistory: [],
    },
    {
      headline: ' Get a free can of energy drink with your chihuahua!',
      id: 'id3',
      paragraph:
        '\n\nOur energy drink is the perfect way to give your chihuahua a boost of energy. Partnering with a local pet store to offer a special discount on our energy drink to chihuahua owners is a great way to reach new customers and get them excited about our product. This campaign will also help to build brand awareness and loyalty among chihuahua owners.',
      title: 'Drink with dog',
    },
    {
      headline:
        " Boost Your Dog's Energy with Our New Line of Energy Drink-Themed Toys!",
      id: 'id4',
      paragraph:
        "\n\nOur new line of branded dog toys and accessories is the perfect way to show your furry friend how much you love them. With a variety of toys to choose from, including balls, plush toys, and chew toys, your dog is sure to find something they love. And with our energy drink-themed designs, your dog will be feeling energized and ready to play all day long.\n\nOur toys are made from high-quality materials that are safe for your dog to chew on. They're also durable, so they can withstand even the most vigorous play sessions. And because they're machine-washable, they're easy to keep clean.\n\nSo why wait? Give your dog the gift of a lifetime with our new line of branded dog toys and accessories!",
      title: 'Energy toys',
    },
    {
      headline:
        ' Help your furry friend get energized with our new line of dog-friendly energy drinks!',
      id: 'id5',
      paragraph:
        ' Our new line of dog-friendly energy drinks is the perfect way to give your furry friend a boost of energy without the harmful ingredients found in traditional energy drinks. Made with natural ingredients, our energy drinks are safe for dogs of all ages and sizes. Plus, they come in a variety of delicious flavors that your dog will love! So why not give our dog-friendly energy drinks a try today? Your dog will thank you!',
      title: 'Energize your pet',
    },
    {
      headline:
        ' A blog or website that provides tips and advice on how to keep your chihuahua active and healthy',
      id: 'id6',
      paragraph:
        " Chihuahuas are small dogs, but they still need plenty of exercise to stay healthy and happy. A lack of exercise can lead to obesity, joint problems, and other health issues. There are many ways to keep your chihuahua active, including:\n\n Walking: Walking is a great way for chihuahuas to get exercise and explore their surroundings. Aim for at least 30 minutes of walking each day.\n Playing fetch: Fetch is a fun game that chihuahuas love. It's a great way to get them running and jumping.\n Agility training: Agility training is a great way to challenge your chihuahua mentally and physically. It involves running through a course of obstacles, such as jumps, tunnels, and weave poles.\n Swimming: Swimming is a great way for chihuahuas to cool off and get exercise. It's also a low-impact activity that's easy on their joints.\n\nBy following these tips, you can help your chihuahua stay active and healthy for many years to come.",
      title: 'Chihuahua care',
    },
    {
      headline: ' Help Chihuahuas in Need',
      id: 'id7',
      paragraph:
        " Chihuahuas are one of the most popular dog breeds in the world, but they can also be one of the most neglected. Many chihuahuas are abandoned or surrendered to shelters every year, and many more are born into puppy mills. By sponsoring a local chihuahua rescue organization or event, you can help to save the lives of these amazing dogs.\n\nThere are many ways to sponsor a chihuahua rescue organization or event. You can donate money, volunteer your time, or even foster a chihuahua in need. Every little bit helps, and every chihuahua deserves a chance to find a loving home.\n\nIf you're interested in sponsoring a chihuahua rescue organization or event, here are a few resources to help you get started:\n\n [The American Chihuahua Rescue Network](https://www.chirescue.org/)\n [The Chihuahua Rescue Organization](https://www.chirescue.org/)\n [The National Chihuahua Rescue Foundation](https://www.nationalchihuahuarescue.org/)",
      title: 'Help Chihuahuas',
    },
    {
      headline: " Boost Your Dog's Energy with These 5 Energy Drinks!",
      id: 'id8',
      paragraph:
        '\nAre you looking for a way to give your dog a boost of energy? If so, you may want to consider giving them an energy drink. Energy drinks are packed with caffeine and other stimulants that can help your dog stay active and alert. In this blog post, we will discuss the benefits of energy drinks for dogs, and we will provide you with a list of five of the best energy drinks for dogs.',
      title: 'Energy Drinks for Dogs',
    },
    {
      headline: ' Reach Chihuahua Owners with Targeted Social Media Ads',
      id: 'id9',
      paragraph:
        "\n\nChihuahuas are one of the most popular dog breeds in the United States, with over 2 million registered chihuahuas in the country. This makes chihuahua owners a valuable target audience for businesses that sell products or services related to dogs.\n\nOne way to reach chihuahua owners is to run a paid advertising campaign on social media. Social media platforms like Facebook and Instagram allow businesses to target their ads to specific demographics, interests, and behaviors. This means that businesses can ensure that their ads are seen by people who are actually interested in what they have to offer.\n\nA paid advertising campaign on social media can be a cost-effective way to reach a large number of chihuahua owners. The cost of the campaign can be tailored to the budget of the business, and the results of the campaign can be tracked and measured.\n\nIf you're a business that sells products or services related to dogs, running a paid advertising campaign on social media is a great way to reach chihuahua owners. This can help you increase brand awareness, generate leads, and drive sales.",
      title: 'Chihuahuas, Ads',
    },
    {
      headline:
        ' Get a free treat for your chihuahua when you buy an energy drink!',
      id: 'id10',
      paragraph:
        " Our chihuahua energy drink is the perfect way to give your furry friend a boost of energy. And now, we're offering a loyalty program for chihuahua owners who purchase our drink. When you buy a bottle of our energy drink, you'll receive a free treat for your chihuahua. So what are you waiting for? Get your chihuahua a treat today!",
      title: 'Treat for chihuahua',
    },
  ],
};
