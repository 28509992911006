import { Font } from '@react-pdf/renderer';

import Montserrat from '../assets/fonts/Montserrat.ttf';


Font.register({
  family: 'Montserrat',
  src: Montserrat,
});

Font.register({
  family: 'Montserrat',
  src: Montserrat,
});

Font.register({
  family: 'Montserrat',
  src: Montserrat,
});

export const documentPage = {
  body: {
    marginTop: 25,
  },
  title: {
    fontSize: 24,
    lineHeight: 1,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
  },

  author: {
    fontSize: 14,
    lineHeight: 2,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    position: 'absolute',
    top: 38,
    left: 0,
    right: 0,
    color: '#6a3cd2',
  },
  subTitle: {
    fontSize: 16,
    lineHeight: 1,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    position: 'absolute',
    letterSpacing: '1.5',
    top: 65,
    left: 0,
    right: 0,
    color: '#5c6166',
    marginBottom: 55,
  },

  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    zIndex: 100,
    textAlign: 'center',
    color: '#232323',
  },
};


export const portfolioTerStyle = {
  container: {
    marginTop: 70,
  },

  item: {
    minWidth: 600,
    minHeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingLeft: 25,
    paddingRight: 25,
    flexGrow: 1,
  },

  sectionHeading: {
    width: '100%',
    height: 100,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },

  sectionPalette: {
    width: '100%',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  sectionImage: {
    width: '100%',
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  mainImageBox: {
    width: '60%',
    height: '100%',
    border: '1px solid #000',
    borderRadius: '8px',
  },

  imageA: {
    width: '100%',
    height: '600px',
    objectFit: 'cover',
    border: '1px solid #000',
    borderRadius: '8px',
  },

  stackImageBox: {
    width: '40%',
    height: '100%',
    border: '1px solid #000',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },

  imageB: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
  },

  imageC: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
  },

  largeText: {
    fontSize: 20,
    lineHeight: 1,
    letterSpacing: 0.8,
    fontFamily: 'Montserrat',
    color: '#063970',
  },

  mutedText: {
    fontSize: 14,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
    color: '#454545',
  },

  mediumText: {
    fontSize: 16,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
  },

  text: {
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
  },
};


export const documentPageStyle = {
  body: {
    margin: '0 auto',
  },
  title: {
    fontSize: 24,
    lineHeight: 1,
    textAlign: 'center',
    fontFamily: 'GraphikSemibold',
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
  },

  author: {
    fontSize: 14,
    lineHeight: 2,
    textAlign: 'center',
    fontFamily: 'GraphikRegular',
    position: 'absolute',
    top: 38,
    left: 0,
    right: 0,
    color: '#6a3cd2',
  },
  subTitle: {
    fontSize: 16,
    lineHeight: 1,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    position: 'absolute',
    letterSpacing: '1.5',
    top: 65,
    left: 0,
    right: 0,
    color: '#5c6166',
    marginBottom: 55,
  },

  pageNumberBox: {
    position: 'relative',
    height: '200px',
    bottom: 5,
    left: 0,
    right: 0,
    zIndex: 100,
  },

  pageNumber: {
    fontSize: 12,
    textAlign: 'center',
    color: '#232323',
  },
};

export const assetStyle = {
  container: {
    marginTop: 70,
  },

  item: {
    minWidth: 600,
    minHeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingLeft: 25,
    paddingRight: 25,
    flexShrink: 1,
  },

  sectionImage: {
    width: '100%',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    flexWrap: 'auto',
  },

  sectionText: {
    width: '100%',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    flexWrap: 'auto',
  },

  imageA: {
    width: 450,
    height: '80%',
    objectFit: 'cover',
    border: '1px solid #000',
    borderRadius: '8px',
  },

  largeText: {
    fontSize: 20,
    lineHeight: 1,
    letterSpacing: 0.8,
    fontFamily: 'Montserrat',
    color: '#063970',
  },

  mediumText: {
    fontSize: 16,
    lineHeight: 1,
    fontFamily: 'GraphikRegular',
    letterSpacing: 0.5,
  },

  text: {
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'GraphikRegular',
    letterSpacing: 0.5,
    textAlign: 'left',
  },
};

export const mockStyle = {
  container: {
    marginTop: 70,
  },

  item: {
    minWidth: 600,
    minHeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingLeft: 25,
    paddingRight: 25,
    flexGrow: 1,
  },

  sectionHeading: {
    width: '60%',
    minHeight: 300,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-around',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'auto',
  },

  sectionImage: {
    width: '100%',
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },

  imageA: {
    width: 500,
    height: '100%',
    objectFit: 'cover',
    border: '1px solid #000',
    borderRadius: '8px',
  },

  largeText: {
    fontSize: 20,
    lineHeight: 1,
    letterSpacing: 0.8,
    fontFamily: 'Montserrat',
    color: '#063970',
  },

  mutedText: {
    fontSize: 14,
    lineHeight: 1,
    fontFamily: 'GraphikRegular',
    letterSpacing: 0.5,
    color: '#454545',
  },

  mediumText: {
    fontSize: 16,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
  },

  text: {
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
  },
};


export const territoryStyle = {
  container: {
    marginTop: 70,
  },

  item: {
    minWidth: 600,
    minHeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingLeft: 25,
    paddingRight: 25,
    flexGrow: 1,
  },

  sectionHeading: {
    width: '100%',
    height: 100,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },

  sectionPalette: {
    width: '100%',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  sectionImage: {
    width: '100%',
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  mainImageBox: {
    width: '60%',
    height: '100%',
    border: '1px solid #000',
    borderRadius: '8px',
  },

  imageA: {
    width: '100%',
    height: '600px',
    objectFit: 'cover',
    border: '1px solid #000',
    borderRadius: '8px',
  },

  stackImageBox: {
    width: '40%',
    height: '100%',
    border: '1px solid #000',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },

  imageB: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
  },

  imageC: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
  },

  largeText: {
    fontSize: 20,
    lineHeight: 1,
    letterSpacing: 0.8,
    fontFamily: 'Montserrat',
    color: '#063970',
  },

  mutedText: {
    fontSize: 14,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
    color: '#454545',
  },

  mediumText: {
    fontSize: 16,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
  },

  text: {
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
  },
};

export const gceModalStyle = {
  container: {
    position: 'relative',
    top: 100,
    left: 0,
    right: 0,
    paddingTop: 30,
    paddingBottom: 30,
  },

  item: {
    minWidth: 450,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },

  sectionImage: {
    width: '80%',
    height: 250,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 30,
    flexShrink: 1,
  },
  image: {
    maxWidth: 250,
    maxHeight: '100%',
    objectFit: 'cover',
    border: '1px solid #000',
  },

  textBox: {
    width: '80%',
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 1,
    flexWrap: 'auto',
    paddingTop: 30,
  },
  text: {
    fontSize: 12,
    lineHeight: 1,
    textAlign: 'justify',
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
    marginBottom: 12,
  },

  mediumText: {
    fontSize: 12,
    lineHeight: 2,
    textAlign: 'justify',
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
    marginBottom: 12,
  },

  largeText: {
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: 0.8,
    fontFamily: 'Montserrat',
    color: '#063970',
    textAlign: 'center',
    marginBottom: 10,
  },
};

export const gceStyle = {
  container: {
    paddingTop: 50,
    position: 'relative',
    top: 100,
    left: 0,
    right: 0,
    paddingBottom: 30,
  },

  item: {
    minWidth: 400,
    maxHeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },

  sectionImage: {
    width: 260,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: '20',
  },

  textWrapper: {
    width: '60%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '5px',
    flexShrink: 1,
  },
  text: {
    fontSize: 12,
    lineHeight: 1,
    textAlign: 'justify',
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
    marginBottom: 12,
  },

  mediumText: {
    fontSize: 12,
    lineHeight: 2,
    textAlign: 'justify',
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
    marginBottom: 12,
  },

  largeText: {
    fontSize: 16,
    lineHeight: 1,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    marginBottom: 10,
  },
  image: {
    minWidth: '100%',
    height: '300px',
    objectFit: 'cover',
    display: 'block',
    border: '1px solid #000000',
  },
};

export const uxraStyle = {
  container: {
    paddingTop: 5,
    position: 'relative',
    top: 90,
    left: 0,
    right: 0,
    paddingBottom: 10,
    marginBottom: 10,
  },

  header: {
    width: '100%',
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '5px 5px',
    backgroundColor: '#f3f3f3',
    borderBottom: 20,
    flexGrow: 1,
  },

  main: {
    marginTop: 10,
    maxWidth: 600,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexShrink: 1,
  },

  sectionIntro: {
    width: 600,
    maxHeight: 400,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: 15,
    flexWrap: 'auto',
  },

  headingBar: {
    width: '70%',
    display: 'flex',
    maxHeight: '80%',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'space-around',
    borderBottom: 1,
    padding: '5px 10px',
    flexWrap: 'auto',
    flexGrow: 1,
  },

  questionBox: {
    display: 'flex',
    width: '70%',
    flexDirection: 'column',
    alignItems: 'left',
    flexWrap: 'auto',
    flexGrow: 1,
    marginBottom: 30,
    marginTop: 30,
    backgroundColor: '#02054E',
    padding: '10px 25px',
    borderRadius: '10%',
  },

  answerBox: {
    display: 'flex',
    width: '70%',
    flexDirection: 'column',
    alignItems: 'left',
    flexGrow: 1,
    paddingBottom: 15,
    marginBottom: 10,
  },

  nameText: {
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: 2,
    fontFamily: 'Montserrat',
    color: '#063970',
    textAlign: 'left',
    marginBottom: 10,
  },

  describeText: {
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    textAlign: 'left',
  },

  answerText: {
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    textAlign: 'left',
    color: '#02054E',
    marginBottom: 10,
  },

  questionText: {
    fontSize: 12,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 1.2,
    textAlign: 'justify',
    color: '#FFFFFF',
  },

  sectionInterview: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    outline: 2,
    background: 'pink',
  },

  colSpacer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 1,
  },

  questionBar: {
    position: 'relative',
    width: 400,
    top: 75,
    left: 0,
    right: 0,
    zIndex: 150,
  },

  answerBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
  },
  text: {
    fontSize: 14,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    letterSpacing: 0.5,
    padding: '5px 0',
  },

  smallText: {
    fontSize: 12,
    lineHeight: 2,
    fontFamily: 'Montserrat',
    padding: '2px 0',
    textAlign: 'left',
  },

  mediumText: {
    fontSize: 14,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    padding: '12px 0',
    textAlign: 'center',
  },

  footerText: {
    fontSize: 12,
    fontFamily: 'Montserrat',
    textAlign: 'left',
    letterSpacing: 1.25,
    marginTop: 5,
    color: '#5c6166',
  },
  spacer: {
    display: 'block',
    width: '100%',
    maxHeight: 120,
    borderTop: '1px solid #FFF',
  },
};
