import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from '@mui/material';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import theme from "../styles/theme";

const CollapseCard = ({ content, header }) => {
  const [open, setOpen] = useState(false);
  content = !content ? 'No data available!' : content;
  header = !header ? 'No data available!' : header;
  return (
    <Card sx={{
      minWidth: 280,
      width: '100%',
      padding: '20px 15px',
    }}>
      <CardHeader
        aria-label="accordian-header"
        title={header}
        action={
          <IconButton
            onClick={() => setOpen(!open)}
            aria-label="expand"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon />
              : <KeyboardArrowDownIcon />}
          </IconButton>
        }
      ></CardHeader>
      <Box sx={{
        backgroundColor: theme.palette.grey.white
      }}>
        <Collapse in={open} timeout="auto"
          unmountOnExit>
          <CardContent aria-label="accordian-content">
            <Container sx={{
              minHeight: 100,
              height: '100%',
              lineHeight: 2,
              whiteSpace: 'pre-line'
            }}>
              {content}
            </Container>
          </CardContent>
        </Collapse>
      </Box>
    </Card>

  );
}

CollapseCard.propTypes = {
  content: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired
};

export default CollapseCard;
