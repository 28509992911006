export const navLinks = [
  {
    text: 'Interaction Simulation',
    url: 'https://www.botski.info/MCS',
  },

  {
    text: 'Influence Visualization',
    url: 'https://www.botski.info/research',
  },
  {
    text: 'Insight Projection',
    url: 'https://www.botski.info/GCE',
  },
  {
    text: 'Journey Mapping',
    url: '#',
  },
  {
    text: 'Know Thyself',
    url: '#',
  },
  // {
  //   text: 'Machine Creative Studio',
  //   url: 'https://mcs.songassets.accenture.com/MCS',
  // },
  // {
  //   text: 'Agent Ally',
  //   url: 'https://agentally.songassets.accenture.com/login',
  // },
  // {
  //   text: 'SMB Classification',
  //   url: 'https://leadsummarizer.ai4ccrisp.accentureanalytics.com/login',
  // },
];
