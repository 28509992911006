import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { defaultInspectSliderInputsValue } from '../../assets/data/constants/assetWorkspaceData';
import AlertToasterBanner from '../../components/AlertToasterBannner';
import Buttons from '../../components/Button';
import AssetCard from '../../components/MCS2/AssetCard';
import AssetSearch from '../../components/MCS2/AssetSearch';
import AssetsDetailsCard from '../../components/MCS2/AssetsDetailsCard';
import ExportPortfolio from '../../components/MCS2/ExportPortfolio';
import FacebookMock from '../../components/MCS2/FacebookMock';
import InstagramMock from '../../components/MCS2/InstagramMock';
import LinkedInMock from '../../components/MCS2/LinkedInMock';
import { TwitterMock } from '../../components/MCS2/TwitterMock';
import WorkspaceArea from '../../components/MCS2/WorkspaceArea';
import YoutubeMock from '../../components/MCS2/YoutubeMock';
import { MCS2Context, MCS2DispatchContext } from '../../context/MCS2Context';
import { useMachineCreativeStudioV2 } from '../../hooks/useMachineCreativeStudioV2';
import { imageArray } from '../../mocks/MCSV2Data';
import { MCSV2_ACTION_TYPES } from '../../reducers/mcsv2ActionTypes';
import theme from '../../styles/theme';

const StyledTab = styled('button')(({ theme }) => ({
  background: theme.palette.grey.white,
  border: 'none',
  flexGrow: 1,
  cursor: 'pointer',
  ...theme.typography.body3,
}));

const AssetWorkSpace = () => {
  const {
    userId,
    currentProject,
    generatedImageAssets,
    generatedTextAssets,
    assets,
    portfolio,
    portfolioAsset,
  } = useContext(MCS2Context);

  const dispatch = useContext(MCS2DispatchContext);
  const { mutateAsync, isLoading, isError } = useMachineCreativeStudioV2();
  const [savedPortfolio, setSavedPortfolio] = useState(portfolioAsset);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [settingsView, setSettingsView] = useState(false);
  const [editMode, setEditMode] = useState('home');
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedPortfolioAsset, setSelectedPortfolioAsset] = useState([]);
  const [portfolioFilter, setPortfolioFilter] = useState('');
  const [showExportPortfolio, setShowExportPortfolio] = useState(false);
  const [shouldClearPortfolios, setShouldClearPortfolios] = useState(false);
  const [iterateText, setIterateText] = useState('');
  const [blendText, setBlendText] = useState('');
  const [requestType, setRequestType] = useState('');
  const [isNew, setIsNew] = useState(true);

  const [selectedTextAssets, setTextSelectedAssets] = useState([]);
  const [editedTerritory, setEditedTerritory] = useState();

  //Individual image or text, and mockup Save to Portfolio
  const [savedImageAsset, setSavedImageAsset] = useState([]);
  const [savedTextAsset, setSavedTextAsset] = useState([]);
  const [savedMockupAsset, setSavedMockupAsset] = useState([]);

  const [isAlert, setIsAlert] = useState(false);
  const [alertBanners, setAlertBanners] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [inspectsliderInputsText, setinspectSliderInputsText] = useState('');
  const [imageNotLoaded, setImageNotLoaded] = useState(true);
  // initial image search for displayedContent
  const initialImageSearch = {
    contentType: 'search',
    type: 'image',
    refinementText: assets.creativeIdea,
    searchType: 'original',
    platform: assets.creativeCanvas,
  };
  // initial text search for displayedContent
  const initialTextSearch = {
    contentType: 'search',
    type: 'text',
    refinementText: assets.creativeIdea,
    searchType: 'original',
    platform: assets.creativeCanvas,
  };

  const [displayedContent, setDisplayedContent] = useState([
    initialImageSearch,
    ...generatedImageAssets,
    initialTextSearch,
    ...generatedTextAssets,
  ]);

  const [secondaryAsset, setSecondaryAsset] = useState();

  const tabRefs = useRef({});
  const assetsRefs = useRef({});
  const assetsTextRefs = useRef({});
  const assetsDetailsRefs = useRef(null);

  const creativeCanvasComponents = {
    instagram_post: InstagramMock,
    linkedin_post: LinkedInMock,
    facebook_post: FacebookMock,
    youtube_thumbnail_post: YoutubeMock,
    twitter_post: TwitterMock,
  };

  // initial ping for images retry logic
  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = async () => {
    // get the three most recent image assets
    let modifiedContent = displayedContent.slice(1, 4);
    const images = await pingForMaterials([
      generatedImageAssets[0].id,
      generatedImageAssets[1].id,
      generatedImageAssets[2].id,
    ]);
    let updatedContent = modifiedContent.map((item, i) => ({
      ...item,
      content: {
        image: images[i],
      },
    }));
    const updatedAssets = [...updatedContent, ...generatedImageAssets.slice(3)];
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_IMAGE_ASSETS,
      payload: updatedAssets,
    });

    // add new images to displayedContent
    setDisplayedContent([
      displayedContent[0],
      ...updatedContent,
      ...displayedContent.slice(4),
    ]);
  };

  async function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const pingForMaterials = async (assetList) => {
    await delay(50000);
    try {
      // setup payload
      const payload = {
        userId,
        projectId: currentProject.projectId,
        assetIdList: assetList,
        requestType: 'getPresignedURLs',
      };
      const { presignedUrlList } = await mutateAsync(payload);
      if (presignedUrlList) {
        setImageNotLoaded(false);
      }
      return presignedUrlList;
    } catch (e) {
      console.log('Error fetching presigned URLs, using mock images: ', e);
      setImageNotLoaded(true);
      return imageArray;
    }
  };

  const iterateAndBlendText = (
    requestType,
    assetId1,
    assetId2,
    directionText,
    fileText = []
  ) => {
    setImageNotLoaded(true);
    const payload = {
      userId,
      projectId: currentProject.projectId,
      projectName: currentProject.projectName,
      requestType,
      directionText,
      assetIdList:
        requestType === 'blend' && !fileText.length
          ? [assetId1, assetId2]
          : [assetId1],
      fileText,
    };

    const searchCriteria = {
      contentType: 'search',
      type: 'text',
      refinementText: directionText,
      searchType: requestType,
      platform: assets.creativeCanvas,
      inputAssets: generatedTextAssets.filter(
        (asset) => asset.id == assetId1 || asset.id == assetId2
      ),
    };

    fetchNewTextAssets(payload, searchCriteria);
  };

  const fetchNewTextAssets = async (payload, searchCriteria) => {
    try {
      setshowLoader(true);
      const { assetid, text_response } = await mutateAsync(payload);

      // save the new assets to context
      const textAssets = [
        {
          id: assetid[0],
          type: 'text',
          content: {
            text: text_response[0],
          },
        },
        {
          id: assetid[1],
          type: 'text',
          content: {
            text: text_response[1],
          },
        },
        {
          id: assetid[2],
          type: 'text',
          content: {
            text: text_response[2],
          },
        },
      ];

      // dispatch new text assets into context
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_TEXT_ASSETS,
        payload: [...textAssets, ...generatedTextAssets],
      });
      setImageNotLoaded(false);

      setDisplayedContent([searchCriteria, ...textAssets, ...displayedContent]);

      setEditMode(null);
      setshowLoader(false);
    } catch (e) {
      setshowLoader(false);
      console.log(`Error on request: ${payload.requestType}`, e);
    }
  };

  const iterateImages = (assetId1, assetId2, directionText, sliderValues) => {
    setImageNotLoaded(true);
    const payload = {
      userId,
      projectId: currentProject.projectId,
      requestType: 'iterateAsset',
      assetIdList: assetId2 ? [assetId1, assetId2] : [assetId1],
      directionText,
      refineResults: sliderValues ?? '',
    };
    const searchCriteria = {
      contentType: 'search',
      type: 'image',
      refinementText: directionText,
      searchType: 'iterate',
      platform: assets.creativeCanvas,
      inputAssets: generatedImageAssets.filter(
        (asset) => asset.id == assetId1 || asset.id == assetId2
      ),
    };
    fetchNewImageAssets(payload, searchCriteria);
  };

  const blendImages = (assetId1, assetId2, directionText, blendImage = []) => {
    setImageNotLoaded(true);
    const payload = {
      userId,
      projectId: currentProject.projectId,
      requestType: 'blendAsset',
      assetIdList: blendImage.length > 0 ? [assetId1] : [assetId1, assetId2],
      directionText,
      blendImage,
    };
    const searchCriteria = {
      contentType: 'search',
      type: 'image',
      refinementText: directionText,
      searchType: 'blend',
      platform: assets.creativeCanvas,
      inputAssets: generatedImageAssets.filter(
        (asset) => asset.id == assetId1 || asset.id == assetId2
      ),
    };
    fetchNewImageAssets(payload, searchCriteria);
  };

  const fetchNewImageAssets = async (payload, searchCriteria) => {
    try {
      setshowLoader(true);
      const { assetIdListNew } = await mutateAsync(payload);

      // save the new assets to context
      const assetData = [
        {
          id: assetIdListNew[0],
          type: 'image',
          content: {
            image: '',
          },
        },
        {
          id: assetIdListNew[1],
          type: 'image',
          content: {
            image: '',
          },
        },
        {
          id: assetIdListNew[2],
          type: 'image',
          content: {
            image: '',
          },
        },
      ];

      // dispatch new image into context
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_IMAGE_ASSETS,
        payload: [...assetData, ...generatedImageAssets],
      });

      const currentContent = displayedContent;
      setDisplayedContent([searchCriteria, ...assetData, ...currentContent]);
      setshowLoader(false);
      const urls = await pingForMaterials(assetIdListNew);
      const assetsWithImages = assetData.map((asset, i) => ({
        ...asset,
        content: {
          image: urls[i],
        },
      }));

      setDisplayedContent([
        searchCriteria,
        ...assetsWithImages,
        ...currentContent,
      ]);
    } catch (e) {
      setshowLoader(false);
      console.log(`Error on request: ${payload.requestType}`, e);
    }
  };

  const renderSavePortfolios = () => {
    const isSelectedStyle = {
      background: theme.palette.primary.main,
      color: theme.palette.grey.white,
    };
    const filterHeader = () => {
      return (
        <Box>
          <Grid2 container>
            <Grid2 item xs={6}>
              <Typography variant="h4">Select and Filter Options</Typography>
            </Grid2>
            <Grid2 item xs={6}>
              <Box sx={{ float: 'right' }}>
                <Button
                  sx={{
                    marginTop: -1,
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    setPortfolioFilter('');
                  }}
                  disabled={portfolioFilter === ''}
                >
                  Clear Filter(s)
                </Button>
              </Box>
            </Grid2>
          </Grid2>
          <Grid2 container width={'100%'} sx={{ paddingTop: 2 }}>
            <Grid item xs={4} width={'33%'}>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  sx={{
                    paddingX: 8,
                    paddingY: 4,
                    border: `1.5px solid ${theme.palette.primary.main}`,
                    width: 200,
                    background:
                      portfolioFilter === 'images'
                        ? theme.palette.primary.main
                        : '',
                    color:
                      portfolioFilter === 'images'
                        ? theme.palette.grey.white
                        : '',
                  }}
                  onClick={() => setPortfolioFilter('images')}
                  disabled={savedImageAsset.length === 0}
                >
                  Images{' '}
                  {savedImageAsset.length > 0
                    ? ` (${savedImageAsset.length})`
                    : '(0)'}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4} width={'33%'}>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  sx={{
                    paddingX: 8,
                    paddingY: 4,
                    border: `1.5px solid ${theme.palette.primary.main}`,
                    width: 200,
                    background:
                      portfolioFilter === 'text'
                        ? theme.palette.primary.main
                        : '',
                    color:
                      portfolioFilter === 'text'
                        ? theme.palette.grey.white
                        : '',
                  }}
                  onClick={() => setPortfolioFilter('text')}
                  disabled={savedTextAsset.length === 0}
                >
                  Text{' '}
                  {savedTextAsset.length > 0
                    ? `(${savedTextAsset.length})`
                    : '(0)'}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4} width={'33%'}>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  sx={{
                    paddingX: 8,
                    paddingY: 4,
                    border: `1.5px solid ${theme.palette.primary.main}`,
                    width: 200,
                    background:
                      portfolioFilter === 'mockups'
                        ? theme.palette.primary.main
                        : '',
                    color:
                      portfolioFilter === 'mockups'
                        ? theme.palette.grey.white
                        : '',
                  }}
                  onClick={() => setPortfolioFilter('mockups')}
                  disabled={savedMockupAsset.length === 0}
                >
                  Mockups{' '}
                  {savedMockupAsset.length > 0
                    ? `(${savedMockupAsset.length})`
                    : '(0)'}
                </Button>
              </Box>
            </Grid>
          </Grid2>
        </Box>
      );
    };
    if (savedPortfolio.length > 0) {
      return (
        <Box width={'100%'} sx={{ paddingX: 4 }}>
          {filterHeader()}
          <Box sx={{ marginTop: 5 }}>
            {(portfolioFilter === 'images' || portfolioFilter === '') && (
              <>
                <Box
                  sx={{
                    paddingY: 6,
                    borderTop: `solid 1px ${theme.palette.grey.light2}`,
                  }}
                >
                  <Typography variant="h3">Images</Typography>
                </Box>
                <Box>
                  <Grid2 container>
                    {savedImageAsset?.map((el) => (
                      <Grid2 item xs={6} key={`image_key_${el?.id}`}>
                        <Box sx={{ width: '100%' }}>
                          <AssetCard
                            width={'100%'}
                            height={'100%'}
                            type="image"
                            content={el?.content}
                            handleClick={() => { }}
                            isPortfolio={true}
                            handleAssetRemoveClick={() => {
                              removeFromPortfolio(el);
                            }}
                            imageNotLoaded={imageNotLoaded}
                          />
                        </Box>
                      </Grid2>
                    ))}
                  </Grid2>
                </Box>
              </>
            )}
            {(portfolioFilter === 'text' || portfolioFilter === '') && (
              <>
                <Box
                  sx={{
                    paddingY: 6,
                    borderTop: `solid 1px ${theme.palette.grey.light2}`,
                  }}
                >
                  <Typography variant="h3">Text</Typography>
                </Box>
                <Box>
                  <Grid2 container>
                    {savedTextAsset?.map((el) => (
                      <Grid2 item xs={6} key={`text_key_${el?.id}`}>
                        <AssetCard
                          width={'100%'}
                          type="text"
                          content={el?.content}
                          height={'100%'}
                          isPortfolio={true}
                          handleAssetRemoveClick={() => {
                            removeFromPortfolio(el);
                          }}
                          imageNotLoaded={false}
                        />
                      </Grid2>
                    ))}
                  </Grid2>
                </Box>
              </>
            )}
            {(portfolioFilter === 'mockups' || portfolioFilter === '') && (
              <>
                <Box
                  sx={{
                    paddingY: 6,
                    borderTop: `solid 1px ${theme.palette.grey.light2}`,
                  }}
                >
                  <Typography variant="h3">Mockup</Typography>
                </Box>
                <Box>
                  <Grid2 container>{renderMockupResult()}</Grid2>
                </Box>
              </>
            )}
          </Box>
        </Box>
      );
    }
    return (
      <Typography
        variant={'subtitle1'}
        component={'div'}
        sx={{ marginBottom: '1rem' }}
      >
        There is currently nothing saved in your Portfolio.
      </Typography>
    );
  };

  const handleADCOptionsClick = (data) => {
    switch (data) {
      case 'blend':
        setEditMode('blend');
        break;
      case 'iterate':
        setEditMode('iterate');
        break;
      case 'mockup':
        setEditMode('mockup');
        break;
      case 'mockup-result':
        setEditMode('mockup-result');
        break;
      case 'home':
        setEditMode('home');
        break;
      default:
        break;
    }
  };

  const handleExitStartOver = () => {
    dispatch({
      type: MCSV2_ACTION_TYPES.CHOSEN_WORKSPACE,
      payload: { workspace: '' },
    });
  };

  const onKeyDown = (event) => {
    const count = 2;
    const nextTab = () => setIndex(selectedIndex + 1);
    const prevTab = () => setIndex(selectedIndex - 1);
    const firstTab = () => setIndex(0);
    const lastTab = () => setIndex(count - 1);

    const keyMap = {
      ArrowRight: nextTab,
      ArrowLeft: prevTab,
      Home: firstTab,
      End: lastTab,
    };

    const action = keyMap[event.key];
    if (action) {
      event.preventDefault();
      action();
    }
  };

  const handleTabChange = (newValue) => {
    setSelectedIndex(newValue);

    if (selectedIndex === 2) {
      setSettingsView(0);
    }

    if (newValue == 1) {
      setSavedPortfolio(savedPortfolio.filter((d) => d.bigCard != 'bigCard'));
      setSelectedPortfolioAsset([]);
    }
  };

  const handleAssetClick = (i, elm) => {
    setSecondaryAsset(null);
    if (editMode === 'mockup-result') handleADCOptionsClick('home');
    if (
      editMode === 'blend' &&
      ((elm.content.image && selectedAssets[0].content.image) ||
        (elm.content.text && selectedAssets[0].content.text))
    ) {
      setSecondaryAsset(elm);
    } else if (
      editMode === 'mockup' &&
      elm.content.text &&
      selectedAssets[0].content.image
    ) {
      setSecondaryAsset(elm);
    } else if (
      editMode === 'mockup' &&
      elm.content.image &&
      selectedAssets[0].content.text
    ) {
      setSecondaryAsset(elm);
    } else {
      const refinedData = displayedContent.filter(
        (d) => d.bigCard != 'bigCard'
      );
      const actualIndex = refinedData.findIndex((asset) => {
        return asset.id === displayedContent[i].id;
      });

      if (assetsRefs.current[i] || assetsDetailsRefs.current[i]) {
        const newSelectedAsset = [...selectedAssets];
        const newSelectedTextAsset = [...selectedTextAssets];
        showAssetsDetail(refinedData, actualIndex, elm);

        newSelectedAsset[0] = displayedContent[i];
        setSelectedAssets(newSelectedAsset);
      }
      setTimeout(() => {
        assetsDetailsRefs.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  };

  const handleCloseDetailsCard = () => {
    const cleanedContent = displayedContent.filter(
      (d) => d.bigCard != 'bigCard'
    );
    setDisplayedContent(cleanedContent);
    setEditMode(null);
    setSelectedAssets([]);
  };

  const giveMeMore = async (type) => {
    setImageNotLoaded(true);
    if (type === 'image') {
      const imagePayload = {
        userId,
        projectId: currentProject.projectId,
        projectName: currentProject.projectName,
        territoryId: assets.territoryId || '',
        creativeIdea: assets.creativeIdea || '',
        refImagery: assets.imagery,
        refMaterials: [],
        refTone: assets.tone,
        refColors: assets.colors,
        requestType: 'assetGenImg',
        creativeCanvas: assets.creativeCanvas,
      };
      const searchCriteria = {
        contentType: 'search',
        type: 'image',
        refinementText: assets.creativeIdea,
        searchType: 'search',
        platform: assets.creativeCanvas,
      };

      fetchNewImageAssets(imagePayload, searchCriteria);
    } else {
      const textPayload = {
        userId,
        projectId: currentProject.projectId,
        projectName: currentProject.projectName,
        creativeIdea: assets.creativeIdea || '',
        creativeCanvas: assets.creativeCanvas,
        territoryId: assets.territoryId || '',
        requestType: 'assetGentext',
        directionText: '',
        refineResults: '',
      };

      const searchCriteria = {
        contentType: 'search',
        type: 'text',
        refinementText: assets.creativeIdea,
        searchType: 'search',
        platform: assets.creativeCanvas,
      };

      fetchNewTextAssets(textPayload, searchCriteria);
    }

    setIsNew(false);
    setEditMode(null);
  };

  const generateMore = async (
    requestType,
    canvas,
    directionText,
    sliderValues,
    userInput,
    isNewFlag,
    sliderInputsText
  ) => {
    setImageNotLoaded(true);
    //this is for passing directionText to assetSearch
    setshowLoader(true);
    //this is for passing directionText to territorySearch
    if (requestType === 'iterateAsset') {
      setIterateText(directionText);
    } else {
      setBlendText(directionText);
    }
    setRequestType(requestType);
    setinspectSliderInputsText(sliderInputsText);

    const textPayload = {
      userId,
      projectId: currentProject.projectId,
      projectName: currentProject.projectName,
      creativeIdea: assets.creativeIdea || '',
      creativeCanvas: canvas || assets.creativeCanvas,
      territoryId: assets.territoryId || '',
      requestType: 'assetGentext',
      directionText: directionText,
      refineResults: sliderValues ?? '',
    };
    const textSearchCriteria = {
      contentType: 'search',
      type: 'text',
      refinementText: directionText,
      searchType: 'search',
      platform: canvas || assets.creativeCanvas,
    };

    let textAssetData = [];
    // fetch new text responses
    let { assetid, text_response } = await mutateAsync(textPayload);
    for (let i = 0; i < assetid.length; i++) {
      textAssetData.push({
        id: assetid[i],
        type: 'text',
        content: {
          text: text_response[i],
        },
      });
    }
    // fetch new images
    const imagePayload = {
      userId,
      projectId: currentProject.projectId,
      assetIdList: generatedImageAssets.map((x) => x.id),
      requestType: 'iterateAsset',
      directionText: directionText,
      refineResults: sliderValues ?? '',
    };
    const imageSearchCriteria = {
      contentType: 'search',
      type: 'image',
      refinementText: directionText,
      searchType: 'search',
      platform: canvas || assets.creativeCanvas,
    };
    let imageAssetData = [];
    let { assetIdListNew } = await mutateAsync(imagePayload);
    for (let i = 0; i < assetIdListNew.length; i++) {
      imageAssetData.push({
        id: assetIdListNew[i],
        type: 'image',
        content: {
          image: '',
        },
      });
    }

    // display temporary content with loading spinners
    const currentContent = displayedContent;
    setDisplayedContent([
      imageSearchCriteria,
      ...imageAssetData,
      textSearchCriteria,
      ...textAssetData,
      ...currentContent,
    ]);
    setshowLoader(false);
    const urls = await pingForMaterials(assetIdListNew);
    const assetsWithImages = imageAssetData.map((asset, i) => ({
      ...asset,
      content: {
        image: urls[i],
      },
    }));

    // display final content with images
    setDisplayedContent([
      imageSearchCriteria,
      ...assetsWithImages,
      textSearchCriteria,
      ...textAssetData,
      ...currentContent,
    ]);
    // dispatch new assets into context
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_TEXT_ASSETS,
      payload: [...textAssetData, ...generatedTextAssets],
    });
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_IMAGE_ASSETS,
      payload: [...assetsWithImages, ...generatedImageAssets],
    });

    setIsNew(isNewFlag);
  };

  const handleRemoveAsset = async (data, type) => {
    if (type === 'image') {
      let tempArr = generatedImageAssets.filter(
        (asset) => asset.id !== data.id
      );
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_IMAGE_ASSETS,
        payload: tempArr,
      });
    } else {
      let tempArr = generatedTextAssets.filter((asset) => asset.id !== data.id);
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_TEXT_ASSETS,
        payload: tempArr,
      });
    }
    // update displayedContent
    let tempDisplayedContent = displayedContent.filter(
      (asset) => asset.id !== data.id
    );
    setDisplayedContent(tempDisplayedContent);
  };

  // fetch images & text assest for portfolio on initial load
  useEffect(() => {
    loadPortfolioImages();
  }, []);

  const loadPortfolioImages = async () => {
    let portfolioAssetData = [];
    let portfolioImageData = [];
    let portfolioTextData = [];
    const payload = {
      userId,
      projectName: currentProject.projectName,
      projectId: currentProject.projectId,
      assetId: [],
      action: 'fetch',
      requestType: 'projectMgmtAsset',
    };
    try {
      const { projects } = await mutateAsync(payload);
      const newProject = projects.find(
        (each) => each.projectId === currentProject.projectId
      );
      newProject?.asset_data?.forEach((each) => {
        if (Object.keys(each[Object.keys(each)[0]]).includes('s3URIs')) {
          portfolioImageData.push({
            id: Object.keys(each)[0],
            type: 'image',
            content: {
              image: each[Object.keys(each)[0]]['s3URIs'],
            },
          });
        } else {
          portfolioTextData.push({
            id: Object.keys(each)[0],
            type: 'text',
            content: {
              text: each[Object.keys(each)[0]]['text_response'],
            },
          });
        }
      });
      let portfolioWithImages = [];
      // fetch images one by one
      for await (const portfolioItem of portfolioImageData) {
        const payload = {
          userId,
          projectId: currentProject.projectId,
          // list of territory IDs we are seeking materials for
          assetIdList: [portfolioItem.id] || [],
          requestType: 'getPresignedURLs',
        };
        try {
          const { presignedUrlList } = await mutateAsync(payload);
          const tempPortfolioItem = {
            id: portfolioItem.id,
            type: 'image',
            content: {
              image: presignedUrlList[0],
            },
          };
          portfolioWithImages.push(tempPortfolioItem);
        } catch (e) {
          console.log(
            `Error loading content for portfolio territory ${portfolioItem.header}: `,
            e
          );
        }
      }
      let getSavedMockup = [...portfolioAsset].filter(
        (data) => data.imgDesc && data.imgSrc
      );
      if (portfolioWithImages)
        portfolioAssetData = [
          ...portfolioWithImages,
          ...portfolioTextData,
          ...getSavedMockup,
        ];
      else {
        portfolioAssetData = [...portfolioTextData, ...getSavedMockup];
      }
    } catch (e) {
      console.log(`Error loading content for portfolio asset: `, e);
    }
    //update portfolio with new asset
    // savedPortfolio automatically changes when we update portfolio in context
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_PORTFOLIO_ASSET,
      payload: portfolioAssetData,
    });
  };

  const removeFromPortfolio = async (displayedContent, isClearAll) => {
    const tempPortfolio = savedPortfolio.filter((elm) => {
      return elm.id !== displayedContent.id;
    });
    let dataArray = [];
    if (isClearAll) {
      displayedContent.map((x) => {
        dataArray.push(x.id);
        return dataArray;
      });
      setIsAlert(false);
    }

    updateProfileAssets(
      isClearAll ? dataArray : [displayedContent.id],
      'delete',
      isClearAll
    );
    // update portfolio in context
    dispatch({
      type: MCSV2_ACTION_TYPES.SET_PORTFOLIO_ASSET,
      payload: isClearAll ? [] : tempPortfolio,
    });
  };

  const saveToPortfolio = (data) => {
    const doesExist = savedPortfolio.find((elm) => {
      return (
        elm.id === data.id &&
        elm.imgDesc === data.imgDesc &&
        elm.imgSrc === data.imgSrc
      );
    });

    if (!doesExist) {
      const tempPortfolioAssets = [...savedPortfolio, data];
      setIsAlert(false);
      // network call to save territory to portfolio on the backend
      updateProfileAssets([data.id], 'save');
      //update portfolio in context
      dispatch({
        type: MCSV2_ACTION_TYPES.SET_PORTFOLIO_ASSET,
        payload: tempPortfolioAssets,
      });
    }
  };

  // function to call backend with add/remove from portfolio
  const updateProfileAssets = async (ids, action, isClearAll) => {
    const payload = {
      userId,
      projectName: currentProject.projectName,
      projectId: currentProject.projectId,
      assetId: ids,
      action,
      requestType: 'projectMgmtAsset',
    };

    try {
      await mutateAsync(payload);
      if (action === 'save' || (action === 'delete' && isClearAll)) {
        handleAlert('success', action);
      }
    } catch (e) {
      if (action === 'save' || (action === 'delete' && isClearAll)) {
        handleAlert('error', action);
      }
      console.log('error saving assets: ', e);
    }
  };

  const handleAlert = (item, action) => {
    setAlertBanners([]);
    const alertList = [
      {
        open: action === 'save' && true,
        alertType: item === 'error' ? 'error' : 'success',
        alertTitle: item === 'error' ? 'Unable to save' : 'Saved to Portfolio',
        alertMessage:
          item === 'error'
            ? 'There was an error saving your Asset to your Portfolio. Please try again.'
            : 'Your Asset was successfully save to Portfolio.',
      },
      {
        open: action === 'delete' && true,
        alertType: item === 'error' ? 'error' : 'success',
        alertTitle: item === 'error' ? 'Unable to clear' : 'Portfolio cleared',
        alertMessage:
          item === 'error'
            ? 'There was an error clearing out your Portfolio. Please try again.'
            : 'Your Portfolio was successfully cleared.',
      },
    ];
    if (action === 'delete') {
      setAlertBanners(alertList[1]);
    } else {
      setAlertBanners(alertList[0]);
    }
    setIsAlert(true);
  };

  const renderAlerts = (
    <div
      style={{
        position: 'absolute',
        zIndex: '500',
        top: '55px',
        left: '50%',
      }}
    >
      <AlertToasterBanner {...alertBanners} style={{ position: 'relative' }} />
    </div>
  );
  // update portfolio when saved portfolios change
  useEffect(() => {
    setSavedPortfolio(portfolioAsset);
  }, [portfolioAsset]);

  useEffect(() => {
    if (editMode === 'home') setSecondaryAsset(null);
  }, [editMode]);

  useEffect(() => {
    const imageAssets = savedPortfolio.filter((data) => data.type === 'image');
    setSavedImageAsset(imageAssets);

    const textAssets = savedPortfolio.filter((data) => data.type === 'text');
    setSavedTextAsset(textAssets);

    const mockupAssets = savedPortfolio.filter(
      (data) => data.imgDesc && data.imgSrc
    );
    setSavedMockupAsset(mockupAssets);
  }, [savedPortfolio]);

  const renderMockupResult = () => {
    const MockupComponent = creativeCanvasComponents[assets.creativeCanvas];
    return (
      <>
        <>
          {savedMockupAsset.map((data, key) => (
            <>
              <Box
                sx={{
                  paddingX: 2,
                  paddingY: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <MockupComponent
                  key={key}
                  imgDesc={data?.imgDesc}
                  imgSrc={data?.imgSrc}
                />
              </Box>
            </>
          ))}
        </>
      </>
    );
  };

  const showAssetsDetail = (refinedData, actualIndex, elm = null) => {
    if ((actualIndex + 1) % 4 == 0) {
      refinedData.splice(actualIndex + 1, 0, { bigCard: 'bigCard' });
    } else {
      if ((actualIndex + 2) % 4 === 0) {
        refinedData.splice(actualIndex + 2, 0, { bigCard: 'bigCard' });
      } else {
        refinedData.splice(actualIndex + 3, 0, { bigCard: 'bigCard' });
      }
    }

    setDisplayedContent(refinedData);
    setEditedTerritory(displayedContent[actualIndex]);
  };

  return (
    <>
      {isAlert && renderAlerts}
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.blue,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
          paddingTop: '4rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            flexGrow: 1,
            maxWidth: '1840px',
          }}
        >
          <Box
            className="side_bar"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflowY: 'hidden',
              background: theme.palette.grey.white,
              maxWidth: selectedIndex === 2 || settingsView ? '708px' : '382px',
              width: '100%',
              height: 'calc(100vh - 65px)',
              transition: '0.4s ease-in-out',
              position: 'absolute',
              zIndex: '200',
            }}
          >
            <Box
              className="tabs"
              sx={{
                boxShadow: '-2px 2px 12px 0px rgba(0, 0, 0, 0.08)',
                borderBottom: `${theme.palette.grey.light} solid 3px`,
                padding: '1rem 1rem 0',
                background: theme.palette.grey.white,
                position: 'absolute',
                width: '100%',
                zIndex: 1,
              }}
            >
              <Box
                role="tablist"
                className="tablist"
                aria-orientation="horizontal"
                sx={{
                  display: 'flex',
                  height: '48px',
                }}
              >
                <StyledTab
                  key={`tab-1`}
                  id="tab-1"
                  type="button"
                  role="tab"
                  aria-selected={selectedIndex === 1}
                  aria-controls="tabpanel-1"
                  onClick={() => {
                    handleTabChange(1);
                  }}
                  ref={(element) => (tabRefs.current[1] = element)}
                  onKeyDown={onKeyDown}
                  style={{
                    width: '50%',
                    color:
                      selectedIndex === 1
                        ? theme.palette.primary.main
                        : theme.palette.grey.black,
                  }}
                >
                  <span className="focus">WorkSpace</span>
                  <div
                    style={{
                      marginTop: '4px',
                      width: '100%',
                      height: '3px',
                      background:
                        selectedIndex === 1
                          ? theme.palette.primary.main
                          : theme.palette.grey.white,
                    }}
                  ></div>
                </StyledTab>
                <StyledTab
                  key={`tab-2`}
                  id="tab-2"
                  type="button"
                  role="tab"
                  aria-selected={selectedIndex === 2}
                  aria-controls="tabpanel-2"
                  tabIndex="-1"
                  onClick={() => {
                    handleTabChange(2);
                  }}
                  ref={(element) => (tabRefs.current[2] = element)}
                  onKeyDown={onKeyDown}
                  style={{
                    width: '50%',
                    color:
                      selectedIndex === 2
                        ? theme.palette.primary.main
                        : theme.palette.grey.black,
                  }}
                >
                  <span className="focus">
                    Portfolio{' '}
                    {savedPortfolio.length > 0
                      ? `(${savedPortfolio.length})`
                      : null}
                  </span>
                  <div
                    style={{
                      marginTop: '4px',
                      width: '100%',
                      height: '3px',
                      background:
                        selectedIndex === 2
                          ? theme.palette.primary.main
                          : theme.palette.grey.white,
                    }}
                  ></div>
                </StyledTab>
              </Box>
            </Box>

            {selectedIndex === 2 ? (
              <Box
                className="tabContent"
                sx={{
                  width: '100%',
                  maxHeight: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: '2rem 0',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  overflowY: 'auto',
                  marginTop: '4rem',
                }}
              >
                {showExportPortfolio ? (
                  <ExportPortfolio
                    savedPortfolio={savedPortfolio}
                    onSelectedTerritoriesChange={(
                      selectedTerritoriesExport
                    ) => {
                      setSelectedTerritoriesExport(selectedTerritoriesExport);
                    }}
                  />
                ) : (
                  <>
                    {!shouldClearPortfolios && renderSavePortfolios()}
                    {shouldClearPortfolios && (
                      <Box>
                        <Typography
                          variant="h3"
                          component={'div'}
                          sx={{ marginBottom: '1rem' }}
                        >
                          Are you sure you want to clear your Portfolio?
                        </Typography>
                        <Typography variant="subtitle1" component={'span'}>
                          This action cannot be undone.
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                    margin: '1rem 1.5rem 3rem',
                    paddingBottom: '3rem',
                    borderTop: `solid 1px ${theme.palette.grey.light2}`,
                    paddingTop: '3rem',
                  }}
                >
                  {showExportPortfolio ? (
                    <>
                      <Buttons
                        variant="song"
                        color="secondary"
                        label="Cancel"
                        sx={{ paddingX: '24px', paddingY: '10px' }}
                        style={{ marginRight: '1.5rem' }}
                        onClick={handleCancelClick}
                      />

                      <Buttons
                        variant="song"
                        color="primary"
                        sx={{ paddingX: '24px', paddingY: '10px' }}
                        startIcon={<LaunchOutlinedIcon />}
                        label={`Export Selected Content (${selectedTerritoriesExport.length})`}
                        onClick={() => {
                          console.log('export');
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Buttons
                        variant="song"
                        disabled={!savedPortfolio?.length}
                        color="secondary"
                        label={
                          shouldClearPortfolios ? 'Go back' : 'Clear Portfolio'
                        }
                        sx={{ padding: '5px 12px' }}
                        style={{ marginRight: '1.5rem' }}
                        onClick={() => {
                          if (showExportPortfolio) {
                            handleCancelClick();
                          }
                          setShouldClearPortfolios(!shouldClearPortfolios);
                          document.activeElement.blur();
                        }}
                      />

                      <Buttons
                        variant="song"
                        disabled={!savedPortfolio?.length}
                        color="primary"
                        label={
                          shouldClearPortfolios
                            ? 'Clear Portfolio'
                            : 'Export Portfolio'
                        }
                        sx={{ padding: '5px 12px', fontSize: '20px' }}
                        onClick={() => {
                          if (shouldClearPortfolios) {
                            removeFromPortfolio(portfolioAsset, true);
                            setSavedPortfolio([]);
                            setShouldClearPortfolios(!shouldClearPortfolios);
                          }
                          if (showExportPortfolio) {
                            console.log('export');
                          }
                          document.activeElement.blur();
                        }}
                      />
                    </>
                  )}
                </Box>
              </Box>
            ) : (
              <WorkspaceArea
                generateMore={generateMore}
                exit={handleExitStartOver}
                setSettingsView={setSettingsView}
                settingsView={settingsView}
                isAsset
                imageNotLoaded={imageNotLoaded}
              />
            )}
          </Box>

          <Box
            className="main"
            sx={{
              width: '100%',
              height: 'calc(100vh - 64px)',
              marginLeft: '21rem',
              padding: '0 2rem 0 5rem',
              zIndex: '10',
              overflowY: 'auto',
            }}
          >
            <Box className="asset_search" paddingTop={'56px'}>
              <Grid
                container
                className="territories_space"
                spacing={0}
                sx={{
                  width: 'unset',
                  justifyContent: 'space-between',
                }}
              >
                {showLoader && (
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    width
                    height
                    marginBottom="2rem"
                  >
                    <CircularProgress />
                  </Box>
                )}
                {displayedContent.map((elm, i) => {
                  if (elm.contentType == 'search') {
                    return (
                      <Box
                        key={`${elm.searchType}_${i}`}
                        sx={{ width: '100%' }}
                      >
                        <AssetSearch
                          assetType={elm.type}
                          refinementText={
                            isNew
                              ? 'None Provided'
                              : requestType === 'iterateAsset'
                                ? iterateText
                                  ? `"${iterateText}"`
                                  : 'None Provided'
                                : blendText
                                  ? `"${blendText}"`
                                  : 'None Provided'
                          }
                          sliderValues={{}}
                          inspectSliderInputsText={
                            inspectsliderInputsText ||
                            defaultInspectSliderInputsValue
                          }
                          searchType={elm.searchType}
                          inputAssets={elm.inputAssets ?? null}
                          giveMeMore={() => giveMeMore(elm.type)}
                          platform={elm.platform}
                          selectedIndex={selectedIndex}
                          settingsView={settingsView}
                          imageNotLoaded={imageNotLoaded}
                        />
                      </Box>
                    );
                  } else {
                    return elm.bigCard == 'bigCard' ? (
                      <Grid
                        item
                        className="territory_container"
                        key={`details_${i}`}
                        xs={12}
                        ref={(element) => (assetsRefs.current[i] = element)}
                      >
                        <div ref={assetsDetailsRefs}></div>
                        <AssetsDetailsCard
                          assets={selectedAssets}
                          blendIterateText={iterateAndBlendText}
                          blendImage={blendImages}
                          iterateImage={iterateImages}
                          closeOnClick={handleCloseDetailsCard}
                          isEditing={false}
                          isDisabled={imageNotLoaded}
                          savedPortfolio={savedPortfolio}
                          secondaryAsset={secondaryAsset}
                          updatePortfolioClick={saveToPortfolio}
                          ADCOptionsOnClick={handleADCOptionsClick}
                          editMode={editMode}
                          creativeCanvas={assets.creativeCanvas}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        item
                        className="asset_container"
                        key={`${elm.id}_${i}`}
                        ref={(element) => (assetsRefs.current[i] = element)}
                      >
                        <AssetCard
                          className="asset_card"
                          type={elm.type}
                          content={elm.content}
                          id={elm.id}
                          handleClick={() => {
                            handleAssetClick(i, elm);
                          }}
                          handleTerritoryRemoveClick={() => {
                            removeTerritory(i);
                          }}
                          handleReplaceTerritoryClick={() => {
                            replaceTerritory(i);
                          }}
                          isDisabled={selectedIndex == 2}
                          isSelected={elm.id === selectedAssets[0]?.id}
                          secondaryAsset={secondaryAsset}
                          handleAssetRemoveClick={() => {
                            handleRemoveAsset(elm, elm.type);
                          }}
                          displayedImageLength={
                            displayedContent.filter(
                              (d) => d.type === 'image' && d.id
                            ).length
                          }
                          displayedTextLength={
                            displayedContent.filter(
                              (d) => d.type === 'text' && d.id
                            ).length
                          }
                          imageNotLoaded={isLoading}
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box
          className="translucent_grey_background"
          sx={{
            position: 'absolute',
            display: (selectedIndex === 2 || settingsView) && 'block',
            flexDirection: 'row',
            height: 'calc(100vh - 64px)',
            width: '100%',
            flexGrow: 1,
            background:
              (selectedIndex === 2 || settingsView) &&
              theme.palette.opacity.clear6,
            zIndex: (selectedIndex === 2 || settingsView) && '100',
            visibility: selectedIndex === 2 || settingsView ? '1' : '0',
            transition: 'visibility 0.1s ease-in, background .3s ease-in',
          }}
        ></Box>
      </Box>
    </>
  );
};

export default AssetWorkSpace;
