import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import Buttons from '../../components/Button';
import FooterButton from '../../components/FooterButton';
import GCEHero from '../../components/GCE/GCEHero';
import GCEIntroForm from '../../components/GCE/GCEIntroForm';
import LoadingPage from '../../components/LoadingPage';
import { GCEContext, GCEDispatchContext } from '../../context/GCEContext';
import { useGenerativeConceptEngine } from '../../hooks/useGenerativeConceptEngine';
import {
  GCE_ACTION_TYPES,
  GCE_API_TYPES,
  fakeResponse,
} from '../../reducers/gceActionTypes';
import { restructureIdeas } from '../../util/restructureIdeas';

import theme from '../../styles/theme';

const GCEStrength = [
  {
    name: 'None',
    percent: 0,
    message: 'Provide information to generate strong ideas.',
  },
  {
    name: 'Weak',
    percent: 25,
    message:
      'You’re off to a good start! Answer more questions to generate stronger ideas.',
  },
  {
    name: 'Vague',
    percent: 50,
    message:
      'Not bad! Answering one or more questions will get you ideas more tailored to your needs.',
  },
  {
    name: 'Good',
    percent: 75,
    message:
      'Awesome! You’ve provided a lot of helpful info for your ideas; you can select “Generate idea” to see what’s in store.',
  },
  {
    name: 'Strong',
    percent: 100,
    message:
      'Awesome! You’ve provided a lot of helpful info for your ideas; you can select “Generate idea” to see what’s in store.',
  },
];

const mapCounterToStrength = (count, questionCount) => {
  if (questionCount == 0) return 0;
  let updatedPercentage = ((count - 1) / questionCount) * 100;
  return Math.round(updatedPercentage / 25);
};

function GCEIntro({ setShowIdeas }) {
  const [counter, setCounter] = useState(0);
  const [predefinedQuestionCount, setPredefinedQuestionCount] = useState(0);
  const state = useContext(GCEContext);
  const dispatch = useContext(GCEDispatchContext);
  const { mutateAsync, isLoading } = useGenerativeConceptEngine();

  const gceHeadings = {
    title: 'What’s your\nidea?',
    caption:
      'Generative Concepting Engine synthesizes customer & market insights and generates ideas to inspire your next product or campaign',
  };
  const gceImages = [
    'assets/images/gce_hero_caption_card.png',
    'assets/images/gce_standard_concept_card.png',
  ];

  const handleGenerateIdeas = async () => {
    let contextIdeas;
    try {
      // generate ideas
      const { descriptions } = await mutateAsync({
        requestId: state.requestId,
        requestType: GCE_API_TYPES.GCE_IDEA_GEN,
        ideaType: state.ideaType,
      });

      // restucture object for key access and add ideaHistory and isBookmarked to each idea
      contextIdeas = restructureIdeas(descriptions, false);
    } catch (e) {
      console.log('error generating ideas');
      contextIdeas = restructureIdeas(fakeResponse.descriptions, false);
    }
    // dispatch ideas to context
    dispatch({
      type: GCE_ACTION_TYPES.SET_IDEAS,
      payload: contextIdeas,
    });
    setShowIdeas(true);
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Grid
        container
        direction={'column'}
        sx={{
          margin: '0 auto',
          position: 'relative',
          maxWidth: '100%',
          height: 'auto',
          backgroundColor: '#e7f2ff',
          paddingBottom: '200px',
        }}
      >
        <Grid
          className="GCE_intro_hero_space"
          item
          direction={'column'}
          display="flex"
          alignItems="center"
          sx={{
            backgroundColor: '#fff',
            padding: '0px 80px 20px',
            marginTop: '65px',
          }}
        >
          <GCEHero
            title={gceHeadings.title}
            caption={gceHeadings.caption}
            images={gceImages}
            doScroll={{
              animate: {
                visible: { scale: 1.2 },
                transition: { ease: 'easeInOut', delay: 2 },
              },
              downElemId: 'genai-app-footer',
            }}
          />
        </Grid>
        <Grid
          item
          direction={'column'}
          display="flex"
          alignItems="center"
          sx={{
            width: '100%',
            backgroundColor: '#e7f2ff',
            height: 'auto',
            marginBottom: '100px',
          }}
        >
          <GCEIntroForm
            counter={counter}
            handleCounter={setCounter}
            handlePredefinedQuestionCount={setPredefinedQuestionCount}
          />
          <FooterButton
            setData={() => {}}
            style={{
              display: 'flex',
              padding: '24px 48px 24px 80px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 2,
                marginRight: '48px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="inputLabel" sx={{ marginBottom: '16px' }}>
                  Strength of Information Provided:
                </Typography>
                <Typography
                  variant="inputLabel"
                  sx={{
                    color: theme.palette.grey.main,
                    fontFamily: 'GraphikRegular',
                    marginLeft: '10px',
                  }}
                >
                  {
                    GCEStrength[
                      mapCounterToStrength(counter, predefinedQuestionCount)
                    ]?.name
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  backgroundColor: 'rgba(181, 181, 181, 1)',
                  width: '100%',
                  height: '10px',
                  marginBottom: '8px',
                  borderRadius: '5px',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    height: '10px',
                    width: `${
                      GCEStrength[
                        mapCounterToStrength(counter, predefinedQuestionCount)
                      ]?.percent
                    }%`,
                    borderRadius: '5px',
                    background:
                      'linear-gradient(90deg, rgba(255,178,213,1) 0%, rgba(161,0,255,1) 50%, rgba(117,0,192,1) 81%, rgba(59,128,224,1) 94%)',
                  }}
                ></Box>
              </Box>

              <Typography variant="body4">
                {
                  GCEStrength[
                    mapCounterToStrength(counter, predefinedQuestionCount)
                  ]?.message
                }
              </Typography>
            </Box>
            <Box>
              <Buttons
                variant="song"
                sx={{ paddingX: '24px', paddingY: '10px' }}
                onClick={handleGenerateIdeas}
                disabled={!state.generateIdeas}
              >
                Generate Ideas
              </Buttons>
            </Box>
          </FooterButton>
        </Grid>
        <span style={{ backgroundColor: '#e7f2ff' }}></span>
        <span
        id="genai-app-footer"
        style={{ backgroundColor: theme.palette.secondary.blue }}
      >
        _
      </span>
      </Grid>
    </>
  );
}

export default GCEIntro;
