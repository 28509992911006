import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import theme from '../../styles/theme';
import { ImageBox } from '../ImageBox';
import ColorPalette from './ColorPalette';

const ThumbnailsDetailCard = ({ imageArray, header, colorPalette }) => {
  return (
    <Box
      className="main_territory_container"
      sx={{
        background: theme.palette.grey.white,
        width: '200px',
        padding: '10px',
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" component="h3" fontSize={12}>
        {header}
      </Typography>
      <Box
        className="territory_space"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
        }}
      >
        <Box
          className="territory_detail_section"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '5px',
            }}
          >
            <Box
              sx={{
                width: '100px',
                height: '90px',
              }}
            >
              <ImageBox
                src={imageArray[0]}
                alt=""
                styles={{ borderRadius: '8px' }}
              />
            </Box>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '40px',
                }}
              >
                <ImageBox
                  src={imageArray[1]}
                  alt=""
                  styles={{ borderRadius: '8px' }}
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '40px',
                }}
              >
                <ImageBox
                  src={imageArray[2]}
                  alt=""
                  styles={{ borderRadius: '8px' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <ColorPalette colors={colorPalette} small={true} />
        </Box>
      </Box>
    </Box>
  );
};

export default ThumbnailsDetailCard;
