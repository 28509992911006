import { Box, InputBase, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
const uploadIcon = 'assets/images/upload.svg';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid ' + theme.palette.grey.main,
    fontSize: 16,
    padding: '18px 12px',
    background: theme.palette.grey.white,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  width: '100%',
}));

const AdvancedSetup = ({ setImagesIdea, setColorsIdea, setToneOfVoice }) => {
  return (
    <>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ marginBottom: 4, marginTop: 4 }}
      >
        Imagery
      </Typography>
      <Typography paragraph gutterBottom sx={{ marginBottom: 4, fontSize: 18 }}>
        Do you have any requirements or specs for images generated in your
        Creative Territory Workspace?
      </Typography>
      <BootstrapInput
        multiline
        placeholder="(e.g., Generated images of cocktails and pastries should not have any visible company names or brand logos.)"
        onChange={(event) => setImagesIdea(event.target.value)}
        style={{
          '::placeholder': {
            color: '#868B90',
            fontFamily: 'GraphikRegular',
            fontSize: 16,
            lineHeight: '28px',
          },
        }}
      />

      <Box marginTop={10}>
        <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
          Colors
        </Typography>
        <Typography
          paragraph
          gutterBottom
          sx={{ marginBottom: 4, fontSize: 18 }}
        >
          Have a specific color palette in mind? Provide a description or add
          the HEX values here:
        </Typography>
        <BootstrapInput
          multiline
          placeholder="(e.g.,  Show me a range of color palettes but they must all include Hex #2a9d8f.)"
          onChange={(event) => setColorsIdea(event.target.value)}
          style={{
            '::placeholder': {
              color: '#868B90',
              fontFamily: 'GraphikRegular',
              fontSize: 16,
              lineHeight: '28px',
            },
          }}
        />
      </Box>

      <Box marginTop={10}>
        <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
          Tone of Voice
        </Typography>
        <Typography
          paragraph
          gutterBottom
          sx={{ marginBottom: 4, fontSize: 18 }}
        >
          Do you have any guidelines or specifications for copy? Share them
          here:
        </Typography>
        <BootstrapInput
          multiline
          minRows={2}
          placeholder="(e.g., Messaging should be casual and welcoming but not cutesy.)"
          onChange={(event) => setToneOfVoice(event.target.value)}
          style={{
            '::placeholder': {
              color: '#868B90',
              fontFamily: 'GraphikRegular',
              fontSize: 16,
              lineHeight: '28px',
            },
          }}
        />
      </Box>
    </>
  );
};

export default AdvancedSetup;
