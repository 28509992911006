import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../styles/theme';
import TextCard from './Card';

const ChatBubble = ({ isUser, text, isOption, basicStyle }) => {
  const renderUserChatBubble = () => {
    return (
      <Box
        sx={{
          maxWidth: 574,
        }}
      >
        <Box
          sx={{
            justifyContent: 'right',
            display: 'flex',
            paddingY: 1,
            color: theme.palette.grey.dark,
          }}
        >
          <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>
            You Replied
          </Typography>
        </Box>
        {isOption ? (
          <Box
            sx={{
              display: 'flex',
              gap: '18px',
            }}
          >
            {text?.map((idea, idx) => {
              return (
                <TextCard
                  key={`rad${idx}${idea}`}
                  each={idea}
                  index={idx}
                  active={idea}
                  handleClick={() => {}}
                />
              );
            })}
          </Box>
        ) : (
          <Card
            sx={{
              maxWidth: 574,
              borderRadius: 4,
              boxShadow: 0,
              backgroundColor: basicStyle
                ? `3px solid ${theme.palette.grey.light}`
                : theme.palette.grey.light,
            }}
          >
            <CardContent>
              <Typography paragraph sx={{ margin: 0 }}>
                {text}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
    );
  };

  const renderAIChatBubble = () => {
    return (
      <Box key={text}>
        {basicStyle ? (
          <>
            <Grid container spacing={0} sx={{ flexGrow: 1 }}>
              <Grid xs={8}>
                <Box
                  sx={{
                    minWidth: 300,
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: 'left',
                      display: 'flex',
                      paddingY: 1.5,
                      color: theme.palette.grey.dark,
                    }}
                  ></Box>
                  <Card
                    sx={{
                      maxWidth: 574,
                      borderRadius: 4,
                      boxShadow: 0,
                      border: `3px solid ${theme.palette.blue.main}`,
                    }}
                  >
                    <CardContent>
                      <Typography paragraph sx={{ margin: 0 }}>
                        {text}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={0} sx={{ flexGrow: 1 }}>
              <Grid sx={{ paddingTop: 3 }}>
                <Avatar
                  src="assets/images/avatar3.svg"
                  sx={{ width: 74, height: 74 }}
                />
              </Grid>
              <Grid xs={8} sx={{ minWidth: 640 }}>
                <Box
                  sx={{
                    maxWidth: 574,
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: 'left',
                      display: 'flex',
                      paddingY: 1.5,
                      color: theme.palette.grey.dark,
                    }}
                  ></Box>
                  <Card
                    sx={{
                      maxWidth: 574,
                      borderRadius: 4,
                      boxShadow: 0,
                      backgroundColor: theme.palette.secondary.blue,
                    }}
                  >
                    <CardContent>
                      <Typography paragraph sx={{ margin: 0 }}>
                        {text}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    );
  };

  return <>{isUser ? renderUserChatBubble() : renderAIChatBubble()}</>;
};

ChatBubble.propTypes = {
  isUser: PropTypes.bool,
  text: PropTypes.string,
  isOption: PropTypes.bool,
  basicStyle: PropTypes.bool,
};

export default ChatBubble;
