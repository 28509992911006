import React from 'react';
import Carousel from 'react-material-ui-carousel';

import { Box } from '@mui/material';
import TerritoryCard from './TerritoryCard';

const TerritoryCarousel = ({
  setSelectedTerritory,
  selectedTerritory,
  projectPortfolio,
  setSelectedTerritoryName,
  isExistingTerritory,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Carousel autoPlay={false} navButtonsAlwaysVisible>
        {projectPortfolio &&
          projectPortfolio.length > 0 &&
          projectPortfolio.map((item, i) => (
            <Box
              key={i}
              sx={{
                justifyContent: 'center',
                alignContent: 'center',
                display: 'flex',
              }}
            >
              <TerritoryCard
                className="territory_card"
                header={item.header}
                description={item.description}
                imageArray={item.imageArray}
                colorPalette={item.colorPalette}
                width={isExistingTerritory ? '361px' : '100%'}
                noRemove
                sx={{ justifyContent: 'center', alignContent: 'center' }}
                handleClick={() => {
                  setSelectedTerritory(item.id);
                  setSelectedTerritoryName(item.header);
                }}
                isSelected={selectedTerritory === item.id}
              />
            </Box>
          ))}
      </Carousel>
    </Box>
  );
};

export default TerritoryCarousel;
