import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { Storage } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { CNSContext, CNSDispatchContext } from '../../context/CNSContext';
import { useCustomerNeedsSpotter } from '../../hooks/useCustomerNeedsSpotter';
import { CNS_ACTION_TYPES } from '../../reducers/cnsActionTypes';
import theme from '../../styles/theme';
import CNSChatTab from './CNSChatTab';
import CNSCustomerNeedTab from './CNSCustomerNeedTab';

const CNSChatSidebar = ({ setIsReset, setIsLoading }) => {
  const state = useContext(CNSContext);
  const dispatch = useContext(CNSDispatchContext);
  const { mutateAsync, isLoading } = useCustomerNeedsSpotter();
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isEdit, setIsEdit] = useState(false);
  const [fileLog, setFileLog] = useState([]);
  const [isValueChange, setChangeValue] = useState(false);
  const [initialValues, setInitialValue] = useState({
    areaOfFocus: {
      insights: state.chatHistory[1].option.includes('insight'),
      mindset: state.chatHistory[1].option.includes('mindsets'),
    },
    audience: state.chatHistory[5].text,
    topic: state.chatHistory[3].text,
    context: state.chatHistory[7].text,
    datasource: state.filesHistory,
  });
  const [customerNeed, setCustomerNeed] = useState(initialValues);
  const [removeAllFiles, setUndoAllFiles] = useState(false);

  const tab_button = {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Graphik',
  };

  const handleChangeOnEdit = (event) => {
    const tempCustomerNeed = JSON.parse(JSON.stringify(customerNeed));
    tempCustomerNeed[event.target.name] = event.target.value;
    setCustomerNeed(tempCustomerNeed);
  };

  useEffect(() => {
    const customerNeedTempValue = JSON.parse(JSON.stringify(customerNeed));
    let newValue = Object.keys(customerNeedTempValue).some((key) => {
      if (key !== 'areaOfFocus' && key !== 'datasource') {
        return customerNeedTempValue[key] !== initialValues[key];
      }
    });
    let areaOfFocus = Object.keys(customerNeedTempValue.areaOfFocus).some(
      (key) => {
        return (
          customerNeedTempValue.areaOfFocus[key] !==
          initialValues.areaOfFocus[key]
        );
      }
    );
    setChangeValue(
      (fileLog.length > 0 &&
        fileLog.some((each) => {
          return each?.isUndoAble === false;
        })) ||
        newValue ||
        areaOfFocus
    );
    if (fileLog.length > 0) {
      customerNeed.datasource = [...fileLog];
      setCustomerNeed(customerNeed);
    }
  }, [customerNeed, fileLog]);

  const handleUndoChange = () => {
    const tempFilesHistoryArr = [...fileLog];
    const tempFiles = tempFilesHistoryArr.filter((x) => {
      return x.isUndoAble !== false;
    });
    initialValues.datasource = tempFiles;
    setCustomerNeed(initialValues);
    deleteUploadedFile();
    setIsEdit(false);
  };

  const deleteUploadedFile = () => {
    const filesHistory = [...fileLog];
    try {
      filesHistory.forEach((each) => {
        if (each.type === 'file' && each.isUndoAble == false) {
          Storage.remove(state.sessionID + each.fileName);
        }
      });
      setUndoAllFiles(true);
    } catch (e) {
      console.log('Deleting file from S3 error: ', e);
    }
  };

  const handleRegenerateAnalysis = async () => {
    let tempDataSource = customerNeed.datasource;
    if (fileLog.length > 0) {
      tempDataSource = fileLog;
    }

    let urlsArray = tempDataSource
      .filter((x) => x.type === 'link')
      .map((y) => y.fileName);
    let outputArray = [];
    Object.keys(customerNeed.areaOfFocus).map((x) => {
      if (customerNeed.areaOfFocus[x] === true) {
        outputArray.push(x);
      }
      return outputArray;
    });
    state.chatHistory[1].text = outputArray.map((each) => {
      if (each == 'insights') {
        return 'Insight & Trends';
      }
      if (each == 'mindset') {
        return 'Mindsets';
      }
    });
    let tempOption = ['summary'];
    state.chatHistory[1].text.forEach((each) => {
      tempOption.push(each.split(' ')[0]?.toLowerCase());
    });
    state.chatHistory[1].option = tempOption;
    dispatch({
      type: CNS_ACTION_TYPES.SET_CHAT_HISTORY,
      payload: state.chatHistory,
    });
    let analysisPayload = {
      sessionId: state.sessionID,
      url: urlsArray,
      qna: [
        {
          question: state.chatHistory[2].text,
          answer: customerNeed.audience,
        },
        {
          question: state.chatHistory[4].text,
          answer: customerNeed.topic,
        },
        {
          question: state.chatHistory[6].text,
          answer: customerNeed.context,
        },
      ],
      outputOptions: outputArray,
      flag_data_uploaded: tempDataSource.some((i) => i.type.includes('file')),
    };
    fileLog.forEach((file) => {
      file.isUndoAble = '';
    });
    setFileLog(fileLog);
    customerNeed.datasource = [...fileLog];
    setInitialValue(customerNeed);
    setIsEdit(false);
    let rawResponse = await mutateAsync(analysisPayload);
    dispatch({
      type: CNS_ACTION_TYPES.SET_ANALYSIS_SUMMARY,
      payload: rawResponse?.message,
    });
  };

  isLoading ? setIsLoading(isLoading) : setIsLoading(false);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey.white,
        padding: '2px 0px 48px 0px',
        flexBasis: '33.33%',
        width: '100%',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: '100',
        boxSizing: ' border-box',
        marginBottom: 1,
        boxShadow: '0px 4px 40px 0px rgba(30, 30, 30, 0.10)',
        minHeight: '59.75rem',
      }}
    >
      <TabContext value={value}>
        <TabList
          onChange={handleChange}
          aria-label="Customer needs and chat"
          variant="fullWidth"
          sx={{
            boxShadow: '0px 4px 40px 0px rgba(30, 30, 30, 0.10)',
          }}
        >
          <Tab label="Customer Needs" value="1" style={tab_button} />
          <Tab label="Chat" value="2" style={tab_button} />
        </TabList>
        <TabPanel value="1" sx={{ padding: '25px 32px' }}>
          <CNSCustomerNeedTab
            setIsReset={setIsReset}
            customerNeed={customerNeed}
            handleChangeOnEdit={handleChangeOnEdit}
            handleUndoChange={handleUndoChange}
            handleRegenerateAnalysis={handleRegenerateAnalysis}
            isValueChange={isValueChange}
            setFileLog={setFileLog}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            chatHistory={state.chatHistory}
            removeAllFiles={removeAllFiles}
            setUndoAllFiles={setUndoAllFiles}
            isLoading={isLoading}
            setCustomerNeed={setCustomerNeed}
          ></CNSCustomerNeedTab>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '25px 32px' }}>
          <CNSChatTab setIsReset={setIsReset}></CNSChatTab>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default CNSChatSidebar;
