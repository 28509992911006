import { Box, Typography } from '@mui/material';
import React from 'react';
import Animation from '../components/Animation';
import theme from '../styles/theme';

const LoadingPage = () => {
  return (
    <Box
      height="auto"
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{ marginTop: '188px' }}
    >
      <Animation />
      <Typography
        variant="h1"
        mt={4}
        fontSize="18px"
        style={{ color: theme.palette.primary.dark, fontWeight: 600 }}
      >
        Creation in progress...
      </Typography>
    </Box>
  );
};

export default LoadingPage;
