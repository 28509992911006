import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useRef } from 'react';
import FileIcon from '../../assets/file-icon.svg';
import theme from '../../styles/theme';
import Buttons from '../Button';
import SVGIcon from '../SVGIcon';

const style = {
  position: 'absolute',
  top: '25%',
  left: '35%',
  width: 1400,
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: 10,
  borderRadius: '20px',
  scale: '0.6',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const customFileInputStyle = {
  display: 'none',
};

const mockProjectName = 'Spring Restaurant Opening';
const mockFilesNames = [
  'berries.jpg',
  'brand.png',
  'brand.png',
  'cake5.jpg',
  'flow-diagram.png',
];

export default function UploadModal({
  open,
  closeModal,
  handleFilesUpload,
  uploaded,
}) {
  const fileInput = useRef();

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="select-project-folder"
        aria-describedby="upload-modal"
      >
        <Box sx={style} overflow="auto">
          <Button
            style={{
              position: 'absolute',
              top: '20px',
              right: '10px',
            }}
            onClick={closeModal}
            aria-label="close"
          >
            <SVGIcon
              name="close"
              width={28}
              height={32}
              color={theme.palette.grey.black}
              strokecolor={theme.palette.grey.white}
            />
          </Button>
          <Box
            sx={{
              display: 'flex',
              marginTop: '20px',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Typography
              variant="h2"
              whiteSpace="pre-wrap"
              component="h2"
              fontSize={40}
            >
              Select Project Folder
            </Typography>
            <Typography
              variant="body1"
              whiteSpace="pre-wrap"
              component="p"
              fontSize={25}
            >
              {mockProjectName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 400px)',
              gap: 9,
              marginTop: '25px',
              fontSize: '24px',
              borderBottom: `1px solid ${theme.palette.grey.light2}`,
              marginBottom: '3rem',
              paddingBottom: '4rem',
            }}
          >
            {mockFilesNames.map((fileName, index) => (
              <Button
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: 2,
                  padding: 5,
                  borderRadius: '10px',
                  backgroundColor: theme.palette.grey.light1,
                  color: theme.palette.grey.black,
                  fontSize: 25,
                  '&:focus': {
                    outline: `1px solid ${theme.palette.primary.main}`,
                  },
                }}
              >
                <img src={FileIcon} width="45px" alt="File Icon" />
                {fileName}
              </Button>
            ))}
          </Box>
          <Typography
            marginBottom={8}
            variant="h2"
            whiteSpace="pre-wrap"
            component="h2"
            fontSize={40}
          >
            Upload From Your Computer
          </Typography>

          <Button
            variant="song"
            color="secondary"
            sx={{ paddingX: '30px', paddingY: '10px', fontSize: 25 }}
            component="label"
            htmlFor="file-input"
          >
            Browse Files
            <input
              id="file-input"
              type="file"
              accept="image/png, image/jpeg,image/jpg"
              onChange={handleFilesUpload}
              ref={fileInput}
              style={customFileInputStyle}
            />
          </Button>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 400px)',
              gap: 9,
              marginTop: '25px',
              fontSize: '24px',
              marginBottom: '3rem',
              paddingBottom: '4rem',
            }}
          >
            {uploaded.map((file, index) => (
              <Button
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: 2,
                  padding: 5,
                  borderRadius: '10px',
                  backgroundColor: theme.palette.grey.light1,
                  color: theme.palette.grey.black,
                  fontSize: '22px',
                  '&:focus': {
                    outline: `1px solid ${theme.palette.primary.main}`,
                  },
                }}
              >
                <img src={FileIcon} width="35px" alt="File Icon" />
                {file.name}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 15,
            }}
          >
            <Buttons
              variant="song"
              sx={{ paddingX: '30px', paddingY: '10px', fontSize: 25 }}
              onClick={() => closeModal()}
            >
              Continue
            </Buttons>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
