import { Box, Modal, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import Buttons from '../../components/Button';
import RefineSlider from '../../components/MCS2/RefineSliders';
import YourCreativeBrief from '../../components/MCS2/YourCreativeBrief';
import SVGIcon from '../../components/SVGIcon';
import { MCS2Context } from '../../context/MCS2Context';
import theme from '../../styles/theme';
import BasicModal from '../BasicModal';
import CreativeCanvas from './CreativeCanvas';
import Settings from './Setting';
const charValue = {
  playful: { x: 'playful', y: 'professional' },
  expected: { x: 'expected', y: 'unexpected' },
  abstract: { x: 'abstract', y: 'concrete' },
};
export default function WorkspaceArea({
  exit,
  generateMore,
  settingsView,
  setSettingsView,
  isAsset,
  imageNotLoaded
}) {
  const { territories, territoryData, generatedImageAssets, generatedTextAssets, assets } = useContext(MCS2Context);
  const [directionText, setDirectionText] = useState('');
  const [creativeCanvas, setCreativeCanvas] = useState('');
  const [versatility, setVersatility] = useState(0.5);
  const [expectation, setExpectation] = useState(0.5);
  const [clarity, setClarity] = useState(0.5);
  const [userInput, setUserInput] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleGenerateMore = () => {
    const sliderValues = {
      playful: versatility,
      expected: expectation,
      abstract: clarity,
    };
    let sliderInputsText = calculateSliderInputs(sliderValues);
    if (isAsset) {
      generateMore(
        'iterateAsset',
        creativeCanvas,
        directionText,
        sliderValues,
        userInput,
        false,
        sliderInputsText
      );
    } else {
      const lastTerritories = territories.slice(-2);

      // generate more is always based on the first territories that were generated by the experience setup, which are the last two territories in the array
     
      generateMore(
        'iterateTerritory',
        lastTerritories[0].id,
        lastTerritories[1].id,
        directionText,
        sliderValues,
        userInput,
        false,
        sliderInputsText
      );
    }
  };

  const handleSettingsView = () => {
    setSettingsView(!settingsView);
  };
  const calculateSliderInputs = (sliderValues) => {
    let sliderString = '';
    _.forEach(sliderValues, function (value, key) {
      let x = charValue[key].x;
      let y = charValue[key].y;

      if (value === 0.5) {
        sliderString = sliderString + `equal parts ${x} and ${y}, `;
      } else if (value === 0) {
        sliderString = sliderString + `not at all ${y}, `;
      } else if (value <= 0.25) {
        sliderString = sliderString + `way more ${x} than ${y}, `;
      } else if (value > 0.25 && value < 0.5) {
        sliderString = sliderString + `slightly more ${x} than ${y}, `;
      } else if (value === 1) {
        sliderString = sliderString + `not at all ${x}, `;
      } else if (value >= 0.75) {
        sliderString = sliderString + `way more ${y} than ${x}, `;
      } else if (value < 0.75 && value > 0.5) {
        sliderString = sliderString + `slightly more ${y} than ${x}, `;
      }
      if (key == 'expected') {
        sliderString = sliderString + 'and ';
      }
    });
    sliderString = sliderString.substring(0, sliderString.length - 2) + '.'; //sentence formation
    sliderString = sliderString.charAt(0).toUpperCase() + sliderString.slice(1); //make first letter uppercase
    return sliderString;
  };

  return (
    <Box
      className="tabContent_workspace"
      sx={{
        backgroundColor: theme.palette.grey.white,
        width: settingsView ? '708px' : '382px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '2rem 0.5rem 2rem 1rem',
        alignItems: 'flex-start',
        paddingTop: '6rem',
        transition: '0.6s ease-in-out',
        overflowY: 'auto',
      }}
    >
      {!settingsView ? (
        <Box
          className="workspace_content_container"
          sx={{
            flexGrow: 1,
            height: '100%',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Box
            className="workspace_scrolling"
            sx={{
              flexGrow: 1,
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <YourCreativeBrief
                bigIdea={isAsset ? assets : territoryData.bigIdea}
                fileCount={territoryData.files?.length || 0}
                editCreativeResult={handleSettingsView}
                isAsset={isAsset}
              />

              <hr
                style={{
                  width: '100%',
                  border: `0.3px thin ${theme.palette.grey.light3}`,
                  margin: '2rem 0',
                }}
              />

              {isAsset && (
                <>
                  <CreativeCanvas
                    oldCreativeCanvas={assets.creativeCanvas}
                    handleCreateCanvasChange={setCreativeCanvas}
                  />
                  <hr
                    style={{
                      width: '100%',
                      border: `0.3px thin ${theme.palette.grey.light3}`,
                      margin: '2rem 0',
                    }}
                  />
                </>
              )}
              <RefineSlider
                directionText={directionText}
                setDirectionText={setDirectionText}
                versatility={versatility}
                setVersatility={setVersatility}
                clarity={clarity}
                setClarity={setClarity}
                expectation={expectation}
                setExpectation={setExpectation}
              />
              <hr
                style={{
                  width: '100%',
                  border: `0.3px thin ${theme.palette.grey.light3}`,
                  marginTop: '1.5rem',
                }}
              />
            </Box>
          </Box>
          {
            Object.values(userInput).map(x => x !== '').filter(y => y && y).length ? (
              <Box>
                <Typography
                  variant="body5"
                  gutterBottom
                  sx={{
                    color: theme.palette.grey.main,
                  }}
                >
                  You have changes in the advanced settings, please click on 'Generate More' to view the results.
                </Typography>
              </Box>
            ) : ''
          }
          <Box
            className="workspace_button_container"
            gap={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              background: theme.palette.grey.white,
              padding: '25px 0 0',
            }}
          >
            <Buttons
              variant="song"
              color="primary"
              label="Generate More"
              sx={{ width: '100%', padding: '5px 12px', fontSize: '16px' }}
              onClick={handleGenerateMore}
              disabled={imageNotLoaded}
            />

            <Buttons
              variant="song"
              color="secondary"
              label="Exit and Start Over"
              sx={{ width: '100%', padding: '5px 12px', fontSize: '16px' }}
              onClick={handleOpen}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'top',
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography
              variant="body4"
              sx={{ color: theme.palette.grey.main, fontSize: '16px' }}
            >
              Workspace
            </Typography>

            <SVGIcon name="angle-bracket" color={theme.palette.grey.main} />
            <Typography
              variant="body4"
              sx={{
                color: settingsView
                  ? theme.palette.primary.main
                  : theme.palette.grey.main,
                fontSize: '16px',
              }}
            >
              Settings
            </Typography>
          </Box>

          <Settings bigIdea={isAsset ? assets.creativeIdea : territoryData.bigIdea} isAsset setUserInput={setUserInput} />

          <Box
            gap={4}
            sx={{
              marginTop: '48px',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 3,
              paddingBottom: '48px',
              marginBottom: '48px',
              flexShrink: 0,
            }}
          >
            <Buttons
              variant="song"
              color="secondary"
              label="Cancel"
              sx={{ padding: '5px 12px', fontSize: '16px' }}
              onClick={handleSettingsView}
            />

            <Buttons
              variant="song"
              color="primary"
              label="Update"
              sx={{ padding: '5px 12px', fontSize: '16px' }}
              onClick={handleSettingsView}
            />
          </Box>
        </Box >
      )
      }
      <Modal open={open} onClose={handleClose}>
        <BasicModal
          title={'Are you sure you want to exit?'}
          description={'Only content in your Portfolio will be saved.'}
          buttonComponents={
            <Box sx={{ float: 'right' }}>
              <Buttons
                variant="song"
                color="secondary"
                sx={{ paddingX: 8, marginX: 6 }}
                onClick={handleClose}
              >
                Cancel
              </Buttons>
              <Buttons
                variant="song"
                sx={{ paddingX: 8, marginX: 6 }}
                onClick={exit}
              >
                Yes, Exit Project
              </Buttons>
            </Box>
          }
        />
      </Modal>
    </Box >
  );
}
