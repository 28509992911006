import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

/**
 * A spinner component that is used as a placeholder for other components until they are done loading
 * @param {object} props - props for component
 */
const Spinner = (props) => {
  const getLoader = () => {
    switch (props.loaderType) {
      default:
        return (
          <ClipLoader
            size={props.loadersize}
            color={props.loaderColor}
            cssOverride={props.loaderCss}
            speedMultiplier={props.loaderSpeed}
          />
        );
    }
  };

  return <>{getLoader()}</>;
};

Spinner.defaultProps = {
  color: '#787878',
  speedMultiplier: 2,
};

Spinner.propTypes = {
  // type of this spinner see react-spinners docs
  loaderType: PropTypes.oneOf(['clip', 'barloader']),

  // e.g. #000
  color: PropTypes.string,

  // e.g. 12px
  size: PropTypes.string,

  // used to create inline styles on the loaders. Any html css property is valid here (e,g. {color: "red"})
  cssOverride: PropTypes.object,
};

export default Spinner;
