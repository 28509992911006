import React from 'react';

function CloseX({ color, width, height }) {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8984 9.60469L21.2687 0.845371C23.7381 -1.7388 27.4583 2.1878 24.9889 4.77196L16.6506 13.4977L24.9889 22.257C27.4583 24.8412 23.7381 28.7342 21.2687 26.1501L12.8984 17.4243L4.56006 26.1501C2.09065 28.7342 -1.66158 24.8412 0.807832 22.257L9.17819 13.4977L0.807832 4.77196C-1.66158 2.1878 2.09065 -1.7388 4.56006 0.845371L12.8984 9.60469Z"
        fill="#0573FF"
      />
    </svg>
  );
}

export default CloseX;
