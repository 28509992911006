import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CNSContext, CNSDispatchContext } from '../../context/CNSContext';
import { CNS_ACTION_TYPES } from '../../reducers/cnsActionTypes';
import theme from '../../styles/theme';
import { CNSQuesList } from '../../types/applicationConstants';
import ChatBubble from '../ChatBubble';
import ChatInput from '../ChatInput';
import CNSFileUpload from './CNSFileUpload';

const CNSChat = ({ setChatLog, setFileLog }) => {
  const divRef = useRef(null);
  const state = useContext(CNSContext);
  const dispatch = useContext(CNSDispatchContext);
  const sessionId = state.sessionID.split('/')[0];
  const [chatHistory, setChatHistory] = useState([
    {
      user: false,
      text: 'Let’s get an idea of what you need. Select all the boxes that interest you.',
      isOption: false,
    },
  ]);
  const [counter, setCounter] = useState(0);
  const [sendChatToApi, setSendChatToApi] = useState([]);
  const [showOptions, setShowOptions] = useState(true);
  const [activeIdeas, setActiveIdeas] = useState([]);

  const handleSelection = (idea) => {
    let tempArray = [...activeIdeas];
    if (idea) {
      tempArray.indexOf(idea) === -1
        ? tempArray.push(idea)
        : tempArray.splice(tempArray.indexOf(idea), 1);
      setActiveIdeas(tempArray);
    }
  };

  const getText = (text) => {
    let textArr = ['summary'];
    text.forEach((each) => {
      textArr.push(each.split(' ')[0]?.toLowerCase());
    });
    return textArr;
  };

  const addUserInputToChatHistory = (text, type) => {
    let count = counter;
    let tempChatHistory = [...chatHistory];
    let tempSendChatToApi = [...sendChatToApi];
    if (counter < 4 && CNSQuesList[count] !== '') {
      tempSendChatToApi.push({
        question: CNSQuesList[count],
        answer: text,
        sessionId: sessionId,
      });
      if (type === 'option') {
        const option = getText(text);
        tempChatHistory.push({
          user: true,
          text,
          option,
          isOption: true,
        });
      } else {
        tempChatHistory.push({
          user: true,
          text,
          isOption: false,
        });
      }
      count = count + 1;
      dispatch({
        type: CNS_ACTION_TYPES.SET_STEP,
        payload: count,
      });
      setCounter(count);
      tempChatHistory.push({
        user: false,
        text: CNSQuesList[count],
        isOption: false,
      });
    } else {
      tempChatHistory.push({
        user: true,
        text,
        isOption: false,
      });
    }
    setChatHistory(tempChatHistory);
    setChatLog(tempChatHistory);
    setSendChatToApi(tempSendChatToApi);
  };

  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const renderChatHistory = () => {
    return chatHistory.map((el, index) => {
      return el.user ? (
        <Box
          key={el.text}
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'right',
            width: '100%',
            paddingX: 3.5,
          }}
        >
          <ChatBubble
            key={`${index}-${el.text}`}
            isUser={el.user}
            text={el.text}
            isOption={el.isOption}
          />
        </Box>
      ) : (
        <ChatBubble
          key={`${index}-${el.text}`}
          isUser={el.user}
          text={el.text}
          isOption={el.isOption}
        />
      );
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      borderRadius="8px"
      boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
      backgroundColor={theme.palette.grey.white}
      width="1440px"
      height='750px'
      sx={{
        overflowX: 'hidden',
        overflowY: 'auto',
        width: '80%',
        padding: '32px 24px 24px 24px',
        position: 'relative',
        bottom: 0,
      }}
    >
      <Box>
        <Typography variant="h1" color={theme.palette.blue.link1} gutterBottom>
          Let's get started!
        </Typography>
      </Box>
      <Box display="flex" sx={{height: '624px',}}>
        <Box
          className="CNSChat_space"
          sx={{
            borderRadius: '8px',
            border: '1px solid',
            borderColor: theme.palette.grey.light2,
            padding: '15px',
            width: '65%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            sx={{
              height: '100%',
              overflowY: 'auto',
              scrollBehavior: 'smooth',
              padding: '1px',
            }}
            container
            className="CNSChat_container"
            ref={divRef}
          >
            <Box
              sx={{
                width: '100%',
                maxHeight: '68vh',
              }}
            >
              {renderChatHistory()}
            </Box>
          </Grid>
          <Box sx={{ width: '100%', paddingTop: '24px' }}>
            <ChatInput
              onClick={addUserInputToChatHistory}
              onEnterPress={addUserInputToChatHistory}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              ideaArray={['Insight & Trends', 'Mindsets']}
              handleSelection={handleSelection}
              activeIdeas={activeIdeas}
            />
          </Box>
        </Box>
        <Box
          sx={{
            padding: '0 15px 15px',
            width: '35%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '10px',
          }}
        >
          <CNSFileUpload setFileLog={setFileLog} />
        </Box>
      </Box>
    </Box>
  );
};

export default CNSChat;
