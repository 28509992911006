import React from 'react';

import {
    Box,
    Typography
} from "@mui/material";
 

const BasicModal = ({title, description, buttonComponents}) => {

    return (
        <Box sx={{
            paddingX:10, 
            paddingY:10,
            width:'40%',
            borderRadius: 3,
            position:'absolute', 
            top:'50%', 
            left:'50%', 
            transform:'translate(-50%, -50%)',
            bgcolor: '#fff'
        }}>
            <Typography variant='h3' gutterBottom sx={{paddingBottom:3}}>{title}</Typography>
            <Typography paragraph gutterBottom sx={{paddingBottom:6}}>{description}</Typography>
            {buttonComponents}
        </Box>
    )
}

export default BasicModal;