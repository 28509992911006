import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { CNSContext } from '../../context/CNSContext';
import theme from '../../styles/theme';
import CollapseCard from '../CollapseCard';
import LoadingPage from '../LoadingPage';
import CNSSummary from './CNSSummary';

const CNSAnalysisSummary = ({
  setCheckedSummary,
  showExportOptions,
  isLoading,
}) => {
  const state = useContext(CNSContext);
  const [summaryData, setSummaryData] = React.useState([]);
  let initialValues = [];

  useEffect(() => {
    initialValues = [];
    const response = state.analysisSummary;
    Object.keys(response).map((item) => {
      if (state.chatHistory[1].option.includes(item)) {
        const childNode = [];
        if (item !== 'summary') {
          Object.keys(response[item]).forEach((key) => {
            childNode.push({
              label: getLabel(key),
              name: getCamelCaseString(key.replace(/_/gi, ' ')),
              checked: false,
              parent: item,
              content: response[item][key],
            });
          });
        }
        initialValues.push({
          label: getLabel(item),
          name: item,
          checked: false,
          content: item === 'summary' ? response[item]['summary'] : '',
          childrenNodes: childNode,
        });
      }
    });
    if (initialValues.length) {
      setSummaryData(initialValues);
    }
  }, [state.analysisSummary]);
  const handleChangeForCheckbox = (event) => {
    const { name, checked } = event.target;
    const tempSummaryChecked = [...summaryData];
    tempSummaryChecked.forEach((item) => {
      if (item.name === name) {
        getUpdatedCheckedSummaryData(item, checked);
      } else if (item.childrenNodes.length > 0) {
        item.childrenNodes?.map((child) => {
          if (child.name == name) {
            child.checked = checked;
          }
        });
        item.checked = item.childrenNodes?.every((child) => {
          return child.checked === true;
        });
      }
    });
    setSummaryData(tempSummaryChecked);
  };

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    const tempSelectAll = [...summaryData];
    tempSelectAll.forEach((item) => {
      getUpdatedCheckedSummaryData(item, checked);
    });
    setSummaryData(tempSelectAll);
  };

  const getUpdatedCheckedSummaryData = (item, checked) => {
    if (item.childrenNodes.length > 0) {
      item.childrenNodes?.forEach((child) => {
        child.checked = checked;
      });
      item.checked = item.childrenNodes?.every((child) => {
        return child.checked == true;
      });
    } else {
      item.checked = checked;
    }
  };

  const getInterminateStatus = (name) => {
    if (name) {
      let index = summaryData.findIndex((e) => e.name === name);
      return (
        summaryData[index].childrenNodes.some((child) => !child.checked) &&
        summaryData[index].childrenNodes.some((child) => child.checked)
      );
    } else {
      let count = 0;
      if (
        summaryData.some((child) => !child.checked) &&
        summaryData.some((child) => child.checked)
      ) {
        count++;
      }
      summaryData.map((summary) => {
        if (
          summary.childrenNodes.some((child) => !child.checked) &&
          summary.childrenNodes.some((child) => child.checked)
        ) {
          count++;
        }
      });
      return count > 0 ? true : false;
    }
  };

  const getCamelCaseString = (str) => {
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  };

  const getLabel = (word) => {
    return word
      .replace(/_/gi, ' ')
      .split(' ')
      .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ');
  };

  const filterCheckedNodes = () => {
    const newNodes = [...summaryData];
    const filterNodes = newNodes
      .map((element) => {
        return {
          ...element,
          childrenNodes: element.childrenNodes.filter(
            (subElement) => subElement.checked === true
          ),
        };
      })
      .filter((filterElement) => {
        return (
          filterElement.childrenNodes.length > 0 ||
          filterElement.checked == true
        );
      });
    return filterNodes;
  };

  useEffect(() => {
    const tempShowExportOptions = !showExportOptions;
    if (tempShowExportOptions && summaryData.length > 0) {
      const tempNode = [...summaryData];
      tempNode.forEach(function iter(a) {
        a.checked = false;
        Array.isArray(a.childrenNodes) && a.childrenNodes.forEach(iter);
      });
      setSummaryData(tempNode);
    }
  }, [showExportOptions]);

  useEffect(() => {
    if (summaryData?.length > 0) {
      setCheckedSummary(filterCheckedNodes);
    }
  }, [summaryData]);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.blue,
        padding: '48px 60px',
        flexBasis: '33.33%',
        width: '100%',
        flexGrow: 0,
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: '100',
        boxSizing: ' border-box',
      }}
    >
      {showExportOptions && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {' '}
          <FormControlLabel
            sx={{
              marginRight: '5px',
            }}
            control={
              <Checkbox
                checked={summaryData.every((e) => e.checked === true)}
                onChange={handleSelectAllChange}
                indeterminate={getInterminateStatus('')}
                inputProps={{ 'aria-label': 'Select All' }}
                sx={{ marginRight: 3 }}
              />
            }
          />
          <Typography
            fontSize={16}
            fontWeight={500}
            sx={{
              alignItems: 'start',
              color: theme.palette.primary.main,
            }}
          >
            Select All
          </Typography>
        </Box>
      )}
      {summaryData?.map((data) => {
        return data?.name === 'summary' ? (
          <CNSSummary
            handleChangeForCheckbox={handleChangeForCheckbox}
            showExportOptions={showExportOptions}
            summary={data}
          ></CNSSummary>
        ) : (
          <Box>
            <Box
              sx={{
                display: 'flex',
                marginTop: '30px',
                marginBottom: '30px',
              }}
              key={`parent_${data.name}`}
            >
              {showExportOptions && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.checked}
                      onChange={handleChangeForCheckbox}
                      name={data?.name}
                      indeterminate={getInterminateStatus(data?.name)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                />
              )}
              <Typography
                variant="h3"
                fontSize={24}
                fontWeight={600}
                lineHeight={'45px'}
              >
                {data?.label}
              </Typography>
            </Box>
            {data?.childrenNodes?.map((each, id) => {
              return (
                <Box key={`child_${each.name}`}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      position: 'relative',
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        bottom: 0,
                        width: showExportOptions ? '90%' : '95%',
                        left: showExportOptions ? '7% ' : '2.5%',
                        borderBottom:
                          each.name === 'openQuestions' ||
                          each.name === 'pointsToExplore'
                            ? '1px solid'
                            : 0,
                        borderColor: theme.palette.grey.main,
                      },
                      marginTop:
                        each.name === 'openQuestions' ||
                        each.name === 'exploratoryQuestions'
                          ? '20px'
                          : 0,
                    }}
                  >
                    {showExportOptions && (
                      <FormControlLabel
                        sx={{ alignItems: 'start' }}
                        control={
                          <Checkbox
                            checked={each.checked}
                            onChange={handleChangeForCheckbox}
                            name={each.name}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                      />
                    )}
                    <CollapseCard header={each.label} content={each.content} />
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default CNSAnalysisSummary;
