import { Grid, Typography } from '@mui/material';
import React from 'react';
import UseCaseCard from './UseCaseCard';


export default function HomeUseCaseGrid(props) {
  const imgSrc = [
    'assets/image/Mask2.png',
    'assets/image/Mask3.png',
    'assets/image/Mask4.png',
  ]
  const appTitle = [
    'Interact',
    'Influence',
    'Insight',
  ];
  const appDescription = [

    'Explore ideas to broaden your brainstorming of new campaigns, products, events, and more.',
    'Generate territories and first drafts of digital assets to super-power your creative output',
    'Interact with machine-powered personas to uncover sentiments and test research protocols',
  ];
  const routes = ['GCE', 'MCS', 'research'];

  const renderCards = () => {
    const cards = appTitle.map((title, idx) => {
      return (
        <Grid key={`${title}_${idx}`} item >
          <UseCaseCard route={routes[idx]}
            imgSrc={imgSrc[idx]} // Corrected line
            width='300px' // Corrected line
            height='100%' // Corrected line
            appTitle={appTitle[idx]}
            appDescription={appDescription[idx]}
            linkLabel={'Text Link'}
          />
        </Grid>
      )
    })
    return cards
  }

  return (
    <>
      <Typography variant='h2' sx={{ color: '#FFF', padding: '3% 0 2% 4%', textAlign: 'center' }}>The 3 Step Process</Typography>

      <Grid container spacing={3} id="tools_container"
        sx={{
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {renderCards()}
      </Grid>
    </>
  );
}
