import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '../../styles/theme';
import SVGIcon from '../SVGIcon';

const ExportPortfolio = ({ savedPortfolio, onSelectedTerritoriesChange }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTerritoriesExport, setSelectedTerritories] = useState([]);

  const territoriesHeader = savedPortfolio.map((portfolio) => portfolio.header);

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedTerritories(isChecked ? territoriesHeader : []);
  };

  const handleTerritoryChange = (event, territory) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedTerritories([...selectedTerritoriesExport, territory]);
    } else {
      setSelectedTerritories(
        selectedTerritoriesExport.filter((item) => item !== territory)
      );
    }
  };

  useEffect(() => {
    onSelectedTerritoriesChange(selectedTerritoriesExport);
  }, [selectedTerritoriesExport, onSelectedTerritoriesChange]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 2,
            marginBottom: 3,
          }}
        >
          <Typography
            variant="body4"
            sx={{ color: theme.palette.grey.main, fontSize: '16px' }}
          >
            Portfolio
          </Typography>

          <SVGIcon name="angle-bracket" color={theme.palette.grey.main} />
          <Typography
            variant="body4"
            sx={{
              color: theme.palette.primary.main,
              fontSize: '16px',
            }}
          >
            Export Portfolio
          </Typography>
        </Box>

        <Box>
          <FormGroup>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '18px',
                  color: theme.palette.primary.main,
                  fontWeight: 800,
                  fontFamily: 'GraphikSemiBold',
                },
              }}
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '25px',
                    },
                  }}
                />
              }
              label="Select All Content"
            />

            <Typography
              marginTop={4}
              color={theme.palette.grey.black}
              fontSize={20}
              variant="h2"
            >
              Territories
            </Typography>
            {territoriesHeader.map((territory, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedTerritoriesExport.includes(territory)}
                    onChange={(event) =>
                      handleTerritoryChange(event, territory)
                    }
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '25px',
                      },
                    }}
                  />
                }
                label={<Typography fontSize="18px">{territory}</Typography>}
              />
            ))}
          </FormGroup>
        </Box>
      </Box>
      <hr
        style={{
          border: `0.3px thin ${theme.palette.grey.main}`,
          marginTop: '20px',
        }}
      />
    </>
  );
};

export default ExportPortfolio;
