import { Box } from '@mui/material';
import React from 'react';
import ChatLog from './ChatLog';

export default function ChatContent({
  content,
  handleBookmarks,
  handleDelete
}) {
  return (
    <Box className="chat_content">
      {content.map((el, idx) => {
        return (
          <Box
            className="ra_chat_bot_card"
            sx={{ ml: 6 }}
            key={`${idx}_${content.length.toString()}_chat`}
          >
            {el.user ? (
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'right',
                  width: '100%',
                  paddingX: 3.5
                }}
              >
                <ChatLog
                  isUser={el.user}
                  text={el.text}
                  questionNumber={el.questionNumber}
                  onDelete={() => handleDelete(el.questionNumber)}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'block',
                  gridColumn: 1 / 3,
                  flex: 1,
                  justifyContent: 'left',
                  width: '100%',
                  paddingX: 3.5
                }}
              >
                <ChatLog
                  isUser={el.user}
                  participantName={el.participantName}
                  participantTemperature={el.participantTemperature}
                  text={el.text}
                  avatarURL={el.avatarURL}
                  color={el.color}
                  bookmarksIcon={el.bookmarked}
                  handleBookmarks={handleBookmarks}
                  source={el.source}
                  questionNumber={el.questionNumber}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
