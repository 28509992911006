import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import theme from '../../styles/theme';
import { ImageBox } from '../ImageBox';

const LinkedInMock = ({ imgDesc, imgSrc }) => {
  return (
    <Box
      sx={{
        width: 300,
        height: '100%',
        border: `0.5px solid ${theme.palette.grey.light}`,
        backgroundColor: theme.palette.primary.white,
      }}
    >
      <Box>
        <img
          src="/assets/images/LinkedInMock1.png"
          alt=""
          style={{ width: '100%' }}
        />
      </Box>
      <Box
        sx={{
          padding: '10px',
        }}
      >
        {imgDesc ? (
          <>
            <Typography fontSize={14} lineHeight={1.2}>
              {imgDesc.length > 150
                ? imgDesc.substring(0, 150) + '...'
                : imgDesc}
            </Typography>
          </>
        ) : (
          ''
        )}
      </Box>
      <Box>
        <ImageBox
          src={imgSrc}
          styles={{
            width: '100%',
            height: '150px',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box>
        <img
          src="/assets/images/LinkedInMock2.png"
          alt=""
          style={{ width: '100%' }}
        />
      </Box>
    </Box>
  );
};

export default LinkedInMock;
