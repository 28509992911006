import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const ImageBox = ({ src, alt, width, height, onClick, styles }) => {
  const [imgSrc, setImgSrc] = useState('');
  const [fallback, setFallback] = useState(false);

  useEffect(() => {
    if (src) {
      setImgSrc(src);
    }
  }, [src]);

  const reloadSrc = (e) => {
    if (fallback) {
      setTimeout(() => setImgSrc(`${imgSrc}&`), 1500);
    } else {
      e.target.src = imgSrc;
      setFallback(true);
    }
  };

  return (
    <Box
      sx={{
        height: 'auto',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={imgSrc}
        alt={alt}
        width={width}
        height={height}
        onClick={onClick}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          ...styles,
        }}
        onError={reloadSrc}
      />
    </Box>
  );
};

ImageBox.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
};
