import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Buttons from '../Button.jsx';
import CloseButton from '../CloseButton.jsx';

const style = {
  position: 'absolute',
  top: '28%',
  left: '43%',
  transform: 'translate(-50%, -50%)',
  width: '780px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '48px',
  borderRadius: '20px',
  scale: '0.7',
};

const container_style = {
  align: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '0px',
  background: 'white',
};

const container_header = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '25px',
  background: 'white',
  lineHeight: '30px',
};

function ResetModal({ onClick }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Buttons
        variant="song"
        color="secondary"
        sx={{ paddingX: '25px' }}
        onClick={handleOpen}
      >
        Reset Interview
      </Buttons>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CloseButton
              btnAction={handleClose}
              styles={{
                position: 'absolute',
                top: '20px',
                right: '10px',
              }}
            />
            <div style={container_style}>
              <div style={container_header}>
                <Typography
                  variant="h4"
                  gutterBottom
                  fontSize={24}
                  fontWeight={600}
                >
                  Reset Interview?
                </Typography>
              </div>
              <Typography
                sx={{ bgcolor: 'white' }}
                id="transition-modal-description"
                whiteSpace="pre-wrap"
                fontSize={16}
              >
                {
                  'Resetting the interview will erase all questions and responses generated so far.\nParticipants created will not be erased.\n\n Would you like to continue?'
                }
              </Typography>
            </div>
            <Grid
              container
              sx={{ mt: 4 }}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Buttons
                  sx={{ marginRight: '25px' }}
                  id={'cancel-reset-modal'}
                  onClick={handleClose}
                  variant={'song'}
                  color={'secondary'}
                  children={'Cancel'}
                />
              </Grid>
              <Grid item>
                <Buttons
                  id={'genai-reset-interview-btn'}
                  href={'/components'}
                  variant={'song'}
                  color={'primary'}
                  label={'Reset Interview'}
                  type={'submit'}
                  onClick={() => {
                    onClick();
                    handleClose();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

ResetModal.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ResetModal;
