import { Box } from '@mui/material';
import React from 'react';
import FacebookMock from '../components/MCS2/FacebookMock';
import InstagramMock from '../components/MCS2/InstagramMock';
import LinkedInMock from '../components/MCS2/LinkedInMock';
import { TwitterMock } from '../components/MCS2/TwitterMock';
import YoutubeMock from '../components/MCS2/YoutubeMock';

const MCSV2Components = () => {
  const imgDesc =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque fermentum dui faucibus in ornare quam viverra orci. Sit amet cursus sit amet dictum sit amet justo donec. Enim facilisis gravida neque convallis a cras semper auctor neque. Neque aliquam vestibulum morbi blandit. Elit sed vulputate mi sit amet mauris commodo quis imperdiet. Faucibus pulvinar elementum integer enim neque volutpat ac. Pretium quam vulputate dignissim suspendisse in est ante in. A diam maecenas sed enim ut. Elementum tempus egestas sed sed risus. Nec feugiat nisl pretium fusce id velit ut tortor pretium. Nunc scelerisque viverra mauris in aliquam sem fringilla. Nunc sed velit dignissim sodales. Vulputate ut pharetra sit amet aliquam id diam maecenas. Ultricies mi quis hendrerit dolor magna eget. Suspendisse in est ante in.';

  const imgSrc = '/assets/images/test-image.png';

  return (
    <Box sx={{ backgroundClip: 'black', padding: 50 }}>
      <InstagramMock imgDesc={imgDesc} imgSrc={imgSrc} />
      <LinkedInMock imgDesc={imgDesc} imgSrc={imgSrc} />
      <FacebookMock imgDesc={imgDesc} imgSrc={imgSrc} />
      <YoutubeMock imgDesc={imgDesc} imgSrc={imgSrc} />
      <TwitterMock imgDesc={imgDesc} imgSrc={imgSrc} />
    </Box>
  );
};

export default MCSV2Components;
