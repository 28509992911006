import { Box, CardContent, InputBase, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Buttons from '../../components/Button';
import { MCS2Context } from '../../context/MCS2Context';
import theme from '../../styles/theme';
import useFileUpload from '../../util/useFileUpload';
import { UploadCard } from './DefaultUploadCard';
import ExpandCard from './ExpandCard';
import UploadModal from './UploadModal';
const upload = 'assets/images/upload.svg';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    border: '1px solid ' + theme.palette.grey.main,
    fontSize: 16,
    padding: '18px 12px',
    background: '#FFF',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  width: '100%',
}));
const customFileInputStyle = {
  display: 'none',
};
const footerLayout = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
  marginTop: '48px',
};

const Setting = ({ bigIdea, isAsset, setUserInput }) => {
  const { territoryData, userId, assets } = useContext(MCS2Context);
  const [bigIdeaContent, setBigIdea] = useState(bigIdea ?? '');
  const [imagesIdea, setImagesIdea] = useState(assets.imagery ?? '');
  const [colorsIdea, setColorsIdea] = useState(assets.colors ?? '');
  const [toneOfVoice, setToneOfVoice] = useState(assets.tone ?? '');
  const [open, setOpen] = useState(false);

  const fileInput = useRef();

  const { uploadedFiles, currentProgress, handleFilesUpload } =
    useFileUpload(userId);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    // addFiles(uploadedFiles);
    // addFileKeys(fileKeys);
  };

  useEffect(() => {
    setUserInput({
      imagery: imagesIdea ?? '',
      colors: colorsIdea ?? '',
      tone: toneOfVoice ?? '',
    });
  }, [imagesIdea, colorsIdea, toneOfVoice]);

  const cardContent = (
    <BootstrapInput
      multiline
      maxRows={3}
      minRows={3}
      id="input-label-description"
      value={bigIdeaContent}
      onChange={(event) => setBigIdea(event.target.value)}
      style={{
        '::placeholder': {
          color: theme.palette.grey.main,
          fontFamily: 'GraphikRegular',
          fontSize: '20px',
          lineHeight: '28px',
        },
      }}
    />
  );

  const uploadContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '0px',
        border: `1px dashed ${theme.palette.primary.main}`,
        borderRadius: '8px',
      }}
    >
      <input
        id="file-input"
        type="file"
        onChange={handleFilesUpload}
        ref={fileInput}
        style={customFileInputStyle}
        accept="image/png, image/jpeg,image/jpg"
      />
      <Buttons
        sx={{
          height: '130px',
          width: '100%',
          paddingTop: 10,
        }}
        component="label"
        onClick={() => {
          openModal();
        }}
        label={
          <Box>
            <Box>
              <img src={upload} height={18} />
            </Box>
            <Typography
              color={theme.palette.grey.black}
              sx={{
                display: 'inline-block',
                paddingRight: 1,
                fontSize: 15,
              }}
              inline
            >
              Drag and Drop or
            </Typography>
            <Typography
              inline
              sx={{
                display: 'inline-block',
                textDecoration: 'underline',
                fontSize: 15,
              }}
            >
              Select a File to Upload
            </Typography>
          </Box>
        }
      ></Buttons>
    </Box>
  );

  const settingsCardContent = (type, text) => {
    return (
      <BootstrapInput
        multiline
        maxRows={3}
        minRows={3}
        id="input-label-description"
        onChange={
          type === 'image'
            ? (event) => setImagesIdea(event.target.value)
            : type === 'color'
            ? (event) => setColorsIdea(event.target.value)
            : type === 'tone'
            ? (event) => setToneOfVoice(event.target.value)
            : ''
        }
        placeholder={text}
        style={{
          '::placeholder': {
            color: theme.palette.grey.main,
            fontFamily: 'GraphikRegular',
            fontSize: 16,
            lineHeight: '28px',
          },
        }}
      />
    );
  };

  return (
    <>
      <Box sx={{ marginTop: '32px' }}></Box>
      <>
        <Typography color={theme.palette.grey.black} fontSize={16} variant="h2">
          Creative Brief
        </Typography>
        <ExpandCard title="Big Idea" titleFontSize={15}>
          <CardContent>{cardContent}</CardContent>
        </ExpandCard>
        <hr
          style={{
            border: `0.3px thin ${theme.palette.grey.light2}`,
          }}
        />
        <ExpandCard title="Reference Materials" titleFontSize={15}>
          <CardContent>{uploadContent}</CardContent>
          <UploadModal
            open={open}
            closeModal={closeModal}
            handleFilesUpload={(event) => {
              handleFilesUpload(event);
            }}
            uploaded={uploadedFiles}
          />

          {uploadedFiles.length > 0 && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ marginBottom: 5, marginTop: 5 }}
              >
                Materials Uploaded
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 5,
                  flexWrap: 'wrap',
                }}
              >
                {uploadedFiles.map((file, index) => {
                  const fileExtension = file.name;
                  const fileType =
                    fileExtension === 'jpg' || fileExtension === 'png'
                      ? 'image'
                      : 'other';
                  return (
                    <UploadCard
                      key={`UploadCard`}
                      fileType={fileType}
                      fileName={file.name}
                      fileSize={(file.size / 1024).toFixed(2) + ' KB'}
                      percent={currentProgress[index]}
                      isSmall={true}
                    />
                  );
                })}
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            {territoryData?.files?.map((file, index) => {
              const fileExtension = file.name;
              const fileType =
                fileExtension === 'jpg' || fileExtension === 'png'
                  ? 'image'
                  : 'other';
              return (
                <UploadCard
                  key={`UploadCard`}
                  fileType={fileType}
                  fileName={file.name}
                  fileSize={(file.size / 1024).toFixed(2) + ' KB'}
                  isSmall={true}
                />
              );
            })}
          </Box>
        </ExpandCard>

        <hr
          style={{
            border: `0.3px thin ${theme.palette.grey.main}`,
          }}
        />
      </>
      <Typography color={theme.palette.grey.black} fontSize={16} variant="h2">
        Advanced Settings
      </Typography>
      <ExpandCard title="Imagery" titleFontSize={20}>
        <CardContent>
          {settingsCardContent(
            'image',
            'Example: Generated images of cocktails and pastries should not have any visible company names or brand logos.'
          )}
        </CardContent>
      </ExpandCard>
      <ExpandCard title="Colors" titleFontSize={20}>
        <CardContent>
          {settingsCardContent(
            'color',
            'Example: I want a range of color palettes but they must all include iterations of Hex #264653 and #2a9d8f'
          )}
        </CardContent>
      </ExpandCard>
      <ExpandCard title="Tone of Voice" titleFontSize={20}>
        <CardContent>
          {settingsCardContent(
            'tone',
            'Example: Messaging should be casual and welcoming but not cutesy or childish.'
          )}
        </CardContent>
      </ExpandCard>
    </>
  );
};

export default Setting;
