import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { circleBlue, rotateSVG } from '../../assets/data/animate-variants';
import theme from '../../styles/theme';
import Button from '../Button';
import SVGIconAnimate from '../SVGIconAnimate';

function RAHero({ title, caption, subCaption, images, doScroll }) {
  const [hover, setHover] = useState(false);
  const scrollRef = useRef(true);

  const handleScroll = () => {
    scrollRef.current = !doScroll ? true : false;

    if (!scrollRef.current) {
      const downElemId = document.getElementById(doScroll.downElemId);

      downElemId.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '90rem',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          backgroundColor: '#FFF',
          columnGap: '0px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '17px',
            width: '100%',
            flexWrap: 'wrap',
            padding: '80px 0px 101px 83px',
          }}
        >
          <Typography
            component={'h1'}
            variant={'displayLarge'}
            sx={{
              display: 'inline',
              lineHeight: '1',
            }}
            gutterBottom
          >
            {title}
          </Typography>

          <Typography component={'h2'} variant="body1">
            {caption}
          </Typography>
          <Typography component={'h2'} variant="body1">
            {subCaption}
          </Typography>

          <Box
            sx={{
              position: 'relative',
              zIndex: 1000,
              top: '14px',
              left: '195px',
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Typography
              variant="body3"
              sx={{
                position: 'absolute',
                top: '5px',
                left: '5px',
                color: '#0573FF',
              }}
            >
              Scroll Down
            </Typography>
            <Button
              variants={'transparent'}
              sx={{
                position: 'absolute',
                top: '30px',
                left: '0',
                paddingRight: '0',
                borderRadius: '50px',
                rotate: '180',
              }}
              onClick={() => handleScroll()}
              endIcon={
                <SVGIconAnimate
                  name={'scroll-down'}
                  sx={{ opacity: 1 }}
                  color={'none'}
                  height={'72px'}
                  strokecolor={theme.palette.blue.link1}
                  variants={!hover ? {} : doScroll.animate}
                />
              }
            />
          </Box>
        </Box>

        <Box
          sx={{
            background: 'none',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            width: '100%',
            flexWrap: 'wrap',
            position: 'relative',
          }}
        >
          <SVGIconAnimate
            name={'circle'}
            color={'#02054E'}
            width={23}
            style={{
              opacity: 1,
              position: 'absolute',
              top: '55px',
              left: '710px',
              zIndex: '200',
              flexShrink: '1',
            }}
            variants={circleBlue}
          />
          <img
            src={images[0]}
            alt="What does a typic weekdays text in image"
            style={{
              position: 'absolute',
              top: '80px',
              left: '360px',
              borderRadius: '8px',
              flexShrink: '1',
            }}
          />
          <SVGIconAnimate
            name={'avatar-up'}
            color={'#FF66C3'}
            style={{
              opacity: 1,
              position: 'absolute',
              top: '225px',
              right: '510px',
              zIndex: '200',
              flexShrink: '1',
            }}
          />
          <img
            src={images[1]}
            alt="A typical weekday for me text in image"
            style={{
              position: 'absolute',
              top: '200px',
              left: '180px',
            }}
          />
          <SVGIconAnimate
            name={'star-4points'}
            strokecolor={'#0573FF'}
            strokeWidth={1}
            color={'none'}
            style={{
              position: 'absolute',
              transform: 'rotate(' + '180deg)',
              top: '325px',
              right: '-10px',
              zIndex: '800',
            }}
            variants={rotateSVG}
          />
          <SVGIconAnimate
            name={'circle'}
            color={'#FFD600'}
            width={40}
            style={{
              opacity: 1,
              position: 'absolute',
              top: '380',
              right: '-10px',
              zIndex: '200',
            }}
            variants={circleBlue}
          />

          <SVGIconAnimate
            name={'avatar-pipe'}
            color={'#0573FF'}
            style={{
              opacity: 1,
              position: 'absolute',
              top: '500px',
              right: '510px',
              zIndex: '200',
            }}
            clipRule="evenodd"
            fillRule="evenodd"
          />
          <img
            src={images[2]}
            alt="I wake up early and get ready text in image"
            style={{
              position: 'absolute',
              top: '475px',
              left: '180px',
            }}
          />

          <SVGIconAnimate
            name={'star-4points'}
            strokecolor={'#0573FF'}
            strokeWidth={1}
            width={50}
            height={50}
            color={'none'}
            style={{
              position: 'absolute',
              transform: 'rotate(' + '180deg)',
              top: '545px',
              right: '490px',
              zIndex: '800',
            }}
            variants={rotateSVG}
          />
        </Box>
      </Box>
    </>
  );
}

RAHero.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  subCaption: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  doScroll: PropTypes.object || PropTypes.func,
};

export default RAHero;
